import { useCallback, useMemo } from 'react';
import { Gesture } from 'react-native-gesture-handler';
import ReactNativeHapticFeedback, {
  HapticFeedbackTypes,
} from 'react-native-haptic-feedback';
import {
  Extrapolation,
  interpolate,
  runOnJS,
  SharedValue,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { LayoutRectangle, useWindowDimensions } from 'react-native';
import { LinkDataTransform } from './useLinkStyles';

const SNAP_DISTANCE_UP = 80;
const SNAP_DISTANCE_DOWN = 60;

function useLinkExpandedGestures({
  containerLayout,
  onClose,
  enabled,
  expandedPosition,
}: {
  containerLayout: LayoutRectangle | null;
  onClose: () => void;
  enabled: boolean;
  expandedPosition: SharedValue<LinkDataTransform>;
}) {
  const dragOffset = useSharedValue({
    x: 0,
    y: 0,
  });
  const start = useSharedValue({
    x: 0,
    y: 0,
  });
  const dragSnappedDown = useSharedValue(0);
  const dragSnappedUp = useSharedValue(0);
  const insets = useSafeAreaInsets();
  const windowDimensions = useWindowDimensions();

  const dragHeightDiff = useMemo(() => {
    if (!containerLayout) {
      return 0;
    }

    const diff = Math.abs(
      Math.min(
        windowDimensions.height -
          containerLayout.height -
          insets.top -
          insets.bottom,
        0,
      ),
    );

    if (diff) {
      // This is the padding applied by the gestureContainer plus extra margin we want
      // for the link actions. We only want to apply the margin if there's actually a diff.
      return diff + 80 + 24;
    }

    return 0;
  }, [containerLayout, windowDimensions.height, insets.top, insets.bottom]);

  const triggerHaptics = useCallback(
    (type: HapticFeedbackTypes = 'impactLight') =>
      ReactNativeHapticFeedback.trigger(type),
    [],
  );

  const dragGesture = Gesture.Pan()
    .enabled(enabled)
    // .simultaneousWithExternalGesture(tapGesture)
    .onUpdate((e) => {
      if (!expandedPosition.value) {
        return;
      }

      // Elastic beyond the edges
      const overshootX = (Math.atan(e.translationX / 20) / Math.PI) * 4;

      // Translation with the start. This can be non-zero after a shrink of the link
      const translationY = start.value.y + e.translationY;
      const translationX = start.value.x + e.translationX;

      // We have to separate the dragOffset and the link's offset due to the scale
      let expandedY = translationY;
      let offsetY = translationY;
      let scale = expandedPosition.value.scale;

      // If the content is too big to fit on the screen
      if (dragHeightDiff) {
        const shrunkScale =
          (expandedPosition.value.expandedHeight - dragHeightDiff) /
          expandedPosition.value.expandedHeight;
        scale = interpolate(
          expandedY,
          [-dragHeightDiff, 0, 1000],
          [shrunkScale, 1, 0.9],
          Extrapolation.CLAMP,
        );

        if (translationY < 0) {
          // If we've dragged up, we start scaling, so we freeze the translation
          expandedY = expandedPosition.value.y;
        }
      }

      // If we've dragged beyond the amount we need to scale, decay the translation. This
      // also happens when the content can fit
      if (translationY < -dragHeightDiff) {
        // Calculates the delta of scroll beyond the dragHeightDiff threshold, then reduces it
        const overshootY =
          (e.translationY + start.value.y + dragHeightDiff) / 3;

        // Because of the scale, the link container is at 0 when the scale ends, so we just need
        // the overshoot
        expandedY = overshootY;
        // The drag has had dragHeightDiff amount of scroll  when the scale ends
        offsetY = -dragHeightDiff + overshootY;
      }

      dragOffset.value = {
        x: start.value.x + overshootX * 10,
        y: offsetY,
      };
      expandedPosition.value = {
        ...expandedPosition.value,
        x: overshootX * 10,
        y: expandedY,
        scale,
      };

      if (translationY > SNAP_DISTANCE_DOWN && dragSnappedDown.value === 0) {
        dragSnappedDown.value = withSpring(1);
        runOnJS(triggerHaptics)('impactLight');
      } else if (
        translationY <= SNAP_DISTANCE_DOWN &&
        dragSnappedDown.value === 1
      ) {
        dragSnappedDown.value = withSpring(0);
        runOnJS(triggerHaptics)('impactLight');
      } else if (
        translationY < -SNAP_DISTANCE_UP - dragHeightDiff &&
        dragSnappedUp.value === 0
      ) {
        dragSnappedUp.value = withSpring(1);
        runOnJS(triggerHaptics)('impactLight');
      } else if (
        translationY >= -SNAP_DISTANCE_UP - dragHeightDiff &&
        dragSnappedUp.value === 1
      ) {
        dragSnappedUp.value = withSpring(0);
        runOnJS(triggerHaptics)('impactLight');
      }

      // if (translationX > SNAP_DISTANCE_DOWN && dragSnappedDown.value === 0) {
      //   dragSnappedDown.value = withSpring(1);
      //   runOnJS(triggerHaptics)('impactLight');
      // } else if (
      //   translationX <= SNAP_DISTANCE_DOWN &&
      //   dragSnappedDown.value === 1
      // ) {
      //   dragSnappedDown.value = withSpring(0);
      //   runOnJS(triggerHaptics)('impactLight');
      // } else if (
      //   translationX < -SNAP_DISTANCE_UP - dragHeightDiff &&
      //   dragSnappedUp.value === 0
      // ) {
      //   dragSnappedUp.value = withSpring(1);
      //   runOnJS(triggerHaptics)('impactLight');
      // } else if (
      //   translationX >= -SNAP_DISTANCE_UP - dragHeightDiff &&
      //   dragSnappedUp.value === 1
      // ) {
      //   dragSnappedUp.value = withSpring(0);
      //   runOnJS(triggerHaptics)('impactLight');
      // }
    })
    .onEnd((e) => {
      // If we've dragged beyond the snap points or we flicked fast enough
      if (
        dragSnappedDown.value ||
        dragSnappedUp.value ||
        (!dragSnappedUp.value && e.velocityY > 2500)
      ) {
        runOnJS(onClose)();
        return;
      }

      const transform = {
        x: 0,
        y: 0,
        scale: 1,
      };

      const drag = {
        x: 0,
        y: 0,
      };

      const translationY = start.value.y + e.translationY;
      const isCloseEnoughToTop = translationY < -dragHeightDiff / 2;
      const isVelocityFastEnough = e.velocityY < -1000;

      // If we've dragged far enough or its fast enough, animate to the shrunken state
      if (dragHeightDiff && (isCloseEnoughToTop || isVelocityFastEnough)) {
        transform.scale =
          (expandedPosition.value.expandedHeight - dragHeightDiff) /
          expandedPosition.value.expandedHeight;
        drag.y = -dragHeightDiff;
      }

      const config = {
        mass: 1,
        damping: 20,
        stiffness: 300,
        restDisplacementThreshold: 0.0001,
        restSpeedThreshold: 0.1,
      };

      dragOffset.value = withSpring(drag, config);
      expandedPosition.value = withSpring(
        {
          ...expandedPosition.value,
          ...transform,
        },
        config,
      );
      start.value = withSpring(drag, config);
    });

  return {
    dragGesture,
    dragOffset,
    dragSnappedDown,
    dragSnappedUp,
    dragHeightDiff,
  };
}

export default useLinkExpandedGestures;
