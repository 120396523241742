import { Colors } from 'app/constants/colors';
import React from 'react';

interface Props {
  color?: Colors;
  Icon: React.ComponentType<{
    width?: number;
    height?: number;
    color?: Colors;
  }>;
  width?: number;
}

function ListItemIcon({ Icon, color, width }: Props) {
  return <Icon color={color} width={width} />;
}

export default ListItemIcon;
