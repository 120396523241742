import React, { useContext } from 'react';
import Button, { ButtonType } from '../Button';
import ContextMenu from '../ContextMenu/ContextMenu';
import { DropdownItem } from '../Dropdown';
import Person from '../Icons/Person';
import { useNavigation } from '@react-navigation/native';
import Routes from '../Navigator/ROUTES';
import { useAuthStatus } from 'app/hooks/use-auth/use-auth-status';
import { Status } from 'app/hooks/use-auth/types';
import StuffListContext from './StuffListContext';
import Cog from '../Icons/Cog';
import CheckmarkCircle from '../Icons/CheckmarkCircle';
import useHasFeature from 'app/hooks/use-has-feature';
import Pressable from '../Pressable';
import EllipsisButton from '../EllipsisButton';
import HStack from '../HStack';
import {
  SharedValue,
  interpolateColor,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { StyleSheet } from 'react-native';
import BlurView from '../BlurView';
import Text, { TextType } from '../Text';
import useColor from 'app/hooks/use-color';

const HeaderMenu = ({
  headerIsFixedAnimatedValue,
  isVisitor,
  isSelecting: providedIsSelecting,
  setIsSelecting: providedSetIsSelecting,
}: {
  headerIsFixedAnimatedValue: SharedValue<number>;
  isVisitor?: boolean;
  isSelecting?: boolean;
  setIsSelecting?: (isSelecting: boolean) => void;
}) => {
  const navigation = useNavigation();
  const authStatus = useAuthStatus();
  const { isSelecting: _isSelecting, setIsSelecting: _setIsSelecting } =
    useContext(StuffListContext);
  const hasCollectionFeature = useHasFeature('collections');
  const hasViewStatesFeature = useHasFeature('view_states');
  const headerColor = useColor('BackgroundGray');
  const fixedHeaderColor = useColor('LayeredSubtleWhite');

  const isSelecting =
    providedIsSelecting !== undefined ? providedIsSelecting : _isSelecting;
  const setIsSelecting =
    providedSetIsSelecting !== undefined
      ? providedSetIsSelecting
      : _setIsSelecting;

  const buttonStyle = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(
      headerIsFixedAnimatedValue.value,
      [0, 1],
      [headerColor, fixedHeaderColor],
    ),
  }));

  if (authStatus !== Status.USER) {
    return null;
  }

  if (!hasCollectionFeature && !hasViewStatesFeature) {
    return (
      <Button
        hitSlop={{ left: 16, top: 16, right: 16, bottom: 16 }}
        type={ButtonType.secondary}
        size="small"
        Icon={Person}
        onPress={() => navigation.navigate(Routes.SETTINGS)}
        shadow={false}
        rounded
      />
    );
  }

  if (isSelecting) {
    return (
      <BlurView amount={4} style={styles.buttonBlur}>
        <Pressable
          onPress={() => setIsSelecting(false)}
          style={[styles.select, buttonStyle]}
        >
          <Text type={TextType.footnoteEmphasized} color="LayeredGray">
            Cancel
          </Text>
        </Pressable>
      </BlurView>
    );
  }

  return (
    <HStack gap={6}>
      {hasCollectionFeature && (
        <BlurView amount={4} style={styles.buttonBlur}>
          <Pressable
            onPress={() => setIsSelecting(true)}
            style={[styles.select, buttonStyle]}
          >
            <Text type={TextType.footnoteEmphasized} color="LayeredGray">
              Select
            </Text>
          </Pressable>
        </BlurView>
      )}
      {!isVisitor && (
        <ContextMenu
          actions={[
            hasCollectionFeature ? (
              <DropdownItem
                label="Select"
                Icon={CheckmarkCircle}
                onPress={() => setIsSelecting(true)}
                key="select"
              />
            ) : null,
            <DropdownItem
              label="Settings"
              Icon={Cog}
              last
              onPress={() => navigation.navigate(Routes.SETTINGS)}
              key="remove"
            />,
          ]}
        >
          {({ open }) => (
            <EllipsisButton
              width={30}
              height={30}
              onPress={open}
              circleStyle={buttonStyle}
            />
          )}
        </ContextMenu>
      )}
    </HStack>
  );
};

const styles = StyleSheet.create({
  buttonBlur: {
    borderRadius: 15,
  },
  select: {
    paddingHorizontal: 12,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
  },
});

export default HeaderMenu;
