import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color: Colors;
}

const Globe = ({ width = 20, height = 20, color }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <Path
        d="M10 19.451C12.7255 19.451 14.951 15.4216 14.951 10.0197C14.951 4.58825 12.7354 0.558825 10 0.558825C7.26472 0.558825 5.04903 4.58825 5.04903 10.0197C5.04903 15.4216 7.27452 19.451 10 19.451ZM10 1.7451C11.9216 1.7451 13.6373 5.56864 13.6373 10.0197C13.6373 14.3922 11.9216 18.2549 10 18.2549C8.07845 18.2549 6.36276 14.3922 6.36276 10.0197C6.36276 5.56864 8.07845 1.7451 10 1.7451ZM9.36276 0.69608V19.2647H10.6471V0.69608H9.36276ZM10 13.6863C6.98041 13.6863 4.30393 14.4804 2.93138 15.7451L3.92158 16.5589C5.2157 15.5294 7.32355 14.9706 10 14.9706C12.6765 14.9706 14.7844 15.5294 16.0785 16.5589L17.0686 15.7451C15.6961 14.4804 13.0196 13.6863 10 13.6863ZM19.049 9.35297H0.950983V10.6372H19.049V9.35297ZM10 6.36276C13.0196 6.36276 15.6961 5.56864 17.0686 4.30393L16.0785 3.49021C14.7844 4.50981 12.6765 5.07844 10 5.07844C7.32355 5.07844 5.2157 4.50981 3.92158 3.49021L2.93138 4.30393C4.30393 5.56864 6.98041 6.36276 10 6.36276ZM10 20C15.4706 20 20 15.4608 20 10C20 4.52942 15.4608 0 9.99021 0C4.52942 0 0 4.52942 0 10C0 15.4608 4.53922 20 10 20ZM10 18.6667C5.26472 18.6667 1.33334 14.7353 1.33334 10C1.33334 5.26472 5.25491 1.33334 9.99021 1.33334C14.7256 1.33334 18.6667 5.26472 18.6667 10C18.6667 14.7353 14.7353 18.6667 10 18.6667Z"
        fill={colorToUse}
      />
    </Svg>
  );
};

export default Globe;
