import { Link } from '@react-navigation/native';
import { Colors } from 'app/constants/colors';
import { useResource } from 'app/hooks/use-resource/use-resource';
import React, { useMemo } from 'react';
import { ViewStyle } from 'react-native';
import Dots from './Icons/Dots';
import LogoHorizontalText from './Icons/LogoHorizontalText';
import LogoVerticalText from './Icons/LogoVerticalText';
import Routes from './Navigator/ROUTES';
import { useAuthStatus } from 'app/hooks/use-auth/use-auth-status';
import { Status } from 'app/hooks/use-auth/types';

interface Props {
  style?: ViewStyle | ViewStyle[];
  type?: 'horizontal' | 'vertical' | 'dots';
  onPress?: () => void;
  width?: number;
  height?: number;
  textColor?: Colors | string;
  link?: boolean;
  animate?: boolean;
  autoAnimate?: boolean;
}

function Logo({
  style,
  onPress,
  width,
  height,
  textColor,
  link = true,
  type = 'horizontal',
  animate = false,
  autoAnimate = false,
}: Props) {
  const status = useAuthStatus();
  const { setting } = useResource(
    'setting',
    status === Status.USER ? 'me' : null,
    {
      // TODO: useResource should be smart enough to dedupe. It'll require
      // a separate, non-react-state, method for request caching.
      fetch: false,
    },
  );

  const to = useMemo(() => {
    if (setting) {
      return {
        screen: Routes.USER,
        params: {
          username: setting.attributes.username,
        },
      };
    }

    return {
      screen: Routes.AUTHENTICATION,
    };
  }, [setting]);

  const content = (
    <>
      {type === 'vertical' && (
        <LogoVerticalText color={textColor} width={width} height={height} />
      )}
      {type === 'horizontal' && (
        <LogoHorizontalText
          color={textColor}
          width={width}
          height={height}
          animate={animate}
        />
      )}
      {type === 'dots' && (
        <Dots
          width={width}
          height={height}
          animate={animate}
          autoAnimate={autoAnimate}
          colorOverride={textColor}
        />
      )}
    </>
  );

  if (!link) {
    return content;
  }

  return (
    <Link style={style} to={to} onPress={onPress}>
      {content}
    </Link>
  );
}

export default Logo;
