import AsyncStorage from '@react-native-async-storage/async-storage';
import config from 'app/config';
import CoolStuffToken from 'app/cool-stuff-token';
import { Platform } from 'react-native';
import makeUseResource from '../make-resource';
import {
  AccessToken,
  LinkData,
  Link,
  Setting,
  User,
  Search,
  MagicLink,
  Subscription,
  Price,
  Tip,
  Stuff,
  WebAuthnCredential,
} from './types';
import { BetaSignup } from './types/beta_signup';
import { SearchSuggestion } from './types/search-suggestion';
import { Topic } from './types/topic';
import { Explorable } from './types/explorable';
import { SmartSearch } from './types/smart_search';
import { Collection } from './types/collection';

export async function getHeaders(): Promise<{ [key: string]: string }> {
  const headers: HeadersInit_ = {};

  if (global.__IS_SERVER__) {
    return headers;
  }

  const token = await CoolStuffToken.get();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const litefsTxid = await AsyncStorage.getItem('litefs-txid');
  if (litefsTxid) {
    headers['litefs-txid'] = litefsTxid;
  }

  if (process.env.NODE_ENV === 'dev') {
    headers['fly-prefer-region'] = 'syd';
  }

  headers['platform'] = Platform.OS;
  headers['node_env'] = process.env.NODE_ENV || 'development';

  return headers;
}

async function handleResponse(response: Response) {
  const litefsTxid = response.headers.get('litefs-txid');
  if (litefsTxid) {
    await AsyncStorage.setItem('litefs-txid', litefsTxid);
  }

  // If 401, reset the token
  // TODO: Figure out how to update UseAuth's context here.
  if (response.status === 401) {
    await CoolStuffToken.remove();
  }
}

const {
  defaultState,
  useResource,
  useResourceList,
  useCreate,
  useDelete,
  useDeleteList,
  useUpdate,
  useListAction,
  useInvalidate,
  useInvalidateList,
  useResourceStateRef,
  ResourceProvider,
  reducer: resourceReducer,
} = makeUseResource<
  | AccessToken
  | LinkData
  | Link
  | Setting
  | User
  | SearchSuggestion
  | Search
  | BetaSignup
  | MagicLink
  | Price
  | Subscription
  | Tip
  | Stuff
  | WebAuthnCredential
  | Topic
  | Explorable
  | SmartSearch
  | Collection
>({
  baseUrl: config.urls.api,
  getHeaders,
  handleResponse,
});

export type ResourceProviderProps = React.ComponentProps<
  typeof ResourceProvider
>;

export {
  defaultState,
  useResource,
  useResourceList,
  useCreate,
  useDelete,
  useDeleteList,
  useUpdate,
  useListAction,
  useInvalidate,
  useInvalidateList,
  useResourceStateRef,
  resourceReducer,
  ResourceProvider,
};
