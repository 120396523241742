import React, { Ref, useCallback } from 'react';
import { Platform, StyleSheet, View, ViewProps } from 'react-native';
import Animated from 'react-native-reanimated';
import Close from '../Icons/Close';
import Pressable from '../Pressable';
import Text, { TextType } from '../Text';
import useStuffsQuery from './useStuffsQuery';
import { useResource } from 'app/hooks/use-resource/use-resource';
import ShadowView, { ShadowType } from '../ShadowView';
import useColorScheme from 'app/hooks/use-color-scheme';
import ThemeView, { makeThemeStyle } from '../ThemeView';

interface Props {
  parsedQuery: ReturnType<typeof useStuffsQuery>['parsedQuery'];
  resetQuery: ReturnType<typeof useStuffsQuery>['resetQuery'];
  style?: ViewProps['style'];
  onFocusToggle?: (focus: boolean) => void;
}

function SearchInputTopic({
  parsedQuery,
  resetQuery,
  style,
  onFocusToggle,
}: Props) {
  const colorScheme = useColorScheme();
  const { topic } = useResource('topic', parsedQuery.topicId);

  const onClear = useCallback(() => {
    resetQuery();
  }, [resetQuery]);

  const onFocus = useCallback(() => {
    if (onFocusToggle) {
      onFocusToggle(true);
    }
  }, [onFocusToggle]);

  return (
    <Pressable
      style={[style]}
      lightStyle={styles.containerLight}
      darkStyle={styles.containerDark}
      onFocus={onFocus}
    >
      <Text type={TextType.body}>{topic ? topic.attributes.title : ''}</Text>
      <Animated.View style={[styles.clearSearchButton]}>
        <Pressable
          style={styles.closeButton}
          onPress={onClear}
          hitSlop={{
            top: 8,
            left: 8,
            right: 8,
            bottom: 8,
          }}
        >
          <Close
            width={12}
            height={12}
            color={colorScheme === 'dark' ? 'gray12' : 'gray9'}
          />
        </Pressable>
      </Animated.View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'container',
    {
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
    },
    {
      backgroundColor: 'BackgroundGray',
    },
  ),
  clearSearchButton: {
    position: 'absolute',
    right: 6,
    top: 6,
    padding: 8,
  },
  closeButton: Platform.select({
    web: {
      padding: 12,
      margin: -12,
    },
    default: {},
  }),
});

export default SearchInputTopic;
