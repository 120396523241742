import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

interface Props {
  gap?: number;
  alignItems?: ViewStyle['alignItems'];
}

const HStack = ({
  children,
  gap,
  alignItems,
}: React.PropsWithChildren<Props>) => (
  <View
    style={[
      styles.container,
      {
        gap,
        alignItems,
      },
    ]}
  >
    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});

export default HStack;
