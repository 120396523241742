import i11n from 'app/i11n';
import { useMemo } from 'react';

type ValidKeys = keyof typeof i11n;

function useCopy<Keys extends ValidKeys[]>(
  keys: Keys,
): { [k in Keys[number]]: string } {
  // @ts-ignore
  return useMemo(
    () => Object.fromEntries(keys.map((key) => [key, i11n[key]])),
    [keys.length],
  );
}

export default useCopy;
