import { Query } from 'app/hooks/make-resource/make-use-resource-list';
import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import Search from './Search';
import { dark, light } from 'app/constants/colors';

interface Props {
  filter?: Query;
  placeholder: React.ReactNode;
  onChangeQuery: (query: { [key: string]: string }) => void;
  searches: string[];
}

function Searches({ onChangeQuery, searches, placeholder }: Props) {
  return searches.length === 0 ? (
    <ThemeView
      darkStyle={styles.placeholderDark}
      lightStyle={styles.placeholderLight}
    >
      {placeholder}
    </ThemeView>
  ) : (
    <ThemeView
      style={styles.container}
      darkStyle={styles.containerDark}
      lightStyle={styles.containerLight}
    >
      {searches.map((searchId, index) => (
        <View key={searchId}>
          <Search searchId={searchId} onChangeQuery={onChangeQuery} />
          {index < searches.length - 1 && (
            <ThemeView
              darkStyle={styles.borderDark}
              lightStyle={styles.borderLight}
            />
          )}
        </View>
      ))}
    </ThemeView>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
  },
  containerDark: {
    backgroundColor: dark.gray4,
  },
  containerLight: {
    backgroundColor: light.SolidWhite,
  },
  ...makeThemeStyle(
    'border',
    {
      height: 1,
      marginLeft: 16,
    },
    {
      backgroundColor: 'DividerGray',
    },
  ),
  ...makeThemeStyle(
    'placeholder',
    {
      // NOTE: This needs to match SearchSuggestionSearches
      borderRadius: 15,
      paddingHorizontal: 42,
      justifyContent: 'center',
      height: 100,
    },
    {
      backgroundColor: 'BackgroundGray',
    },
  ),
});

export default React.memo(Searches);
