import { useResource } from 'app/hooks/use-resource/use-resource';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Search from '../Searches/Search';
import Text, { TextType } from '../Text';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import useStuffsQuery from './useStuffsQuery';

interface Props {
  searchSuggestionId: string;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
}

function SearchSuggestionSearches({
  searchSuggestionId,
  onChangeQuery,
}: Props) {
  const { ready, searchSuggestion } = useResource(
    'search_suggestion',
    searchSuggestionId,
  );

  if (!ready) {
    return null;
  }

  const searches = searchSuggestion.relationships.searches.data;

  return (
    <View style={styles.container}>
      <Text type={TextType.caption1Emphasized} color="gray11">
        {searchSuggestion.attributes.title}
      </Text>
      <View style={styles.searches}>
        {searches.length ? (
          searches.map(({ id }) => (
            <Search key={id} searchId={id} onChangeQuery={onChangeQuery} />
          ))
        ) : (
          <ThemeView
            darkStyle={styles.placeholderDark}
            lightStyle={styles.placeholderLight}
          >
            <Text type={TextType.subheadline} color="gray11">
              {searchSuggestion.attributes.empty_message}
            </Text>
          </ThemeView>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    gap: 6,
    paddingHorizontal: 0,
  },
  searches: {
    gap: 12,
  },
  ...makeThemeStyle(
    'placeholder',
    {
      // NOTE: This needs to match Searches
      borderRadius: 15,
      paddingHorizontal: 42,
      justifyContent: 'center',
      height: 100,
    },
    {
      backgroundColor: 'BackgroundGray',
    },
  ),
});

export default SearchSuggestionSearches;
