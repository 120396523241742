import React, { useEffect, useState } from 'react';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import {
  useCreate,
  useInvalidateList,
} from 'app/hooks/use-resource/use-resource';
import Page from 'app/components/Page';
import RefreshControl from 'app/components/RefreshControl';
import { StyleSheet, View } from 'react-native';
import AnimatedLottieView from 'lottie-react-native';
import successJson from '../assets/lottie/success.json';
import useCopy from 'app/hooks/use-copy';

const FinalizeSubscription = ({
  route,
  navigation,
}: ScreenProps<typeof Routes.FINALIZE_SUBSCRIPTION>) => {
  const { checkoutSessionId, receipt } = route.params || {};
  const createSubscription = useCreate('subscription');
  const invalidateSubscriptions = useInvalidateList('subscription');
  const [success, setSuccess] = useState(false);
  const copy = useCopy([
    'screenTitleSubscriptionUpgrade',
    'screenTitleSubscriptionFinalize',
  ]);

  useEffect(() => {
    if (!checkoutSessionId && !receipt) {
      navigation.replace(Routes.SETTINGS);
      return;
    }

    const finalize = async () => {
      try {
        await createSubscription({
          type: 'subscription',
          attributes: {
            checkout_session_id: checkoutSessionId,
            receipt,
          },
        });
        setSuccess(true);
        invalidateSubscriptions();
        setTimeout(() => {
          navigation.goBack();
        }, 3000);
      } catch (error) {
        navigation.goBack();
      }
    };

    finalize();
  }, []);

  return (
    <Page title={copy.screenTitleSubscriptionFinalize} type="formSheet">
      <View style={styles.container}>
        {success ? (
          <AnimatedLottieView
            source={successJson}
            autoPlay
            loop={false}
            style={{ width: 48, height: 48 }}
          />
        ) : (
          <RefreshControl refreshing />
        )}
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 100,
    alignItems: 'center',
    marginTop: 40,
  },
});

export default FinalizeSubscription;
