import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors | string;
}

const Close = ({ color = 'gray11', width = 12, height = 12 }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 12 12" fill="none">
      <Path
        d="M7.40969 5.99915L11.7062 1.71268C11.8943 1.52453 12 1.26935 12 1.00326C12 0.737183 11.8943 0.481998 11.7062 0.29385C11.518 0.105701 11.2628 0 10.9967 0C10.7307 0 10.4755 0.105701 10.2873 0.29385L6.00085 4.59031L1.71439 0.29385C1.52624 0.105701 1.27105 -1.98247e-09 1.00497 0C0.738888 1.98247e-09 0.483703 0.105701 0.295555 0.29385C0.107406 0.481998 0.0017052 0.737183 0.00170519 1.00326C0.00170519 1.26935 0.107406 1.52453 0.295555 1.71268L4.59201 5.99915L0.295555 10.2856C0.201903 10.3785 0.127571 10.489 0.0768438 10.6108C0.026117 10.7325 0 10.8631 0 10.995C0 11.1269 0.026117 11.2575 0.0768438 11.3793C0.127571 11.501 0.201903 11.6116 0.295555 11.7044C0.388441 11.7981 0.498951 11.8724 0.62071 11.9232C0.742469 11.9739 0.873067 12 1.00497 12C1.13687 12 1.26747 11.9739 1.38923 11.9232C1.51099 11.8724 1.6215 11.7981 1.71439 11.7044L6.00085 7.40799L10.2873 11.7044C10.3802 11.7981 10.4907 11.8724 10.6125 11.9232C10.7342 11.9739 10.8648 12 10.9967 12C11.1286 12 11.2592 11.9739 11.381 11.9232C11.5028 11.8724 11.6133 11.7981 11.7062 11.7044C11.7998 11.6116 11.8741 11.501 11.9249 11.3793C11.9756 11.2575 12.0017 11.1269 12.0017 10.995C12.0017 10.8631 11.9756 10.7325 11.9249 10.6108C11.8741 10.489 11.7998 10.3785 11.7062 10.2856L7.40969 5.99915Z"
        fill={colorToUse}
      />
    </Svg>
  );
};

export default Close;
