import useIsTouchDevice from 'app/hooks/use-is-touch-device';
import { useResource } from 'app/hooks/use-resource/use-resource';
import React, { ReactNode, useMemo, useState } from 'react';
import { Platform, StyleSheet, View, ViewProps } from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import CloseButton from '../CloseButton';
import ContextMenu from '../ContextMenu/ContextMenu';
import { DropdownItem } from '../Dropdown';
import HoverView from '../HoverView';
import Browsers from '../Icons/Browsers';
import CoolStuffDesktop from '../Icons/CoolStuffDesktop';
import CoolStuffIcon from '../Icons/CoolStuffIcon';
import ExpandingArrows from '../Icons/ExpandingArrows';
import Heart from '../Icons/Heart';
import Hello from '../Icons/Hello';
import OurStuff from '../Icons/OurStuff';
import PlusCircle from '../Icons/PlusCircle';
import Shareable from '../Icons/Shareable';
import ShareStar from '../Icons/ShareStar';
import Sparkles from '../Icons/Sparkles';
import Star from '../Icons/Star';
import Trash from '../Icons/Trash';
import Pressable from '../Pressable';
import { ShadowType } from '../ShadowView';
import Text, { TextType } from '../Text';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import BlurView from '../BlurView';
import Swipeable from '../Swipable';
import useCopy from 'app/hooks/use-copy';
import { range } from 'lodash';

export const TIP_PEEK = 8;
export const TIP_HEIGHT = 94;

interface Props {
  tipId: string;
  onDelete: () => void;
  onContainerPress: () => void;
  expandedValue: Animated.SharedValue<number>;
  style?: ViewProps['style'];
  disabled?: boolean;
  isSingleTip: boolean;
  containerStyle?: ViewProps['style'];
  index: number;
  expanded: boolean;
}

const Tip = ({
  tipId,
  onDelete,
  onContainerPress,
  expandedValue,
  style,
  disabled,
  isSingleTip,
  index,
  expanded,
}: Props) => {
  const { tip, ready } = useResource('tip', tipId, { fetch: false });
  const isTouchDevice = useIsTouchDevice();
  const hovered = useSharedValue(isTouchDevice ? 1 : 0);
  const [isHovered, setIsHovered] = useState(false);
  const copy = useCopy(['tipsClear']);

  const translateYExpanded = useMemo(() => {
    const sumHeightsAboveIndex = range(1, index + 1)
      // We add 10 for the margin given to each
      .reduce((partialSum, h) => partialSum + TIP_HEIGHT + 10, 0);
    const aggregatePeek = Math.min(index * TIP_PEEK, TIP_PEEK * 2);

    const value = -sumHeightsAboveIndex + aggregatePeek;
    return value;
  }, [index]);

  const containerStyle = useAnimatedStyle(() => {
    return {
      borderRadius: 20,
      overflow: 'visible',
      zIndex: 20 - index,
      marginBottom: isSingleTip ? 0 : 10,
      transform: [
        {
          translateY: interpolate(
            expandedValue.value,
            [0, 1],
            [translateYExpanded, 0],
          ),
        },
        // Scale about the bottom of the tip so the NATIVE_CARD_PEEK remains exact
        {
          translateY: TIP_HEIGHT / 2,
        },
        {
          scale: interpolate(
            expandedValue.value,
            [0, 1],

            [Math.max(1 - index * 0.07, 0), 1],
          ),
        },
        {
          translateY: -TIP_HEIGHT / 2,
        },
      ],
    };
  }, [expandedValue, index, translateYExpanded, isSingleTip]);

  const innerContainerStyle = useAnimatedStyle(
    () => ({
      opacity: interpolate(
        expandedValue.value,
        [0, 1],
        [
          index < 3
            ? (Platform.OS === 'web'
                ? { 0: 1, 1: 0.6, 2: 0.4 }
                : { 0: 1, 1: 0.4, 2: 0.0 })[index]
            : 0,
          1,
        ],
      ),
    }),
    [expandedValue, index],
  );

  const contentStyle = useAnimatedStyle(() => {
    if (index === 0) {
      return { opacity: 1 };
    }
    return {
      opacity: expandedValue.value,
    };
  }, [expandedValue, index]);

  const closeButtonStyle = useAnimatedStyle(
    () => ({
      opacity: withSpring(hovered.value, {
        damping: 15,
        mass: 0.3,
      }),
    }),
    [hovered],
  );

  if (!ready) {
    return null;
  }

  const iconName = tip.attributes.icon;
  const icon = iconNameToElement[iconName] || iconNameToElement['sparkles'];
  const href = tip.attributes.href;

  let tipContent = (
    <>
      <View style={styles.iconContainer}>{icon}</View>
      <View style={styles.contentText}>
        <Text type={TextType.bodyEmphasized} color="HeadlineBlack">
          {tip.attributes.title}
        </Text>
        <Text type={TextType.subheadline} color="SupportingGray">
          {tip.attributes.body}
        </Text>
      </View>
    </>
  );

  if (href) {
    tipContent = (
      <Pressable
        onPress={isSingleTip ? undefined : onContainerPress}
        inAppLinkToDelay={isSingleTip ? 0 : 350}
        href={tip.attributes.href}
        hrefAttrs={{
          target: tip.attributes.href.startsWith('/') ? 'self' : 'blank',
          rel: 'nofollow',
        }}
        style={styles.content}
        disabled={disabled}
      >
        {tipContent}
      </Pressable>
    );
  } else {
    tipContent = <View style={styles.content}>{tipContent}</View>;
  }

  return (
    <Animated.View style={containerStyle}>
      <Swipeable
        closed={!expanded}
        enabled={Platform.OS !== 'web' ? expanded || isSingleTip : false}
        overshootFriction={8}
        containerStyle={styles.swipableContainer}
        renderRightActions={() => (
          <Pressable onPress={onDelete}>
            <ThemeView
              lightStyle={styles.clearButtonLight}
              darkStyle={styles.clearButtonDark}
            >
              <Text type={TextType.body} color="gray11">
                {copy.tipsClear}
              </Text>
            </ThemeView>
          </Pressable>
        )}
      >
        <ContextMenu
          actions={[
            <DropdownItem
              key="clear"
              label="Clear"
              renderIcon={(isActive) =>
                isActive ? <Trash color="white" /> : <Trash color="red9" />
              }
              last
              textColor="red9"
              activeTextColor="white"
              activeBackgroundColor="red9"
              onPress={onDelete}
            />,
          ]}
          // onPress={onPress}
          onHoverIn={() => {
            hovered.value = 1;
            setIsHovered(true);
          }}
          onHoverOut={(event) => {
            // This prevents losing hover when hovering over the close button
            if (event.nativeEvent.type === 'pointerleave') {
              hovered.value = 0;
              setIsHovered(false);
            }
          }}
          style={[styles.container, style]}
          disabled={disabled}
        >
          <BlurView amount={24} style={styles.blurView}>
            <HoverView
              hovered={isHovered}
              lightStyle={styles.innerContainerLight}
              darkStyle={styles.innerContainerDark}
              style={innerContainerStyle}
              damping={10}
              shadowEnabled={isHovered}
              shadowType={ShadowType.medium}
              parallaxEnabled={false}
              borderRadius={20}
            >
              <Animated.View style={contentStyle}>{tipContent}</Animated.View>
            </HoverView>
          </BlurView>
          {Platform.OS === 'web' && (
            <Animated.View style={[styles.close, closeButtonStyle]}>
              <CloseButton onPress={onDelete} />
            </Animated.View>
          )}
        </ContextMenu>
      </Swipeable>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {},
  ...makeThemeStyle(
    'innerContainer',
    {
      borderRadius: 20,
      padding: 16,
    },
    {
      backgroundColor: 'gray4',
    },
  ),
  blurView: {
    borderRadius: 20,
    // overflow: 'hidden',
  },
  close: {
    position: 'absolute',
    top: -10,
    left: -14,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
  },
  contentText: {
    flex: 1,
  },
  iconContainer: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareStarIcon: {
    marginLeft: 3,
  },
  ...makeThemeStyle(
    'clearButton',
    {
      borderRadius: 20,
      height: '100%',
      paddingHorizontal: 40,
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 8,
    },
    {
      backgroundColor: 'gray4',
    },
  ),
  swipableContainer: {
    maxHeight: '100%',
    overflow: Platform.select({
      web: 'visible',
      default: 'visible',
    }),
  },
});

const iconNameToElement: { [name: string]: ReactNode | null } = {
  alien: <Text type={TextType.largeTitle}>👽</Text>,
  plus_circle: <PlusCircle width={28} />,
  cool_stuff_desktop: <CoolStuffDesktop width={30} />,
  cool_stuff_app: <CoolStuffIcon width={30} />,
  extensions: <Browsers width={32} />,
  expanding_arrows: <ExpandingArrows width={30} />,
  heart: <Heart width={30} />,
  hello: <Hello width={30} />,
  our_stuff: <OurStuff width={30} />,
  share_star: <ShareStar width={30} style={styles.shareStarIcon} />,
  shareable: <Shareable width={32.71} />,
  star: <Star width={30} />,
  sparkles: <Sparkles width={30} height={39.97} />,
};

export default Tip;
