import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const Chevron = ({ width = 8, height = 14, color = 'gray8' }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 8 14" fill="none">
      <Path
        d="M8.00028 6.99633C8.00028 6.71578 7.89764 6.48313 7.67867 6.26416L2.40977 1.11159C2.23185 0.933675 2.01973 0.851562 1.76655 0.851562C1.25334 0.851562 0.835938 1.25528 0.835938 1.76849C0.835938 2.02167 0.945421 2.25432 1.13017 2.43908L5.8106 6.98949L1.13017 11.5536C0.945421 11.7383 0.835938 11.9642 0.835938 12.2242C0.835938 12.7374 1.25334 13.1479 1.76655 13.1479C2.01973 13.1479 2.23185 13.059 2.40977 12.8811L7.67867 7.72851C7.90448 7.50954 8.00028 7.27689 8.00028 6.99633Z"
        fill={colorToUse}
      />
    </Svg>
  );
};

export default Chevron;
