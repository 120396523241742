import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const Share = ({ width = 16, height = 20, color = 'blue9' }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 16 20" fill="none">
      <Path
        d="M7.85999 13.0616C8.24103 13.0616 8.5689 12.7426 8.5689 12.3704V3.26983L8.51573 1.94063L9.10944 2.56978L10.4564 4.00532C10.5804 4.1471 10.7576 4.21799 10.9349 4.21799C11.2982 4.21799 11.5817 3.95215 11.5817 3.58883C11.5817 3.40275 11.502 3.26097 11.3691 3.12805L8.37395 0.239256C8.19672 0.0620292 8.04608 0 7.85999 0C7.68276 0 7.53212 0.0620292 7.34603 0.239256L4.35091 3.12805C4.21799 3.26097 4.1471 3.40275 4.1471 3.58883C4.1471 3.95215 4.41294 4.21799 4.78511 4.21799C4.95348 4.21799 5.14843 4.1471 5.27249 4.00532L6.61055 2.56978L7.21311 1.94063L7.15995 3.26983V12.3704C7.15995 12.7426 7.47895 13.0616 7.85999 13.0616ZM2.78245 20H12.9464C14.7984 20 15.7288 19.0784 15.7288 17.253V8.40939C15.7288 6.58396 14.7984 5.66238 12.9464 5.66238H10.4741V7.08906H12.9198C13.7971 7.08906 14.3022 7.56757 14.3022 8.48914V17.1732C14.3022 18.0948 13.7971 18.5733 12.9198 18.5733H2.80018C1.91405 18.5733 1.42667 18.0948 1.42667 17.1732V8.48914C1.42667 7.56757 1.91405 7.08906 2.80018 7.08906H5.25476V5.66238H2.78245C0.930439 5.66238 0 6.58396 0 8.40939V17.253C0 19.0784 0.930439 20 2.78245 20Z"
        fill={colorToUse}
      />
    </Svg>
  );
};

export default Share;
