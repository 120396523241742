import { useContext } from 'react';
import { AuthContext } from './context';
import { Status } from './types';

export function useAuthStatus() {
  const { token } = useContext(AuthContext);

  let _status = Status.PENDING;
  if (token !== null) {
    _status = token ? Status.USER : Status.GUEST;
  }

  return _status;
}
