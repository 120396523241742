import { LinkingOptions } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Platform } from 'react-native';

const Routes = {
  HOME: 'HOME',
  USER: 'USER',
  SAVE_LINK: 'SAVE_LINK',
  GUEST_HOME: 'GUEST_HOME',
  PRICES: 'PRICES',
  SUBSCRIPTION: 'SUBSCRIPTION',
  STRIPE_CHECKOUT: 'STRIPE_CHECKOUT',
  FINALIZE_SUBSCRIPTION: 'FINALIZE_SUBSCRIPTION',
  BROWSER_EXTENSIONS: 'BROWSER_EXTENSIONS',
  NOT_FOUND: 'NOT_FOUND',
  AUTHENTICATION: 'AUTHENTICATION',
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  REGISTER: 'REGISTER',
  MAGIC_LINK: 'MAGIC_LINK',
  EXTENSIONS: 'EXTENSIONS',
  SETTINGS: 'SETTINGS',
  SETTINGS_MAIN: 'SETTINGS_MAIN',
  SETTINGS_APPEARANCE: 'SETTINGS_APPEARANCE',
  SETTINGS_APP_ICON: 'SETTINGS_APP_ICON',
  ADD_TO_COLLECTION: 'ADD_TO_COLLECTION',
  EDIT_COLLECTION: 'EDIT_COLLECTION',
} as const;

export type RouteType = keyof typeof Routes;

export const RouteTitles: { [k in RouteType]: string } = {
  [Routes.HOME]: 'Cool Stuff • Collect Your Internet',
  [Routes.USER]: 'User',
  [Routes.SAVE_LINK]: 'Save Link',
  [Routes.GUEST_HOME]: 'GuestHome',
  [Routes.PRICES]: 'Manage Your Plan',
  [Routes.SUBSCRIPTION]: 'Manage Your Plan',
  [Routes.STRIPE_CHECKOUT]: 'Checkout',
  [Routes.FINALIZE_SUBSCRIPTION]: 'Upgrading Your Plan',
  [Routes.BROWSER_EXTENSIONS]: 'Browser Extensions',
  [Routes.NOT_FOUND]: 'Not Found',
  [Routes.AUTHENTICATION]: 'Authentication',
  [Routes.LOGIN]: 'Log In',
  [Routes.SIGNUP]: 'Create Account',
  [Routes.REGISTER]: 'Register',
  [Routes.MAGIC_LINK]: 'Check Your Email',
  [Routes.EXTENSIONS]: 'Extensions',
  [Routes.SETTINGS]: 'Settings',
  [Routes.SETTINGS_MAIN]: 'Settings',
  [Routes.SETTINGS_APPEARANCE]: 'Appearance',
  [Routes.SETTINGS_APP_ICON]: 'App Icon',
  [Routes.ADD_TO_COLLECTION]: 'Add to Collection',
  [Routes.EDIT_COLLECTION]: 'Edit Collection',
};

export type Params = {
  [Routes.USER]: {
    username?: string;
  };
  [Routes.SAVE_LINK]: {
    url?: string;
  };
  [Routes.SETTINGS]: undefined;
  [Routes.SETTINGS_MAIN]: undefined;
  [Routes.SETTINGS_APPEARANCE]: undefined;
  [Routes.SETTINGS_APP_ICON]: undefined;
  [Routes.PRICES]: undefined;
  [Routes.SUBSCRIPTION]: undefined;
  [Routes.STRIPE_CHECKOUT]: { priceId: string };
  [Routes.FINALIZE_SUBSCRIPTION]: {
    checkoutSessionId?: string;
    receipt?: string;
  };
  [Routes.BROWSER_EXTENSIONS]: undefined;
  [Routes.SIGNUP]: undefined;
  [Routes.LOGIN]: undefined;
  [Routes.REGISTER]: undefined;
  [Routes.AUTHENTICATION]: undefined;
  [Routes.GUEST_HOME]: undefined;
  [Routes.EXTENSIONS]: undefined;
  [Routes.MAGIC_LINK]: {
    magicLinkId?: string;
    email: string;
  };
  [Routes.ADD_TO_COLLECTION]: {
    links: string[];
  };
  [Routes.EDIT_COLLECTION]: {
    collection: string;
  };
};

export const linking: LinkingOptions<{}> = {
  prefixes: ['https://coolstuff.app', 'coolstuff://', 'coolstuff-dev://'],
  config: {
    initialRouteName: Platform.select({
      web: Routes.USER,
      default: Routes.HOME,
    }),
    screens: {
      [Routes.USER]: ':username?',
      [Routes.SAVE_LINK]: 'save',
      [Routes.EXTENSIONS]: 'extensions',
      [Routes.ADD_TO_COLLECTION]: 'add-to-collection',
      [Routes.EDIT_COLLECTION]: 'collection',
      [Routes.SETTINGS]: {
        initialRouteName: Routes.SETTINGS_MAIN,
        screens: {
          [Routes.SETTINGS_MAIN]: 'settings',
          [Routes.SETTINGS_APPEARANCE]: 'settings/appearance',
          [Routes.SETTINGS_APP_ICON]: 'settings/app-icon',
        },
      },
      [Routes.SUBSCRIPTION]: {
        initialRouteName: Routes.PRICES,
        screens: {
          [Routes.PRICES]: 'subscription',
          [Routes.STRIPE_CHECKOUT]: 'subscription/checkout',
          [Routes.FINALIZE_SUBSCRIPTION]: 'subscription/finalize',
        },
      },
      [Routes.NOT_FOUND]: '*',
    },
  },
};

export const guestLinking: LinkingOptions<{}> = {
  prefixes: ['https://coolstuff.app', 'coolstuff://', 'coolstuff-dev://'],
  config: {
    screens: {
      [Routes.AUTHENTICATION]: {
        initialRouteName: Routes.HOME,
        screens: {
          [Routes.HOME]: '',
          [Routes.LOGIN]: 'login',
          [Routes.SIGNUP]: 'signup',
          [Routes.MAGIC_LINK]: 'auth/magic_link',
        },
      },
      [Routes.USER]: ':username',
      [Routes.NOT_FOUND]: '*',
    },
  },
};

export type ScreenProps<RouteName extends keyof Params> =
  NativeStackScreenProps<Params, RouteName>;

export default Routes;
