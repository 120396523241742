import { useCallback } from 'react';
import { FetcherResponseSingle } from './make-fetcher';
import makeProvider from './make-provider';
import { Resource } from './types';
import { makeListUrl, makeRetrieveUrl } from './urls';

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

function makeUseCreate<ValidResource extends Resource>({
  useResourceDispatch,
  baseUrl,
  useResourceFetcher,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
  useResourceFetcher: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceFetcher'];
  baseUrl: string;
}) {
  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  function useCreate<
    Type extends keyof TypeToResource,
    TypedResource extends TypeToResource[Type],
  >(type: Type) {
    const dispatch = useResourceDispatch();
    const fetcher = useResourceFetcher();

    const create = useCallback(
      async (
        payload: RecursivePartial<TypedResource>,
        options: {
          include?: string[];
          invalidateLists?: boolean | (keyof TypeToResource)[];
        } = { invalidateLists: true },
      ) => {
        const { include, invalidateLists } = options;

        const url = makeListUrl({ baseUrl, type, include });

        const _response = (await fetcher(url, {
          method: 'POST',
          payload: {
            data: payload,
          },
        })) as FetcherResponseSingle<ValidResource>;

        const response = _response.response;
        const data = _response.data;

        // @ts-ignore
        dispatch({
          type: 'fetch_resource_success',
          payload: data,
          meta: { url: `create:${url}`, type },
        });

        if (invalidateLists) {
          if (typeof invalidateLists === 'boolean') {
            // Invalidate all lists for this type
            dispatch({
              type: 'invalidate_list_type',
              meta: { type },
            });
          } else {
            for (const invalidateType of invalidateLists) {
              dispatch({
                type: 'invalidate_list_type',
                meta: { type: invalidateType },
              });
            }
          }
        }

        // If the response included `Retry-After`, schedule an invalidation in that time.
        const retryAfter = response.headers.get('retry-after');
        if (retryAfter) {
          setTimeout(() => {
            const retrieveUrl = makeRetrieveUrl({
              baseUrl,
              type,
              id: data.data.id,
              include,
            });

            dispatch({
              type: 'invalidate_resource',
              meta: { type },
              payload: { url: retrieveUrl },
            });
          }, parseFloat(retryAfter) * 1000);
        }

        return data.data as FetcherResponseSingle<ValidResource>['data']['data'];
      },
      [dispatch, type, fetcher],
    );

    return create;
  }

  return useCreate;
}

export default makeUseCreate;
