import { Platform, useColorScheme as useColorSchemeRN } from 'react-native';
import { useResource } from './use-resource/use-resource';
import { useAuthStatus } from './use-auth/use-auth-status';
import { Status } from './use-auth/types';

function useColorScheme() {
  const status = useAuthStatus();
  const { setting } = useResource(
    'setting',
    status === Status.USER ? 'me' : null,
    {
      // TODO: useResource should be smart enough to dedupe. It'll require
      // a separate, non-react-state, method for request caching.
      fetch: false,
    },
  );

  const systemColorScheme = useColorSchemeRN();

  if (setting) {
    const attrs = setting.attributes;

    const customColorScheme = Platform.select({
      web: attrs.appearance_web,
      ios: attrs.appearance_ios,
      default: attrs.appearance_ios,
    });

    if (customColorScheme !== 'system') {
      return customColorScheme;
    }
  }

  return systemColorScheme;
}

export default useColorScheme;
