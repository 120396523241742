import React, { useCallback, useEffect, useState } from 'react';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import DraggableFlatList, {
  RenderItemParams,
} from 'react-native-draggable-flatlist';

import { StyleSheet, View } from 'react-native';
import Page from 'app/components/Page';
import useCopy from 'app/hooks/use-copy';
import { useResource, useUpdate } from 'app/hooks/use-resource/use-resource';
import { useToast } from 'app/hooks/use-toast';
import CollectionLink from './CollectionLink';
import Image from 'app/components/Image';
import { dark, light } from 'app/constants/colors';
import ThemeView, { makeThemeStyle } from 'app/components/ThemeView';
import TextInput from 'app/components/TextInput';
import Pressable from 'app/components/Pressable';
import Text, { TextType } from 'app/components/Text';
import Button, { ButtonType } from 'app/components/Button';

const EditCollection = ({
  route,
  navigation,
}: ScreenProps<typeof Routes.EDIT_COLLECTION>) => {
  const { collection: collectionId } = route.params || {};
  const copy = useCopy([
    'screenEditCollectionTitle',
    'screenEditCollectionTitlePlaceholder',
    'screenEditCollectionTitleCancel',
    'screenEditCollectionTitleSave',
    'screenEditCollectionTitleSaveConfirmation',
  ]);
  const setToast = useToast();
  const { collection } = useResource('collection', collectionId);
  const [linkIds, setLinkIds] = useState<string[]>([]);
  const [collectionTitle, setCollectionTitle] = useState<string>('');
  const updateCollection = useUpdate('collection');

  const onSave = useCallback(() => {
    updateCollection(
      collectionId,
      {
        type: 'collection',
        id: collectionId,
        attributes: {
          title: collectionTitle,
        },
        relationships: {
          links: {
            data: linkIds.map((id) => ({
              type: 'link',
              id,
            })),
          },
        },
      },
      {
        invalidateLists: ['stuff'],
      },
    );

    navigation.goBack();
    setToast({
      type: 'info',
      text: copy.screenEditCollectionTitleSaveConfirmation,
      delay: 2000,
    });
  }, [
    collectionId,
    collectionTitle,
    linkIds,
    updateCollection,
    navigation,
    copy.screenEditCollectionTitleSaveConfirmation,
    setToast,
  ]);

  useEffect(() => {
    if (collection) {
      setLinkIds(collection.relationships.links.data.map((r) => r.id));
      setCollectionTitle(collection.attributes.title);
    }
  }, [collection]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Pressable
          onPress={() => {
            navigation.goBack();
          }}
        >
          <Text color="Primary">{copy.screenEditCollectionTitleCancel}</Text>
        </Pressable>
      ),
      headerRight: () => (
        <Pressable onPress={onSave}>
          <Text color="Primary" type={TextType.bodyEmphasized}>
            {copy.screenEditCollectionTitleSave}
          </Text>
        </Pressable>
      ),
    });
  }, [
    navigation,
    copy.screenEditCollectionTitleCancel,
    copy.screenEditCollectionTitleSave,
    onSave,
  ]);

  if (!collection) {
    return null;
  }

  const renderItem = ({
    item,
    drag,
    isActive,
    getIndex,
  }: RenderItemParams<string>) => {
    const isLast =
      (getIndex() || 0) >= collection.relationships.links.data.length - 1;

    return (
      <ThemeView
        style={[styles.linkItem, isLast && styles.linkItemLast]}
        darkStyle={!isLast && styles.linkItemBorderDark}
        lightStyle={!isLast && styles.linkItemBorderLight}
      >
        <CollectionLink linkId={item} onStartDrag={drag} />
      </ThemeView>
    );
  };

  return (
    <>
      <Page title={copy.screenEditCollectionTitle} fullHeight>
        <View style={styles.container}>
          <Image
            source={collection.attributes.thumbnail}
            style={styles.thumbnail}
            contentPosition="top center"
          />
          <TextInput
            placeholder={copy.screenEditCollectionTitlePlaceholder}
            onChangeText={setCollectionTitle}
            value={collectionTitle}
            autoCapitalize="none"
            inputStyle={styles.textInput}
          />
          <ThemeView
            style={styles.listContainer}
            darkStyle={styles.listContainerDark}
            lightStyle={styles.listContainerLight}
          >
            <DraggableFlatList
              data={linkIds}
              onDragEnd={({ data }) => setLinkIds(data)}
              keyExtractor={(item) => item}
              renderItem={renderItem}
            />
          </ThemeView>
        </View>
      </Page>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 32,
  },
  thumbnail: {
    width: 144,
    borderRadius: 13,
    aspectRatio: 1,
    borderWidth: 1,
    borderColor: light.BackgroundGray,
    alignSelf: 'center',
  },
  listContainer: {
    borderRadius: 13,
    paddingHorizontal: 12,
  },
  listContainerLight: {
    backgroundColor: light.SolidWhite,
  },
  listContainerDark: {
    backgroundColor: dark.gray4,
  },
  linkItem: {
    height: 64,
    borderBottomWidth: 1,
    justifyContent: 'center',
  },
  linkItemLast: {
    borderColor: 'transparent',
  },
  ...makeThemeStyle(
    'linkItemBorder',
    {
      borderBottomWidth: 1,
    },
    {
      borderColor: 'gray4',
    },
  ),
  textInput: {
    textAlign: 'center',
  },
});

export default EditCollection;
