import { useNavigation } from '@react-navigation/native';
import countryToCurrency from 'country-to-currency';
import Routes from 'app/components/Navigator/ROUTES';
import { useCallback, useEffect, useState } from 'react';
import { Price } from './use-resource/types';

function useMakePurchase(): {
  makePurchase: (price: Price) => Promise<void>;
  localizePrice: (price: Price) => string;
  restorePurchase: null | (() => Promise<void>);
} {
  const navigation = useNavigation();
  const [{ currency, languageCode }, setLocal] = useState({
    currency: 'usd',
    languageCode: 'en-US',
  });

  useEffect(() => {
    async function load() {
      const response = await fetch('https://freeipapi.com/api/json');

      const data = await response.json();
      const countryCode: keyof typeof countryToCurrency = data.countryCode;
      const currencyToSet = countryToCurrency[countryCode];

      setLocal({
        currency: currencyToSet,
        languageCode,
      });
    }

    try {
      load();
    } catch (error) {}
  }, []);

  const makePurchase = useCallback(
    async (price: Price) => {
      navigation.replace(Routes.STRIPE_CHECKOUT, {
        priceId: price.id,
      });
    },
    [navigation],
  );

  const localizePrice = useCallback(
    (price: Price) => {
      const cost =
        price.attributes.cost_for_locales[currency.toLowerCase()] ||
        price.attributes.cost;

      if (cost === 0) {
        return '';
      }

      return new Intl.NumberFormat(languageCode, {
        style: 'currency',
        currency: currency,
      }).format(cost / 100);
    },
    [currency, languageCode],
  );

  return { makePurchase, localizePrice, restorePurchase: null };
}

export default useMakePurchase;
