const USE_PROD_ON_DEV = true;

const ENV: 'production' | 'development' =
  process.env.NODE_ENV === 'production' ? 'production' : 'development';

export default {
  urls: {
    api:
      process.env.NODE_ENV === 'production' || USE_PROD_ON_DEV
        ? 'https://api.coolstuff.app'
        : 'https://api-cool-stuff.mac-studio.home.arpa',
    webApp: 'https://coolstuff.app',
    iosApp: 'https://apps.apple.com/gb/app/cool-stuff-save-links/id6478186207',
    terms:
      'https://funontheinternet.notion.site/Cool-Stuff-terms-of-use-e93e0a5909ab4a1ead9f738bd6c4faf2?pvs=4',
    privacy:
      'https://funontheinternet.notion.site/Cool-Stuff-privacy-policy-278789cb54414dd2bd3e0bce19ae92f0',
    extensions:
      'https://funontheinternet.notion.site/Cool-Stuff-extensions-5dee6c2d7def4d60a69b3ac36b519c4b?pvs=4',
    safariExtension:
      'https://apps.apple.com/us/app/cool-stuff-for-safari/id6478342963',
    chromeExtension:
      'https://chromewebstore.google.com/detail/cool-stuff/kgnnfindhiakkfahbjmjebddpkikapgh',
    about: 'https://apps.apple.com/gb/app/cool-stuff-save-links/id6478186207',
    contact: 'mailto:hey@coolstuff.app',
    instagram: 'https://www.instagram.com/coolstuff.app/',
  },
  env: ENV,
  accessGroup:
    process.env.NODE_ENV === 'production'
      ? 'group.app.coolstuff.ios'
      : 'group.app.coolstuff.ios-dev',
  bundleId:
    process.env.NODE_ENV === 'production'
      ? 'app.coolstuff.ios'
      : 'app.coolstuff.ios-dev',
  sentryDsn:
    'https://e9bdb4fc1f8548c9937177d0d89e8ecf@o533253.ingest.sentry.io/4505019971403776',
  stripePublishableKey:
    process.env.NODE_ENV === 'production'
      ? 'pk_live_51O0aZoLVHSBbDhhXQzopCcNJ7UMvJEKThIfzrsT1fxttQB4GYo7fhN6i7IBYwBj60xGoyyYKudaFNtwyaAwO9iWN00szrx3BZX'
      : 'pk_test_51O0aZoLVHSBbDhhXt11y4QyBjRO1by4vn6SaLLCO4ssBPMhKghIYE1xrzVYcoEON0oafJBiGoTCGOJBFwu7dk22500bRQxUBdz',
};
