import { usePortal } from '@gorhom/portal';
import Button, { ButtonType } from 'app/components/Button';
import Dialog from 'app/components/Dialog';
import { light } from 'app/constants/colors';
import React from 'react';
import { ActionSheetIOS, Platform, StyleSheet, View } from 'react-native';

const useConfirm = () => {
  const portal = usePortal('confirm');

  function confirm({
    cancelText,
    confirmText,
    title,
    body,
    callback,
    confirmButtonType = ButtonType.primary,
  }: {
    cancelText?: string;
    confirmText: string;
    title?: string;
    body?: string;
    callback?: (confirm: boolean) => void;
    confirmButtonType?: ButtonType;
  }) {
    if (Platform.OS === 'ios') {
      ActionSheetIOS.showActionSheetWithOptions(
        {
          options: [cancelText || 'Cancel', confirmText],
          destructiveButtonIndex:
            confirmButtonType === ButtonType.secondaryDanger ? 1 : -1,
          cancelButtonIndex: 0,
          tintColor: light.blue9,
          message: body,
          title,
        },
        (buttonIndex) => {
          if (buttonIndex === 1) {
            if (callback) {
              callback(true);
            }
          } else {
            if (callback) {
              callback(false);
            }
          }
        },
      );
      return;
    }

    const confirmElement = (
      <Dialog
        isVisible
        title={title}
        body={body}
        onClose={() => {
          portal.removePortal('confirm');
          if (callback) {
            callback(false);
          }
        }}
        hostName="confirm"
      >
        <View style={styles.buttons}>
          <Button
            type={confirmButtonType}
            onPress={() => {
              portal.removePortal('confirm');
              if (callback) {
                callback(true);
              }
            }}
            shadow={false}
          >
            {confirmText}
          </Button>
          {cancelText && (
            <Button
              type={ButtonType.secondaryBold}
              onPress={() => {
                portal.removePortal('confirm');
                if (callback) {
                  callback(false);
                }
              }}
              shadow={false}
            >
              {cancelText}
            </Button>
          )}
        </View>
      </Dialog>
    );

    portal.addPortal('confirm', confirmElement);
  }

  return confirm;
};

const styles = StyleSheet.create({
  buttons: {
    gap: 12,
  },
});

export default useConfirm;
