import React, { useEffect, useRef } from 'react';
import { SwipeableProps } from 'react-native-gesture-handler/lib/typescript/components/Swipeable';
import RNSwipeable from 'react-native-gesture-handler/Swipeable';

interface Props extends SwipeableProps {
  closed?: boolean;
}

const Swipeable = ({ closed, ...props }: Props) => {
  const ref = useRef<RNSwipeable>(null);

  useEffect(() => {
    if (closed && ref.current) {
      ref.current.close();
    }
  }, [closed]);

  return <RNSwipeable ref={ref} {...props} />;
};

export default Swipeable;
