import { useCallback } from 'react';
import { FetcherResponseList } from './make-fetcher';
import makeProvider from './make-provider';
import { Resource } from './types';
import { makeListActionUrl } from './urls';

function makeUseListAction<ValidResource extends Resource>({
  useResourceDispatch,
  useResourceFetcher,
  baseUrl,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
  useResourceFetcher: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceFetcher'];
  baseUrl: string;
}) {
  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  function useListAction<Type extends keyof TypeToResource>(type: Type) {
    const dispatch = useResourceDispatch();
    const fetcher = useResourceFetcher();

    const listAction = useCallback(
      async (
        name: string,
        method: 'POST' | 'GET' | 'DELETE' | 'PATCH',
        options: {
          query?: { [name: string]: string };
        } = {},
      ) => {
        const { query = {} } = options;
        const url = makeListActionUrl({ name, baseUrl, type, query });

        const response = (await fetcher(url, {
          method,
        })) as FetcherResponseList<ValidResource>;

        const responseList = response.data;

        dispatch({
          type: 'fetch_resource_list_success',
          payload: responseList,
          meta: {
            url,
            listKey: url,
            type,
          },
        });
      },
      [dispatch, type, fetcher],
    );

    return listAction;
  }

  return useListAction;
}

export default makeUseListAction;
