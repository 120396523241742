import React from 'react';
import Dialog from 'app/components/Dialog';
import useCopy from 'app/hooks/use-copy';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import { StyleSheet, View } from 'react-native';
import Button, { ButtonType } from 'app/components/Button';
import config from 'app/config';
import { PortalHost } from '@gorhom/portal';

const Extensions = ({ navigation }: ScreenProps<typeof Routes.EXTENSIONS>) => {
  const copy = useCopy([
    'screenExtensionsTitle',
    'screenExtensionsBody',
    'screenExtensionsSafari',
    'screenExtensionsChrome',
  ]);

  return (
    <>
      <View style={StyleSheet.absoluteFill}>
        <PortalHost name="extensions" />
      </View>
      <Dialog
        isVisible
        title={copy.screenExtensionsTitle}
        body={copy.screenExtensionsBody}
        onClose={() => {
          navigation.goBack();
        }}
        hostName="extensions"
      >
        <View style={styles.buttons}>
          <Button
            type={ButtonType.secondaryBold}
            href={config.urls.safariExtension}
            shadow={false}
          >
            {copy.screenExtensionsSafari}
          </Button>
          <Button
            type={ButtonType.secondaryBold}
            href={config.urls.chromeExtension}
            shadow={false}
          >
            {copy.screenExtensionsChrome}
          </Button>
        </View>
      </Dialog>
    </>
  );
};

const styles = StyleSheet.create({
  buttons: {
    gap: 12,
  },
});

export default Extensions;
