import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import useCopy from 'app/hooks/use-copy';
import { Setting } from 'app/hooks/use-resource/types';
import {
  useDelete,
  useInvalidateList,
  useListAction,
  useResource,
  useUpdate,
} from 'app/hooks/use-resource/use-resource';
import { useToast } from 'app/hooks/use-toast';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import Button, { ButtonType } from 'app/components/Button';
import Page from 'app/components/Page';
import Text, { TextType } from 'app/components/Text';
import List, {
  ListItem,
  ListItemIcon,
  ListItemInput,
} from 'app/components/List';
import ListItemLabel from 'app/components/List/ListItemLabel';
import ListItemSwitch from 'app/components/List/ListItemSwitch';
import Chevron from 'app/components/Icons/Chevron';
import Checkmark from 'app/components/Icons/Checkmark';
import useShareLink from 'app/hooks/use-share-link';
import { useLinkBuilder } from '@react-navigation/native';
import Sparkles from 'app/components/Icons/Sparkles';
import Fireworks from 'app/components/Icons/Fireworks';
import Lock from 'app/components/Icons/Lock';
import usePrices from 'app/hooks/use-prices';
import PlaneOutline from 'app/components/Icons/PlaneOutline';
import config from 'app/config';
import Passkeys from './Passkeys/Passkeys';
import useConfirm from 'app/hooks/use-confirm';
import * as StoreReview from 'app/store-review';
import { AuthContext } from 'app/hooks/use-auth/context';
import Customization from './Customization';
import BetaFeatures from './BetaFeatures';

interface Props {
  setting: Setting;
  navigation: ScreenProps<
    typeof Routes.USER | typeof Routes.USER
  >['navigation'];
}

const productTypeToIcon = {
  free: Sparkles,
  plus: Fireworks,
};

function Settings({
  navigation,
}: ScreenProps<typeof Routes.USER | typeof Routes.USER>) {
  const { setting, ready } = useResource('setting', 'me', {
    // TODO: useResource should be smart enough to dedupe. It'll require
    // a separate, non-react-state, method for request caching.
    fetch: false,
  });

  if (!ready) {
    return null;
  }

  return <SettingsScreen setting={setting} navigation={navigation} />;
}

function SettingsScreen({ setting, navigation }: Props) {
  const copy = useCopy([
    'logoutButtonText',
    'settingsTitle',
    'settingsShareableEnabledHelpText',
    'settingsShareableDisabledHelpText',
    'settingsDisplayNamePlaceholder',
    'settingsResetTips',
    'settingsSendFeedback',
    'settingsGetBrowserExtensions',
    'homePageLinkCopied',
    'authenticationPageFooterText',
    'settingsDisplayNameUpdatedConfirmation',
    'settingsShareableEnabledConfirmation',
    'settingsShareableDisabledConfirmation',
    'createAccountPrivacyButtonText',
    'createAccountTermsButtonText',
    'createAccountAndButtonText',
    'settingsDeleteUserTitle',
    'settingsDeleteUserBody',
    'settingsDeleteUserBodyWithSubscription',
    'settingsDeleteUserConfirmText',
    'settingsDeleteUserConfirmTextWithSubscription',
    'settingsDeleteUserConfirmToast',
  ]);
  const auth = useContext(AuthContext);
  const updateSettings = useUpdate('setting');
  const setToast = useToast();
  const shareLink = useShareLink();
  const buildLink = useLinkBuilder();
  const tipListAction = useListAction('tip');
  const invalidateStuff = useInvalidateList('stuff');
  const confirm = useConfirm();
  const deleteUser = useDelete('user');

  const [displayName, _setDisplayName] = useState(
    setting ? setting.attributes.display_name : '',
  );
  const [isPublic, _setPublic] = useState(
    setting ? setting.attributes.public : true,
  );

  const debouncedUpdateSettings = useCallback(
    debounce(
      (
        id: string,
        attributes: Partial<Setting['attributes']>,
        confirmationText: string,
      ) => {
        updateSettings(id, {
          id: id,
          attributes,
          type: 'setting',
        });

        setToast({
          text: confirmationText,
          type: 'info',
          delay: 4000,
          portalHostName: 'modal',
        });
      },
      1000,
    ),
    [updateSettings, setToast],
  );

  const setDisplayName = useCallback(
    (_displayName: string) => {
      _setDisplayName(_displayName);

      if (_displayName) {
        debouncedUpdateSettings(
          setting.id,
          {
            ...setting?.attributes,
            display_name: _displayName,
          },
          copy.settingsDisplayNameUpdatedConfirmation,
        );
      }
    },
    [
      debouncedUpdateSettings,
      setting,
      copy.settingsDisplayNameUpdatedConfirmation,
    ],
  );

  const setPublic = useCallback(
    (_public: boolean) => {
      _setPublic(_public);

      debouncedUpdateSettings(
        setting.id,
        {
          ...setting?.attributes,
          public: _public,
        },
        _public
          ? copy.settingsShareableEnabledConfirmation
          : copy.settingsShareableDisabledConfirmation,
      );
    },
    [
      debouncedUpdateSettings,
      setting,
      copy.settingsShareableDisabledConfirmation,
      copy.settingsShareableEnabledConfirmation,
    ],
  );

  useEffect(() => {
    _setDisplayName(setting.attributes.display_name);
  }, [setting.attributes.display_name]);

  const shareableUrl = useMemo(() => {
    const path = buildLink(Routes.USER, {
      username: setting?.attributes.username,
    });
    return `https://coolstuff.app${path}`;
  }, [buildLink, setting?.attributes.username]);

  const onShare = useCallback(() => {
    shareLink({
      url: shareableUrl,
      shareConfirmation: copy.homePageLinkCopied,
    });
  }, [copy.homePageLinkCopied, shareLink, shareableUrl]);

  const { prices, renewalInfo, isSubscribed } = usePrices();

  const onResetTips = useCallback(async () => {
    setToast({
      text: 'Tips reset',
      type: 'info',
      delay: 4000,
      portalHostName: 'modal',
    });

    await tipListAction('reset_onboarding_tips', 'POST');
    invalidateStuff();
  }, [setToast, tipListAction, invalidateStuff]);

  const onPressPrice = useCallback(() => {
    navigation.navigate(Routes.SUBSCRIPTION, {
      screen: Routes.PRICES,
    });
  }, [navigation]);

  const onLogout = useCallback(() => {
    auth.setToken('');
  }, [auth]);

  const onDeleteUser = useCallback(() => {
    let body = copy.settingsDeleteUserBody;
    let confirmText = copy.settingsDeleteUserConfirmText;
    let confirmButtonType = ButtonType.secondaryDanger;

    if (isSubscribed) {
      body = copy.settingsDeleteUserBodyWithSubscription;
      confirmText = copy.settingsDeleteUserConfirmTextWithSubscription;
      confirmButtonType = ButtonType.secondaryBold;
    }

    const callback = async (didConfirm: boolean) => {
      if (!didConfirm) {
        return;
      }

      if (setting.attributes.username == 'appstorereview') {
        setToast({
          type: 'info',
          text: 'App Store review demo account cannot be deleted.',
          delay: 5000,
        });
        return;
      }

      if (isSubscribed) {
        onPressPrice();
      } else {
        await deleteUser(setting.attributes.username);
        onLogout();
        navigation.navigate(Routes.HOME);
        setToast({
          type: 'info',
          text: copy.settingsDeleteUserConfirmToast,
          delay: 5000,
        });
      }
    };

    confirm({
      title: copy.settingsDeleteUserTitle,
      body,
      confirmText,
      confirmButtonType,
      callback,
    });
  }, [
    confirm,
    copy,
    isSubscribed,
    deleteUser,
    onLogout,
    onPressPrice,
    setToast,
    setting.attributes.username,
    navigation,
  ]);

  return (
    <Page title={copy.settingsTitle} fullHeight>
      <View style={styles.container}>
        <List
          help={
            isPublic
              ? copy.settingsShareableEnabledHelpText
              : copy.settingsShareableDisabledHelpText
          }
        >
          <ListItem>
            <ListItemLabel>Display Name</ListItemLabel>
            <ListItemInput
              placeholder={copy.settingsDisplayNamePlaceholder}
              onChangeText={setDisplayName}
              value={displayName}
              autoCapitalize="none"
              returnKeyType="next"
              spellCheck={false}
              autoComplete="off"
              autoCorrect={false}
            />
          </ListItem>
          <ListItem onPress={() => setPublic(!isPublic)}>
            <ListItemLabel>Shareable</ListItemLabel>
            <ListItemSwitch onValueChange={setPublic} value={isPublic} />
          </ListItem>
        </List>

        <List
          help={
            <View style={styles.publicHelpTextContainer}>
              <Lock color="LayeredGray" />
              <Text
                type={TextType.caption1}
                color="LayeredGray"
                style={styles.publicHelpText}
              >
                {`coolstuff.app/${setting?.attributes.username}`}
              </Text>
            </View>
          }
        >
          <ListItem onPress={onShare} disabled={!isPublic}>
            <ListItemLabel color="blue9">Share Your Link</ListItemLabel>
            <ListItemIcon Icon={PlaneOutline} color="blue9" />
          </ListItem>
        </List>

        <List
          title="PLAN"
          help={renewalInfo}
          helpPosition="right"
          helpColor={
            renewalInfo.includes('Expires') ? 'DangerRed' : 'LayeredGray'
          }
        >
          {prices &&
            prices.map((price) => (
              <ListItem key={price.productType} onPress={onPressPrice} withIcon>
                <ListItemIcon
                  Icon={productTypeToIcon[price.productType]}
                  width={32}
                />
                <ListItemLabel type={TextType.body} subtitle={price.subtitle}>
                  {price.title}
                </ListItemLabel>
                <ListItemIcon Icon={price.isSubscribed ? Checkmark : Chevron} />
              </ListItem>
            ))}
        </List>

        <Customization setting={setting} navigation={navigation} />
        <BetaFeatures
          setting={setting}
          navigation={navigation}
          updateSettings={debouncedUpdateSettings}
        />

        <List>
          {Platform.OS === 'web' && (
            <ListItem
              onPress={() => Linking.openURL(config.urls.iosApp)}
              href={config.urls.iosApp}
              hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
            >
              <ListItemLabel>iPhone App</ListItemLabel>
              <ListItemIcon Icon={Chevron} />
            </ListItem>
          )}
          <ListItem
            onPress={() => Linking.openURL(config.urls.safariExtension)}
            href={config.urls.safariExtension}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            <ListItemLabel>Safari Extension</ListItemLabel>
            <ListItemIcon Icon={Chevron} />
          </ListItem>
          <ListItem
            onPress={() => Linking.openURL(config.urls.chromeExtension)}
            href={config.urls.chromeExtension}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            <ListItemLabel>Chrome Extension</ListItemLabel>
            <ListItemIcon Icon={Chevron} />
          </ListItem>
        </List>

        <Passkeys
          email={setting.attributes.email}
          username={setting.attributes.username}
        />

        <List help="hey@coolstuff.app">
          <ListItem
            onPress={() => Linking.openURL(config.urls.instagram)}
            href={config.urls.instagram}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            <ListItemLabel>Follow on Instagram</ListItemLabel>
            <ListItemIcon Icon={Chevron} />
          </ListItem>
          {Platform.OS !== 'web' && (
            <ListItem onPress={() => StoreReview.requestReview()}>
              <ListItemLabel>Leave Review</ListItemLabel>
              <ListItemIcon Icon={Chevron} />
            </ListItem>
          )}
          <ListItem
            onPress={() => Linking.openURL(config.urls.contact)}
            href={config.urls.contact}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            <ListItemLabel>Contact Us</ListItemLabel>
            <ListItemIcon Icon={Chevron} />
          </ListItem>
        </List>

        <List>
          <ListItem
            onPress={() => Linking.openURL(config.urls.terms)}
            href={config.urls.terms}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            <ListItemLabel>Terms of Use</ListItemLabel>
            <ListItemIcon Icon={Chevron} />
          </ListItem>
          <ListItem
            onPress={() => Linking.openURL(config.urls.privacy)}
            href={config.urls.privacy}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            <ListItemLabel>Privacy Policy</ListItemLabel>
            <ListItemIcon Icon={Chevron} />
          </ListItem>
        </List>

        <View style={styles.buttons}>
          <Button
            style={styles.button}
            type={ButtonType.secondary}
            shadow={false}
            onPress={onResetTips}
          >
            {copy.settingsResetTips}
          </Button>
          <Button
            style={styles.button}
            type={ButtonType.secondary}
            shadow={false}
            onPress={() => auth.setToken('')}
          >
            {copy.logoutButtonText}
          </Button>
          <Button
            style={styles.button}
            type={ButtonType.tertiaryDanger}
            shadow={false}
            size="small"
            onPress={onDeleteUser}
          >
            Delete Account
          </Button>
        </View>
        <View style={styles.footer}>
          <Text
            type={TextType.subheadline}
            style={styles.footer}
            color="LayeredGray"
          >
            Cool Stuff
          </Text>
        </View>
      </View>
    </Page>
  );
}

const styles = StyleSheet.create({
  container: {
    gap: 36,
  },
  input: {
    marginBottom: 24,
  },
  inputLabel: {
    marginBottom: 8,
    marginLeft: 20,
  },
  logout: {},
  buttons: {
    gap: 12,
  },
  button: {
    alignItems: 'center',
  },
  feedbackSpanner: {
    transform: [{ rotateY: '180deg' }],
    marginLeft: 4,
  },
  footer: {
    alignItems: 'center',
  },
  publicHelpTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
    marginTop: 8,
  },
  publicHelpText: {
    marginLeft: 6,
  },
});

export default Settings;
