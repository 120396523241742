import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const Heart = ({ width = 30, height = 26, color }: Props) => {
  const colorToUse = useColor(color || '#EFA7EB');

  return (
    <Svg width={width} height={height} viewBox="0 0 30 26" fill="none">
      <Path
        d="M2.37449 2.37064C-0.791497 5.53148 -0.791495 10.6562 2.37449 13.8171L12.7819 24.157C13.8425 25.2107 14.3728 25.7376 15.0302 25.7368C15.6876 25.736 16.2166 25.2078 17.2746 24.1515L27.6255 13.8174C30.7915 10.6566 30.7915 5.53181 27.6255 2.37096C24.4595 -0.789882 19.3265 -0.789885 16.1605 2.37096L15.0002 3.52938L13.8395 2.37063C10.6735 -0.790213 5.54047 -0.790211 2.37449 2.37064Z"
        fill={colorToUse}
      />
    </Svg>
  );
};

export default Heart;
