import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const Trash = ({ width = 17, height = 18, color = 'red9' }: Props) => {
  const colorToUse = useColor(color);
  return (
    <Svg width={width} height={height} viewBox="0 0 17 18" fill="none">
      <Path
        d="M4.25 9C4.25 9.4675 4.6325 9.85 5.1 9.85H11.9C12.3675 9.85 12.75 9.4675 12.75 9C12.75 8.5325 12.3675 8.15 11.9 8.15H5.1C4.6325 8.15 4.25 8.5325 4.25 9ZM8.5 0.5C3.808 0.5 0 4.308 0 9C0 13.692 3.808 17.5 8.5 17.5C13.192 17.5 17 13.692 17 9C17 4.308 13.192 0.5 8.5 0.5ZM8.5 15.8C4.7515 15.8 1.7 12.7485 1.7 9C1.7 5.2515 4.7515 2.2 8.5 2.2C12.2485 2.2 15.3 5.2515 15.3 9C15.3 12.7485 12.2485 15.8 8.5 15.8Z"
        fill={colorToUse}
      />
    </Svg>
  );
};

export default Trash;
