import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
  opacity?: number;
}

const OurStuff = ({ width = 31, height = 31, color, opacity = 1 }: Props) => {
  const colorToUse = useColor(color || '#B8B8BA');

  return (
    <Svg width={width} height={height} viewBox="0 0 31 31" fill="none">
      <Rect
        x="0.607422"
        y="6.94434"
        width="30.0002"
        height="23.5104"
        rx="3.65445"
        fill={colorToUse}
      />
      <Path
        d="M16.6713 23.5824C16.3941 23.5824 16.2176 23.4061 16.1798 23.154C15.6254 19.3483 15.1339 18.504 11.1519 18.1008C10.8873 18.0629 10.7235 17.8865 10.7235 17.6219C10.7235 17.3951 10.8873 17.1934 11.1519 17.1556C15.1213 16.6138 15.4615 15.9333 16.1798 12.1024C16.2429 11.8504 16.4192 11.6865 16.6713 11.6865C16.9107 11.6865 17.0997 11.8629 17.1375 12.115C17.7046 15.8577 18.2212 16.6138 22.178 17.1556C22.4301 17.1934 22.6064 17.3951 22.6064 17.6219C22.6064 17.8865 22.4301 18.0755 22.178 18.1008C18.2086 18.6427 17.8558 19.3483 17.1375 23.1666C17.0871 23.4061 16.9107 23.5824 16.6713 23.5824ZM11.5552 25.7122C11.3535 25.7122 11.2528 25.5735 11.1898 25.3719C10.8117 23.5194 10.9755 23.4313 8.93408 23.1289C8.71986 23.091 8.60645 22.965 8.60645 22.7634C8.60645 22.587 8.71986 22.4357 8.90888 22.4105C10.9755 22.0703 10.8117 21.982 11.1898 20.1422C11.2528 19.928 11.3661 19.802 11.5552 19.802C11.7442 19.802 11.8702 19.9406 11.9205 20.1422C12.2735 21.982 12.1474 22.0703 14.2141 22.4105C14.3904 22.4357 14.5165 22.587 14.5165 22.7634C14.5165 22.9398 14.3904 23.091 14.2141 23.1289C12.1474 23.4313 12.2735 23.5194 11.9205 25.4098C11.8702 25.5861 11.7316 25.7122 11.5552 25.7122Z"
        fill="#939396"
        fillOpacity={opacity}
      />
      <Path
        d="M6.5918 2.47697C6.5918 1.36017 7.49714 0.454834 8.61393 0.454834H22.5991C23.7159 0.454834 24.6213 1.36017 24.6213 2.47697H6.5918Z"
        fill={colorToUse}
        fillOpacity={opacity}
      />
      <Path
        d="M3.65234 5.72184C3.65234 4.60505 4.55768 3.69971 5.67448 3.69971H25.541C26.6578 3.69971 27.5632 4.60505 27.5632 5.72184H3.65234Z"
        fill={colorToUse}
        fillOpacity={opacity}
      />
    </Svg>
  );
};

export default OurStuff;
