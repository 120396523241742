import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';
import { StyleSheet } from 'react-native';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const LogoHorizontalText = ({ width = 150, height = 18.28, color }: Props) => {
  const textColor = useColor(color || 'gray12');

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 143 24"
      fill="none"
      style={styles.container}
    >
      <Path
        d="M11.2696 24C17.2896 24 21.5278 20.1311 21.7365 14.9458L21.7525 14.7692H16.0856L16.0535 14.9458C15.6522 17.5304 13.8381 19.1197 11.2856 19.1197C8.05886 19.1197 6.00401 16.4548 6.00401 11.9759V11.9599C6.00401 7.5291 8.04281 4.88027 11.2696 4.88027C13.9666 4.88027 15.6522 6.58194 16.0214 8.98997L16.0535 9.23077H21.7204V9.07023C21.5117 3.88495 17.3217 0 11.2696 0C4.3505 0 0 4.41472 0 11.9759V11.992C0 19.5532 4.31839 24 11.2696 24Z"
        fill={textColor}
      />
      <Path
        d="M31.2085 23.9518C36.7149 23.9518 40.1503 20.6288 40.1503 14.8495V14.8174C40.1503 9.11839 36.6507 5.7311 31.2085 5.7311C25.7824 5.7311 22.2667 9.1505 22.2667 14.8174V14.8495C22.2667 20.6127 25.6701 23.9518 31.2085 23.9518ZM31.2246 19.6334C29.25 19.6334 28.046 17.9318 28.046 14.8495V14.8174C28.046 11.7993 29.2821 10.0495 31.2085 10.0495C33.135 10.0495 34.3871 11.7993 34.3871 14.8174V14.8495C34.3871 17.9157 33.1671 19.6334 31.2246 19.6334Z"
        fill={textColor}
      />
      <Path
        d="M49.4137 23.9518C54.9201 23.9518 58.3555 20.6288 58.3555 14.8495V14.8174C58.3555 9.11839 54.8559 5.7311 49.4137 5.7311C43.9876 5.7311 40.4719 9.1505 40.4719 14.8174V14.8495C40.4719 20.6127 43.8753 23.9518 49.4137 23.9518ZM49.4298 19.6334C47.4552 19.6334 46.2512 17.9318 46.2512 14.8495V14.8174C46.2512 11.7993 47.4873 10.0495 49.4137 10.0495C51.3402 10.0495 52.5923 11.7993 52.5923 14.8174V14.8495C52.5923 17.9157 51.3723 19.6334 49.4298 19.6334Z"
        fill={textColor}
      />
      <Path
        d="M58.8704 23.5788H64.5694V0.41357H58.8704V23.5788Z"
        fill={textColor}
      />
      <Path
        d="M80.4181 24C86.5505 24 90.1786 21.0622 90.1786 16.4388V16.4227C90.1786 12.7144 87.899 10.7237 82.9866 9.77659L80.6107 9.32709C78.1224 8.86154 77.0308 8.20334 77.0308 6.95117V6.93512C77.0308 5.60268 78.2508 4.62341 80.4181 4.62341C82.5371 4.62341 84.0301 5.57057 84.2067 7.09565L84.2227 7.27224H89.697L89.6809 6.93512C89.4722 2.7291 86.1813 0 80.3699 0C75.0562 0 71.1873 2.87358 71.1873 7.33645V7.35251C71.1873 10.9003 73.6114 13.1639 78.2027 14.0147L80.5786 14.4642C83.2916 14.994 84.3191 15.604 84.3191 16.9204V16.9365C84.3191 18.3813 82.8742 19.3766 80.5304 19.3766C78.2669 19.3766 76.5331 18.3973 76.2441 16.9043L76.212 16.7278H70.7378L70.7538 17.0167C71.0107 21.4475 74.6548 24 80.4181 24Z"
        fill={textColor}
      />
      <Path
        d="M98.7677 23.9518C99.9557 23.9518 100.807 23.8555 101.433 23.7431V19.6977C101.112 19.7458 100.823 19.7779 100.373 19.7779C98.9764 19.7779 98.3022 19.2161 98.3022 17.707V10.2742H101.433V6.10033H98.3022V2.0709H92.6032V6.10033H90.2273V10.2742H92.6032V18.4776C92.6032 22.4268 94.5296 23.9518 98.7677 23.9518Z"
        fill={textColor}
      />
      <Path
        d="M107.965 23.9518C110.614 23.9518 112.396 22.6997 113.279 20.5003H113.391V23.5826H119.074V6.10033H113.391V16.0214C113.391 17.9639 112.251 19.3284 110.453 19.3284C108.575 19.3284 107.644 18.1405 107.644 16.1338V6.10033H101.945V17.3538C101.945 21.4957 104.128 23.9518 107.965 23.9518Z"
        fill={textColor}
      />
      <Path
        d="M121.916 23.5826H127.615V10.2742H130.858H133.186V23.5826H138.885V10.2742H142.128V6.10033H138.724V5.34582C138.724 4.33445 139.383 3.74047 140.956 3.74047C141.389 3.74047 141.839 3.75652 142.16 3.82074V0.288963C141.534 0.160535 140.426 0.0481605 139.431 0.0481605C134.856 0.0481605 133.186 1.63746 133.186 5.08896V6.10033H130.858H127.454V5.34582C127.454 4.33445 128.113 3.74047 129.686 3.74047C130.119 3.74047 130.569 3.75652 130.89 3.82074V0.288963C130.264 0.160535 129.156 0.0481605 128.161 0.0481605C123.585 0.0481605 121.916 1.63746 121.916 5.08896V6.10033H119.588V10.2742H121.916V23.5826Z"
        fill={textColor}
      />
    </Svg>
  );
};

const styles = StyleSheet.create({
  container: {
    overflow: 'visible',
  },
});

export default LogoHorizontalText;
