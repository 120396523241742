import React, { useCallback, useState } from 'react';
import List, { ListItem } from 'app/components/List';
import { Feature, Setting } from 'app/hooks/use-resource/types';
import ListItemLabel from 'app/components/List/ListItemLabel';
import { NavigationProp } from '@react-navigation/native';
import Routes from 'app/components/Navigator/ROUTES';
import useCopy from 'app/hooks/use-copy';
import ListItemSwitch from 'app/components/List/ListItemSwitch';

const featureConfig: { feature: Feature; title: string; subtitle?: string }[] =
  [
    { feature: 'collections', title: 'Collections' },
    { feature: 'smart_searches', title: 'Suggestions' },
    {
      feature: 'view_states',
      title: 'Pinch to Zoom',
    },
  ];

function BetaFeatures({
  setting,
  navigation,
  updateSettings,
}: {
  setting: Setting;
  // TODO: Type navigation properly
  navigation: NavigationProp<ReactNavigation.RootParamList>;
  updateSettings: (
    id: string,

    attributes: Partial<Setting['attributes']>,
    confirmationText: string,
  ) => void;
}) {
  const copy = useCopy([
    'settingsBetaFeaturesTitle',
    'settingsBetaFeaturesHelpText',
    'settingsBetaFeaturesHelpTextWithoutPlus',
    'settingsBetaFeaturesToggleEnabled',
    'settingsBetaFeaturesToggleDisabled',
  ]);
  const hasBetaFeaturesEntitlement =
    setting && setting.attributes.entitlements.includes('beta_features');
  const [featureEnabledLocal, setFeatureEnabledLocal] = useState(() =>
    Object.fromEntries(
      featureConfig.map(({ feature }) => [
        feature,
        setting.attributes.features.includes(feature),
      ]),
    ),
  );

  const openPrices = useCallback(() => {
    navigation.navigate(Routes.SUBSCRIPTION, {
      screen: Routes.PRICES,
    });
  }, [navigation]);

  const updateFeature = (feature: Feature, title: string, enabled: boolean) => {
    let newFeatures = [...setting.attributes.features];
    if (enabled) {
      newFeatures.push(feature);
    } else {
      newFeatures = newFeatures.filter((f) => f !== feature);
    }

    setFeatureEnabledLocal((existing) => ({
      ...existing,
      [feature]: enabled,
    }));

    const confirmationText = `${title} ${
      enabled
        ? copy.settingsBetaFeaturesToggleEnabled
        : copy.settingsBetaFeaturesToggleDisabled
    }`;

    updateSettings(
      'me',
      {
        features: newFeatures,
      },
      confirmationText,
    );
  };

  return (
    <List
      title={copy.settingsBetaFeaturesTitle}
      help={
        hasBetaFeaturesEntitlement
          ? copy.settingsBetaFeaturesHelpText
          : copy.settingsBetaFeaturesHelpTextWithoutPlus
      }
    >
      {featureConfig.map(({ title, feature, subtitle }) => {
        const enabled = featureEnabledLocal[feature];

        return (
          <ListItem
            key={feature}
            disabled={!hasBetaFeaturesEntitlement}
            onPress={() => updateFeature(feature, title, !enabled)}
            disabledOnPress={openPrices}
          >
            <ListItemLabel subtitle={subtitle}>{title}</ListItemLabel>
            <ListItemSwitch
              onValueChange={(newEnabled) =>
                updateFeature(feature, title, newEnabled)
              }
              value={enabled}
              disabled={!hasBetaFeaturesEntitlement}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

export default BetaFeatures;
