import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

export default function Sparkles({ width = 30, height = 39.97, color }: Props) {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 24 31" fill="none">
      <G clipPath="url(#clip0_376_9243)">
        <Path
          d="M11.1233 6.11467C11.2865 6.11467 11.3745 6.01423 11.3995 5.86356C11.7763 3.82952 11.7385 3.72907 13.9233 3.33985C14.0739 3.30217 14.1743 3.21429 14.1743 3.05106C14.1743 2.90039 14.0739 2.79994 13.9233 2.77483C11.7385 2.3856 11.7763 2.28515 11.3995 0.251116C11.3745 0.100446 11.2865 0 11.1233 0C10.96 0 10.8722 0.100446 10.8471 0.251116C10.4704 2.28515 10.5081 2.3856 8.32338 2.77483C8.16016 2.79994 8.07227 2.90039 8.07227 3.05106C8.07227 3.21429 8.16016 3.30217 8.32338 3.33985C10.5081 3.72907 10.4704 3.82952 10.8471 5.86356C10.8722 6.01423 10.96 6.11467 11.1233 6.11467Z"
          fill={colorToUse || 'url(#paint0_linear_376_9243)'}
        />
        <Path
          d="M5.04688 14.753C5.28544 14.753 5.44866 14.5899 5.47378 14.3638C5.92578 11.0114 6.03878 11.0114 9.50419 10.346C9.71763 10.3083 9.88086 10.1576 9.88086 9.91908C9.88086 9.69309 9.71763 9.52986 9.50419 9.50474C6.03878 9.01507 5.91322 8.90206 5.47378 5.49944C5.44866 5.26089 5.28544 5.09766 5.04688 5.09766C4.82087 5.09766 4.65765 5.26089 4.61998 5.512C4.21819 8.8644 4.02985 8.85185 0.589564 9.50474C0.376116 9.54241 0.212891 9.69309 0.212891 9.91908C0.212891 10.1702 0.376116 10.3083 0.639788 10.346C4.05497 10.8985 4.21819 10.9863 4.61998 14.3387C4.65765 14.5899 4.82087 14.753 5.04688 14.753Z"
          fill={colorToUse || 'url(#paint0_linear_376_9243)'}
        />
        <Path
          d="M13.5591 28.64C13.8856 28.64 14.1242 28.4013 14.187 28.0623C15.0784 21.1943 16.0451 20.1397 22.8504 19.3863C23.202 19.3486 23.4406 19.0975 23.4406 18.7585C23.4406 18.432 23.202 18.181 22.8504 18.1433C16.0451 17.39 15.0784 16.3352 14.187 9.45466C14.1242 9.11564 13.8856 8.88965 13.5591 8.88965C13.2326 8.88965 12.9941 9.11564 12.9439 9.45466C12.0525 16.3352 11.0731 17.39 4.28041 18.1433C3.91629 18.181 3.67773 18.432 3.67773 18.7585C3.67773 19.0975 3.91629 19.3486 4.28041 19.3863C11.0605 20.2778 12.0022 21.1943 12.9439 28.0623C12.9941 28.4013 13.2326 28.64 13.5591 28.64Z"
          fill={colorToUse || 'url(#paint0_linear_376_9243)'}
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_376_9243"
          x1="12.4271"
          y1="7.44134"
          x2="11.8846"
          y2="-5.60978"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0" stopColor="#1088FC" />
          <Stop offset="1" stopColor="#46B0FB" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_376_9243"
          x1="7.11257"
          y1="16.8479"
          x2="6.25877"
          y2="-3.76071"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0" stopColor="#1088FC" />
          <Stop offset="1" stopColor="#46B0FB" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_376_9243"
          x1="17.7817"
          y1="32.9251"
          x2="16.0341"
          y2="-9.23025"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0" stopColor="#1088FC" />
          <Stop offset="1" stopColor="#46B0FB" />
        </LinearGradient>
        <ClipPath id="clip0_376_9243">
          <Rect
            width="23.2283"
            height="30.95"
            fill="white"
            transform="translate(0.214844)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
