import * as React from 'react';
import Svg, {
  Defs,
  G,
  LinearGradient,
  Mask,
  Path,
  RadialGradient,
  Stop,
} from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
}

const Browsers = ({ width = 41, height = 33 }: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 41 33" fill="none">
      <G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9639 3.55176C23.5622 3.55176 25.8963 5.88578 25.8963 9.48408V22.5157C25.8963 26.114 23.5622 28.448 19.9639 28.448H6.93232C3.33402 28.448 1 26.114 1 22.5157V9.48408C1 5.88578 3.33402 3.55176 6.93232 3.55176H19.9639Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9639 3.55176C23.5622 3.55176 25.8963 5.88578 25.8963 9.48408V22.5157C25.8963 26.114 23.5622 28.448 19.9639 28.448H6.93232C3.33402 28.448 1 26.114 1 22.5157V9.48408C1 5.88578 3.33402 3.55176 6.93232 3.55176H19.9639Z"
          fill="white"
        />
        <G>
          <Mask
            id="mask0_2483_25610"
            maskUnits="userSpaceOnUse"
            x="4"
            y="6"
            width="19"
            height="20"
          >
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.448 25.1286C18.4896 25.1286 22.5766 21.0416 22.5766 16C22.5766 10.9584 18.4896 6.87134 13.448 6.87134C8.40637 6.87134 4.31934 10.9584 4.31934 16C4.31934 21.0416 8.40637 25.1286 13.448 25.1286Z"
              fill="white"
            />
          </Mask>
          <G mask="url(#mask0_2483_25610)">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.01855 9.64759L9.97532 18.2879L13.4041 11.8514L25.3219 11.8657L14.5114 4.69336L5.01855 9.64759Z"
              fill="#DC493C"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.837 11.8522L13.3847 11.8508C13.3847 11.8508 14.9961 12.1111 15.5674 12.5835C16.6343 13.4655 17.4855 15.112 17.4855 15.112L11.4189 28.6077L23.1325 21.6712L23.837 11.8522Z"
              fill="#FFCD41"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.4919 27.7423L17.0743 18.0139L10.014 18.3056L5.02192 9.65625L3.53613 21.9505L11.4919 27.7423Z"
              fill="#139F5B"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.4266 19.1684L12.9961 25.12L17.0334 18.093L14.4266 19.1684Z"
              fill="url(#paint0_linear_2483_25610)"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.79883 11.0059L9.5507 14.8449L9.64827 17.6768L5.79883 11.0059Z"
              fill="url(#paint1_linear_2483_25610)"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.4258 11.8525H21.5796L14.8696 13.6683L13.4258 11.8525Z"
              fill="url(#paint2_linear_2483_25610)"
            />
            <Path
              d="M13.4475 19.8382C15.5673 19.8382 17.2857 18.1198 17.2857 16C17.2857 13.8803 15.5673 12.1619 13.4475 12.1619C11.3278 12.1619 9.60938 13.8803 9.60938 16C9.60938 18.1198 11.3278 19.8382 13.4475 19.8382Z"
              fill="#4687F4"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.29785 16C9.29785 13.7083 11.1556 11.8506 13.4472 11.8506C15.7389 11.8506 17.5966 13.7083 17.5966 16C17.5966 18.2916 15.7389 20.1493 13.4472 20.1493C11.1556 20.1493 9.29785 18.2916 9.29785 16ZM13.4472 12.473C11.4993 12.473 9.92026 14.0521 9.92026 16C9.92026 17.9479 11.4993 19.5269 13.4472 19.5269C15.3951 19.5269 16.9742 17.9479 16.9742 16C16.9742 14.0521 15.3951 12.473 13.4472 12.473Z"
              fill="white"
            />
          </G>
        </G>
      </G>
      <G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.28126 24.5V7.50001C9.27956 6.64594 9.44652 5.79994 9.77257 5.01055C10.0986 4.22117 10.5773 3.50393 11.1813 2.90001C11.7852 2.29609 12.5024 1.81737 13.2918 1.49132C14.0812 1.16527 14.9272 0.998307 15.7813 1.00001H32.7813C33.6353 0.998307 34.4813 1.16527 35.2707 1.49132C36.0601 1.81737 36.7773 2.29609 37.3813 2.90001C37.9852 3.50393 38.4639 4.22117 38.79 5.01055C39.116 5.79994 39.283 6.64594 39.2813 7.50001V24.5C39.283 25.3541 39.116 26.2001 38.79 26.9895C38.4639 27.7789 37.9852 28.4961 37.3813 29.1C36.7773 29.7039 36.0601 30.1827 35.2707 30.5087C34.4813 30.8348 33.6353 31.0017 32.7813 31H15.7813C14.9272 31.0017 14.0812 30.8348 13.2918 30.5087C12.5024 30.1827 11.7852 29.7039 11.1813 29.1C10.5773 28.4961 10.0986 27.7789 9.77257 26.9895C9.44652 26.2001 9.27956 25.3541 9.28126 24.5Z"
          fill="white"
        />
        <Path
          d="M24.3053 29.0249C31.4988 29.0249 37.3303 23.1934 37.3303 15.9999C37.3303 8.80634 31.4988 2.97485 24.3053 2.97485C17.1118 2.97485 11.2803 8.80634 11.2803 15.9999C11.2803 23.1934 17.1118 29.0249 24.3053 29.0249Z"
          fill="url(#paint3_linear_2483_25610)"
        />
        <Path
          d="M24.3057 27.8699V25.8699"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M24.3057 6.11987V4.11987"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M22.2383 27.6966L22.5856 25.7269M26.0151 6.27699L26.3624 4.30737"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M20.2422 27.1566L20.9262 25.2772M27.6811 6.71825L28.3652 4.83887"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M18.3574 26.2912L19.3574 24.5592M29.2324 7.45519L30.2324 5.72314"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M16.6826 25.0986L17.9682 23.5665M30.6632 8.43712L31.9488 6.90503"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M15.1953 23.6297L16.7274 22.3442M31.8568 9.6491L33.3889 8.36353"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M14.0156 21.9302L15.7477 20.9302M32.8517 11.0552L34.5837 10.0552"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M13.127 20.0629L15.0063 19.3789M33.5653 12.624L35.4446 11.9399"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M12.6025 18.0746L14.5722 17.7273M34.0221 14.2977L35.9917 13.9504"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M12.4346 16.0005H14.4346M34.1846 16.0005H36.1846"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M12.6084 13.946L14.578 14.2933M34.028 17.7229L35.9976 18.0702"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M13.1475 11.9429L15.0268 12.6269M33.5858 19.3818L35.4652 20.0658"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M14.0117 10.0767L15.7438 11.0767M32.8478 20.9517L34.5798 21.9517"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M15.1992 8.35938L16.7313 9.64495M31.8607 22.34L33.3928 23.6256"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M16.6582 6.90625L17.9438 8.43834M30.6388 23.5677L31.9244 25.0998"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M18.3604 5.71484L19.3604 7.44689M29.2354 24.5509L30.2354 26.2829"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M20.2432 4.83984L20.9272 6.71923M27.6821 25.2781L28.3661 27.1575"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M22.2305 4.32275L22.5778 6.29237M26.0073 25.7423L26.3546 27.7119"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M23.2637 27.8266L23.3508 26.8304M25.2465 5.16318L25.3336 4.16699"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M21.2354 27.482L21.4942 26.5161M27.1235 5.50719L27.3823 4.54126"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M19.2793 26.7543L19.7019 25.848M28.8939 6.1358L29.3165 5.22949"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M17.4902 25.7317L18.0638 24.9126M30.5391 7.09601L31.1127 6.27686"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M15.916 24.3975L16.6231 23.6904M32.0027 8.31087L32.7098 7.60376"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M14.5781 22.8236L15.3973 22.25M33.2138 9.77475L34.033 9.20117"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M13.5459 21.022L14.4522 20.5994M34.1644 11.4075L35.0707 10.9849"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M12.8311 19.0815L13.797 18.8227M34.8059 13.1934L35.7718 12.9346"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M12.4639 17.0377L13.4601 16.9506M35.1273 15.0549L36.1235 14.9678"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M12.4727 14.968L13.4689 15.0552M35.1361 16.9508L36.1323 17.038"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M12.8154 12.9382L13.7814 13.1971M34.7902 18.8264L35.7562 19.0852"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M13.5371 10.98L14.4434 11.4026M34.1556 20.5945L35.0619 21.0172"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M14.5732 9.19995L15.3924 9.77353M33.209 22.2488L34.0281 22.8224"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M15.9141 7.61084L16.6212 8.31795M32.0007 23.6975L32.7078 24.4046"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M17.4775 6.27075L18.0511 7.0899M30.5264 24.9065L31.1 25.7256"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M19.2852 5.24731L19.7078 6.15362M28.8997 25.8658L29.3223 26.7721"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M21.2227 4.54297L21.4815 5.50889M27.1108 26.5178L27.3696 27.4837"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          d="M23.2539 4.17749L23.3411 5.17369M25.2367 26.8409L25.3239 27.8371"
          stroke="white"
          strokeWidth="0.25075"
          strokeLinecap="square"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5479 8.26489L23.2729 14.8399L15.0479 23.7649L25.3729 17.3399L33.5479 8.26489Z"
          fill="white"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5484 8.26489L23.2734 14.8399L25.3734 17.3399L33.5484 8.26489Z"
          fill="#FF3B30"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_2483_25610"
          x1="13.2388"
          y1="16.7644"
          x2="9.7256"
          y2="21.4603"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#13834D" />
          <Stop offset="1" stopColor="#139F5B" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_2483_25610"
          x1="11.9016"
          y1="13.4312"
          x2="6.14047"
          y2="11.2075"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#B4372C" />
          <Stop offset="1" stopColor="#CF4539" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_2483_25610"
          x1="10.9127"
          y1="12.5718"
          x2="11.3727"
          y2="15.5872"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D3AA36" />
          <Stop offset="1" stopColor="#FFCD41" stopOpacity="0.01" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_2483_25610"
          x1="24.3051"
          y1="29.0283"
          x2="24.3051"
          y2="2.97249"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1E6FF1" />
          <Stop offset="1" stopColor="#28CEFB" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default Browsers;
