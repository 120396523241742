import BlurView from 'app/components/BlurView';
import Button, { ButtonType } from 'app/components/Button';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import Pressable from 'app/components/Pressable';
import CoolText, { fontWeights, TextType } from 'app/components/Text';
import TextInput from 'app/components/TextInput';
import config from 'app/config';
import { dark, light } from 'app/constants/colors';
import useAuth from 'app/hooks/use-auth';
import { APP_STORE_REVIEW_EMAIL } from 'app/hooks/use-auth/use-auth';
import useCopy from 'app/hooks/use-copy';
import useIsMobile from 'app/hooks/use-is-mobile';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet, View, Text } from 'react-native';
import Animated from 'react-native-reanimated';

const GuestHome = ({
  navigation,
  route,
}: ScreenProps<typeof Routes.USER | typeof Routes.USER>) => {
  const copy = useCopy([
    'signupEmailPlaceholder',
    'authenticationSeeOurStuff',
    'guestHomeTitle',
    'guestHomeSubtitle',
    'guestHomeLoginButtonText',
    'guestHomeSignupButtonText',
  ]);

  const { autofill, authenticate, errorShakeAnimatedValue, pending } =
    useAuth();
  const [email, setEmail] = useState<string>(route?.params?.email || '');
  const isMobile = useIsMobile();

  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    autofill();
  }, [autofill]);

  const handleLogin = useCallback(async () => {
    await authenticate(email, password);
  }, [email, password, authenticate]);

  return (
    <View style={styles.container}>
      <View style={[styles.content, isMobile && styles.contentMobile]}>
        <View style={[styles.contentTop, isMobile && styles.contentTopMobile]}>
          <Pressable
            href={config.urls.iosApp}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            <BlurView amount={8} style={styles.announcementContainer}>
              <Text style={styles.announcement}>
                Cool Stuff is now on iPhone
              </Text>
              <Text style={styles.announcement}>🎉</Text>
            </BlurView>
          </Pressable>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{copy.guestHomeTitle}</Text>
            <Text style={styles.subtitle}>{copy.guestHomeSubtitle}</Text>
          </View>
        </View>
        <Animated.View style={[styles.buttons]}>
          <TextInput
            placeholder={copy.signupEmailPlaceholder}
            onChangeText={setEmail}
            onSubmitEditing={handleLogin}
            value={email}
            placeholderColor={dark.whiteA11}
            color="white"
            autoCapitalize="none"
            textContentType="emailAddress"
            returnKeyType="next"
            type="fancy"
            autoComplete={Platform.select({
              web: 'webauthn',
              default: undefined,
            })}
          />
          {email === APP_STORE_REVIEW_EMAIL && (
            <TextInput
              placeholder="Password"
              onChangeText={setPassword}
              onSubmitEditing={handleLogin}
              value={password}
              placeholderColor={dark.whiteA11}
              color="white"
              autoCapitalize="none"
              textContentType="password"
              returnKeyType="next"
              type="fancy"
              secureTextEntry
            />
          )}
          <Button
            type={ButtonType.primary}
            onPress={handleLogin}
            pending={pending}
            shadow={false}
            shakeAnimatedValue={errorShakeAnimatedValue}
          >
            {copy.guestHomeLoginButtonText}
          </Button>
          <Button
            type={ButtonType.secondaryBold}
            onPress={() =>
              navigation.navigate(Routes.SIGNUP, {
                email,
              })
            }
            shadow={false}
            forceColorScheme="light"
          >
            {copy.guestHomeSignupButtonText}
          </Button>
          <Button
            type={ButtonType.tertiary}
            onPress={() => {
              if (Platform.OS !== 'web') {
                navigation.navigate(Routes.USER, { username: 'coolstuff' });
              }
            }}
            href="https://coolstuff.app/coolstuff"
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
            forceColorScheme="light"
          >
            {copy.authenticationSeeOurStuff}
          </Button>
        </Animated.View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 375,
    gap: 48,
    width: '100%',
  },
  contentMobile: {
    gap: 32,
  },
  contentTop: {
    gap: 28,
  },
  contentTopMobile: {
    gap: 20,
  },
  titleContainer: {
    alignItems: 'center',
    textShadowColor: 'rgba(0, 0, 0, 0.15)',
    textShadowOffset: {
      width: 0,
      height: 2,
    },
    textShadowRadius: 12,
  },
  title: {
    fontWeight: fontWeights.medium,
    fontSize: 17,
    color: light.white,
    textAlign: 'center',
  },
  subtitle: {
    fontWeight: fontWeights.medium,
    fontSize: 17,
    color: light.white,
    opacity: 0.6,
  },
  announcementContainer: {
    backgroundColor: light.LayeredSubtleGray,
    paddingVertical: 18,
    paddingHorizontal: 16,
    borderRadius: 34,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    gap: 8,
  },
  announcement: {
    fontWeight: fontWeights.medium,
    fontSize: 15,
    color: light.white,
    textAlign: 'center',
  },
  appDownload: {
    padding: 16,
    borderRadius: 100,
    backgroundColor: light.BackgroundGray,
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  buttons: {
    gap: 12,
  },
  seeOurCoolStuff: {
    alignSelf: 'center',
  },
  errors: {
    alignSelf: 'center',
    textAlign: 'center',
    paddingHorizontal: 16,
  },
});

export default GuestHome;
