import useColorScheme from 'app/hooks/use-color-scheme';
import useWindowDimensions from 'app/hooks/use-window-dimensions';
import React, { useCallback, useEffect } from 'react';
import {
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
  ViewStyle,
} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

interface Props extends KeyboardAvoidingViewProps {
  lightStyle?: ViewStyle;
  darkStyle?: ViewStyle;
}

function KeyboardAvoidingView({
  lightStyle,
  darkStyle,
  style,
  ...props
}: Props) {
  const colorScheme = useColorScheme();
  let styles = [];

  if (lightStyle || darkStyle) {
    styles.push(colorScheme === 'light' ? lightStyle : darkStyle);
  }

  if (style) {
    if (Array.isArray(style)) {
      style.filter((s) => s).forEach((s) => styles.push(s));
    } else {
      styles.push(style);
    }
  }

  const windowDimensions = useWindowDimensions();
  const webHeight = useSharedValue(windowDimensions.height);

  const onViewportResize = useCallback(
    (event) => {
      webHeight.value = withSpring(event.target.height, {
        stiffness: 50,
        mass: 0.3,
        damping: 20,
        overshootClamping: true,
      });
    },
    [webHeight],
  );

  useEffect(() => {
    if (Platform.OS !== 'web' || global.__IS_SERVER__) {
      return;
    }

    const visualViewport = window.visualViewport;

    if (!visualViewport) {
      return;
    }

    visualViewport.addEventListener('scroll', onViewportResize);
    visualViewport.addEventListener('resize', onViewportResize);

    return () => {
      visualViewport.removeEventListener('scroll', onViewportResize);
      visualViewport.removeEventListener('resize', onViewportResize);
    };
  }, [onViewportResize]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      maxHeight: webHeight.value,
    }),
    [webHeight],
  );

  if (Platform.OS === 'web') {
    styles.push(animatedStyle);
    return <Animated.View {...props} style={styles} />;
  }

  return <RNKeyboardAvoidingView {...props} style={styles} />;
}

export default KeyboardAvoidingView;
