import { useResource } from 'app/hooks/use-resource/use-resource';
import React from 'react';
import Filter from '../Filter';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';

const Topic = ({
  topicId,
  onChangeQuery,
}: {
  topicId: string;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
}) => {
  const { topic } = useResource('topic', topicId);

  if (!topic) {
    return null;
  }

  return (
    <Filter
      name={topic.attributes.title}
      onPress={() => {
        onChangeQuery({
          'filter[topic.id]': topicId,
        });
      }}
      enabled={topic.meta.enabled}
    />
  );
};

export default Topic;
