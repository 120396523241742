import BlurView from 'app/components/BlurView';
import CoolGradient from 'app/components/CoolGradient';
import Apple from 'app/components/Icons/Apple';
import Logo from 'app/components/Logo';
import Pressable from 'app/components/Pressable';
import { fontWeights } from 'app/components/Text';
import config from 'app/config';
import { light } from 'app/constants/colors';
import useCopy from 'app/hooks/use-copy';
import useIsMobile from 'app/hooks/use-is-mobile';
import useConfirm from 'app/hooks/use-confirm';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { ButtonType } from 'app/components/Button';

const AuthenticationContainer = ({ children }: PropsWithChildren) => {
  const isMobile = useIsMobile();
  const copy = useCopy([
    'signupButtonText',
    'signupEmailPlaceholder',
    'loginButtonText',
    'guestHomeHeaderTitle',
    'guestHomeTitle',
    'guestHomeSubtitle',
    'guestHomeGetApp',
    'guestHomeFooterApp',
    'guestHomeFooterAbout',
    'guestHomeFooterSafari',
    'guestHomeFooterChrome',
    'guestHomeFooterPrivacy',
    'guestHomeFooterTerms',
    'guestHomeFooterContact',
    'guestHomeFooterInstagram',
    'guestHomeFooterCopyright',
    'guestHomeFooterAppStoreDialogTitle',
    'guestHomeFooterAppStoreDialogConfirmText',
    'guestHomeFooterAppStoreDialogBody',
  ]);
  const [gradientHasRendered, setGradientHasRendered] = useState(false);

  const confirm = useConfirm();

  const footerLinks: { title: string; href?: string; onPress?: () => void }[] =
    useMemo(
      () => [
        {
          title: copy.guestHomeFooterAbout,
          href: config.urls.about,
        },
        {
          title: copy.guestHomeFooterApp,
          href: config.urls.iosApp,
          onPress: () => {
            confirm({
              confirmText: copy.guestHomeFooterAppStoreDialogConfirmText,
              title: copy.guestHomeFooterAppStoreDialogTitle,
              body: copy.guestHomeFooterAppStoreDialogBody,
              confirmButtonType: ButtonType.secondaryBold,
            });
          },
        },
        {
          title: copy.guestHomeFooterSafari,
          href: config.urls.safariExtension,
        },
        {
          title: copy.guestHomeFooterChrome,
          href: config.urls.chromeExtension,
        },
        {
          title: copy.guestHomeFooterTerms,
          href: config.urls.terms,
        },
        {
          title: copy.guestHomeFooterPrivacy,
          href: config.urls.privacy,
        },
        {
          title: copy.guestHomeFooterContact,
          href: config.urls.contact,
        },
        {
          title: copy.guestHomeFooterInstagram,
          href: config.urls.instagram,
        },
      ],
      [
        copy.guestHomeFooterAbout,
        copy.guestHomeFooterApp,
        copy.guestHomeFooterPrivacy,
        copy.guestHomeFooterTerms,
        copy.guestHomeFooterContact,
        copy.guestHomeFooterInstagram,
        copy.guestHomeFooterSafari,
        copy.guestHomeFooterChrome,
        copy.guestHomeFooterAppStoreDialogTitle,
        copy.guestHomeFooterAppStoreDialogBody,
        copy.guestHomeFooterAppStoreDialogConfirmText,
        confirm,
      ],
    );

  return (
    <>
      <CoolGradient onLayout={() => setGradientHasRendered(true)} />
      {gradientHasRendered && (
        <View style={[styles.container, isMobile && styles.containerMobile]}>
          <View style={[styles.header, isMobile && styles.headerMobile]}>
            <Logo
              width={32}
              height={14}
              type="dots"
              animate
              textColor="white"
            />
            <View>
              {!isMobile && (
                <Pressable href={config.urls.iosApp}>
                  <BlurView amount={16} style={styles.appDownload}>
                    <Apple style={styles.appleLogo} />
                    <Text style={styles.appDownloadText}>
                      {copy.guestHomeGetApp}
                    </Text>
                  </BlurView>
                </Pressable>
              )}
            </View>
            <View
              style={[StyleSheet.absoluteFill, styles.headerTitleContainer]}
            >
              <Text style={styles.headerText}>{copy.guestHomeHeaderTitle}</Text>
            </View>
          </View>
          <View style={[styles.contentContainer]}>{children}</View>
          <View style={[styles.footer, isMobile && styles.footerMobile]}>
            <View
              style={[styles.footerLinks, isMobile && styles.footerLinksMobile]}
            >
              {footerLinks.map(({ title, href, onPress }) => (
                <Pressable
                  key={title}
                  href={href}
                  hrefAttrs={{
                    target: 'black',
                    rel: 'nofollow',
                  }}
                  onPress={onPress}
                >
                  <Text style={styles.footerText}>{title}</Text>
                </Pressable>
              ))}
            </View>
            <Text style={styles.footerTextCopyright}>
              {copy.guestHomeFooterCopyright}
            </Text>
          </View>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 32,
  },
  containerMobile: {
    flex: 1,
    paddingHorizontal: 20,
  },
  contentContainer: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
    height: 76,
  },
  headerMobile: {
    height: 64,
  },
  headerText: {
    fontWeight: fontWeights.regular,
    fontSize: 15,
    lineHeight: 15,
    color: light.white,
  },
  headerTitleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  appDownload: {
    padding: 16,
    borderRadius: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  appDownloadText: {
    fontWeight: fontWeights.semibold,
    fontSize: 15,
    lineHeight: 15,
    color: light.white,
  },
  appleLogo: {
    marginTop: -2,
  },
  footer: {
    paddingVertical: 32,
    alignSelf: 'center',
    gap: 16,
    alignItems: 'center',
  },
  footerMobile: {
    paddingVertical: 16,
  },
  footerLinks: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    rowGap: 4,
    columnGap: 32,
  },
  footerLinksMobile: {
    columnGap: 16,
  },
  footerText: {
    fontWeight: fontWeights.regular,
    fontSize: 13,
    color: light.white,
  },
  footerTextCopyright: {
    fontWeight: fontWeights.regular,
    fontSize: 13,
    color: light.white,
    opacity: 0.7,
  },
});

export default AuthenticationContainer;
