import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const StarOutline = ({
  width = 22,
  height = 21,
  color = 'HeadlineBlack',
}: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 22 21" fill="none">
      <Path
        d="M5.38135 19.1111L5.38133 19.1111L5.37622 19.1149C5.08795 19.3294 4.91638 19.2502 4.89697 19.2374C4.88473 19.2292 4.86562 19.2133 4.8529 19.1641C4.83895 19.11 4.82692 18.9852 4.90059 18.7753L4.90065 18.7753L4.90378 18.766L6.91183 12.8109L7.10156 12.2483L6.61794 11.9037L1.40376 8.18926C1.40345 8.18905 1.40315 8.18883 1.40285 8.18861C1.24265 8.07347 1.18117 7.97137 1.15896 7.91491C1.13755 7.8605 1.14341 7.82875 1.14877 7.81255C1.1528 7.80035 1.21312 7.65434 1.53756 7.65434H7.95417H8.5358L8.71517 7.10106L10.6296 1.19588C10.6832 1.03661 10.7559 0.954227 10.8072 0.914216C10.8592 0.873666 10.9056 0.862744 10.9401 0.862744C10.9744 0.862744 11.0171 0.873392 11.064 0.910519C11.1106 0.947413 11.1801 1.02649 11.2299 1.18506L11.2299 1.18506L11.2313 1.18964L13.1241 7.09839L13.3022 7.65434H13.886H20.3025C20.6269 7.65434 20.6872 7.80035 20.6912 7.81252C20.6966 7.82872 20.7025 7.86049 20.681 7.91491C20.6588 7.9715 20.5971 8.07389 20.4363 8.18929L20.4357 8.18969L15.2655 11.9057L14.7872 12.2495L14.9735 12.8083L16.9584 18.7633L16.9585 18.7634C17.0289 18.9745 17.0165 19.102 17.0013 19.1596C16.9874 19.2124 16.9668 19.2293 16.9546 19.2374C16.9372 19.2489 16.7635 19.3321 16.4583 19.1108C16.4578 19.1104 16.4573 19.1101 16.4569 19.1097L11.4054 15.3961L10.9327 15.0486L10.4591 15.3951L5.38135 19.1111Z"
        stroke={colorToUse}
        strokeWidth="1.6"
      />
    </Svg>
  );
};

export default StarOutline;
