import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Keyboard, StyleSheet, useWindowDimensions } from 'react-native';
import BottomSheet from '@gorhom/bottom-sheet';
import BlurView from '../BlurView';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import SearchSheetBackdrop from './SearchSheetBackdrop';
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import LinkExpandedContext from '../StuffList/LinkExpandedContext';
import { Portal } from '@gorhom/portal';
import SearchSheetContent, {
  focusedAnimatedConfig,
} from './SearchSheetContent';
import StuffListContext from '../StuffList/StuffListContext';
import useColorScheme from 'app/hooks/use-color-scheme';

interface Props {
  presentedAnimatedValue: Animated.SharedValue<number>;
}

export const bottomSheetAnimationConfig = {
  damping: 36,
  overshootClamping: false,
  restDisplacementThreshold: 0.1,
  restSpeedThreshold: 0.1,
  stiffness: 300,
  mass: 1.3,
};

const bottomSheetSnapPoints = [0.125, 0.93];

function SearchSheetBottom({
  presentedAnimatedValue,
}: PropsWithChildren<Props>) {
  const bottomSheetRef = useRef<BottomSheet>(null);
  const colorScheme = useColorScheme();
  const { linkIsExpanded } = useContext(LinkExpandedContext);
  const { isSelecting } = useContext(StuffListContext);
  const windowDimenisons = useWindowDimensions();
  const [search, setSearch] = useState('');
  const focusedAnimatedValue = useSharedValue(0);
  const headerIsFixedAnimatedValue = useSharedValue(0);

  const animatedPositition = useSharedValue(
    windowDimenisons.height * bottomSheetSnapPoints[0],
  );

  // Dervied the presentedAnimatedValue (a [-1, 0, 1] value) from the
  // animated position. We use this instead of animatedIndex as
  // animated index doesn't follow the animatedConfig we provide. We
  // want to based all our animations exactly on the movement of the
  // sheet.
  useAnimatedReaction(
    () => animatedPositition.value,
    () => {
      presentedAnimatedValue.value = interpolate(
        animatedPositition.value,
        // The animated position is a translation from the top of the screen,
        // so we have to reverse them.
        [
          windowDimenisons.height -
            windowDimenisons.height * bottomSheetSnapPoints[1],
          windowDimenisons.height -
            windowDimenisons.height * bottomSheetSnapPoints[0],
          windowDimenisons.height,
        ],
        [1, 0, -1],
      );
    },
  );

  const {
    parsedQuery,
    onChangeQuery: _onChangeQuery,
    onChangeQueryKey: _onChangeQueryKey,
    resetQuery,
    isVisitor,
    username,
    hasFinishedFirstLoad,
  } = useContext(StuffListContext);

  const onChangeQueryKey = useCallback(
    (key: string, value: string | boolean | undefined) => {
      _onChangeQueryKey(key, value);

      if (bottomSheetRef.current) {
        Keyboard.dismiss();
        bottomSheetRef.current.collapse();
      }
    },
    [_onChangeQueryKey],
  );

  const onChangeQuery = useCallback(
    (_query: { [key: string]: string }, searchId?: string | null) => {
      _onChangeQuery(_query, searchId);

      // We run this during the nextTick so that effects run due to changing
      // query have a chance to complete. This is required because our onClose
      // handler inspects parsedQuery.isActive to determine its behaviour.
      // Generally, it's desired for us to have bottomSheet callbacks be called
      // with the latest query updates.
      setTimeout(() => {
        if (bottomSheetRef.current) {
          Keyboard.dismiss();
          bottomSheetRef.current.collapse();
        }
      });
    },
    [_onChangeQuery],
  );

  const onCancel = useCallback(() => {
    if (bottomSheetRef.current) {
      bottomSheetRef.current.collapse();
    }
  }, []);

  const onClose = useCallback(() => {
    Keyboard.dismiss();
    if (!parsedQuery.isActive) {
      setSearch('');
    }
    focusedAnimatedValue.value = withSpring(0, focusedAnimatedConfig);
  }, [focusedAnimatedValue, parsedQuery.isActive]);

  // Bottom sheet doesn't reliably call onClose or onAnimate
  // when quickly dismissing the sheet after opening it. This
  // is a failsafe to ensure it is closed.
  useAnimatedReaction(
    () => presentedAnimatedValue.value,
    (current, previous) => {
      if (current === 0 && previous !== 0) {
        runOnJS(onClose)();
      }
    },
    [onClose],
  );

  const onAnimate = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (toIndex === 0) {
        onClose();
      }
    },
    [onClose],
  );

  const onSearchInputFocusToggle = useCallback((focused: boolean) => {
    if (!bottomSheetRef.current) {
      return;
    }

    if (focused) {
      bottomSheetRef.current.expand();
    }
  }, []);

  useEffect(() => {
    if (!bottomSheetRef.current) {
      return;
    }

    if (hasFinishedFirstLoad) {
      bottomSheetRef.current.snapToIndex(0);
    }
  }, [hasFinishedFirstLoad]);

  useEffect(() => {
    if (!bottomSheetRef.current) {
      return;
    }

    if (linkIsExpanded || isSelecting) {
      bottomSheetRef.current.snapToPosition(0);
    } else {
      bottomSheetRef.current.snapToIndex(0);
    }
  }, [linkIsExpanded, isSelecting]);

  const searchPageAnimatedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(presentedAnimatedValue.value, [0, 0.1], [0, 1]),
  }));

  return (
    <Portal hostName="bottomSheet">
      <BottomSheet
        animationConfigs={bottomSheetAnimationConfig}
        animatedPosition={animatedPositition}
        overDragResistanceFactor={4}
        index={-1}
        snapPoints={[
          bottomSheetSnapPoints[0] * windowDimenisons.height,
          bottomSheetSnapPoints[1] * windowDimenisons.height,
        ]}
        style={
          colorScheme === 'dark'
            ? styles.bottomSheetDark
            : styles.bottomSheetLight
        }
        containerStyle={styles.bottomSheetContainer}
        backdropComponent={SearchSheetBackdrop}
        ref={bottomSheetRef}
        onAnimate={onAnimate}
        onClose={onClose}
        handleComponent={() => (
          <ThemeView
            darkStyle={styles.handleDark}
            lightStyle={styles.handleLight}
          />
        )}
        backgroundStyle={styles.background}
        backgroundComponent={(props) => <BlurView amount={24} {...props} />}
      >
        <SearchSheetContent
          search={search}
          setSearch={setSearch}
          parsedQuery={parsedQuery}
          onChangeQuery={onChangeQuery}
          onChangeQueryKey={onChangeQueryKey}
          resetQuery={resetQuery}
          isVisitor={isVisitor}
          username={username}
          onCancel={onCancel}
          onSearchInputFocusToggle={onSearchInputFocusToggle}
          presentedAnimatedValue={presentedAnimatedValue}
          focusedAnimatedValue={focusedAnimatedValue}
          headerIsFixedAnimatedValue={headerIsFixedAnimatedValue}
          searchPageAnimatedStyle={searchPageAnimatedStyle}
          containerWidth={windowDimenisons.width - 32}
          searchInputStyle={styles.searchInput}
          isBottomSheet
        />
      </BottomSheet>
    </Portal>
  );
}

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'bottomSheet',
    {
      shadowColor: 'rgba(0, 0, 0, 0.2)',
      shadowOffset: { width: 0, height: 6 },
      shadowOpacity: 1,
      shadowRadius: 12,
      zIndex: 1,
      borderRadius: 10,
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  background: {
    borderRadius: 10,
  },
  bottomSheetContainer: {
    zIndex: 1,
    overflow: 'visible',
    borderRadius: 10,
  },
  searchInput: {
    marginBottom: 12,
  },
  ...makeThemeStyle(
    'handle',
    {
      borderRadius: 36,
      height: 5,
      width: 36,
      marginTop: 6,
      marginBottom: 8,
      alignSelf: 'center',
    },
    {
      backgroundColor: 'blackA8',
    },
    true,
  ),
});

export default SearchSheetBottom;
