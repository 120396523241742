import { Colors, light } from 'app/constants/colors';
import React, { ReactNode, useState } from 'react';
import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Pressable from '../Pressable';
import Text, { TextType } from '../Text';
import { makeThemeStyle } from '../ThemeView';
import useColorScheme from 'app/hooks/use-color-scheme';

interface Props extends ViewProps {
  label: string;
  Icon?: React.ReactNode;
  ActiveIcon?: React.ReactNode;
  renderIcon?: (isActive: boolean) => ReactNode;
  last?: boolean;
  textColor?: Colors;
  activeTextColor?: Colors;
  activeBackgroundColor?: Colors;
  onPress: () => void;
}

function DropdownItem({
  label,
  Icon,
  ActiveIcon,
  renderIcon,
  last,
  onPress,
  textColor,
  activeTextColor,
  activeBackgroundColor = 'blue9',
  ...props
}: Props) {
  const [isActive, setIsActive] = useState(false);
  const colorScheme = useColorScheme();

  const IconToUse = (isActive && ActiveIcon) || Icon;

  const icon = renderIcon ? (
    renderIcon(isActive)
  ) : (
    <IconToUse
      width={20}
      height={20}
      color={isActive ? 'white' : 'gray12'}
      style={styles.icon}
    />
  );

  return (
    <Pressable
      style={[
        last
          ? colorScheme === 'light'
            ? styles.containerLastLight
            : styles.containerLastDark
          : colorScheme === 'light'
          ? styles.containerLight
          : styles.containerDark,
        isActive && {
          backgroundColor: light[activeBackgroundColor],
          borderBottomColor: 'transparent',
        },
        last && {
          borderBottomWidth: 0,
        },
      ]}
      onHoverIn={() => setIsActive(true)}
      onPress={onPress}
      onPressIn={() => {
        ReactNativeHapticFeedback.trigger('selection');
        setIsActive(true);
      }}
      onPressOut={() => {
        setIsActive(false);
      }}
      onHoverOut={() => setIsActive(false)}
      accessibilityRole="link"
      {...props}
    >
      <Text
        type={TextType.body}
        color={
          isActive ? activeTextColor || 'white' : textColor || 'HeadlineBlack'
        }
      >
        {label}
      </Text>
      <View style={styles.iconContainer}>{icon}</View>
    </Pressable>
  );
}

const containerStyle: ViewStyle = {
  flexDirection: 'row',
  alignItems: 'center',
  paddingVertical: 13,
  paddingHorizontal: 16,
  justifyContent: 'space-between',
  borderBottomWidth: 1,
};

const styles = StyleSheet.create({
  ...makeThemeStyle('container', containerStyle, {
    borderBottomColor: 'DividerGray',
    backgroundColor: 'LayeredWhite',
  }),
  ...makeThemeStyle(
    'containerLast',
    { ...containerStyle, borderBottomWidth: 0 },
    {
      borderBottomColor: 'DividerGray',
      backgroundColor: 'LayeredWhite',
    },
  ),
  containerItemPressed: {
    borderBottomColor: 'transparent',
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconContainer: {
    height: 20,
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default DropdownItem;
