import { format } from 'date-fns';
import { useMemo } from 'react';
import { Linking, Platform } from 'react-native';
import useCopy from './use-copy';
import { Price, Subscription } from './use-resource/types';
import {
  useResourceList,
  useResourceStateRef,
} from './use-resource/use-resource';
import useMakePurchase from './use-make-purchase';
import { startCase } from 'lodash';
import config from 'app/config';
import { useToast } from './use-toast';
import { deepLinkToSubscriptions } from 'react-native-iap';

function isType<Type>(item: Type | undefined): item is Type {
  return !!item;
}

export interface DisplayPrice {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  cost: string;
  productType: Price['attributes']['product_type'];
  type: Price['attributes']['type'];
  isSubscribed: boolean;
  onPress?: () => void;
  href?: string;
  hrefAttrs?: object;
}

function usePrices(): {
  prices: DisplayPrice[];
  renewalInfo: string;
  isSubscribed: boolean;
  activeSubscribedPrice?: Price;
  restorePurchase?: null | (() => Promise<void>);
} {
  const { priceList } = useResourceList('price');
  const { subscriptionList } = useResourceList('subscription');
  const resourceStateRef = useResourceStateRef();
  const copy = useCopy([
    'freeSubscriptionSubtitle',
    'freeSubscriptionDescription',
    'plusSubscriptionSubtitle',
    'plusSubscriptionDescription',
    'subscriptionsManage',
    'subscriptionsManageInApp',
  ]);
  const { makePurchase, localizePrice, restorePurchase } = useMakePurchase();
  const setToast = useToast();

  const pricesWithSubscriptions = useMemo(() => {
    if (!priceList || !subscriptionList) {
      return {
        renewalInfo: '',
        prices: [],
        isSubscribed: false,
      };
    }

    const productTypeToCopy = {
      free: {
        subtitle: copy.freeSubscriptionSubtitle,
        description: copy.freeSubscriptionDescription,
      },
      plus: {
        subtitle: copy.plusSubscriptionSubtitle,
        description: copy.plusSubscriptionDescription,
      },
    };

    const subscriptions = subscriptionList
      .map((id) => resourceStateRef?.current?.entities?.subscription?.[id])
      .filter(isType);

    const subscriptionsByProductType: {
      [index: string]: Subscription | undefined;
    } = Object.fromEntries(
      subscriptions.map((subscription) => {
        const price =
          resourceStateRef?.current?.entities?.price?.[
            subscription.relationships.price.data.id
          ];
        if (!price) {
          return [];
        }

        return [price.attributes.product_type, subscription];
      }),
    );

    let renewalInfo = '';
    let isSubscribed = false;
    const activeSubscribedPrice = subscriptions.length
      ? resourceStateRef?.current?.entities?.price?.[
          subscriptions[0].relationships.price.data.id
        ]
      : undefined;

    const prices = priceList
      .map((id) => resourceStateRef?.current?.entities?.price?.[id])
      .filter(isType)
      .filter((price) => {
        /* If we have an active subscription to a product with multiple prices, we want
         * to render the one that's subscribed to, even if its not visible (which is the
         * case for cool_stuff prices). Otherwise we only show visible prices.
         */
        if (
          activeSubscribedPrice?.attributes.product_type ===
          price.attributes.product_type
        ) {
          if (activeSubscribedPrice.id === price.id) {
            return true;
          } else {
            return false;
          }
        } else if (!price.attributes.visible) {
          return false;
        }

        // Otherwise, just show platform appropriate prices
        if (Platform.OS === 'web') {
          return price.attributes.platform !== 'apple';
        } else {
          return price.attributes.platform !== 'stripe';
        }
      })
      .map((_price) => {
        const subscription =
          subscriptionsByProductType[_price.attributes.product_type];

        const price: DisplayPrice = {
          id: _price.id,
          subtitle: productTypeToCopy[_price.attributes.product_type].subtitle,
          title: startCase(_price.attributes.product_type),
          description:
            productTypeToCopy[_price.attributes.product_type].description,
          isSubscribed: false,
          productType: _price.attributes.product_type,
          type: _price.attributes.type,
          cost: localizePrice(_price),
        };

        let urlToManage = '';

        // Build the URL that can be used to manage this price
        if (activeSubscribedPrice) {
          if (activeSubscribedPrice.attributes.platform === 'stripe') {
            if (Platform.OS === 'web') {
              // We can manage the Stripe price directly on web
              urlToManage = `${config.urls.api}/stripe/create-billing-portal`;
            } else {
              // Whereas on mobile, we have to open the subscriptions page
              // first, as the user might not be logged in.
              // TODO: In the future, we could support a ?next functionality
              // to not only load a route, but to then load the portal.
              urlToManage = 'https://coolstuff.app/subscription';
            }
          }
        }

        async function onPress() {
          if (!activeSubscribedPrice) {
            await makePurchase(_price);
          } else {
            // Build the URL that can be used to manage this price
            let url = '';
            if (urlToManage) {
              if (Platform.OS === 'web') {
                // We'll handle via an href
                return;
              } else {
                Linking.openURL(url);
              }
            } else {
              // If the subscription is apple, then we can manage it on iOS.
              if (Platform.OS === 'ios') {
                // url = 'App-prefs:APPLE_ACCOUNT&path=SUBSCRIPTIONS';
                deepLinkToSubscriptions({
                  sku: activeSubscribedPrice.attributes.apple_product_id,
                });
              } else {
                //... but not on native
                setToast({
                  type: 'info',
                  text: copy.subscriptionsManageInApp,
                  delay: 4000,
                });
              }
            }
          }
        }

        // Build the href attrs if we're on web
        let href;
        let hrefAttrs;
        if (urlToManage && Platform.OS === 'web') {
          href = urlToManage;
          hrefAttrs = { target: 'blank', rel: 'nofollow' };
        }

        // Buttons are pressable unless there's an active subscription from Cool Stuff
        const noSubscriptionIsPlusPrice =
          !activeSubscribedPrice && price.productType === 'plus';
        const isSubscribedToNonCoolStuffPlus =
          activeSubscribedPrice &&
          activeSubscribedPrice.attributes.platform !== 'cool_stuff';

        if (noSubscriptionIsPlusPrice || isSubscribedToNonCoolStuffPlus) {
          price.onPress = onPress;
        }

        if (!subscription) {
          // If there's no subscription, pretend this is if the price is free
          if (price.productType === 'free' && subscriptions.length === 0) {
            price.isSubscribed = subscriptions.length === 0;
          }

          return price;
        }

        if (subscription.attributes.current_period_end_at) {
          const date = new Date(
            subscription.attributes.current_period_end_at + '+00:00',
          );
          const periodEnd = format(date, 'P');

          renewalInfo = `${
            subscription.attributes.cancel_at_period_end ? 'Expires' : 'Renews'
          } ${periodEnd}`;
        }

        isSubscribed = true;

        return {
          ...price,
          isSubscribed: true,
          href,
          hrefAttrs,
        };
      });

    return {
      prices,
      renewalInfo,
      isSubscribed,
      activeSubscribedPrice,
      restorePurchase,
    };
  }, [
    priceList,
    subscriptionList,
    resourceStateRef,
    copy,
    makePurchase,
    localizePrice,
    setToast,
    restorePurchase,
  ]);

  return pricesWithSubscriptions;
}

export default usePrices;
