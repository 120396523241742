import React, { useContext, useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import MaskedView from '@react-native-masked-view/masked-view';
import LinearGradient from 'react-native-linear-gradient';
import { BlurView } from 'expo-blur';
import LinkExpandedContext from './StuffList/LinkExpandedContext';
import Animated, {
  SharedValue,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import useColorScheme from 'app/hooks/use-color-scheme';
import CoolBlurView from './BlurView';
import Text from './Text';

const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

const StatusBarBlurHeader = ({
  headerIsFixedAnimatedValue,
  headerVisible,
  containerWidth,
}: {
  headerIsFixedAnimatedValue: SharedValue<number>;
  headerVisible: boolean;
  containerWidth: number;
}) => {
  const insets = useSafeAreaInsets();
  const { linkIsExpanded } = useContext(LinkExpandedContext);
  const animatedValue = useSharedValue(1);
  const colorScheme = useColorScheme();

  useEffect(() => {
    setTimeout(
      () => {
        animatedValue.value = withSpring(linkIsExpanded ? 0 : 1, {
          stiffness: 80,
          damping: 20,
        });
      },
      linkIsExpanded ? 0 : 400,
    );
  }, [animatedValue, linkIsExpanded]);

  const blurStyle = useAnimatedStyle(
    () => ({
      opacity: animatedValue.value,
    }),
    [animatedValue],
  );
  const containerStyle = useAnimatedStyle(
    () => ({
      opacity: headerIsFixedAnimatedValue.value,
    }),
    [headerIsFixedAnimatedValue],
  );

  const height = headerVisible ? 150 : insets.top * 1.25;
  const blurSize = height;

  if (Platform.OS === 'web') {
    return (
      <Animated.View style={[containerStyle]} pointerEvents="none">
        <View style={[styles.containerWeb, { height }]}>
          <View style={[styles.background, { width: containerWidth - 16 }]} />
        </View>
        <View style={[styles.containerWeb1, { height }]} />
        <View style={[styles.containerWeb1, { height }]} />
        <View style={[styles.containerWeb1, { height }]} />
        <View style={[styles.containerWeb1, { height }]} />
      </Animated.View>
    );
  }

  return (
    <Animated.View
      style={[styles.container, containerStyle]}
      pointerEvents="none"
    >
      <MaskedView
        style={[{ height }]}
        maskElement={
          <LinearGradient
            colors={['rgba(255, 255, 255, 1)', 'transparent']}
            style={styles.gradient}
            locations={[0, 1]}
          />
        }
      >
        <View style={styles.background} />
      </MaskedView>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  containerWeb: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    mask: 'linear-gradient(0deg, rgba(0,0,0,0) 12.5%, black)',
    alignItems: 'center',
  },
  containerWeb1: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    mask: 'linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,1))',
    backdropFilter: 'blur(2px)',
    WebkitBackdropFilter: 'blur(2px)',
  },
  containerWeb2: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    mask: 'linear-gradient(0deg, rgba(0,0,0,0) 25%, rgba(0,0,0,1))',
    backdropFilter: 'blur(4px)',
    WebkitBackdropFilter: 'blur(4px)',
  },
  containerWeb3: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    mask: 'linear-gradient(0deg, rgba(0,0,0,0) 50%, rgba(0,0,0,1))',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
  },
  containerWeb4: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    mask: 'linear-gradient(0deg, rgba(0,0,0,0) 75%, rgba(0,0,0,1))',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
  },
  gradient: {
    height: '100%',
    flex: 1,
  },
  background: {
    backgroundColor: 'rgba(0,0,0,0.36)',
    height: '100%',
    flex: 1,
  },
});

export default StatusBarBlurHeader;
