import { Platform } from 'react-native';

function useIsTouchDevice() {
  if (Platform.OS === 'ios') {
    return true;
  }

  if (global.__IS_SERVER__) {
    return false;
  }

  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

export default useIsTouchDevice;
