import { Query } from 'app/hooks/make-resource/make-use-resource-list';
import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import Collection from './Collection';
import { dark, light } from 'app/constants/colors';

interface Props {
  filter?: Query;
  placeholder: React.ReactNode;
  onChangeQuery: (query: { [key: string]: string }) => void;
  collections: string[];
}

function Collectiones({ onChangeQuery, collections, placeholder }: Props) {
  return collections.length === 0 ? (
    <ThemeView
      darkStyle={styles.placeholderDark}
      lightStyle={styles.placeholderLight}
    >
      {placeholder}
    </ThemeView>
  ) : (
    <ThemeView
      style={styles.container}
      darkStyle={styles.containerDark}
      lightStyle={styles.containerLight}
    >
      {collections.map((collectionId, index) => (
        <View key={collectionId}>
          <Collection
            collectionId={collectionId}
            onChangeQuery={onChangeQuery}
          />
          {index < collections.length - 1 && (
            <ThemeView
              darkStyle={styles.borderDark}
              lightStyle={styles.borderLight}
            />
          )}
        </View>
      ))}
    </ThemeView>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
  },
  containerDark: {
    backgroundColor: dark.gray4,
  },
  containerLight: {
    backgroundColor: light.SolidWhite,
  },
  ...makeThemeStyle(
    'border',
    {
      height: 1,
      marginLeft: 16,
    },
    {
      backgroundColor: 'DividerGray',
    },
  ),
  ...makeThemeStyle(
    'placeholder',
    {
      // NOTE: This needs to match CollectionSuggestionCollectiones
      borderRadius: 15,
      paddingHorizontal: 42,
      justifyContent: 'center',
      height: 100,
    },
    {
      backgroundColor: 'BackgroundGray',
    },
  ),
});

export default React.memo(Collectiones);
