import React, { PropsWithChildren } from 'react';
import Routes, { RouteTitles } from './ROUTES';
import NotFound from 'app/screens/NotFound';
import Authentication from 'app/screens/Authentication';
import {
  NativeStackNavigationOptions,
  createNativeStackNavigator,
} from '@react-navigation/native-stack';
import {
  createStackNavigator as _createStackNavigator,
  HeaderStyleInterpolators,
  StackCardInterpolatedStyle,
  StackCardInterpolationProps,
  StackNavigationOptions,
  TransitionPresets,
} from '@react-navigation/stack';
import { Animated, Platform, StyleSheet, View } from 'react-native';
import Settings from 'app/screens/Settings';
import GuestHome from 'app/screens/GuestHome';
import Pressable from '../Pressable';
import Text, { TextType } from '../Text';
import useColor from 'app/hooks/use-color';
import Modal from '../Modal';
import LayoutAnimation, { CoolLayoutAnimationType } from '../LayoutAnimation';
import MagicLink from 'app/screens/MagicLink';
import User from 'app/screens/User';
import useCheckIsVisitor from 'app/hooks/use-is-visitor';
import FinalizeSubscription from 'app/screens/FinalizeSubscription';
import StripeCheckout from 'app/screens/StripeCheckout';
import Prices from 'app/screens/Prices';
import AuthenticationContainer from 'app/screens/AuthenticationContainer';
import SaveLink from 'app/screens/SaveLink';
import { getFocusedRouteNameFromRoute, Route } from '@react-navigation/native';
import Extensions from 'app/screens/Extensions';
import Appearance from 'app/screens/Settings/Appearance';
import useColorScheme from 'app/hooks/use-color-scheme';
import { useAuthStatus } from 'app/hooks/use-auth/use-auth-status';
import { Status } from 'app/hooks/use-auth/types';
import AppIcon from 'app/screens/Settings/AppIcon';
import AddToCollection from 'app/screens/AddToCollection';
import EditCollection from 'app/screens/EditCollection/EditCollection';
import HeaderMenu from '../StuffList/HeaderMenu';
import { useSharedValue } from 'react-native-reanimated';
import useHasFeature from 'app/hooks/use-has-feature';

const createStackNavigator =
  Platform.OS === 'web' ? _createStackNavigator : createNativeStackNavigator;

const UserStack = createStackNavigator();
const GuestStack = createStackNavigator();
const AuthenticationStack = _createStackNavigator();
const SubscriptionStack = _createStackNavigator();
const SettingsStack = createStackNavigator();

const ModalScreen = ({
  children,
  navigation,
  // This is required to allow navigators to be rendered within modals. As the navigator renders
  // within absoluteFill containers, we don't actually need to render screen modals in a portal.
  usePortal = false,
}: PropsWithChildren<{
  navigation: any;
  usePortal?: boolean;
}>) => {
  if (Platform.OS !== 'web') {
    return children;
  }

  return (
    <Modal
      visible
      transparent
      onRequestClose={() => navigation.goBack()}
      darkenBackground
      centered
      usePortal={usePortal}
    >
      <LayoutAnimation
        type={CoolLayoutAnimationType.up}
        style={styles.modalScreenWebContainer}
      >
        <View style={styles.modalScreenWebInnerContainer}>{children}</View>
      </LayoutAnimation>
    </Modal>
  );
};

const SubscriptionNavigator = ({ navigation }: { navigation: any }) => {
  const headerBackgroundColor = useColor('SolidWhite');
  const colorScheme = useColorScheme();

  return (
    <ModalScreen navigation={navigation}>
      <SubscriptionStack.Navigator
        screenOptions={({ route }) => ({
          cardStyle: styles.card,
          animationEnabled: false,
          title: RouteTitles[route.name],
        })}
      >
        <SubscriptionStack.Screen
          name={Routes.PRICES}
          component={Prices}
          options={{
            headerShown: false,
          }}
        />
        {Platform.OS === 'web' && (
          <SubscriptionStack.Screen
            name={Routes.STRIPE_CHECKOUT}
            component={StripeCheckout}
            options={{
              headerShown: Platform.OS !== 'web',
              headerLargeTitle: true,
              headerTransparent: true,
              headerStyle: {
                backgroundColor: headerBackgroundColor,
              },
              headerBlurEffect: colorScheme === 'light' ? 'light' : 'dark',
            }}
          />
        )}
        <SubscriptionStack.Screen
          name={Routes.FINALIZE_SUBSCRIPTION}
          component={FinalizeSubscription}
          options={{
            headerShown: false,
          }}
        />
      </SubscriptionStack.Navigator>
    </ModalScreen>
  );
};

const SettingsNavigator = ({ navigation }: { navigation: any }) => {
  const titleColor = useColor('HeadlineBlack');
  const primaryColor = useColor('Primary');
  const colorScheme = useColorScheme();

  return (
    <ModalScreen navigation={navigation}>
      <SettingsStack.Navigator
        screenOptions={({ route }) => ({
          cardStyle: styles.card,
          animationEnabled: true,
          headerShown: Platform.OS !== 'web',
          headerTransparent: true,
          headerBlurEffect:
            colorScheme === 'light' ? 'systemUltraThinMaterialLight' : 'dark',
          headerTintColor: primaryColor,
          headerTitleStyle: {
            color: titleColor,
          },
          title: RouteTitles[route.name],

          headerRight: () => (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
            >
              <Text color="Primary" type={TextType.body}>
                Done
              </Text>
            </Pressable>
          ),
          ...TransitionPresets.SlideFromRightIOS,
        })}
      >
        <SettingsStack.Screen
          name={Routes.SETTINGS_MAIN}
          component={Settings}
        />
        <SettingsStack.Screen
          name={Routes.SETTINGS_APPEARANCE}
          component={Appearance}
        />
        <SettingsStack.Screen
          name={Routes.SETTINGS_APP_ICON}
          component={AppIcon}
        />
      </SettingsStack.Navigator>
    </ModalScreen>
  );
};

const UserStackNavigator = () => {
  const tintColor = useColor('blue9');
  const headerColor = useColor('gray12');
  const checkIsVisitor = useCheckIsVisitor();
  const colorScheme = useColorScheme();
  const headerIsFixedAnimatedValue = useSharedValue(0);
  const hasCollectionFeature = useHasFeature('collections');
  const hasViewStatesFeature = useHasFeature('view_states');

  return (
    <>
      <UserStack.Navigator
        screenOptions={({ route }) => ({
          cardStyle: styles.card,
          animationEnabled: Platform.OS !== 'web',
          headerTintColor: tintColor,
          headerTitleStyle: { color: headerColor },
          title: RouteTitles[route.name],
        })}
      >
        {Platform.OS !== 'web' && (
          <UserStack.Screen
            name={Routes.HOME}
            component={User}
            options={{
              headerShown: false,
            }}
          />
        )}
        <UserStack.Screen
          name={Routes.USER}
          component={User}
          options={({ route, navigation }) => {
            const username = route.params?.username;
            const isVisitor = checkIsVisitor(username);

            const options: NativeStackNavigationOptions = {
              title: `${username ? `${username}'s` : 'My'} Stuff`,
              headerShown: Platform.OS !== 'web',
              headerLargeTitle: true,
              headerTransparent: true,
              presentation: 'modal',
            };

            if (isVisitor) {
              options.headerLeft = () => (
                <Pressable
                  onPress={() => {
                    navigation.goBack();
                  }}
                >
                  <Text color="Primary" type={TextType.body}>
                    Done
                  </Text>
                </Pressable>
              );
              if (hasCollectionFeature || hasViewStatesFeature) {
                options.headerRight = () => (
                  <HeaderMenu
                    headerIsFixedAnimatedValue={headerIsFixedAnimatedValue}
                    isVisitor
                  />
                );
              }
              options.headerBlurEffect =
                colorScheme === 'light' ? 'light' : 'dark';
              options.title = `${username ? `${username}'s` : 'My'} Stuff`;
            } else {
              options.headerTitle = () => <View />;
              options.headerTitleStyle = {
                color: 'transparent',
              };
            }

            return options;
          }}
        />
        <UserStack.Screen
          name={Routes.SETTINGS}
          component={SettingsNavigator}
          options={({ navigation }) => ({
            headerShown: false,
            presentation: Platform.OS === 'web' ? 'transparentModal' : 'modal',
          })}
        />
        <UserStack.Screen
          name={Routes.SUBSCRIPTION}
          component={SubscriptionNavigator}
          options={{
            headerShown: false,
            presentation:
              Platform.OS === 'web' ? 'transparentModal' : 'formSheet',
            sheetAllowedDetents: 'medium',
          }}
        />
        <UserStack.Screen
          name={Routes.SAVE_LINK}
          component={SaveLink}
          options={{
            headerShown: false,
            presentation: 'transparentModal',
            animation: 'fade',
          }}
        />
        <UserStack.Screen
          name={Routes.EXTENSIONS}
          component={Extensions}
          options={{
            headerShown: false,
            presentation: 'transparentModal',
            animation: 'fade',
          }}
        />
        <UserStack.Screen
          name={Routes.ADD_TO_COLLECTION}
          options={{
            headerShown: Platform.OS !== 'web',
            presentation: Platform.OS === 'web' ? 'transparentModal' : 'modal',
          }}
        >
          {(props) => (
            <ModalScreen usePortal {...props}>
              <AddToCollection {...props} />
            </ModalScreen>
          )}
        </UserStack.Screen>
        <UserStack.Screen
          name={Routes.EDIT_COLLECTION}
          options={{
            headerShown: Platform.OS !== 'web',
            presentation: Platform.OS === 'web' ? 'transparentModal' : 'modal',
          }}
        >
          {(props) => (
            <ModalScreen usePortal {...props}>
              <EditCollection {...props} />
            </ModalScreen>
          )}
        </UserStack.Screen>
        <UserStack.Screen name={Routes.NOT_FOUND} component={NotFound} />
      </UserStack.Navigator>
    </>
  );
};

function forHorizontalSlide({
  current,
  next,
  inverted,
  layouts: { screen },
}: StackCardInterpolationProps): StackCardInterpolatedStyle {
  const screenWidth = Platform.OS === 'web' ? screen.width / 8 : screen.width;

  const translateFocused = Animated.multiply(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [screenWidth, 0],
      extrapolate: 'clamp',
    }),
    inverted,
  );

  const translateUnfocused = next
    ? Animated.multiply(
        next.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, -screenWidth],
          extrapolate: 'clamp',
        }),
        inverted,
      )
    : 0;

  const shadowOpacity = current.progress.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 0.3],
    extrapolate: 'clamp',
  });

  return {
    cardStyle: {
      overflow: 'visible',
      transform: [
        // Translation for the animation of the current card
        { translateX: translateFocused },
        // Translation for the animation of the card on top of this
        { translateX: translateUnfocused },
      ],
    },
    shadowStyle: { shadowOpacity },
  };
}

const AuthenticationNavigator = () => {
  const screenOptions: StackNavigationOptions = ({ route }) => ({
    headerTransparent: true,
    headerTintColor: 'white',
    headerTitleAlign: 'center',
    headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
    cardStyleInterpolator: forHorizontalSlide,
    headerBackTitle: ' ',
    title: RouteTitles[route.name],
  });

  if (Platform.OS === 'web') {
    screenOptions.headerShown = false;
  }

  const navigator = (
    <AuthenticationStack.Navigator screenOptions={screenOptions}>
      <AuthenticationStack.Screen
        name={Routes.HOME}
        component={GuestHome}
        options={{ headerShown: false }}
      />
      <AuthenticationStack.Screen
        name={Routes.LOGIN}
        component={Authentication}
      />
      <AuthenticationStack.Screen
        name={Routes.SIGNUP}
        component={Authentication}
      />
      <AuthenticationStack.Screen
        name={Routes.MAGIC_LINK}
        component={MagicLink}
      />
    </AuthenticationStack.Navigator>
  );

  return <AuthenticationContainer>{navigator}</AuthenticationContainer>;
};

const GuestStackNavigator = () => {
  const colorScheme = useColorScheme();

  return (
    <GuestStack.Navigator
      screenOptions={({ route }) => ({
        title: RouteTitles[route.name],
        ...Platform.select({
          default: {
            headerShown: false,
            animationEnabled: false,
            cardStyle: styles.card,
          },
          ios: {
            headerShown: false,
            animationEnabled: true,
            cardStyle: styles.card,
            presentation: 'modal',
          },
        }),
      })}
    >
      <GuestStack.Screen
        name={Routes.AUTHENTICATION}
        component={AuthenticationNavigator}
        options={({ route }) => ({
          /*
           * As the auth container uses nested routes, the title for an auth page takes
           * one render cycle to display. Prior to that, the parent Auth route's title
           * is displayed. So it goes from Auth -> Login · Cool Stuff. By using this function
           * we can set the title for the nested page from the parent route and avoid that issue.
           */
          title:
            RouteTitles[getFocusedRouteNameFromRoute(route) ?? Routes.HOME],
          headerShown: false,
        })}
      />
      <GuestStack.Screen
        name={Routes.USER}
        component={User}
        options={({ navigation }) => ({
          headerShown: Platform.OS !== 'web',
          headerLargeTitle: true,
          headerTransparent: true,
          headerLeft: () => (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
            >
              <Text color="Primary" type={TextType.body}>
                Done
              </Text>
            </Pressable>
          ),
          headerBlurEffect: colorScheme === 'light' ? 'light' : 'dark',
        })}
      />
    </GuestStack.Navigator>
  );
};

const Navigator = () => {
  const status = useAuthStatus();

  if (status === Status.PENDING) {
    return null;
  }

  if (status === Status.USER) {
    return <UserStackNavigator />;
  }

  return <GuestStackNavigator />;
};

const styles = StyleSheet.create({
  card: {
    // Fixes clipping of shadows on web
    overflow: 'visible',
  },
  modalScreenWebContainer: {
    paddingHorizontal: 8,
    paddingVertical: 24,
    justifyContent: 'center',
    /*
     * Matches the dimensions set by Page.
     *
     * We need this for the Subscription navigator thats rendered within a modal.
     * @react-navigation sets all screens with a non-configurable absoluteFill. The
     * idea being that screens should take up the entire screen. But when rendering within
     * a modal, it has the effect of prohibiting the instrinsic size of the modal's content
     * of bubbling up the modal container, resulting in 0 size. So that means we need to
     * set the dimensions on the modal container to force its size.
     *
     * We can do this for all modals as all modals use the same page and thus have the same
     * dimensions.
     */
    maxHeight: 930,
    maxWidth: 425,
    minWidth: 320,
    width: '100%',
    height: '100%',
  },
  modalScreenWebInnerContainer: {
    overflow: 'hidden',
    borderRadius: 24,
    flex: 1,
  },
});

export default Navigator;
