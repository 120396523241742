import makeFetcher from './make-fetcher';
import makeProvider from './make-provider';
import { Resource } from './types';
import makeUseResource from './make-use-resource';
import makeUseResourceList from './make-use-resource-list';
import makeUseCreate from './make-use-create';
import makeUseUpdate from './make-use-update';
import makeUseDelete from './make-use-delete';
import makeUseInvalidateList from './make-use-invalidate-list';
import makeUseInvalidate from './make-use-invalidate';
import makeUseDeleteList from './make-use-delete-list';
import makeUseListAction from './make-use-list-action';

interface Options {
  baseUrl: string;
  getHeaders?: () => Promise<{ [key: string]: string }>;
  handleResponse?: (response: Response) => void;
}

export default function makeResource<ValidResource extends Resource>({
  baseUrl,
  getHeaders,
  handleResponse,
}: Options) {
  const fetcher = makeFetcher<ValidResource>(getHeaders, handleResponse);
  const {
    defaultState,
    useResourceDispatch,
    useResourceStateRef,
    ResourceProvider,
    useRequest,
    useList,
    useEntity,
    reducer,
    useResourceFetcher,
  } = makeProvider<ValidResource>({ fetcher });

  const useResource = makeUseResource({
    baseUrl,
    useResourceFetcher,
    useResourceDispatch,
    useRequest,
    useEntity,
  });

  const useResourceList = makeUseResourceList({
    baseUrl,
    useResourceFetcher,
    useResourceDispatch,
    useRequest,
    useList,
  });

  const useCreate = makeUseCreate({
    baseUrl,
    useResourceFetcher,
    useResourceDispatch,
  });

  const useUpdate = makeUseUpdate({
    baseUrl,
    useResourceFetcher,
    useResourceDispatch,
    useResourceStateRef,
  });

  const useDelete = makeUseDelete({
    baseUrl,
    useResourceFetcher,
    useResourceDispatch,
  });

  const useDeleteList = makeUseDeleteList({
    baseUrl,
    useResourceFetcher,
    useResourceDispatch,
  });

  const useListAction = makeUseListAction({
    baseUrl,
    useResourceFetcher,
    useResourceDispatch,
  });

  const useInvalidate = makeUseInvalidate({
    useResourceDispatch,
    baseUrl,
  });

  const useInvalidateList = makeUseInvalidateList({
    useResourceDispatch,
  });

  return {
    useResource,
    useResourceList,
    useCreate,
    useUpdate,
    useDelete,
    useDeleteList,
    useListAction,
    useInvalidateList,
    useInvalidate,
    useResourceStateRef,
    ResourceProvider,
    defaultState,
    reducer,
  };
}
