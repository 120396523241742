import { useCallback } from 'react';
import makeProvider from './make-provider';
import { Resource } from './types';
import { makeListUrl } from './urls';

function makeUseDeleteList<ValidResource extends Resource>({
  useResourceDispatch,
  baseUrl,
  useResourceFetcher,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
  useResourceFetcher: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceFetcher'];
  baseUrl: string;
}) {
  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  function useDeleteList<Type extends keyof TypeToResource>(type: Type) {
    const dispatch = useResourceDispatch();
    const fetcher = useResourceFetcher();

    const deleteEntityList = useCallback(
      async (
        options: {
          query?: { [name: string]: string };
        } = {},
      ) => {
        const { query = {} } = options;
        const url = makeListUrl({ baseUrl, type, query });

        await fetcher(url, {
          method: 'DELETE',
        });

        // List actions need to always return a list
        dispatch({
          type: 'delete_resource_list_success',
          payload: {},
          meta: {
            url,
            listKey: url,
            type,
          },
        });
      },
      [dispatch, type, fetcher],
    );

    return deleteEntityList;
  }

  return useDeleteList;
}

export default makeUseDeleteList;
