import { useCallback } from 'react';
import makeProvider from './make-provider';
import { Resource } from './types';

function makeUseInvalidateList<ValidResource extends Resource>({
  useResourceDispatch,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
}) {
  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  function useInvalidate<Type extends keyof TypeToResource>(type: Type) {
    const dispatch = useResourceDispatch();

    const invalidate = useCallback(async () => {
      dispatch({
        type: 'invalidate_list_type',
        meta: { type },
      });
    }, [dispatch, type]);

    return invalidate;
  }

  return useInvalidate;
}

export default makeUseInvalidateList;
