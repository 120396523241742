import { BottomSheetTextInput } from '@gorhom/bottom-sheet';
import { Colors, light } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import shadows from 'app/styles/shadows';
import React, {
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  StyleSheet,
  Text as RNText,
  TextInput as RNTextInput,
  TextInputProps,
  View,
  ViewStyle,
  ViewProps,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  Platform,
} from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import BlurView from './BlurView';
import Pressable from './Pressable';
import Text, { TextType } from './Text';
import { makeThemeStyle } from './ThemeView';
import useColorScheme from 'app/hooks/use-color-scheme';

const AnimatedTextInput = Animated.createAnimatedComponent(RNTextInput);
const AnimatedBottomSheetTextInput =
  Animated.createAnimatedComponent(BottomSheetTextInput);

interface Props extends TextInputProps {
  helpText?: string;
  inputStyle?: ViewStyle | ViewStyle[];
  inputContainerStyle?: ViewStyle | ViewStyle[];
  leftElementStyle?: ViewStyle | ViewStyle[];
  rightElementStyle?: ViewStyle | ViewStyle[];
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  type?: 'app' | 'fancy';
  containerRef?: Ref<View>;
  inputWithLeftRef?: Ref<View>;
  placeholderColor?: Colors | string;
  helpTextColor?: Colors | string;
  color?: Colors;
  center?: boolean;
  onTextWidthChange?: (width: number) => void;
  inputPointerEvents?: ViewProps['pointerEvents'];
  horizontalPadding?: boolean;
  autoComplete?: TextInputProps['autoComplete'] | 'webauthn';
  isBottomSheet?: boolean;
  selectionColor?: TextInputProps['selectionColor'];
}

const focusConfig = {
  mass: 1,
  stiffness: 300,
  damping: 30,
};

function TextInput(
  {
    value,
    placeholder,
    helpText,
    style,
    inputStyle,
    inputContainerStyle: providedInputContainerStyle,
    leftElement,
    leftElementStyle,
    rightElement,
    rightElementStyle,
    containerRef,
    inputWithLeftRef,
    type = 'app',
    placeholderColor: _placeholderColor = 'gray11',
    color: _color = 'gray12',
    helpTextColor: _helpTextColor = 'gray12',
    selectionColor = light.Primary,
    center = false,
    onTextWidthChange,
    onLayout,
    inputPointerEvents,
    horizontalPadding = true,
    isBottomSheet = false,
    ...props
  }: Props,
  _providedRef: RefObject<View>,
) {
  const placeholderColor = useColor(_placeholderColor);
  const color = useColor(_color);
  const helpTextColor = useColor(_helpTextColor);

  const _ref = useRef<View>(null);
  const ref = _providedRef || _ref;
  const colorScheme = useColorScheme();
  const focusedValue = useSharedValue(0);

  useEffect(() => {
    if (props.autoFocus) {
      // Needs to be done on the next frame for some reason.
      setTimeout(() => {
        ref.current?.focus();
      });
    }
  }, [props.autoFocus, ref]);

  const outerContainerAnimatedStyle = useAnimatedStyle(() => {
    if (type === 'fancy') {
      return {
        shadowOpacity: focusedValue.value,
      };
    }

    return {};
  }, [focusedValue, type]);

  const outerContainerStyle = useMemo(
    () => [
      colorScheme === 'light'
        ? typeToOuterContainerStyle[type].light
        : typeToOuterContainerStyle[type].dark,
      outerContainerAnimatedStyle,
    ],
    [colorScheme, type, outerContainerAnimatedStyle],
  );

  const inputContainerAnimatedStyle = useAnimatedStyle(() => {
    if (type === 'fancy') {
      return {
        borderColor: interpolateColor(
          focusedValue.value,
          [0, 1],
          [light.whiteA9, light.white],
        ),
      };
    }

    return {};
  }, [focusedValue, type]);

  const inputContainerStyle = useMemo(() => {
    return [
      styles.containerBase,
      colorScheme === 'light'
        ? typeToContainerStyle[type].light
        : typeToContainerStyle[type].dark,
      inputContainerAnimatedStyle,
      providedInputContainerStyle,
    ];
  }, [
    colorScheme,
    providedInputContainerStyle,
    type,
    inputContainerAnimatedStyle,
  ]);

  const InnerContainer =
    type === 'fancy' || Platform.OS === 'web' ? BlurView : Animated.View;

  const _onBlur = props.onBlur;
  const _onFocus = props.onFocus;

  const onFocus = useCallback(
    (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (_onFocus) {
        _onFocus(event);
      }

      focusedValue.value = withSpring(1, focusConfig);
    },
    [_onFocus, focusedValue],
  );

  const onBlur = useCallback(
    (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (_onBlur) {
        _onBlur(event);
      }

      focusedValue.value = withSpring(0, focusConfig);
    },
    [_onBlur, focusedValue],
  );

  const TextInputComponent = isBottomSheet
    ? AnimatedBottomSheetTextInput
    : AnimatedTextInput;

  return (
    <>
      {onTextWidthChange && (
        <RNText
          style={styles.textMeasure}
          onLayout={(event) =>
            onTextWidthChange(event.nativeEvent.layout.width)
          }
        >
          {value || placeholder}
        </RNText>
      )}
      <Pressable
        style={[style, styles.pressable]}
        ref={containerRef}
        onPress={() => {
          if (ref.current) {
            ref.current.focus();
          }
        }}
        tabindex={-1}
      >
        <Animated.View style={outerContainerStyle}>
          <InnerContainer
            amount={24}
            blurType="light"
            style={inputContainerStyle}
            onLayout={onLayout}
          >
            {leftElement && (
              <Animated.View
                style={[
                  styles.leftElement,
                  horizontalPadding && styles.leftElementHorizontalPadding,
                  leftElementStyle,
                ]}
              >
                {leftElement}
              </Animated.View>
            )}
            <TextInputComponent
              {...props}
              value={value}
              placeholder={placeholder}
              style={[
                styles.input,
                horizontalPadding && styles.inputHorizontalPadding,
                { color },
                inputStyle,
              ]}
              placeholderTextColor={placeholderColor}
              onFocus={onFocus}
              onBlur={onBlur}
              ref={ref}
              pointerEvents={inputPointerEvents}
              selectionColor={selectionColor}
            />
            {rightElement && (
              <Animated.View
                style={[
                  styles.rightElement,
                  horizontalPadding && styles.rightElementHorizontalPadding,
                  rightElementStyle,
                ]}
              >
                {rightElement}
              </Animated.View>
            )}
          </InnerContainer>
        </Animated.View>
        {helpText && (
          <Text
            type={TextType.footnote}
            color={helpTextColor}
            style={styles.helpText}
          >
            {helpText}
          </Text>
        )}
      </Pressable>
    </>
  );
}

export const TEXT_INPUT_PADDING = 12;

const styles = StyleSheet.create({
  pressable: {
    cursor: 'auto',
  },
  containerBase: {
    flexDirection: 'row',
    alignItems: 'stretch',
    minHeight: 0,
    borderRadius: 13,
    height: 46,
    maxHeight: 46,
  },
  ...makeThemeStyle(
    'containerApp',
    {},
    {
      backgroundColor: 'grayA4',
      color: 'gray12',
    },
  ),
  containerFancy: {
    backgroundColor: Platform.select({
      web: 'rgba(0, 0, 0, 0.08)',
      default: 'rgba(0, 0, 0, 0.3)',
    }),
    borderWidth: 1.5,
  },
  outerContainerFancy: {
    ...shadows.activatedField,
    overflow: 'visible',
  },
  input: {
    fontSize: 17,
    flex: 1,
  },
  inputHorizontalPadding: {
    marginHorizontal: TEXT_INPUT_PADDING,
    // NOTE: This used to be applied by I don't know why
    // paddingRight: 24,
  },
  textMeasure: {
    fontSize: 17,
    position: 'absolute',
    opacity: 0,
    alignSelf: 'flex-start',
  },
  contentContainerStyle: {
    flex: 1,
  },
  helpText: {
    marginHorizontal: 14,
    paddingVertical: 5,
  },
  leftElement: {
    marginLeft: 6,
  },
  leftElementHorizontalPadding: {
    marginRight: -TEXT_INPUT_PADDING,
  },
  rightElement: {
    marginRight: 6,
  },
  rightElementHorizontalPadding: {
    marginLeft: -TEXT_INPUT_PADDING,
  },
});

const typeToContainerStyle = {
  app: {
    dark: styles.containerAppDark,
    light: styles.containerAppLight,
  },
  fancy: {
    dark: styles.containerFancy,
    light: styles.containerFancy,
  },
};

const typeToOuterContainerStyle = {
  app: {
    dark: {},
    light: {},
  },
  fancy: {
    dark: styles.outerContainerFancy,
    light: styles.outerContainerFancy,
  },
};

export default React.forwardRef(TextInput);
