import { useLinkTo } from '@react-navigation/native';
import config from 'app/config';
import useColorScheme from 'app/hooks/use-color-scheme';
import React, { RefObject } from 'react';
import {
  GestureResponderEvent,
  Linking,
  Platform,
  Pressable as RNPressable,
  StyleSheet,
  View,
} from 'react-native';
import { PressableProps, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';

const AnimatedPressable = Animated.createAnimatedComponent(RNPressable);

interface Props extends PressableProps {
  lightStyle?: ViewStyle | ViewStyle[];
  darkStyle?: ViewStyle | ViewStyle[];
  href?: string;
  inAppLinkToDelay?: number;
  hrefAttrs?: object;
}

const Pressable = (
  { lightStyle, darkStyle, style, href, inAppLinkToDelay, ...props }: Props,
  ref: RefObject<View>,
) => {
  const colorScheme = useColorScheme();
  let styles = [];
  const linkTo = useLinkTo();

  if (lightStyle || darkStyle) {
    styles.push(colorScheme === 'light' ? lightStyle : darkStyle);
  }

  if (style) {
    if (Array.isArray(style)) {
      style.filter((s) => s).forEach((s) => styles.push(s));
    } else {
      styles.push(style);
    }
  }

  if (!props.onPress) {
    styles.push(stylesheet.disabled);
  }

  const onPress = async (event: GestureResponderEvent) => {
    if (!href) {
      return props.onPress && props.onPress(event);
    }

    // @ts-expect-error: these properties exist on web, but not in React Native
    const hasModifierKey =
      event.metaKey || event.altKey || event.ctrlKey || event.shiftKey; // ignore clicks with modifier keys
    // @ts-expect-error: these properties exist on web, but not in React Native
    const isLeftClick = event.button == null || event.button === 0; // only handle left clicks
    const isSelfTarget = [undefined, null, '', 'self', '_self'].includes(
      // @ts-expect-error: these properties exist on web, but not in React Native
      event.currentTarget?.target,
    ); // let browser handle "target=_blank" etc.

    let shouldHandle = false;
    let path = href;
    let isAppPath = path.startsWith('/');

    // If the path is a coolstuff link, then it's internal
    if (path.startsWith(config.urls.webApp)) {
      path = path.replace(config.urls.webApp, '');

      // This excludes a bare URL from being routed.
      if (path) {
        isAppPath = true;
      }
    }

    // We always handle on iOS
    if (Platform.OS !== 'web' || !event) {
      shouldHandle = true;
    } else if (!hasModifierKey && isLeftClick && isSelfTarget && isAppPath) {
      // On web we only handle if it's a self link to an in-app url
      event.preventDefault();
      shouldHandle = true;
    }

    // If we shouldn't handle, let `href` take carte of it
    if (!shouldHandle) {
      return;
    }

    // If it's an in-app route
    if (isAppPath) {
      if (inAppLinkToDelay) {
        setTimeout(() => linkTo(path), inAppLinkToDelay);
      } else {
        linkTo(path);
      }
      // Otherwise we use Linking to handle
    } else {
      Linking.openURL(href);
    }

    // Always call a custom handler
    if (props.onPress) {
      props.onPress(event);
    }
  };

  return (
    <AnimatedPressable
      {...props}
      href={href}
      onPress={onPress}
      style={styles}
      ref={ref}
    />
  );
};

const stylesheet = StyleSheet.create({
  disabled: {
    cursor: 'auto',
  },
});

export default React.forwardRef(Pressable);
