import React, { useContext, useEffect } from 'react';
import StuffListContext from './StuffListContext';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Text, { TextType } from '../Text';
import useCopy from 'app/hooks/use-copy';
import pluralize from 'pluralize';
import { StyleSheet, View } from 'react-native';
import { makeThemeStyle } from '../ThemeView';
import { Portal } from '@gorhom/portal';
import BlurView from '../BlurView';
import ShadowView, { ShadowType } from '../ShadowView';
import EllipsisButton from '../EllipsisButton';
import ContextMenu from '../ContextMenu/ContextMenu';
import { DropdownItem } from '../Dropdown';
import { useNavigation } from '@react-navigation/native';
import Routes from '../Navigator/ROUTES';
import { bottomSheetAnimationConfig } from '../SearchSheet/SearchSheetBottom';
import PlusSquare from '../Icons/PlusSquare';
import Trash from '../Icons/Trash';
import useConfirm from 'app/hooks/use-confirm';
import { useToast } from 'app/hooks/use-toast';
import { ButtonType } from '../Button';
import {
  useDeleteList,
  useInvalidateList,
} from 'app/hooks/use-resource/use-resource';
import LinearGradient from '../LinearGradient';

const SelectToolbar = () => {
  const { selectedLinkIds, isSelecting, setIsSelecting } =
    useContext(StuffListContext);
  const copy = useCopy([
    'stuffListSelectToolbarItem',
    'stuffListSelectToolbarSelected',
    'stuffListSelectToolbarAddToCollection',
    'stuffListSelectToolbarRemove',
    'stuffListSelectToolbarRemoveConfirmation',
    'stuffListSelectToolbarRemoveConfirmationConfirmationText',
    'stuffListSelectToolbarRemoveConfirmationCancelText',
    'stuffListSelectToolbarRemoveConfirmationConfirmed',
  ]);
  const animatedValue = useSharedValue(0);
  const navigation = useNavigation();
  const confirm = useConfirm();
  const setToast = useToast();
  const deleteLinkList = useDeleteList('link');
  const invalidateStuff = useInvalidateList('stuff');

  useEffect(() => {
    animatedValue.value = withSpring(
      isSelecting ? 1 : 0,
      // We use the same config as the bottom sheet so the animations match on iOS
      bottomSheetAnimationConfig,
    );
  }, [animatedValue, isSelecting]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        { translateY: interpolate(animatedValue.value, [0, 1], [40, 0]) },
      ],
      opacity: animatedValue.value,
    }),
    [animatedValue],
  );
  const gradientStyle = useAnimatedStyle(
    () => ({
      opacity: animatedValue.value,
    }),
    [animatedValue],
  );
  const itemPluralized = pluralize(
    copy.stuffListSelectToolbarItem,
    selectedLinkIds.size,
  );

  return (
    <Portal hostName="bottomSheet">
      <View style={styles.positionContainer} pointerEvents="box-none">
        {isSelecting && (
          <Animated.View
            style={[gradientStyle, styles.linearGradient]}
            pointerEvents="none"
          >
            <LinearGradient
              style={styles.linearGradient}
              colors={['transparent', 'rgba(0,0,0,0.5)']}
            />
          </Animated.View>
        )}
        <ContextMenu
          actions={[
            <DropdownItem
              label={copy.stuffListSelectToolbarAddToCollection}
              Icon={PlusSquare}
              last
              onPress={() => {
                setIsSelecting(false);
                navigation.navigate(Routes.ADD_TO_COLLECTION, {
                  links: Array.from(selectedLinkIds),
                });
              }}
              key="collection"
            />,
            <DropdownItem
              label={copy.stuffListSelectToolbarRemove}
              renderIcon={(isActive) =>
                isActive ? <Trash color="white" /> : <Trash color="red9" />
              }
              last
              onPress={() => {
                confirm({
                  cancelText:
                    copy.stuffListSelectToolbarRemoveConfirmationCancelText,
                  confirmText:
                    copy.stuffListSelectToolbarRemoveConfirmationConfirmationText,
                  body: `${copy.stuffListSelectToolbarRemoveConfirmation} ${selectedLinkIds.size} ${itemPluralized}?`,
                  confirmButtonType: ButtonType.secondaryDanger,
                  callback: (didConfirm) => {
                    if (didConfirm) {
                      setIsSelecting(false);

                      setTimeout(async () => {
                        // Stuff IDs for links are the link ID
                        await deleteLinkList({
                          query: {
                            'filter[id]': Array.from(selectedLinkIds).join(','),
                          },
                        });
                        invalidateStuff();
                      }, 800);

                      setToast({
                        type: 'info',
                        text: `${selectedLinkIds.size} ${itemPluralized} ${copy.stuffListSelectToolbarRemoveConfirmationConfirmed}`,
                        delay: 2000,
                      });
                    }
                  },
                });
              }}
              key="remove"
            />,
          ]}
        >
          {({ open }) => (
            <ShadowView
              type={ShadowType.big}
              animatedStyle={animatedStyle}
              lightStyle={styles.containerLight}
              darkStyle={styles.containerDark}
              borderRadius={23}
            >
              <BlurView amount={24} style={styles.blurContainer}>
                <Text type={TextType.body} style={styles.title}>
                  {`${selectedLinkIds.size} ${itemPluralized} ${copy.stuffListSelectToolbarSelected}`}
                </Text>
                <EllipsisButton onPress={open} width={30} height={30} />
              </BlurView>
            </ShadowView>
          )}
        </ContextMenu>
      </View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  title: {
    flex: 1,
    textAlign: 'center',
  },
  positionContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ...makeThemeStyle(
    'container',
    {
      borderRadius: 23,
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 50,
      width: 345,
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  blurContainer: {
    paddingLeft: 38,
    paddingRight: 8,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 23,
    gap: 16,
    width: 345,
    justifyContent: 'center',
  },
  linearGradient: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 150,
  },
});

export default SelectToolbar;
