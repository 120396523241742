import { Colors } from 'app/constants/colors';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const Shareable = ({ width = 34, height = 28 }: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 34 28" fill="none">
      <Path
        d="M20.302 26.106C27.4428 26.106 33.355 20.1809 33.355 13.053C33.355 5.91224 27.4299 0 20.2893 0C13.1612 0 7.24902 5.91224 7.24902 13.053C7.24902 20.1809 13.1741 26.106 20.302 26.106ZM20.2893 24.1609C17.3587 24.1609 14.3897 22.9579 12.4319 20.872C13.8139 18.6966 16.8212 17.4168 20.2893 17.4168C23.7316 17.4168 26.7646 18.6709 28.1594 20.872C26.1887 22.9579 23.2326 24.1609 20.2893 24.1609ZM20.2893 15.2413C17.8322 15.2157 15.8999 13.1681 15.8999 10.4168C15.887 7.8318 17.845 5.68189 20.2893 5.68189C22.7463 5.68189 24.6786 7.8318 24.6786 10.4168C24.6786 13.1681 22.7591 15.2669 20.2893 15.2413Z"
        fill="#B8B8BA"
      />
      <Path
        d="M8.89961 27.9999C13.3914 27.9999 17.1537 24.2504 17.1537 19.7458C17.1537 15.2156 13.4297 11.4917 8.89961 11.4917C4.38226 11.4917 0.645508 15.2156 0.645508 19.7458C0.645508 24.2887 4.36946 27.9999 8.89961 27.9999Z"
        fill="#299764"
      />
      <Path
        d="M8.89934 26.2466C12.4185 26.2466 15.4002 23.3033 15.4002 19.7458C15.4002 16.1754 12.4697 13.2449 8.89934 13.2449C5.34176 13.2449 2.39844 16.1882 2.39844 19.7458C2.39844 23.3289 5.34176 26.2466 8.89934 26.2466Z"
        fill="#299764"
      />
      <Path
        d="M8.11875 23.2524C7.91399 23.2524 7.64526 23.1628 7.47889 22.9836L5.31618 20.6034C5.16262 20.437 5.09863 20.2067 5.09863 20.0275C5.09863 19.5668 5.45696 19.2213 5.90486 19.2213C6.17359 19.2213 6.37834 19.3493 6.51912 19.4901L8.09315 21.1921L11.2028 16.8667C11.3564 16.6492 11.5867 16.5083 11.8811 16.5083C12.3162 16.5083 12.6873 16.8538 12.6873 17.3018C12.6873 17.4425 12.6234 17.6217 12.5082 17.788L8.79699 22.9453C8.65623 23.1373 8.40028 23.2524 8.11875 23.2524Z"
        fill="#F2FCF5"
      />
    </Svg>
  );
};

export default Shareable;
