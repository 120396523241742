import React from 'react';
import BlurView from '../BlurView';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import { StyleSheet, View } from 'react-native';
import Toast from 'app/hooks/use-toast';
import Animated from 'react-native-reanimated';

interface Props {
  children: React.ReactNode;
}

function PageNative({ children }: Props) {
  return (
    <ThemeView
      lightStyle={styles.appContainerLight}
      darkStyle={styles.appContainerDark}
    >
      <BlurView amount={24} style={styles.blurView}>
        <Animated.ScrollView
          nestedScrollEnabled
          contentContainerStyle={[
            styles.scrollViewNative,
            styles.scrollViewContainer,
          ]}
          contentInsetAdjustmentBehavior="automatic"
        >
          <View style={styles.scrollViewContentMobile}>{children}</View>
        </Animated.ScrollView>
        <Toast portalHostName="modal" />
      </BlurView>
    </ThemeView>
  );
}

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'appContainer',
    {
      flex: 1,
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  scrollViewContainer: {
    flexGrow: 1,
    paddingBottom: 36,
    overflow: 'visible',
  },
  scrollViewContentMobile: {
    paddingHorizontal: 16,
  },
  scrollViewNative: {
    paddingTop: 36,
  },
  blurView: {
    flex: 1,
  },
});

export default PageNative;
