import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import { Platform, StyleSheet } from 'react-native';
import Animated, {
  Easing,
  interpolate,
  useAnimatedProps,
  useSharedValue,
  withRepeat,
  withSequence,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import Svg, { Circle } from 'react-native-svg';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

interface Props {
  width?: number;
  height?: number;
  animate?: boolean;
  autoAnimate?: boolean;
  colorOverride?: Colors;
}

const accelerateDuration = 300;
const decelerateDuration = 350;
const offset = 150;

const waitDuration = offset * 3;

const accelerate = {
  duration: accelerateDuration,
  easing: Easing.out(Easing.poly(3)),
};

const decelerate = {
  duration: decelerateDuration,
  easing: Easing.in(Easing.elastic(1.4)),
};

const Dots = ({
  width = 45,
  height = 15,
  animate,
  autoAnimate,
  colorOverride,
}: Props) => {
  const blueBounce = useSharedValue(0);
  const redBounce = useSharedValue(0);
  const pinkBounce = useSharedValue(0);
  const color = useColor(colorOverride);

  const blueStyle = useAnimatedProps(() => {
    return {
      cy: interpolate(
        blueBounce.value,
        [0, 1],
        [29, 0],
        // [0, 1, 2, 6],
        // [29, 0, 29, 29],
        // Extrapolation.CLAMP,
      ),
    };
  }, [blueBounce]);
  const redStyle = useAnimatedProps(
    () => ({
      cy: interpolate(
        redBounce.value,
        [0, 1],
        [29, 0],
        // [1, 2, 3, 4, 6],
        // [29, 29, 0, 29, 29],
        // Extrapolation.CLAMP,
      ),
    }),
    [redBounce],
  );
  const pinkStyle = useAnimatedProps(
    () => ({
      cy: interpolate(
        pinkBounce.value,
        [0, 1],
        [29, 0],
        // [3, 4, 5, 6],
        // [29, 29, 0, 29],
        // Extrapolation.CLAMP,
      ),
    }),
    [pinkBounce],
  );

  const runAnimation = React.useCallback(() => {
    blueBounce.value = withRepeat(
      withSequence(
        withTiming(1, accelerate),
        withTiming(0, decelerate),
        withTiming(0, {
          duration: waitDuration,
        }),
      ),
      -1,
    );
    redBounce.value = withRepeat(
      withSequence(
        withTiming(0, {
          duration: offset,
        }),
        withTiming(1, accelerate),
        withTiming(0, decelerate),
        withTiming(0, {
          duration: waitDuration - offset,
        }),
      ),
      -1,
    );
    pinkBounce.value = withRepeat(
      withSequence(
        withTiming(0, {
          duration: offset * 2,
        }),
        withTiming(1, accelerate),
        withTiming(0, decelerate),
        withTiming(0, {
          duration: waitDuration - offset * 2,
        }),
      ),
      -1,
    );
  }, [blueBounce, pinkBounce, redBounce]);

  const stopAnimation = React.useCallback(() => {
    blueBounce.value = withSpring(0, decelerate);
    redBounce.value = withSpring(0, decelerate);
    pinkBounce.value = withSpring(0, decelerate);
  }, [blueBounce, pinkBounce, redBounce]);

  React.useEffect(() => {
    if (autoAnimate) {
      runAnimation();
    }
  }, [autoAnimate, runAnimation]);

  return (
    <Svg
      width={width}
      // On iOS, SVGs cant have visible overflow, so we need to set it to a
      // larger height. It doesn't matter to layout as we only use it in the
      // splash screen.
      height={Platform.select({
        web: height,
        default: height * 2,
      })}
      viewBox="0 0 178 59"
      fill="none"
      style={styles.container}
      onMouseEnter={() => {
        if (!animate) {
          return;
        }

        runAnimation();
      }}
      onMouseOut={(event) => {
        if (!animate) {
          return;
        }
        const tagName = event.nativeEvent?.toElement?.tagName;

        if (!tagName || tagName === 'circle' || tagName === 'svg') {
          return;
        }

        stopAnimation();
      }}
    >
      <AnimatedCircle
        cx="30.2339"
        r="29.2964"
        fill={color || '#5F7EE3'}
        animatedProps={blueStyle}
      />
      <AnimatedCircle
        cx="89.3316"
        r="29.2964"
        fill={color || '#E6534C'}
        animatedProps={redStyle}
      />
      <AnimatedCircle
        cx="148.429"
        r="29.2964"
        fill={color || '#EFA7EB'}
        animatedProps={pinkStyle}
      />
    </Svg>
  );
};

const styles = StyleSheet.create({
  container: {
    overflow: 'visible',
  },
});

export default Dots;
