import Button, { ButtonType } from 'app/components/Button';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import Text, { TextType } from 'app/components/Text';
import TextInput from 'app/components/TextInput';
import { dark } from 'app/constants/colors';
import useAuth, { APP_STORE_REVIEW_EMAIL } from 'app/hooks/use-auth/use-auth';
import useCopy from 'app/hooks/use-copy';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import config from 'app/config';
import AuthenticationPage from 'app/components/AuthenticationPage';

const Authentication = ({
  route,
  navigation,
}: ScreenProps<
  typeof Routes.LOGIN | typeof Routes.SIGNUP | typeof Routes.REGISTER
>) => {
  const [email, setEmail] = useState<string>(route?.params?.email || '');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const {
    autofill,
    cancelAutofill,
    authenticate,
    register,
    pending,
    errorShakeAnimatedValue,
  } = useAuth();
  const isLogin = route.name === Routes.LOGIN;
  const isSignup = route.name === Routes.SIGNUP;
  const textColor = 'white';
  const placeholderTextColor = dark.whiteA11;
  const [autoFocus, setAutoFocus] = useState(false);

  const copy = useCopy([
    'loginButtonText',
    'signupButtonText',
    'createAccountTerms',
    'createAccountPrivacyButtonText',
    'createAccountTermsButtonText',
    'createAccountAndButtonText',
    'signupUsernameHelpText',
    'signupUsernamePlaceholder',
    'signupEmailPlaceholder',
    'loginEmailPlaceholder',
    'registerCopy',
    'registerButtonText',
    'postRegister',
    'alreadyHaveInvite',
    'loginTitle',
    'registerTitle',
    'signupTitle',
    'authenticationPageFooterText',
  ]);

  useLayoutEffect(() => {
    if (isLogin) {
      autofill();
    }

    // This seems to be the only way to ensure that the autofill has started
    // before setting autofocus. Without this, the user has to tap the field
    // again to get the autofill suggestions.
    setTimeout(() => setAutoFocus(true), 300);

    return () => {
      if (isLogin) {
        cancelAutofill();
      }
    };
  }, [autofill, cancelAutofill, isLogin]);

  const handleLogin = useCallback(async () => {
    await authenticate(email, password);
  }, [email, password, authenticate]);

  const handleSignup = useCallback(async () => {
    await register(username, email);
  }, [email, username, register]);

  let fields = null;
  let cta = null;
  let secondary = null;
  let title = '';

  if (isSignup || isLogin) {
    if (isSignup) {
      title = copy.signupTitle;
      fields = (
        <>
          <TextInput
            placeholder={copy.signupUsernamePlaceholder}
            onChangeText={setUsername}
            value={username}
            style={styles.input}
            placeholderColor={placeholderTextColor}
            autoCapitalize="none"
            // This maps to textContentType on iOS
            autoComplete={Platform.select({
              web: 'webauthn',
              default: 'username',
            })}
            helpText={copy.signupUsernameHelpText}
            returnKeyType="next"
            type="fancy"
            color={textColor}
            helpTextColor={dark.whiteA12}
            leftElementStyle={styles.usernameLeftElementStyle}
            leftElement={
              <Text type={TextType.body} color="white">
                coolstuff.app/
              </Text>
            }
            autoFocus={autoFocus}
            selectionColor="white"
          />
          <TextInput
            placeholder={copy.signupEmailPlaceholder}
            onChangeText={setEmail}
            value={email}
            style={styles.input}
            placeholderColor={placeholderTextColor}
            color={textColor}
            autoCapitalize="none"
            textContentType="emailAddress"
            returnKeyType="next"
            type="fancy"
            onSubmitEditing={handleSignup}
            selectionColor="white"
          />
        </>
      );

      cta = (
        <Button
          type={ButtonType.primary}
          onPress={handleSignup}
          style={styles.button}
          disabled={!email || !username}
          pending={pending}
          disabledTransparent
          forceColorScheme="light"
          shakeAnimatedValue={errorShakeAnimatedValue}
        >
          {copy.signupButtonText}
        </Button>
      );

      secondary = (
        <Text
          type={TextType.footnote}
          style={{ textAlign: 'center' }}
          color="whiteA12"
        >
          {`${copy.createAccountTerms}\n`}
          <Text
            type={TextType.footnoteEmphasized}
            color="white"
            onPress={() => Linking.openURL(config.urls.terms)}
            href={config.urls.terms}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            {copy.createAccountTermsButtonText}
          </Text>
          {` ${copy.createAccountAndButtonText} `}
          <Text
            type={TextType.footnoteEmphasized}
            color="white"
            onPress={() => Linking.openURL(config.urls.privacy)}
            href={config.urls.privacy}
            hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
          >
            {copy.createAccountPrivacyButtonText}
          </Text>
          .
        </Text>
      );
    } else {
      title = copy.loginTitle;

      fields = (
        <>
          <TextInput
            placeholder={copy.loginEmailPlaceholder}
            onChangeText={setEmail}
            value={email}
            style={styles.input}
            placeholderColor={placeholderTextColor}
            color={textColor}
            autoCapitalize="none"
            // This maps to textContentType on iOS
            autoComplete={Platform.select({
              web: 'webauthn',
              default: 'username',
            })}
            returnKeyType="next"
            type="fancy"
            onSubmitEditing={handleLogin}
            selectionColor="white"
            autoFocus={autoFocus}
          />
          {email === APP_STORE_REVIEW_EMAIL && (
            <TextInput
              placeholder="Password"
              onChangeText={setPassword}
              onSubmitEditing={handleLogin}
              value={password}
              placeholderColor={dark.whiteA11}
              color="white"
              autoCapitalize="none"
              textContentType="password"
              returnKeyType="next"
              type="fancy"
              secureTextEntry
            />
          )}
        </>
      );

      cta = (
        <Button
          type={ButtonType.primary}
          onPress={handleLogin}
          style={styles.button}
          disabled={!email}
          pending={pending}
          disabledTransparent
          forceColorScheme="light"
          shakeAnimatedValue={errorShakeAnimatedValue}
        >
          {copy.loginButtonText}
        </Button>
      );
    }
  }

  return (
    <AuthenticationPage>
      {fields}
      {cta}
      <View style={styles.secondaryAction}>{secondary}</View>
    </AuthenticationPage>
  );
};

const styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
  },
  secondaryAction: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  usernameLeftElementStyle: {
    marginLeft: 12,
    justifyContent: 'center',
  },
});

export default Authentication;
