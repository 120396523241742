export function makeRetrieveUrl({
  baseUrl,
  type,
  id,
  include,
}: {
  baseUrl: string;
  type: string;
  id: string;
  include?: string[];
}) {
  let url = `${baseUrl}/${type}s/${id}`;

  if (include) {
    url = `${url}?include=${include.join(',')}`;
  }

  return url;
}

export function makeListUrl({
  baseUrl,
  type,
  include,
  query,
}: {
  baseUrl: string;
  type: string;
  include?: string[];
  query?: ConstructorParameters<typeof URLSearchParams>[0];
}) {
  let url = `${baseUrl}/${type}s`;

  const queryParams = new URLSearchParams(query);

  if (include) {
    queryParams.append('include', include.join(','));
  }

  const querystring = queryParams.toString();
  if (querystring) {
    url = `${url}?${querystring}`;
  }

  return url;
}

export function makeListActionUrl({
  baseUrl,
  type,
  include,
  query,
  name,
}: {
  name: string;
  baseUrl: string;
  type: string;
  include?: string[];
  query?: ConstructorParameters<typeof URLSearchParams>[0];
}) {
  let url = `${baseUrl}/${type}s/${name}`;

  const queryParams = new URLSearchParams(query);

  if (include) {
    queryParams.append('include', include.join(','));
  }

  const querystring = queryParams.toString();
  if (querystring) {
    url = `${url}?${querystring}`;
  }

  return url;
}
