import React, { useCallback, useContext } from 'react';
import { Platform, StyleSheet, View, ViewProps } from 'react-native';
import Close from '../Icons/Close';
import Pressable from '../Pressable';
import Text, { TextType } from '../Text';
import useStuffsQuery from './useStuffsQuery';
import { useResource } from 'app/hooks/use-resource/use-resource';
import useColorScheme from 'app/hooks/use-color-scheme';
import { makeThemeStyle } from '../ThemeView';
import { useNavigation } from '@react-navigation/native';
import Routes from '../Navigator/ROUTES';
import EllipsisButton from '../EllipsisButton';

interface Props {
  parsedQuery: ReturnType<typeof useStuffsQuery>['parsedQuery'];
  resetQuery: ReturnType<typeof useStuffsQuery>['resetQuery'];
  style?: ViewProps['style'];
  onFocusToggle?: (focus: boolean) => void;
  isVisitor: boolean;
}

function SearchInputCollection({
  parsedQuery,
  resetQuery,
  style,
  onFocusToggle,
  isVisitor,
}: Props) {
  const colorScheme = useColorScheme();
  const { collection } = useResource('collection', parsedQuery.collectionId);
  const navigation = useNavigation();

  const onClear = useCallback(() => {
    resetQuery();
  }, [resetQuery]);

  const onEdit = useCallback(() => {
    if (!collection) {
      return;
    }

    navigation.navigate(Routes.EDIT_COLLECTION, {
      collection: collection.id,
    });
  }, [collection, navigation]);

  const onFocus = useCallback(() => {
    if (onFocusToggle) {
      onFocusToggle(true);
    }
  }, [onFocusToggle]);

  return (
    <Pressable
      style={[style]}
      lightStyle={styles.containerLight}
      darkStyle={styles.containerDark}
      onFocus={onFocus}
    >
      <View style={styles.editButtonContainer}>
        {!isVisitor && (
          <EllipsisButton
            width={20}
            height={20}
            onPress={onEdit}
            style={styles.button}
          />
        )}
      </View>
      <Text type={TextType.body} style={styles.title}>
        {collection?.attributes?.title || ''}
      </Text>
      <Pressable
        style={styles.button}
        onPress={onClear}
        hitSlop={{
          top: 8,
          left: 8,
          right: 8,
          bottom: 8,
        }}
      >
        <Close
          width={12}
          height={12}
          color={colorScheme === 'dark' ? 'gray12' : 'gray9'}
        />
      </Pressable>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'container',
    {
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
      flexDirection: 'row',
      paddingHorizontal: 8,
    },
    {
      backgroundColor: 'BackgroundGray',
    },
  ),
  title: {
    flex: 1,
    textAlign: 'center',
  },
  editButtonContainer: {
    width: 36,
  },
  button: Platform.select({
    web: {
      // Emulates hitslop
      padding: 20,
      margin: -12,
    },
    default: {
      padding: 8,
    },
  }),
});

export default SearchInputCollection;
