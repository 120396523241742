import { dark, light } from 'app/constants/colors';
import useIsTouchDevice from 'app/hooks/use-is-touch-device';
import { useDelete, useResource } from 'app/hooks/use-resource/use-resource';
import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import { LayoutAnimation, Platform, StyleSheet, ViewStyle } from 'react-native';
import { View } from 'react-native';
import useStuffsQuery from '../CollectionSheet/useStuffsQuery';
import Text, { TextType } from '../Text';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import BlurView from '../BlurView';
import ContextMenu from '../ContextMenu/ContextMenu';
import Trash from '../Icons/Trash';
import { DropdownItem } from '../Dropdown';
import useCopy from 'app/hooks/use-copy';
import { RNSpringConfig } from '../LayoutAnimation';
import Routes from '../Navigator/ROUTES';
import { useLinkBuilder } from '@react-navigation/native';
import useShareLink from 'app/hooks/use-share-link';
import Share from '../Icons/Share';
import Ellipsis from '../Icons/Ellipsis';
import {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Pressable from '../Pressable';
import Image from '../Image';

interface Props {
  collectionId: string;
  style?: ViewStyle;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
}

const InnerContainer = Platform.select({
  web: BlurView,
  default: View,
});

const hoverConfig = {
  damping: 20,
  mass: 1,
  stiffness: 600,
};

function Collection({ collectionId, style, onChangeQuery }: Props) {
  const { ready, collection } = useResource('collection', collectionId);
  const copy = useCopy(['searchSheetItemName', 'collectionCopiedToClipboard']);

  const deleteCollection = useDelete('search');
  const isTouchDevice = useIsTouchDevice();
  const isHoveredValue = useSharedValue(isTouchDevice ? 1 : 0);
  const buildLink = useLinkBuilder();
  const shareLink = useShareLink();

  const ellipsisStyle = useAnimatedStyle(
    () => ({
      opacity: isHoveredValue.value,
    }),
    [isHoveredValue],
  );

  const onPress = useCallback(() => {
    if (!collection) {
      return;
    }

    onChangeQuery({
      'filter[collection.id]': collection.id,
    });
  }, [collection, onChangeQuery]);

  const removeCollection = useCallback(() => {
    LayoutAnimation.configureNext(RNSpringConfig);
    deleteCollection(collectionId);
  }, [deleteCollection, collectionId]);

  const onShare = useCallback(
    (tryNative = true) => {
      if (!collection) {
        return;
      }
      const path = buildLink(Routes.USER, {
        'filter[collection.id]': collection.id,
      });
      const url = `https://coolstuff.app${path}`;
      shareLink({
        url,
        shareConfirmation: copy.collectionCopiedToClipboard,
        tryNative,
      });
    },
    [buildLink, collection, copy.collectionCopiedToClipboard, shareLink],
  );

  if (!ready) {
    return null;
  }

  return (
    <ContextMenu
      actions={[
        <DropdownItem
          label="Share"
          Icon={Share}
          onPress={onShare}
          key="share"
        />,
        <DropdownItem
          label="Remove"
          renderIcon={(isActive) =>
            isActive ? <Trash color="white" /> : <Trash color="red9" />
          }
          last
          textColor="red9"
          activeTextColor="white"
          activeBackgroundColor="red9"
          onPress={removeCollection}
          key="remove"
        />,
      ]}
      onPress={onPress}
      onHoverIn={() => (isHoveredValue.value = withSpring(1, hoverConfig))}
      onHoverOut={(event) => {
        if (event.type !== 'pointerleave') {
          return;
        }

        isHoveredValue.value = withSpring(0, hoverConfig);
      }}
    >
      {({ open }) => (
        <ThemeView
          darkStyle={styles.containerDark}
          lightStyle={styles.containerLight}
          style={[styles.container, style]}
        >
          <InnerContainer amount={24} style={styles.innerContainer}>
            {collection.attributes.thumbnail ? (
              <Image
                source={collection.attributes.thumbnail}
                style={styles.thumbnail}
                contentPosition="top center"
              />
            ) : (
              <ThemeView
                darkStyle={styles.placeholderDark}
                lightStyle={styles.placeholderLight}
              />
            )}
            <View style={styles.content}>
              <Text type={TextType.body} numberOfLines={1} ellipsizeMode="tail">
                {collection.attributes.title}
              </Text>
              <Text type={TextType.caption1} color="SupportingGray">
                {collection.attributes.link_count}{' '}
                {pluralize(
                  copy.searchSheetItemName,
                  collection.attributes.link_count,
                )}
              </Text>
            </View>
            <Pressable onPress={open} style={[styles.ellipsis, ellipsisStyle]}>
              <Ellipsis />
            </Pressable>
          </InnerContainer>
        </ThemeView>
      )}
    </ContextMenu>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
  },
  containerLight: {
    backgroundColor: light.SolidWhite,
  },
  containerDark: {
    backgroundColor: dark.gray4,
  },
  innerContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 15,
    gap: 16,
  },
  content: {
    gap: 2,
    flex: 1,
  },
  ellipsis: {
    padding: 8,
    margin: -8,
  },
  thumbnail: {
    minWidth: 60,
    borderRadius: 13,
    aspectRatio: 1,
    borderWidth: 1,
    borderColor: light.BackgroundGray,
  },
  ...makeThemeStyle(
    'placeholder',
    {
      minWidth: 60,
      borderRadius: 13,
      aspectRatio: 1,
      borderWidth: 1,
    },
    {
      backgroundColor: 'BackgroundGray',
      borderColor: 'BackgroundGray',
    },
  ),
});

export default React.memo(Collection);
