import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text, { TextType } from '../Text';

interface Props {
  children: string;
  color?: React.ComponentProps<typeof Text>['color'];
  type?: React.ComponentProps<typeof Text>['type'];
  subtitleColor?: React.ComponentProps<typeof Text>['color'];
  subtitle?: string;
  disabled?: boolean;
}

function ListItemLabel({
  children,
  color,
  subtitleColor = 'LayeredGray',
  subtitle,
  type = TextType.body,
  disabled = false,
}: Props) {
  return (
    <View style={[styles.container]}>
      <Text
        color={disabled ? 'LayeredGray' : color}
        type={type}
        numberOfLines={1}
      >
        {children}
      </Text>
      {subtitle && (
        <Text
          color={subtitleColor}
          type={TextType.subheadline}
          style={styles.subtitle}
        >
          {subtitle}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 11,
    flex: 1,
    minWidth: 'auto',
  },
  subtitle: {
    marginTop: 0,
  },
});

export default ListItemLabel;
