import { Portal } from '@gorhom/portal';
import BlurView from 'app/components/BlurView';
import Close from 'app/components/Icons/Close';
import Text, { TextType } from 'app/components/Text';
import { makeThemeStyle } from 'app/components/ThemeView';
import { dark, light } from 'app/constants/colors';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FullWindowOverlay } from 'react-native-screens';
import {
  useContext,
  createContext,
  useContextSelector,
} from 'use-context-selector';
import useColorScheme from './use-color-scheme';

interface ToastType {
  type: 'info' | 'error';
  text: string;
  status: 'open' | 'closing';
  portalHostName?: string;
}

interface State {
  toast: ToastType | null;
  setToast: (toast: ToastType | null) => void;
}

const defaultState: State = {
  toast: null,
  setToast: () => {},
};

const Context = createContext<State>(defaultState);

interface ToastProviderProps {
  children?: React.ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
  const [toast, setToast] = useState<ToastType | null>(null);

  return (
    <Context.Provider value={{ toast, setToast }}>{children}</Context.Provider>
  );
}

export function useToast() {
  const _setToast = useContextSelector(Context, (state) => state.setToast);

  const setToast = useCallback(
    ({
      type,
      text,
      delay,
      portalHostName,
    }: {
      type: ToastType['type'];
      text: string;
      delay: number;
      portalHostName?: string;
    }) => {
      _setToast({
        text,
        type,
        status: 'open',
        portalHostName,
      });

      setTimeout(() => {
        _setToast({
          text,
          type,
          status: 'closing',
          portalHostName,
        });
      }, delay);
    },
    [_setToast],
  );

  return setToast;
}

export default function Toast({ portalHostName }: { portalHostName?: string }) {
  const { toast, setToast } = useContext(Context);
  const insets = useSafeAreaInsets();

  const animatedValue = useSharedValue(0);
  const topInset = Platform.select({
    web: 32,
    default: insets.top + 16,
  });
  const style = useAnimatedStyle(
    () => ({
      top: interpolate(animatedValue.value, [0, 1], [-50, topInset]),
      opacity: animatedValue.value,
    }),
    [animatedValue, topInset],
  );

  useEffect(() => {
    if (!toast) {
      return;
    }

    if (toast.portalHostName && portalHostName !== toast.portalHostName) {
      return;
    }

    let value = 0;
    if (toast.status === 'open') {
      value = 1;
    }

    animatedValue.value = withSpring(
      value,
      {
        stiffness: 100,
        mass: 0.4,
        damping: 20,
      },
      () => {
        if (value === 0) {
          runOnJS(setToast)(null);
        }
      },
    );
  }, [animatedValue, toast, setToast, portalHostName]);

  const colorScheme = useColorScheme();
  const toastBorderColor = colorScheme === 'light' ? dark.grayA7 : dark.grayA3;

  if (!toast) {
    return null;
  }

  return (
    <Portal name={portalHostName}>
      <FullWindowOverlay style={StyleSheet.absoluteFill} pointerEvents="none">
        <Animated.View style={[styles.toastContainer, style]}>
          <BlurView
            darkStyle={styles.toastDark}
            lightStyle={styles.toastLight}
            style={{
              borderColor: toastBorderColor,
            }}
            amount={16}
          >
            {toast.type === 'error' && (
              <View style={styles.errorIcon}>
                <Close color="SolidWhite" />
              </View>
            )}
            <Text style={styles.toastText} type={TextType.bodyEmphasized}>
              {toast.text}
            </Text>
          </BlurView>
        </Animated.View>
      </FullWindowOverlay>
    </Portal>
  );
}

const styles = StyleSheet.create({
  toastContainer: {
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  ...makeThemeStyle(
    'toast',
    {
      height: 56,
      borderRadius: 28,
      justifyContent: 'center',
      paddingHorizontal: 16,
      alignItems: 'center',
      flexDirection: 'row',
      gap: 6,
      borderWidth: 1,
    },
    {
      backgroundColor: 'grayA10',
    },
    true,
  ),
  toastText: {
    textAlign: 'center',
  },
  errorIcon: {
    backgroundColor: light.DangerRed,
    padding: 6,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
