import React, { useCallback } from 'react';
import Page from 'app/components/Page';
import useCopy from 'app/hooks/use-copy';
import { Platform } from 'react-native';
import List, { ListItem, ListItemIcon } from 'app/components/List';
import ListItemLabel from 'app/components/List/ListItemLabel';
import { useResource, useUpdate } from 'app/hooks/use-resource/use-resource';
import { Setting, AppearanceType } from 'app/hooks/use-resource/types';
import Checkmark from 'app/components/Icons/Checkmark';

export const appearanceToLabels: { [k in AppearanceType]: string } = {
  system: 'System',
  light: 'Light',
  dark: 'Dark',
};
const appearances: AppearanceType[] = ['system', 'light', 'dark'];

const Appearance = () => {
  const copy = useCopy([
    'settingsAppearanceTitle',
    'settingsAppearanceHelpTextWithoutPlus',
  ]);
  const updateSetting = useUpdate('setting');
  const { setting } = useResource('setting', 'me', {
    // TODO: useResource should be smart enough to dedupe. It'll require
    // a separate, non-react-state, method for request caching.
    fetch: false,
  });
  const hasAppearanceEntitlement =
    setting && setting.attributes.entitlements.includes('set_appearance');

  const updateAppearance = useCallback(
    (appearance: AppearanceType) => {
      if (!setting) {
        return;
      }

      const attributes: Partial<Setting['attributes']> = {};

      if (Platform.OS === 'ios') {
        attributes.appearance_ios = appearance;
      } else if (Platform.OS === 'web') {
        attributes.appearance_web = appearance;
      } else {
        throw Error(`Setting appearance isn't supported on ${Platform.OS}`);
      }

      updateSetting(setting.id, {
        id: setting.id,
        attributes,
        type: 'setting',
      });
    },
    [setting, updateSetting],
  );

  if (!setting) {
    return null;
  }

  const currentAppearance = Platform.select({
    web: setting.attributes.appearance_web,
    default: setting.attributes.appearance_ios,
  });

  return (
    <Page title={copy.settingsAppearanceTitle} fullHeight backButton>
      <List
        help={
          !hasAppearanceEntitlement &&
          copy.settingsAppearanceHelpTextWithoutPlus
        }
      >
        {appearances.map((appearance) => (
          <ListItem
            onPress={() => updateAppearance(appearance)}
            key={appearance}
          >
            <ListItemLabel>{appearanceToLabels[appearance]}</ListItemLabel>
            {currentAppearance == appearance && (
              <ListItemIcon Icon={Checkmark} />
            )}
          </ListItem>
        ))}
      </List>
    </Page>
  );
};

export default Appearance;
