import { useCallback } from 'react';
import makeProvider from './make-provider';
import { Resource } from './types';
import { makeRetrieveUrl } from './urls';

function makeUseDelete<ValidResource extends Resource>({
  useResourceDispatch,
  useResourceFetcher,
  baseUrl,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
  useResourceFetcher: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceFetcher'];
  baseUrl: string;
}) {
  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  function useDelete<Type extends keyof TypeToResource>(type: Type) {
    const dispatch = useResourceDispatch();
    const fetcher = useResourceFetcher();

    const deleteEntity = useCallback(
      async (
        id: string,
        {
          invalidateLists = true,
          localOnly = false,
        }: {
          invalidateLists?: boolean | (keyof TypeToResource)[];
          localOnly?: boolean;
        } = {},
      ) => {
        const url = makeRetrieveUrl({
          baseUrl,
          type,
          id,
        });

        // @ts-ignore
        dispatch({
          type: 'delete_resource_success',
          payload: { id },
          meta: { type },
        });

        if (!localOnly) {
          await fetcher(url, {
            method: 'DELETE',
          });

          if (invalidateLists) {
            if (typeof invalidateLists === 'boolean') {
              // Invalidate all lists for this type
              dispatch({
                type: 'invalidate_list_type',
                meta: { type },
              });
            } else {
              for (const invalidateType of invalidateLists) {
                dispatch({
                  type: 'invalidate_list_type',
                  meta: { type: invalidateType },
                });
              }
            }
          }
        }
      },
      [dispatch, type, fetcher],
    );

    return deleteEntity;
  }

  return useDelete;
}

export default makeUseDelete;
