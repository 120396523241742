import React, { useRef } from 'react';
import { useState } from 'react';
import { Keyboard, StyleSheet, View } from 'react-native';
import TextInput from '../TextInput';
import Pressable from '../Pressable';
import { range } from 'lodash';
import Text, { TextType } from '../Text';
import Caret from './Caret';

interface Props {
  onFilled: (code: string) => void;
  numDigits: number;
}

const OTPInput = ({ onFilled, numDigits }: Props) => {
  const [text, setText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<typeof TextInput>(null);
  const focusedInputIndex = text.length;

  const handlePress = () => {
    if (!Keyboard.isVisible()) {
      Keyboard.dismiss();
    }
    inputRef.current?.focus();
  };

  const handleTextChange = (value: string) => {
    setText(value);
    if (value.length === numDigits) {
      onFilled(value);
    }
  };

  return (
    <View>
      <View style={styles.container}>
        {range(numDigits).map((_, index) => {
          const char = text[index];
          const isFocusedInput = isFocused && index === focusedInputIndex;

          return (
            <Pressable
              key={`${char}-${index}`}
              onPress={handlePress}
              style={[
                styles.otpInputContainer,
                isFocusedInput && styles.otpInputContainerFocused,
              ]}
              testID="otp-input"
            >
              {isFocusedInput ? (
                <Caret />
              ) : (
                <Text type={TextType.title2} color="white">
                  {char}
                </Text>
              )}
            </Pressable>
          );
        })}
      </View>
      <TextInput
        value={text}
        onChangeText={handleTextChange}
        maxLength={numDigits}
        inputMode="numeric"
        textContentType="oneTimeCode"
        autoFocus
        style={[StyleSheet.absoluteFillObject, styles.hiddenInput]}
        autoComplete="one-time-code"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 8,
  },
  otpInputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 49,
    width: 49,
    borderWidth: 2,
    borderRadius: 13,
    borderColor: 'rgba(255, 255, 255, 0.4)',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  otpInputContainerFocused: {
    borderColor: 'white',
  },
  hiddenInput: {
    opacity: 0.01,
  },
});

export default OTPInput;
