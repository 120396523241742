import React, { ReactNode, useEffect, useState } from 'react';
import {
  LayoutAnimationConfig,
  LayoutRectangle,
  ViewProps,
} from 'react-native';
import Animated, {
  FadeOut,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  WithSpringConfig,
} from 'react-native-reanimated';

export enum CoolLayoutAnimationType {
  up = 'up',
  upSlow = 'upSlow',
  fadeIn = 'fadeIn',
}

export const RNSpringConfig: LayoutAnimationConfig = {
  duration: 700,
  create: {
    type: 'linear',
    property: 'opacity',
  },
  update: {
    type: 'spring',
    springDamping: 0.7,
  },
  delete: {
    type: 'spring',
    springDamping: 1,
    property: 'opacity',
  },
};

const typeToSpringConfig: {
  [type in CoolLayoutAnimationType]: WithSpringConfig;
} = {
  up: {
    stiffness: 100,
    mass: 0.25,
    damping: 20,
  },
  upSlow: {
    stiffness: 100,
    mass: 0.7,
    damping: 20,
  },
  fadeIn: {
    stiffness: 100,
    mass: 0.25,
    damping: 20,
  },
};

interface Props {
  type: CoolLayoutAnimationType;
  children: ReactNode;
  enabled?: boolean;
  style?: ViewProps['style'];
}

const _LayoutAnimation = ({ type, children, style: _style }: Props) => {
  const value = useSharedValue(0);

  useEffect(() => {
    value.value = withSpring(1, typeToSpringConfig[type]);
  });

  const [layout, setLayout] = useState<LayoutRectangle | null>(null);

  const style = useAnimatedStyle(() => {
    if (!layout) {
      return { opacity: 0 };
    }

    if (type === CoolLayoutAnimationType.up) {
      return {
        opacity: value.value,
        transform: [
          {
            scale: interpolate(value.value, [0, 1], [0.9, 1]),
          },
          {
            translateY: interpolate(
              value.value,
              [0, 1],
              [layout.height * 0.1, 0],
            ),
          },
        ],
      };
    }

    if (type === CoolLayoutAnimationType.upSlow) {
      return {
        opacity: value.value,
        transform: [
          {
            scale: interpolate(value.value, [0, 1], [0.92, 1]),
          },
          {
            translateY: interpolate(
              value.value,
              [0, 1],
              [layout.height * 0.05, 0],
            ),
          },
        ],
      };
    }

    if (type === CoolLayoutAnimationType.fadeIn) {
      return {
        opacity: value.value,
      };
    }

    return {};
  }, [layout, type, value]);

  return (
    <Animated.View
      onLayout={(event) => setLayout(event.nativeEvent.layout)}
      style={[style, _style]}
      exiting={FadeOut}
    >
      {children}
    </Animated.View>
  );
};

const LayoutAnimation = (props: Props) => {
  const enabled = props.enabled === undefined ? true : props.enabled;

  if (!enabled) {
    return <>{props.children}</>;
  }

  return <_LayoutAnimation {...props} />;
};

export default LayoutAnimation;
