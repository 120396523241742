import { NavigationContainer, Theme } from '@react-navigation/native';
import {
  ResourceProvider,
  ResourceProviderProps,
} from 'app/hooks/use-resource/use-resource';
import React, { useCallback, useMemo, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Navigator, { linking } from '../Navigator';
import { KeysProvider } from 'react-native-hotkeys';
import { withIAPContext } from 'react-native-iap';

import { AuthProvider } from 'app/hooks/use-auth';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { dark, light } from 'app/constants/colors';
import { PortalHost, PortalProvider } from '@gorhom/portal';
import Toast, { ToastProvider, useToast } from 'app/hooks/use-toast';
import LinkExpandedContext from '../StuffList/LinkExpandedContext';
import { RouteTitles, RouteType, guestLinking } from '../Navigator/ROUTES';
import Splash from '../StuffList/Splash';
import useLandingMessage from 'app/hooks/use-landing-message';
import { JSONAPIError } from 'app/hooks/make-resource/make-fetcher';
import useColorScheme from 'app/hooks/use-color-scheme';
import { useAuthStatus } from 'app/hooks/use-auth/use-auth-status';
import { Status } from 'app/hooks/use-auth/types';

const App = ({
  resourceState,
  resourceDispatch,
}: {
  resourceState?: ResourceProviderProps['initialState'];
  resourceDispatch?: ResourceProviderProps['dispatch'];
}) => {
  const colorScheme = useColorScheme();
  const colors = colorScheme === 'light' ? light : dark;
  useLandingMessage();

  // Remove as pagination makes this weird. We might instead, build
  // something that automatically detects if there's new data, and then
  // adds a `hasNew` to the list response. The UI can use that to show
  // a refresh button.
  // useInvalidateOnForeground();

  const theme: Theme = {
    dark: false,
    colors: {
      primary: light.coolBlue,
      background: 'transparent',
      card: colors['gray4'],
      text: colors['gray12'],
      border: colors['gray6'],
      notification: light.coolBlue,
    },
  };

  const setToast = useToast();

  const onError = useCallback(
    (error: JSONAPIError) => {
      setToast({
        type: 'info',
        text: error.errors[0].title,
        delay: 4000,
      });
    },
    [setToast],
  );

  const status = useAuthStatus();

  if (status === Status.PENDING) {
    return <Splash />;
  }

  const initialState =
    resourceState ||
    (Platform.OS === 'web'
      ? !global.__IS_SERVER__ && window.HYDRATED_RESOURCE_STATE
      : undefined);

  let portals = (
    <>
      <PortalHost name="header" />
      <PortalHost name="expandedLinks" />
      <PortalHost name="bottomSheet" />
      <PortalHost name="contextMenu" />
      <PortalHost name="splash" />
      <PortalHost name="confirm" />
    </>
  );

  if (Platform.OS === 'web') {
    portals = (
      <View style={styles.portalContainerWeb} pointerEvents="box-none">
        {portals}
      </View>
    );
  }

  return (
    <ResourceProvider
      initialState={initialState}
      dispatch={resourceDispatch}
      onError={onError}
    >
      <NavigationContainer
        linking={status === Status.USER ? linking : guestLinking}
        theme={theme}
        documentTitle={{
          formatter: (options, route) => {
            let title = options?.title;

            if (!title && route?.name) {
              title = RouteTitles[route.name as RouteType];
            }

            if (Platform.OS === 'web' && !title.includes('Cool Stuff')) {
              return `${title} • Cool Stuff`;
            }

            return title;
          },
        }}
      >
        <PortalProvider>
          <Navigator />
          {portals}
        </PortalProvider>
      </NavigationContainer>
    </ResourceProvider>
  );
};

// We render the App as it's own component just with providers so we
// can use hooks in the main App
let AppContainer = ({
  token,
  resourceState,
  resourceDispatch,
}: {
  token?: string;
  resourceState?: ResourceProviderProps['initialState'];
  resourceDispatch?: ResourceProviderProps['dispatch'];
}) => {
  const initialMetrics = global.__IS_SERVER__
    ? {
        frame: {
          width: 320,
          height: 640,
          x: 0,
          y: 0,
        },
        insets: {
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        },
      }
    : undefined;

  const [linkIsExpanded, setLinkIsExpanded] = useState(false);
  const linkExpandedContext = useMemo(
    () => ({
      linkIsExpanded,
      setLinkIsExpanded,
    }),
    [linkIsExpanded],
  );

  return (
    <ToastProvider>
      <AuthProvider token={token}>
        <GestureHandlerRootView style={styles.gestureHandlerRoot}>
          <PortalProvider>
            <KeysProvider>
              <SafeAreaProvider initialMetrics={initialMetrics}>
                <LinkExpandedContext.Provider value={linkExpandedContext}>
                  <App
                    resourceState={resourceState}
                    resourceDispatch={resourceDispatch}
                  />
                  <Toast />
                </LinkExpandedContext.Provider>
              </SafeAreaProvider>
            </KeysProvider>
          </PortalProvider>
        </GestureHandlerRootView>
      </AuthProvider>
    </ToastProvider>
  );
};

const styles = StyleSheet.create({
  gestureHandlerRoot: {
    flex: 1,
    maxHeight: '100%',
  },
  portalContainerWeb: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
});

if (Platform.OS === 'ios') {
  AppContainer = withIAPContext(AppContainer);
}

export default AppContainer;
