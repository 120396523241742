import React, { PropsWithChildren } from 'react';
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { useHeaderHeight } from '@react-navigation/elements';
import LayoutAnimation, { CoolLayoutAnimationType } from './LayoutAnimation';

const AuthenticationPage = ({ children }: PropsWithChildren) => {
  const headerHeight = useHeaderHeight();
  return (
    <View
      style={[
        styles.scrollViewContainer,
        Platform.OS === 'ios' && styles.scrollViewContainerIOS,
        {
          marginTop: headerHeight,
        },
      ]}
    >
      <ScrollView
        style={styles.scrollView}
        centerContent
        contentContainerStyle={[
          styles.scrollViewContent,
          styles.contentContainer,
        ]}
        keyboardShouldPersistTaps="always"
      >
        <LayoutAnimation
          type={CoolLayoutAnimationType.upSlow}
          style={styles.contentContainer}
          enabled={Platform.OS === 'web'}
        >
          {children}
        </LayoutAnimation>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    flex: 1,
  },
  scrollViewContainerIOS: {
    // This the height of an iOS keyboard with the password autofill
    // Remove and replace with KeyboardAvoidingVie when this bug is resolved:
    // https://github.com/facebook/react-native/issues/39411#issuecomment-1726601414
    // 91 is the header
    height: Dimensions.get('window').height - 336 - 91,
    flex: 0,
  },
  scrollView: {
    flex: 1,
    paddingHorizontal: Platform.select({
      web: 16,
      default: 0,
    }),
  },
  scrollViewContent: Platform.select({
    web: {
      maxWidth: 375,
      flex: 1,
      alignSelf: 'center',
      justifySelf: 'center',
      width: '100%',
    },
    default: {
      padding: 20,
    },
  }),
  contentContainer: {
    gap: 12,
  },
});

export default AuthenticationPage;
