import { useCallback, useEffect, useMemo, useState } from 'react';
import { FetcherResponseList, JSONAPIError } from './make-fetcher';
import makeProvider, { RequestStatus } from './make-provider';
import { Resource } from './types';
import { makeListUrl } from './urls';

type ListName<S extends string> = `${S}List`;
export type Query = ConstructorParameters<typeof URLSearchParams>[0];

type ErrorResourceObject<Type extends string> = {
  [type in ListName<Type>]: null;
} & {
  ready: false;
  errors: JSONAPIError['errors'];
  meta: null;
  hasMore: boolean;
  fetchMore: () => void;
  status: RequestStatus;
};

type UnreadyResourceObject<Type extends string> = {
  [type in ListName<Type>]: null;
} & {
  ready: false;
  errors: null;
  meta: null;
  hasMore: boolean;
  fetchMore: () => void;
  status: RequestStatus;
};

type ReadyResourceObject<Type extends string> = {
  [type in ListName<Type>]: string[];
} & {
  meta: {
    count: number;
  };
  hasMore: boolean;
  fetchMore: () => void;
  ready: true;
  errors: null;
  status: RequestStatus;
};

type UseResourceList<Type extends string> =
  | ErrorResourceObject<Type>
  | UnreadyResourceObject<Type>
  | ReadyResourceObject<Type>;

function makeUseResourceList<ValidResource extends Resource>({
  useResourceDispatch,
  useRequest,
  useList,
  baseUrl,
  useResourceFetcher,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
  useResourceFetcher: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceFetcher'];
  useList: ReturnType<typeof makeProvider<ValidResource>>['useList'];
  useRequest: ReturnType<typeof makeProvider<ValidResource>>['useRequest'];
  baseUrl: string;
}) {
  /**
   * Retrieves a resource list for a given type.
   *
   * @param type the resource type
   * @param [options] the options to configure the resource fetch
   * @param [options.include=[]] the relationships to include in the API request as `?include` parameter
   * @param [options.refresh=false] whether or not the refresh the data even if there is a cached value
   * @returns the resource list response
   */
  function useResourceList<Type extends ValidResource['type']>(
    type: Type,
    options: {
      include?: string[];
      refresh?: boolean;
      query?: { [name: string]: string };
      fetch?: boolean;
    } = {},
  ): UseResourceList<Type> {
    const { include, query = {}, refresh = false, fetch = true } = options;
    const dispatch = useResourceDispatch();
    const fetcher = useResourceFetcher();
    const [cursor, setCursor] = useState<string | null>(null);
    const queryWithPage = { ...query };
    if (cursor) {
      queryWithPage['page[cursor]'] = cursor;
    }

    // Build the URL and the listkey
    const url = makeListUrl({
      baseUrl,
      type,
      include,
      query: queryWithPage,
    });
    const listKey = makeListUrl({ baseUrl, type, include, query });

    // Get the request satte and the list itself
    const request = useRequest(url);
    const currentList = useList(type, listKey);
    const useExistingValue =
      (currentList &&
        request &&
        ['error', 'success'].includes(request.status)) ||
      false;

    // Reset the cursor on invalidation
    useEffect(() => {
      if (request?.status === 'invalidated') {
        setCursor(null);
      }
    }, [request?.status]);

    // Reset cursor when list key changes
    useEffect(() => {
      setCursor(null);
    }, [listKey]);

    // Build pagination info
    const hasMore = Boolean(currentList && currentList.links.next);
    const fetchMore = useCallback(() => {
      if (currentList && currentList.links.next) {
        setCursor(currentList.links.next);
      }
    }, [currentList]);
    const isPaginated = Boolean(cursor);

    // Invalidate when the URL changes
    useEffect(() => {
      if (request?.status === 'success' && refresh) {
        dispatch({ type: 'invalidate_list_type', meta: { type } });
      }
    }, [refresh, url, dispatch]);

    const load = useCallback(async () => {
      try {
        dispatch({
          type: 'fetch_resource_list_pending',
          meta: { url, listKey, type },
        });

        const response = (
          global.__IS_SERVER__ ? fetcher(url) : await fetcher(url)
        ) as FetcherResponseList<ValidResource>;

        const responseList = response.data;

        dispatch({
          type: 'fetch_resource_list_success',
          payload: responseList,
          meta: {
            url,
            listKey,
            type,
            paginated: isPaginated,
          },
        });
      } catch (error) {
        if (error instanceof JSONAPIError) {
          dispatch({
            type: 'fetch_resource_list_error',
            payload: { error },
            meta: {
              url,
              listKey,
              type,
            },
          });
        } else {
          throw error;
        }
      }
    }, [dispatch, isPaginated, listKey, type, url, fetcher]);

    const shouldLoad = useMemo(() => {
      if (!fetch) {
        return false;
      }

      if (!url) {
        return false;
      }

      if (useExistingValue) {
        return false;
      }

      // If the request has finished or is in progress by another hook, we don't
      // need to refetch.
      if (request?.status === 'pending' || request?.status === 'error') {
        return false;
      }

      return true;
    }, [request, url, useExistingValue, fetch]);

    // Fetch the list
    useEffect(() => {
      if (shouldLoad) {
        load();
      }
    }, [shouldLoad, load]);

    // If we're SSR, we need to load immediately as effects aren't run
    if (global.__IS_SERVER__ && shouldLoad) {
      load();
    }

    const listName: ValidResource['type'] = `${type}List`;

    if (currentList?.data) {
      return {
        [listName]: currentList.data,
        meta: currentList.meta,
        errors: request?.error?.errors || null,
        ready: true,
        hasMore,
        fetchMore,
        status: request?.status || 'pending',
      };
    }

    if (request?.error) {
      return {
        [listName]: null,
        meta: null,
        errors: request?.error?.errors || null,
        ready: false,
        hasMore,
        fetchMore,
        status: request?.status || 'pending',
      };
    }

    return {
      [listName]: null,
      meta: null,
      errors: null,
      ready: false,
      hasMore,
      fetchMore,
      status: request?.status || 'pending',
    };
  }

  return useResourceList;
}

export default makeUseResourceList;
