export interface RelationshipSingle<Type extends string> {
  data: {
    type: Type;
    id: string;
  } | null;
}

export interface RelationshipMany<Type extends string> {
  data: {
    type: Type;
    id: string;
  }[];
  meta: {
    count: number;
  };
}

type Relationship<Type extends string = string> =
  | RelationshipSingle<Type>
  | RelationshipMany<Type>;

export interface Resource<Type extends string> {
  id: string;
  type: Type;
  attributes: object;
  relationships: {
    [type: string]: Relationship<any>;
  };
}
