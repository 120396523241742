import { merge } from 'lodash';
import { useCallback } from 'react';
import { FetcherResponseSingle } from './make-fetcher';
import makeProvider from './make-provider';
import { Resource } from './types';
import { makeRetrieveUrl } from './urls';

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

function makeUseUpdate<ValidResource extends Resource>({
  useResourceDispatch,
  useResourceStateRef,
  baseUrl,
  useResourceFetcher,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
  useResourceStateRef: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceStateRef'];
  useResourceFetcher: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceFetcher'];
  baseUrl: string;
}) {
  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  function useUpdate<
    Type extends keyof TypeToResource,
    TypedResource extends TypeToResource[Type],
  >(type: Type) {
    const dispatch = useResourceDispatch();
    const fetcher = useResourceFetcher();
    const resourceStateRef = useResourceStateRef();

    const load = useCallback(
      async (
        id: string,
        payload: RecursivePartial<TypedResource>,
        options: {
          include?: string[];
          invalidateLists?: boolean | (keyof TypeToResource)[];
          optimistic?: boolean;
        } = {},
      ) => {
        const { include, invalidateLists, optimistic = true } = options;

        const url = makeRetrieveUrl({ baseUrl, type, id: id || '', include });
        let currentValue = null;

        const entitiesForType = resourceStateRef?.current?.entities[type];
        if (entitiesForType) {
          currentValue = entitiesForType[id];
        }

        // Immediately store the optimistic payload
        if (optimistic && currentValue) {
          dispatch({
            type: 'fetch_resource_success',
            payload: { data: merge({}, currentValue, payload) },
            meta: { url, type },
          });
        }

        const response = (await fetcher(url, {
          method: 'PATCH',
          payload: {
            data: payload,
          },
        })) as FetcherResponseSingle<ValidResource>;

        // TODO: Handles errors and undo optimistic

        dispatch({
          type: 'fetch_resource_success',
          payload: response.data,
          meta: { url, type },
        });

        if (invalidateLists) {
          if (typeof invalidateLists === 'boolean') {
            // Invalidate all lists for this type
            dispatch({
              type: 'invalidate_list_type',
              meta: { type },
            });
          } else {
            for (const invalidateType of invalidateLists) {
              dispatch({
                type: 'invalidate_list_type',
                meta: { type: invalidateType },
              });
            }
          }
        }

        // If the response included `Retry-After`, schedule an invalidation in that time.
        const retryAfter = response.response.headers.get('retry-after');
        if (retryAfter) {
          setTimeout(() => {
            dispatch({
              type: 'invalidate_resource',
              meta: { type },
              payload: { url },
            });
          }, parseFloat(retryAfter) * 1000);
        }

        return response.data
          .data as FetcherResponseSingle<TypedResource>['data'];
      },
      [dispatch, type, resourceStateRef, fetcher],
    );

    return load;
  }

  return useUpdate;
}

export default makeUseUpdate;
