import AnimatedLottieView from 'lottie-react-native';
import React, { useEffect, useState } from 'react';
import {
  Platform,
  RefreshControl as RNRefreshControl,
  RefreshControlProps,
} from 'react-native';

import loadingLight from '../assets/lottie/loading-light.json';
import loadingDark from '../assets/lottie/loading-dark.json';
import loadingWhite from '../assets/lottie/loading-white.json';
import ButtonType from './Button/ButtonTypes';
import useColorScheme from 'app/hooks/use-color-scheme';

interface Props extends RefreshControlProps {
  type?: ButtonType;
  disabled?: boolean | null;
  width?: number;
  height?: number;
}

const RefreshControl = ({
  refreshing,
  type,
  disabled,
  height = 32,
  width = 32,

  ...props
}: Props) => {
  const appearance = useColorScheme();

  const [key, setKey] = useState(1);

  let source;

  if (type === ButtonType.primary && !disabled) {
    source = loadingWhite;
  } else {
    source = appearance === 'light' ? loadingLight : loadingDark;
  }

  // Update the key when the source changes. Lottie's web player doesn't detect changes in
  // source so we need to use a key to force it.
  useEffect(() => setKey((_key) => _key + 1), [source]);

  if (!refreshing) {
    return null;
  }

  return (
    <AnimatedLottieView
      source={source}
      autoPlay
      loop
      style={{ width, height }}
      key={key}
    />
  );
};

export default RefreshControl;
