import Hamburger from 'app/components/Icons/Hamburger';
import Image from 'app/components/Image';
import Pressable from 'app/components/Pressable';
import Text, { TextType } from 'app/components/Text';
import useLinkDataUrl from 'app/hooks/use-link-data-url';
import { useResource } from 'app/hooks/use-resource/use-resource';
import React from 'react';
import { StyleSheet, View } from 'react-native';

const CollectionLink = ({
  linkId,
  onStartDrag,
}: {
  linkId: string;
  onStartDrag: () => void;
}) => {
  const { link } = useResource('link', linkId);
  const { linkData } = useResource(
    'link_data',
    link?.relationships.link_data.data?.id ?? null,
  );

  const url = useLinkDataUrl({ linkData, expanded: false });

  if (!linkData) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Image
        source={linkData.attributes.thumbnail}
        placeholder={{
          blurhash: linkData.attributes.blur_hash,
        }}
        style={styles.thumbnail}
      />
      <View style={styles.text}>
        <Text numberOfLines={1} type={TextType.subheadline}>
          {linkData.attributes.title}
        </Text>
        <Text numberOfLines={1} type={TextType.footnote} color="LayeredGray">
          {url}
        </Text>
      </View>
      <Pressable onPressIn={onStartDrag}>
        <Hamburger color="LayeredSubtleGray" />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    gap: 16,
    alignItems: 'center',
  },
  thumbnail: {
    height: 40,
    width: 40,
    borderRadius: 4,
  },
  text: {
    flex: 1,
  },
});

export default CollectionLink;
