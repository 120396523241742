import React, { useMemo, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber/native';

import { Platform, StyleSheet, View } from 'react-native';
import * as THREE from 'three';

import { Mesh } from 'three';

import lavaFrag from './lava-frag';
import lavaVert from './lava-vert';

function Background() {
  const bg = useRef<Mesh>(null);

  const uniforms = useMemo(
    () => ({
      u_time: {
        value: 0,
      },
      u_color: {
        value: [
          // These are slightly saturated brand colors
          new THREE.Color('#ED9FE9').convertLinearToSRGB(),
          new THREE.Color('#5272E0').convertLinearToSRGB(),
          new THREE.Color('#E3463E').convertLinearToSRGB(),
        ],
      },
    }),
    [],
  );

  useFrame(({ clock }) => {
    if (!bg.current) {
      return;
    }

    bg.current.material.uniforms.u_time.value =
      (clock.getElapsedTime() + 23) * 0.2;
  });

  return (
    <mesh ref={bg} position={[0, 0, 0]} scale={30} rotation={[-0.2, 0, 0]}>
      <planeGeometry args={[1, 1, 256, 256]} />
      <shaderMaterial
        fragmentShader={lavaFrag}
        vertexShader={lavaVert}
        uniforms={uniforms}
      />
    </mesh>
  );
}

function Scene() {
  // const { camera, scene } = useThree();

  // useEffect(() => {
  //   setInterval(() => {
  //     console.info(camera.position, camera.rotation);
  //   }, 1000);
  // }, [camera]);
  return (
    <>
      <Background />
      <ambientLight intensity={1} />
    </>
  );
}

function CoolGradient({ onLayout }: { onLayout?: () => void }) {
  return (
    <View style={styles.container}>
      <Canvas
        camera={{
          ...Platform.select({
            web: {
              position: [0, 1.6709751763040315, 5.5872350505568145],
              rotation: [0.4, 0, 0],
              fov: 45,
            },
            default: {
              position: [0, 1.6709751763040315, 4.5872350505568145],
              rotation: [0.47, 0, 0],
              fov: 50,
            },
          }),
        }}
        linear
        onCreated={onLayout}
      >
        <color attach="background" args={['#FF99B8']} />
        <Scene />
      </Canvas>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  noise: {
    opacity: Platform.select({
      web: 0.15,
      default: 0.05,
    }),
    mixBlendMode: 'soft-light',
  },
});

export default CoolGradient;
