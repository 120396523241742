import { Colors, light } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { range } from 'lodash';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import { ViewProps } from 'react-native-svg/lib/typescript/fabric/utils';

interface Props {
  width?: number;
  height?: number;
  dotColor?: Colors;
  style?: ViewProps['style'];
}

const EllipsisCircle = ({
  dotColor = 'LayeredGray',
  width = 30,
  height = 30,
  style,
}: Props) => {
  const colorToUse = useColor(dotColor);
  const scaleFactor = width / 30;
  const gap = 2.82 * scaleFactor;
  const circleWidth = 4 * scaleFactor;

  return (
    <ThemeView
      darkStyle={styles.containerDark}
      lightStyle={styles.containerLight}
      style={[
        {
          width,
          height,
          borderRadius: width / 2,
        },
        style,
      ]}
    >
      <View style={[styles.circles, { gap }]}>
        {range(3).map((id) => (
          <View
            style={[
              {
                backgroundColor: colorToUse,
                width: circleWidth,
                height: circleWidth,
                borderRadius: circleWidth / 2,
              },
            ]}
            key={id}
          />
        ))}
      </View>
    </ThemeView>
  );
};

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'container',
    {
      alignItems: 'center',
      justifyContent: 'center',
    },
    {
      backgroundColor: 'BackgroundGray',
    },
  ),
  circles: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default EllipsisCircle;
