import React from 'react';
import { useEffect } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';

const config = {
  duration: 350,
};

const Caret = () => {
  const animatedValue = useSharedValue(1);

  useEffect(() => {
    animatedValue.value = withRepeat(
      withSequence(withTiming(1, config), withTiming(0, config)),
      -1,
      true,
    );
  }, [animatedValue]);

  const style = useAnimatedStyle(
    () => ({
      opacity: animatedValue.value,
    }),
    [animatedValue],
  );

  return <Animated.View style={[style, styles.container]} />;
};

const styles = {
  container: {
    width: 2,
    height: 30,
    borderRadius: 2,
    backgroundColor: 'white',
  },
};

export default Caret;
