import * as React from 'react';
import Svg, {
  Circle,
  Defs,
  G,
  LinearGradient,
  Mask,
  Rect,
  Stop,
} from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
}

const CoolStuffIcon = ({ width = 32, height = 33 }: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 33" fill="none">
      <G>
        <Rect
          x="1"
          y="1"
          width="30"
          height="30"
          rx="6.99919"
          fill="url(#paint0_linear_2484_25712)"
        />
        <Circle cx="24.248" cy="16" r="4.09471" fill="#EFA7EB" />
        <Circle cx="16.0019" cy="16" r="4.09471" fill="#E6534C" />
        <Circle cx="7.75585" cy="16" r="4.09471" fill="#5F7EE3" />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_2484_25712"
          x1="16"
          y1="1"
          x2="16"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="white" />
          <Stop offset="1" stopColor="#FAFAFA" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default CoolStuffIcon;
