import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const ExpandingArrows = ({ width = 28, height = 28, color }: Props) => {
  const colorToUse = useColor(color || '#FBFDFF');

  return (
    <Svg width={width} height={height} viewBox="0 0 28 28" fill="none">
      <Path
        d="M3.72853 28.0026H24.271C26.7647 28.0026 27.9996 26.4358 27.9996 23.3021V4.71324C27.9996 1.57959 26.7647 -0.00244141 24.271 -0.00244141H3.72853C1.2468 -0.00244141 0 1.57959 0 4.71324V23.3021C0 26.4358 1.2468 28.0026 3.72853 28.0026Z"
        fill="#3C3C43"
        fillOpacity="0.3"
      />
      <Path
        d="M6.23444 13.9832C5.5262 13.9832 4.96973 13.4268 4.96973 12.7185V6.46246C4.96973 5.38324 5.50934 4.96167 6.45365 4.96167H12.6929C13.4012 4.96167 13.9576 5.53501 13.9576 6.24325C13.9576 6.95149 13.4012 7.50795 12.6929 7.50795H11.816L9.08425 7.33933L13.0975 11.1672C13.6203 11.6731 13.6372 12.5669 13.1145 13.0895C12.5918 13.6291 11.7149 13.6461 11.1921 13.0727L7.33052 9.0256L7.51601 11.8416V12.7185C7.51601 13.4268 6.94268 13.9832 6.23444 13.9832ZM15.3066 23.0386C14.5984 23.0386 14.0251 22.4653 14.0251 21.7571C14.0251 21.0488 14.5984 20.4924 15.3066 20.4924H16.1835L18.9153 20.661L14.885 16.8331C14.3622 16.3271 14.3454 15.4334 14.8682 14.9108C15.4078 14.3712 16.2679 14.3542 16.8075 14.9276L20.6521 18.9747L20.4834 16.1585V15.2817C20.4834 14.5735 21.04 14.017 21.7483 14.017C22.4564 14.017 23.0298 14.5735 23.0298 15.2817V21.5378C23.0298 22.617 22.4734 23.0386 21.529 23.0386H15.3066Z"
        fill={colorToUse}
      />
    </Svg>
  );
};

export default ExpandingArrows;
