import React, { useCallback } from 'react';
import { ViewProps } from 'react-native';

import { useDelete, useResource } from 'app/hooks/use-resource/use-resource';
import BaseTips from './BaseTips';

const StuffTips = ({
  stuffId,
  style,
  columnWidth,
}: {
  stuffId: string;
  style?: ViewProps['style'];
  columnWidth: number;
}) => {
  // TODO: We disable fetch due to the order in which listeners of the resource store
  // are notified about the change in data. It seems it's not done in a single
  // render sweep, so somehow the hook is being notified of the non-existence of
  // the deleted entity before the StuffList is.
  const { stuff } = useResource('stuff', stuffId, { fetch: false });
  const deleteStuff = useDelete('stuff');

  const onClearAll = useCallback(() => {
    // Delete the stuff so we render nicely
    deleteStuff(stuffId, {
      localOnly: true,
    });
  }, [deleteStuff, stuffId]);

  if (!stuff) {
    return null;
  }

  return (
    <BaseTips
      tipIds={stuff.relationships.tips.data.map((r) => r.id)}
      style={style}
      tipStyle={{ width: columnWidth }}
      onClearAll={onClearAll}
    />
  );
};

export default StuffTips;
