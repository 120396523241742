import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color: Colors | string;
}

const Hamburger = ({ width = 20, height = 20, color }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 28 20" fill="none">
      <Path
        d="M2 2H26"
        stroke={colorToUse}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <Path
        d="M2 10H26"
        stroke={colorToUse}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <Path
        d="M2 18H26"
        stroke={colorToUse}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default Hamburger;
