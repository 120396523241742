import { Portal } from '@gorhom/portal';
import React, { useContext, useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Logo from '../Logo';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import StuffListContext from './StuffListContext';

const SplashMobile = () => {
  const { isRefreshing } = useContext(StuffListContext);
  const visibleValue = useSharedValue(1);
  const [render, setRender] = useState(true);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: visibleValue.value,
  }));

  useEffect(() => {
    if (isRefreshing === false) {
      visibleValue.value = withSpring(
        0,
        {
          mass: 1,
          stiffness: 300,
          damping: 50,
          restDisplacementThreshold: 0.001,
          restSpeedThreshold: 0.01,
        },
        () => {
          runOnJS(setRender)(false);
        },
      );
    }
  }, [isRefreshing, visibleValue]);

  if (!render) {
    return null;
  }

  return (
    <Portal hostName="splash">
      <ThemeView
        lightStyle={styles.containerLight}
        darkStyle={styles.containerDark}
        style={[StyleSheet.absoluteFillObject, animatedStyle]}
      >
        <Logo type="dots" animate autoAnimate width={63} height={21} />
      </ThemeView>
    </Portal>
  );
};

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'container',
    {
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
    },
    {
      backgroundColor: 'SolidWhite',
    },
  ),
});

const Splash = () => {
  if (Platform.OS === 'web') {
    return null;
  }

  return <SplashMobile />;
};

export default Splash;
