import React, { useEffect, useRef } from 'react';
import {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { LayoutRectangle, Platform, View, ViewProps } from 'react-native';
import ShadowView, { ShadowType } from './ShadowView';

interface Props {
  children: React.ReactNode;
  hovered: boolean;
  parallaxEnabled?: boolean;
  shadowEnabled?: boolean;
  style?: ViewProps['style'];
  darkStyle?: ViewProps['style'];
  lightStyle?: ViewProps['style'];
  damping?: number;
  shadowType?: ShadowType;
  borderRadius?: number;
  translateZ?: number;
}

const HoverView = ({
  children,
  hovered,
  style,
  darkStyle,
  lightStyle,
  parallaxEnabled = true,
  shadowEnabled = false,
  damping = 2,
  shadowType = ShadowType.big,
  borderRadius,
  translateZ = 16,
}: Props) => {
  const hoverAnimatedValue = useSharedValue(0);
  const offsetX = useSharedValue(0.5);
  const offsetY = useSharedValue(0.5);
  const layoutRef = useRef<LayoutRectangle | null>(null);
  const viewRef = useRef<View | null>(null);

  const hoverStyle = useAnimatedStyle(() => {
    const rotation = 1;
    const translation = 1;

    if (Platform.OS !== 'web' || !layoutRef.current) {
      return {};
    }

    const transform = [
      `perspective(${layoutRef.current.width * 2.5})`,
      `rotateX(${interpolate(
        offsetY.value,
        [0, 1],
        [-rotation, rotation],
      )}deg)`,
      `rotateY(${interpolate(
        offsetX.value,
        [0, 1],
        [rotation, -rotation],
      )}deg)`,
      `translateX(${interpolate(
        offsetX.value,
        [0, 1],
        [-translation, translation],
      )}px)`,
      `translateY(${interpolate(
        offsetY.value,
        [0, 1],
        [-translation, translation],
      )}px)`,
      `translateZ(${interpolate(
        hoverAnimatedValue.value,
        [0, 1],
        [0, translateZ],
      )}px)`,
    ].join(' ');

    return {
      transform,
      transformOrigin: '50%',
      transformStyle: 'preserve-3d',
    };
  }, [hoverAnimatedValue, offsetX, offsetY, translateZ]);

  useEffect(() => {
    hoverAnimatedValue.value = withSpring(hovered ? 1 : 0, {
      damping,
      mass: 0.12,
      stiffness: 50,
      restDisplacementThreshold: 0.001,
      restSpeedThreshold: 0.1,
    });
  }, [hovered, hoverAnimatedValue, damping]);

  return (
    <ShadowView
      type={shadowType}
      enabled={shadowEnabled}
      style={[{ perspective: 800 }, style]}
      darkStyle={darkStyle}
      lightStyle={lightStyle}
      animatedStyle={hoverStyle}
      borderRadius={borderRadius}
      ref={viewRef}
      onMouseEnter={() => {
        if (viewRef.current) {
          viewRef.current.measure((x, y, _width, height, pageX, pageY) => {
            layoutRef.current = {
              x: pageX,
              y: pageY,
              width: _width,
              height,
            };
          });
        }
      }}
      // TODO: Replace with onPointerMove when RN 0.71 is released (and presumably a new version
      // of RNW is released).
      onMouseMove={(event) => {
        if (!layoutRef.current || !parallaxEnabled) {
          return;
        }

        const { x, y, width, height } = layoutRef.current;
        const deltaX = event.nativeEvent.clientX - x;
        const deltaY = event.nativeEvent.clientY - y;

        offsetX.value = deltaX / width;
        offsetY.value = deltaY / height;
      }}
      onMouseOut={() => {
        offsetX.value = withSpring(0.5, {
          mass: 0.2,
          damping: 10,
        });
        offsetY.value = withSpring(0.5, {
          mass: 0.2,
          damping: 10,
        });
      }}
    >
      {children}
    </ShadowView>
  );
};

export default HoverView;
