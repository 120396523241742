import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color: Colors;
}

const Plane = ({ width = 25, height = 26, color }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 25 26" fill="none">
      <G clipPath="url(#clip0_658_11678)">
        <Path
          d="M14.6719 25.5508C15.5156 25.5508 16.1133 24.8242 16.5469 23.6992L24.2227 3.64844C24.4336 3.10938 24.5508 2.62891 24.5508 2.23047C24.5508 1.46875 24.082 1 23.3203 1C22.9219 1 22.4414 1.11719 21.9023 1.32812L1.74609 9.05078C0.761719 9.42578 0 10.0234 0 10.8789C0 11.957 0.820312 12.3203 1.94531 12.6602L8.27344 14.582C9.02344 14.8164 9.44531 14.793 9.94922 14.3242L22.8047 2.3125C22.957 2.17188 23.1328 2.19531 23.25 2.30078C23.3672 2.41797 23.3789 2.59375 23.2383 2.74609L11.2734 15.6484C10.8164 16.1289 10.7812 16.5273 11.0039 17.3125L12.8672 23.5C13.2188 24.6836 13.582 25.5508 14.6719 25.5508Z"
          fill={colorToUse}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_658_11678">
          <Rect width="24.5508" height="25.6758" fill={colorToUse} />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default Plane;
