import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import Button, { ButtonType } from './Button';
import PlaneIcon from './Icons/Plane';
import useCopy from 'app/hooks/use-copy';
import useShareLink from 'app/hooks/use-share-link';

const SharePage = ({ url }: { url: string }) => {
  const copy = useCopy(['homePageLinkCopied']);
  const shareLink = useShareLink();

  const onShare = useCallback(() => {
    shareLink({
      url,
      shareConfirmation: copy.homePageLinkCopied,
    });
  }, [copy.homePageLinkCopied, shareLink, url]);

  return (
    <Button
      type={ButtonType.primary}
      Icon={PlaneIcon}
      onPress={onShare}
      size="large"
      rounded
      iconStyle={styles.icon}
    />
  );
};

const styles = StyleSheet.create({
  icon: {
    marginLeft: -1.5,
    marginTop: 1,
  },
});

export default SharePage;
