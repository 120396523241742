import { CurrentRenderContext, useNavigation } from '@react-navigation/native';
import { useContext, useLayoutEffect, useMemo } from 'react';
import { Platform } from 'react-native';
import { ParsedQuery } from '../SearchSheet/types';

function useNavigationOptions({
  displayName,
  parsedQuery,
  ogGraphImage,
}: {
  displayName: string;
  ogGraphImage?: string;
  parsedQuery: ParsedQuery;
}) {
  const navigation = useNavigation();

  const navigationOptions = useMemo(() => {
    let title = displayName;

    if (Platform.OS !== 'web') {
      return { title };
    }

    let terms = [];

    if (parsedQuery.reactionLove) {
      terms.push('loved');
    }

    if (parsedQuery.reactionFlag) {
      terms.push('flagged');
    }

    if (parsedQuery.reactionEmphasis) {
      terms.push('emphasised');
    }

    if (parsedQuery.search) {
      terms.push(parsedQuery.search);
    }

    if (terms.length) {
      title = `${title} - ${terms.join(', ')}`;
    }

    return {
      title,
      ogImage: ogGraphImage,
    };
  }, [displayName, ogGraphImage, parsedQuery]);

  // React-navigation's navigation.setOptions doesn't work in SSR. It actually
  // uses a small context object that's set on mount from the route's options. We're
  // using that same mechanism here to overwrite it when we have the user loaded.
  const current = useContext(CurrentRenderContext);

  useLayoutEffect(() => {
    navigation.setOptions(navigationOptions);
  }, [navigation, navigationOptions]);

  // If we're SSR, we need to load immediately as effects aren't run
  if (global.__IS_SERVER__) {
    // @ts-ignore
    current.options = navigationOptions;
  }
}

export default useNavigationOptions;
