import { Colors, dark, light } from 'app/constants/colors';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text, { TextType } from '../Text';
import ThemeView, { makeThemeStyle } from '../ThemeView';

interface Props {
  title?: string;
  help?: string | React.ReactNode;
  helpPosition?: 'left' | 'right';
  helpColor?: Colors;
}

function List({
  children,
  title,
  help,
  helpPosition = 'left',
  helpColor = 'LayeredGray',
}: React.PropsWithChildren<Props>) {
  let childrenLength = React.Children.toArray(children).filter(
    (child) => child,
  ).length;

  return (
    <View>
      {title && (
        <Text
          type={TextType.caption1Emphasized}
          color="gray11"
          style={styles.title}
        >
          {title}
        </Text>
      )}
      <ThemeView
        lightStyle={styles.listLight}
        darkStyle={styles.listDark}
        style={styles.list}
      >
        {React.Children.map(children, (child, index) => {
          if (!child) {
            return child;
          }

          if (index >= childrenLength - 1) {
            return child;
          }

          return React.cloneElement(child, {
            borderBottom: true,
          });
        })}
      </ThemeView>
      {help &&
        (typeof help === 'string' ? (
          <Text
            type={TextType.caption1}
            color={helpColor}
            style={[styles.help, helpPosition === 'right' && styles.helpRight]}
          >
            {help}
          </Text>
        ) : (
          help
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  list: {
    overflow: 'hidden',
  },
  listLight: {
    borderRadius: 10,
    backgroundColor: light.SolidWhite,
  },
  listDark: {
    borderRadius: 10,
    backgroundColor: dark.gray4,
  },
  help: {
    paddingLeft: 16,
    marginTop: 8,
  },
  helpRight: {
    paddingRight: 16,
    paddingLeft: 0,
    alignSelf: 'flex-end',
  },
  title: {
    paddingLeft: 16,
    marginBottom: 8,
  },
});

export default List;
