import React, { ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import BlurView from '../BlurView';
import ShadowView, { ShadowType } from '../ShadowView';

interface Props extends Partial<ComponentProps<typeof ShadowView>> {}

function DropdownContainer({ style, children, ...props }: Props) {
  return (
    <ShadowView
      {...props}
      type={ShadowType.medium}
      style={[style, styles.container]}
      enabled={false}
    >
      <BlurView amount={24} style={styles.innerContainer}>
        {children}
      </BlurView>
    </ShadowView>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 17,
  },
  innerContainer: {
    borderRadius: 17,
    overflow: 'hidden',
  },
});

export default DropdownContainer;
