import { Colors, dark, light } from 'app/constants/colors';
import useColorScheme from 'app/hooks/use-color-scheme';
import React from 'react';
import {
  ColorSchemeName,
  Platform,
  StyleSheet,
  Text as RNText,
  TextProps,
  TextStyle,
  ViewStyle,
} from 'react-native';
import Animated from 'react-native-reanimated';

export enum TextType {
  // subhead = 'subhead',
  // caption = 'caption',
  // logo = 'logo',

  caption2 = 'caption2',
  caption1 = 'caption1',
  footnote = 'footnote',
  subheadline = 'subheadline',
  callout = 'callout',
  body = 'body',
  headline = 'headline',
  title3 = 'title3',
  title2 = 'title2',
  title1 = 'title1',
  largeTitle = 'largeTitle',

  caption2Emphasized = 'caption2Emphasized',
  caption1Emphasized = 'caption1Emphasized',
  footnoteEmphasized = 'footnoteEmphasized',
  subheadlineEmphasized = 'subheadlineEmphasized',
  calloutEmphasized = 'calloutEmphasized',
  bodyEmphasized = 'bodyEmphasized',
  headlineEmphasized = 'headlineEmphasized',
  title3Emphasized = 'title3Emphasized',
  title2Emphasized = 'title2Emphasized',
  title1Emphasized = 'title1Emphasized',
  largeTitleEmphasized = 'largeTitleEmphasized',

  // Frontpage custom types
  frontPageCTA = 'frontPageCTA',
  frontPageCTAMobile = 'frontPageCTAMobile',
}

interface Props extends TextProps {
  type?: TextType;
  color?: Colors | string;
  animated?: boolean;
  forceColorScheme?: ColorSchemeName;
}

const baseStyle: TextStyle = {
  // fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
};

export const fontWeights: { [index: string]: TextStyle['fontWeight'] } = {
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  heavy: '800',
};

const baseStyles = {
  [TextType.frontPageCTA]: {
    ...baseStyle,
    fontSize: 96,
    lineHeight: 96,
    // fontSize: isMobile ? 34 : 26,
    // lineHeight: isMobile ? 41 : 32,
    fontWeight: fontWeights.heavy,
  },
  [TextType.frontPageCTAMobile]: {
    ...baseStyle,
    fontSize: 42,
    lineHeight: 42,
    // fontSize: isMobile ? 34 : 26,
    // lineHeight: isMobile ? 41 : 32,
    fontWeight: fontWeights.heavy,
  },
  [TextType.largeTitle]: {
    ...baseStyle,
    fontSize: 34,
    lineHeight: 41,
    // fontSize: isMobile ? 34 : 26,
    // lineHeight: isMobile ? 41 : 32,
    fontWeight: fontWeights.regular,
  },
  [TextType.title1]: {
    ...baseStyle,
    fontSize: 28,
    lineHeight: 34,
    // fontSize: isMobile ? 28 : 22,
    // lineHeight: isMobile ? 34 : 26,
    fontWeight: fontWeights.regular,
  },
  [TextType.title2]: {
    ...baseStyle,
    fontSize: 22,
    lineHeight: 28,
    // fontSize: isMobile ? 22 : 17,
    // lineHeight: isMobile ? 28 : 22,
    fontWeight: fontWeights.regular,
  },
  [TextType.title3]: {
    ...baseStyle,
    fontSize: 20,
    lineHeight: 25,
    // fontSize: isMobile ? 20 : 15,
    // lineHeight: isMobile ? 25 : 20,
    fontWeight: fontWeights.regular,
  },
  [TextType.headline]: {
    ...baseStyle,
    fontSize: 17,
    lineHeight: 22,
    // fontSize: isMobile ? 17 : 13,
    // lineHeight: isMobile ? 22 : 16,
    fontWeight: fontWeights.bold,
  },
  [TextType.subheadline]: {
    ...baseStyle,
    fontSize: 15,
    lineHeight: 20,
    // fontSize: isMobile ? 15 : 11,
    // lineHeight: isMobile ? 20 : 14,
    fontWeight: fontWeights.regular,
  },
  [TextType.body]: {
    ...baseStyle,
    fontSize: 17,
    lineHeight: 22,
    // fontSize: isMobile ? 17 : 13,
    // lineHeight: isMobile ? 22 : 16,
    fontWeight: fontWeights.regular,
  },
  [TextType.callout]: {
    ...baseStyle,
    fontSize: 16,
    lineHeight: 21,
    // fontSize: isMobile ? 16 : 12,
    // lineHeight: isMobile ? 21 : 15,
    fontWeight: fontWeights.regular,
  },
  [TextType.footnote]: {
    ...baseStyle,
    fontSize: 13,
    lineHeight: 18,
    // fontSize: isMobile ? 13 : 10,
    // lineHeight: isMobile ? 18 : 13,
    fontWeight: fontWeights.regular,
  },
  [TextType.caption1]: {
    ...baseStyle,
    fontSize: 12,
    lineHeight: 16,
    // fontSize: isMobile ? 12 : 10,
    // lineHeight: isMobile ? 16 : 13,
    fontWeight: fontWeights.regular,
  },
  [TextType.caption2]: {
    ...baseStyle,
    fontSize: 11,
    lineHeight: 13,
    // fontSize: isMobile ? 11 : 10,
    // lineHeight: isMobile ? 13 : 13,
    fontWeight: fontWeights.regular,
  },
};

const styles = StyleSheet.create({
  [TextType.frontPageCTA]: baseStyles.frontPageCTA,
  [TextType.frontPageCTAMobile]: baseStyles.frontPageCTAMobile,
  [TextType.largeTitle]: baseStyles.largeTitle,
  [TextType.largeTitleEmphasized]: {
    ...baseStyles.largeTitle,
    fontWeight: fontWeights.bold,
  },
  [TextType.title1]: baseStyles.title1,
  [TextType.title1Emphasized]: {
    ...baseStyles.title1,
    fontWeight: fontWeights.bold,
  },
  [TextType.title2]: baseStyles.title2,
  [TextType.title2Emphasized]: {
    ...baseStyles.title2,
    fontWeight: fontWeights.bold,
  },
  [TextType.title3]: baseStyles.title3,
  [TextType.title3Emphasized]: {
    ...baseStyles.title3,
    fontWeight: fontWeights.semibold,
  },
  [TextType.headline]: baseStyles.headline,
  [TextType.headlineEmphasized]: {
    ...baseStyles.headline,
    fontWeight: fontWeights.heavy,
  },
  [TextType.subheadline]: baseStyles.subheadline,
  [TextType.subheadlineEmphasized]: {
    ...baseStyles.subheadline,
    fontWeight: fontWeights.semibold,
  },
  [TextType.body]: baseStyles.body,
  [TextType.bodyEmphasized]: {
    ...baseStyles.body,
    fontWeight: fontWeights.semibold,
  },
  [TextType.callout]: baseStyles.callout,
  [TextType.calloutEmphasized]: {
    ...baseStyles.callout,
    fontWeight: fontWeights.semibold,
  },
  [TextType.footnote]: baseStyles.footnote,
  [TextType.footnoteEmphasized]: {
    ...baseStyles.footnote,
    fontWeight: fontWeights.semibold,
  },
  [TextType.caption1]: baseStyles.caption1,
  [TextType.caption1Emphasized]: {
    ...baseStyles.caption1,
    fontWeight: fontWeights.medium,
  },
  [TextType.caption2]: baseStyles.caption2,
  [TextType.caption2Emphasized]: {
    ...baseStyles.caption2,
    fontWeight: fontWeights.semibold,
  },
});

function Text({
  animated,
  forceColorScheme,
  type = TextType.body,
  ...props
}: Props) {
  const colorScheme = useColorScheme();
  const color: Colors | string = props.color || 'HeadlineBlack';

  const style = [
    styles[type],
    {
      color:
        color.startsWith('#') ||
        color.startsWith('rgb') ||
        color.startsWith('hsl')
          ? color
          : (forceColorScheme || colorScheme) === 'light'
          ? light[color]
          : dark[color],
    },
    props.style,
  ];

  const Component = animated ? Animated.Text : RNText;

  return <Component {...props} style={style} />;
}

export default Text;
