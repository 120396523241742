import { useResource } from './use-resource/use-resource';
import { useCallback } from 'react';
import { useAuthStatus } from './use-auth/use-auth-status';
import { Status } from './use-auth/types';

function useCheckIsVisitor() {
  const status = useAuthStatus();

  const { setting, status: settingStatus } = useResource(
    'setting',
    status === Status.USER ? 'me' : null,
    {
      // TODO: useResource should be smart enough to dedupe. It'll require
      // a separate, non-react-state, method for request caching.
      fetch: false,
    },
  );

  const checkIsVisitor = useCallback(
    (username: string | null) => {
      if (status === Status.GUEST) {
        return true;
      }

      // We're not done loading
      if (settingStatus === 'pending' || !setting) {
        return null;
      }

      // This is valid iOS
      if (!username) {
        // On mobile we don't have a username (as we don't redirect the URL)
        return false;
      }

      // Otherwise we can just compare usernames
      return setting.attributes.username !== username;
    },
    [setting, status, settingStatus],
  );

  return checkIsVisitor;
}

export default useCheckIsVisitor;
