import React, { useCallback, useEffect, useState } from 'react';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import { StyleSheet, View } from 'react-native';
import Page from 'app/components/Page';
import Dialog from 'app/components/Dialog';
import useCopy from 'app/hooks/use-copy';
import Button, { ButtonType } from 'app/components/Button';
import {
  useCreate,
  useResourceList,
  useUpdate,
} from 'app/hooks/use-resource/use-resource';
import TextInput from 'app/components/TextInput';
import { PortalHost } from '@gorhom/portal';
import CollectionItem from 'app/components/CollectionItem';
import { useToast } from 'app/hooks/use-toast';
import Pressable from 'app/components/Pressable';
import Plus from 'app/components/Icons/Plus';
import { Collection } from 'app/hooks/use-resource/types/collection';
import { range } from 'lodash';

const AddToCollection = ({
  route,
  navigation,
}: ScreenProps<typeof Routes.ADD_TO_COLLECTION>) => {
  const { links: linkIds } = route.params || {};
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const copy = useCopy([
    'collectionsCreateNewCollectionBody',
    'collectionsCreateNewCollectionTitle',
    'collectionsCreateNewCollectionSave',
    'collectionsCreateNewCollectionCancel',
    'collectionsCreateNewCollectionTitle',
    'collectionsCreateNewCollectionPlaceholder',
    'screenCollectionsTitle',
    'collectionsAddedConfirmation',
    'collectionsAddedConfirmationPlural',
    'collectionsLinkAlreadyInCollection',
  ]);
  const createCollection = useCreate('collection');
  const updateCollection = useUpdate('collection');
  const [createCollectionTitle, setCreateCollectionTitle] = useState('');
  const { collectionList } = useResourceList('collection');
  const setToast = useToast();

  const onCreateCollection = useCallback(() => {
    setIsCreateVisible(false);
    createCollection({
      type: 'collection',
      attributes: {
        title: createCollectionTitle,
      },
    });
  }, [createCollectionTitle, createCollection]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable
          onPress={() => {
            setIsCreateVisible(true);
          }}
        >
          <Plus color="Primary" />
        </Pressable>
      ),
    });
  }, [navigation]);

  const onSelectCollection = useCallback(
    (collection: Collection) => {
      const existing = collection.relationships.links.data;
      const existingIds = new Set(existing.map((e) => e.id));
      const toAdd = linkIds.filter((linkId) => !existingIds.has(linkId));

      if (!toAdd.length) {
        navigation.goBack();
        setToast({
          type: 'info',
          text: copy.collectionsLinkAlreadyInCollection,
          delay: 2000,
        });
        return;
      }

      updateCollection(
        collection.id,
        {
          type: 'collection',
          id: collection.id,
          relationships: {
            links: {
              data: [
                ...toAdd.map(
                  (id) =>
                    ({
                      id,
                      type: 'link',
                    } as { id: string; type: 'link' }),
                ),
                ...existing,
              ],
            },
          },
        },
        {
          invalidateLists: ['explorable'],
        },
      );

      setToast({
        type: 'info',
        text:
          toAdd.length > 1
            ? copy.collectionsAddedConfirmationPlural
            : copy.collectionsAddedConfirmation,
        delay: 2000,
      });
      navigation.goBack();
    },
    [linkIds, updateCollection, navigation, copy, setToast],
  );

  return (
    <>
      <Page title={copy.screenCollectionsTitle} fullHeight>
        <View style={styles.container}>
          {collectionList &&
            collectionList.map((collectionId) => (
              <CollectionItem
                collectionId={collectionId}
                key={collectionId}
                style={styles.collection}
                onPress={onSelectCollection}
              />
            ))}

          {collectionList &&
            range(3 - (collectionList.length % 3)).map((id) => (
              <View style={styles.collection} key={id} />
            ))}
        </View>
      </Page>
      <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
        <PortalHost name="collections" />
      </View>
      <Dialog
        isVisible={isCreateVisible}
        onClose={() => setIsCreateVisible(false)}
        title={copy.collectionsCreateNewCollectionTitle}
        body={copy.collectionsCreateNewCollectionBody}
        hostName="collections"
      >
        <TextInput
          placeholder={copy.collectionsCreateNewCollectionPlaceholder}
          onChangeText={setCreateCollectionTitle}
          onSubmitEditing={onCreateCollection}
          value={createCollectionTitle}
          autoCapitalize="none"
        />
        <View style={styles.buttons}>
          <Button
            type={ButtonType.secondary}
            shadow={false}
            onPress={() => setIsCreateVisible(false)}
            style={styles.button}
          >
            {copy.collectionsCreateNewCollectionCancel}
          </Button>
          <Button
            type={ButtonType.primary}
            shadow={false}
            onPress={onCreateCollection}
            disabled={!createCollectionTitle}
            style={styles.button}
          >
            {copy.collectionsCreateNewCollectionSave}
          </Button>
        </View>
      </Dialog>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 12,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  textContainer: {
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
  collection: {
    flex: 1,
    flexBasis: 88,
  },
  buttons: {
    gap: 12,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
  },
});

export default AddToCollection;
