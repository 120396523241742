import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import React from 'react';
import { ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

export default function SearchIcon({
  color,
  style,
  width = 17,
  height = 16,
}: {
  color: Colors;
  style?: ViewStyle;
  width?: number;
  height?: number;
}) {
  const colorToUse = useColor(color);

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      style={style}
    >
      <Path
        d="M6.62375 12.9448C8.02933 12.9448 9.32549 12.4903 10.386 11.7328L14.3755 15.7223C14.5606 15.9074 14.8047 16 15.0656 16C15.6127 16 15.9999 15.5792 15.9999 15.0405C15.9999 14.788 15.9157 14.5439 15.7305 14.3672L11.7663 10.3945C12.5996 9.30037 13.0961 7.94529 13.0961 6.47238C13.0961 2.91215 10.184 0 6.62375 0C3.07194 0 0.151367 2.90373 0.151367 6.47238C0.151367 10.0326 3.06352 12.9448 6.62375 12.9448ZM6.62375 11.5476C3.84626 11.5476 1.54853 9.24987 1.54853 6.47238C1.54853 3.6949 3.84626 1.39716 6.62375 1.39716C9.40124 1.39716 11.699 3.6949 11.699 6.47238C11.699 9.24987 9.40124 11.5476 6.62375 11.5476Z"
        fill={colorToUse}
      />
    </Svg>
  );
}
