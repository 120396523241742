import { useResource } from 'app/hooks/use-resource/use-resource';
import React from 'react';
import SearchSuggestionSearches from './SearchSuggestionSearches';
import SearchSuggestionTerm from './SearchSuggestionTerm';
import useStuffsQuery from './useStuffsQuery';

interface Props {
  activeKey: 'ArrowUp' | 'ArrowDown' | 'Enter' | 'Escape' | null;
  searchSuggestionId: string;
  onSelectSuggestion: (suggestion: string) => void;
  onSetSuggestion: (suggestion: string) => void;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
}

function SearchSuggestion({
  activeKey,
  searchSuggestionId,
  onSelectSuggestion,
  onSetSuggestion,
  onChangeQuery,
}: Props) {
  const { ready, searchSuggestion } = useResource(
    'search_suggestion',
    searchSuggestionId,
  );

  if (!ready) {
    return null;
  }

  if (searchSuggestion.attributes.type === 'terms') {
    return (
      <SearchSuggestionTerm
        activeKey={activeKey}
        searchSuggestionId={searchSuggestionId}
        onSelectSuggestion={onSelectSuggestion}
        onSetSuggestion={onSetSuggestion}
      />
    );
  }

  return (
    <SearchSuggestionSearches
      searchSuggestionId={searchSuggestionId}
      onChangeQuery={onChangeQuery}
    />
  );
}

export default SearchSuggestion;
