import { RefObject } from 'react';
import { LayoutRectangle, View } from 'react-native';

export function scaleLayout(
  layout: LayoutRectangle,
  scale: number,
  origin: [number, number] = [0.5, 0.5],
) {
  const { width, height, x, y } = layout;

  const unscaledWidth = width / scale;
  const unscaledHeight = height / scale;

  // Get the  origin
  const originX = x + unscaledWidth * origin[0];
  const originY = y + unscaledHeight * origin[1];

  // offset from origin
  let originOffsetX = originX - x;
  let originOffsetY = originY - y;

  // Scale down that offset from the origin
  const scaledOriginOffsetX = originOffsetX / scale;
  const scaledOriginOffsetY = originOffsetY / scale;

  const unscaledX = originX - scaledOriginOffsetX;
  const unscaledY = originY - scaledOriginOffsetY;

  return {
    width: unscaledWidth,
    height: unscaledHeight,
    x: unscaledX,
    y: unscaledY,
  };
}

export function measure(
  ref: RefObject<View>,
  reverseScale?: number,
  scaleOrigin?: [number, number],
) {
  return new Promise<{
    pageX: number;
    pageY: number;
    height: number;
    width: number;
  }>((resolve) => {
    if (!ref.current) {
      resolve({
        pageX: 0,
        pageY: 0,
        width: 0,
        height: 0,
      });
    }

    if (ref.current) {
      ref.current.measure((x, y, width, height, pageX, pageY) => {
        let layout = { x: pageX, y: pageY, width, height };

        if (reverseScale) {
          layout = scaleLayout(layout, reverseScale, scaleOrigin);
        }

        resolve({
          pageX: layout.x,
          pageY: layout.y,
          width: layout.width,
          height: layout.height,
        });
      });
    }
  });
}
