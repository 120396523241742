import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color: Colors;
}

export default function PlusSquare({ color, width = 20, height = 20 }: Props) {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <Path
        d="M3.41118 20H16.5887C18.8702 20 19.9999 18.8701 19.9999 16.6322V3.36773C19.9999 1.12982 18.8702 0 16.5887 0H3.41118C1.14068 0 0 1.11896 0 3.36773V16.6322C0 18.881 1.14068 20 3.41118 20ZM3.43291 18.2509C2.34655 18.2509 1.74905 17.6751 1.74905 16.5453V3.45464C1.74905 2.32482 2.34655 1.74905 3.43291 1.74905H16.5671C17.6425 1.74905 18.2509 2.32482 18.2509 3.45464V16.5453C18.2509 17.6751 17.6425 18.2509 16.5671 18.2509H3.43291Z"
        fill={colorToUse}
      />
      <Path
        d="M4.97559 9.98375C4.97559 10.5161 5.34494 10.8745 5.89899 10.8745H9.0929V14.0793C9.0929 14.6225 9.46224 15.0027 9.99454 15.0027C10.5378 15.0027 10.918 14.6334 10.918 14.0793V10.8745H14.1228C14.6659 10.8745 15.0462 10.5161 15.0462 9.98375C15.0462 9.44051 14.6659 9.06032 14.1228 9.06032H10.918V5.8664C10.918 5.31236 10.5378 4.93213 9.99454 4.93213C9.46224 4.93213 9.0929 5.31236 9.0929 5.8664V9.06032H5.89899C5.34494 9.06032 4.97559 9.44051 4.97559 9.98375Z"
        fill={colorToUse}
      />
    </Svg>
  );
}
