import { Entitlement } from './use-resource/types';
import { useResource } from './use-resource/use-resource';

function useHasEntitlement(entitlement: Entitlement) {
  const { setting } = useResource('setting', 'me', {
    // TODO: useResource should be smart enough to dedupe. It'll require
    // a separate, non-react-state, method for request caching.
    fetch: false,
  });

  return (
    setting &&
    setting.attributes.entitlements &&
    setting.attributes.entitlements.includes(entitlement)
  );
}

export default useHasEntitlement;
