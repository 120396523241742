import { Link } from '@react-navigation/native';
import Logo from 'app/components/Logo';
import Text, { TextType } from 'app/components/Text';
import useCopy from 'app/hooks/use-copy';
import React from 'react';
import { StyleSheet, View } from 'react-native';

const NotFound = () => {
  const copy = useCopy(['pageNotFoundTitle', 'pageNotFoundSubtitle']);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.titleContainer}>
          <Logo type="dots" animate />
          <Text type={TextType.largeTitleEmphasized}>
            {copy.pageNotFoundTitle}
          </Text>
        </View>
        <Text type={TextType.title3} color="gray11" style={styles.subtitle}>
          {copy.pageNotFoundSubtitle}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
    maxWidth: 300,
  },
  titleContainer: {
    gap: 16,
    alignItems: 'center',
  },
  subtitle: {
    textAlign: 'center',
  },
});

export default NotFound;
