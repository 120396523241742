import { ButtonType } from 'app/components/Button';
import Chevron from 'app/components/Icons/Chevron';
import { ListItem, ListItemIcon } from 'app/components/List';
import ListItemLabel from 'app/components/List/ListItemLabel';
import { TextType } from 'app/components/Text';
import useConfirm from 'app/hooks/use-confirm';
import useCopy from 'app/hooks/use-copy';
import { useDelete, useResource } from 'app/hooks/use-resource/use-resource';
import { useToast } from 'app/hooks/use-toast';
import { format } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

const PasskeyItem = ({ id, ...rest }: { id: string }) => {
  const { webauthnCredential, ready } = useResource('webauthn_credential', id);
  const deleteWebauthnCredential = useDelete('webauthn_credential');
  const confirm = useConfirm();
  const copy = useCopy([
    'settingsPasskeysRemovePasskeyTitle',
    'settingsPasskeysRemovePasskeyBody',
    'settingsPasskeysRemovePasskeyConfirmText',
    'settingsPasskeysRemovePasskeyToastMessage',
  ]);
  const setToast = useToast();

  const subtitle = useMemo(() => {
    if (!ready) {
      return;
    }

    return `Created ${format(
      new Date(webauthnCredential.attributes.created_at + '+00:00'),
      'MMM d, YYY',
    )}`;
  }, [webauthnCredential, ready]);

  const onPress = useCallback(() => {
    confirm({
      confirmText: copy.settingsPasskeysRemovePasskeyConfirmText,
      body: copy.settingsPasskeysRemovePasskeyBody,
      title: copy.settingsPasskeysRemovePasskeyTitle,
      confirmButtonType: ButtonType.secondaryDanger,
      callback: (didConfirm) => {
        if (didConfirm) {
          deleteWebauthnCredential(id);
          setToast({
            type: 'info',
            text: copy.settingsPasskeysRemovePasskeyToastMessage,
            delay: 2000,
          });
        }
      },
    });
  }, [
    confirm,
    copy.settingsPasskeysRemovePasskeyToastMessage,
    copy.settingsPasskeysRemovePasskeyTitle,
    copy.settingsPasskeysRemovePasskeyBody,
    copy.settingsPasskeysRemovePasskeyConfirmText,
    deleteWebauthnCredential,
    setToast,
    id,
  ]);

  if (!ready) {
    return null;
  }

  return (
    <ListItem onPress={onPress} {...rest}>
      <ListItemLabel type={TextType.body} subtitle={subtitle}>
        {webauthnCredential.attributes.aaname}
      </ListItemLabel>
      <ListItemIcon Icon={Chevron} />
    </ListItem>
  );
};

export default PasskeyItem;
