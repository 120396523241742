import React, { Ref, useCallback, useEffect, useState } from 'react';
import { Keyboard, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import { BottomSheetScrollView, BottomSheetView } from '@gorhom/bottom-sheet';
import SearchInput from './SearchInput';
import useStuffsQuery from './useStuffsQuery';
import Explorables from '../Explorables';
import Animated, {
  AnimatedStyleProp,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { PortalHost } from '@gorhom/portal';

interface Props {
  parsedQuery: ReturnType<typeof useStuffsQuery>['parsedQuery'];
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  onChangeQueryKey: ReturnType<typeof useStuffsQuery>['onChangeQueryKey'];
  resetQuery: ReturnType<typeof useStuffsQuery>['resetQuery'];
  username: string;
  isVisitor: boolean;
  onCancel: () => void;
  onSearchInputFocusToggle?: (focused: boolean) => void;
  presentedAnimatedValue: Animated.SharedValue<number>;
  focusedAnimatedValue?: Animated.SharedValue<number>;
  headerIsFixedAnimatedValue: Animated.SharedValue<number>;
  searchPageAnimatedStyle?: AnimatedStyleProp<ViewStyle>;
  searchInputRef?: Ref<View>;
  searchInputStyle?: ViewProps['style'];
  paddingHorizontal?: number;
  autoFocus?: boolean;
  isBottomSheet?: boolean;
  containerWidth: number;
  // To control the search (needed in SearchSheetBottom)
  search?: string;
  setSearch?: (search: string) => void;
}

export const focusedAnimatedConfig = {
  damping: 11,
  mass: 0.5,
  stiffness: 100,
  restDisplacementThreshold: 0.001,
  restSpeedThreshold: 0.1,
  overshootClamping: true,
};

function SearchSheetContent({
  parsedQuery,
  onChangeQuery,
  onChangeQueryKey,
  resetQuery,
  isVisitor,
  username,
  onCancel,
  onSearchInputFocusToggle,
  presentedAnimatedValue,
  headerIsFixedAnimatedValue,
  focusedAnimatedValue: providedFocusedAnimatedValue,
  searchPageAnimatedStyle: providedSearchPageAnimatedStyle,
  paddingHorizontal = 16,
  searchInputRef,
  searchInputStyle,
  autoFocus,
  containerWidth,
  isBottomSheet = false,
  search: _providedSearch,
  setSearch: _providedSetSearch,
}: Props) {
  const _focusedAnimatedValue = useSharedValue(0);
  const focusedAnimatedValue =
    providedFocusedAnimatedValue || _focusedAnimatedValue;

  const [_search, _setSearch] = useState('');
  const search = _providedSearch || _search;
  const setSearch = useCallback(
    (newSearch: string) => {
      if (_providedSetSearch) {
        _providedSetSearch(newSearch);
      } else {
        _setSearch(newSearch);
      }
    },
    [_providedSetSearch],
  );

  const searchPageAnimatedStyle = useAnimatedStyle(
    () => ({
      display: focusedAnimatedValue.value && search ? 'none' : 'flex',
      opacity: focusedAnimatedValue.value && search ? 0 : 1,
    }),
    [focusedAnimatedValue, search],
  );

  const onFocusToggle = useCallback(
    (focus: boolean) => {
      if (onSearchInputFocusToggle) {
        onSearchInputFocusToggle(focus);
      }

      if (focus) {
        // On dev, only setting cancelVisible and allowing the animation to happen
        // via an effect caused lag when the active animation start upon focusing.
        // Weirdly, this didn't happen before the refactor to support modal search sheet.
        // A fix was to delay the setting of the cancel state as its not important
        // during the animation.
        focusedAnimatedValue.value = withSpring(1, focusedAnimatedConfig);
      }
    },
    [focusedAnimatedValue, onSearchInputFocusToggle],
  );

  const onScrollBeginDrag = useCallback(() => {
    Keyboard.dismiss();
  }, []);

  const SheetView = !isBottomSheet ? View : BottomSheetView;
  const SheetScrollView = !isBottomSheet
    ? Animated.ScrollView
    : BottomSheetScrollView;

  return (
    <>
      <SheetView style={{ paddingHorizontal }}>
        <SearchInput
          parsedQuery={parsedQuery}
          onChangeQuery={onChangeQuery}
          onChangeQueryKey={onChangeQueryKey}
          resetQuery={resetQuery}
          presentedAnimatedValue={presentedAnimatedValue}
          focusedAnimatedValue={focusedAnimatedValue}
          headerIsFixedAnimatedValue={headerIsFixedAnimatedValue}
          username={username}
          isVisitor={isVisitor}
          onFocusToggle={onFocusToggle}
          onCancel={onCancel}
          suggestionsPortalName="searchSheet"
          ref={searchInputRef}
          style={searchInputStyle}
          autoFocus={autoFocus}
          containerWidth={containerWidth}
          search={search}
          setSearch={setSearch}
          // It's causing a weird bug where the sheet extends beyond its max snap point
          // isBottomSheet={isBottomSheet}
        />
      </SheetView>
      <SheetScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[styles.scrollViewContentContainer]}
        onScrollBeginDrag={onScrollBeginDrag}
        style={providedSearchPageAnimatedStyle}
      >
        <View style={{ paddingHorizontal }}>
          <PortalHost name="searchSheet" />
        </View>
        <Animated.View style={searchPageAnimatedStyle}>
          <Explorables
            onChangeQuery={onChangeQuery}
            paddingHorizontal={paddingHorizontal}
            username={username}
          />
        </Animated.View>
      </SheetScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  scrollViewContentContainer: {
    paddingVertical: 36,
    paddingBottom: 72,
  },
});

export default SearchSheetContent;
