import List, { ListItem, ListItemIcon } from 'app/components/List';
import useCopy from 'app/hooks/use-copy';
import {
  useCreate,
  useInvalidateList,
  useResourceList,
} from 'app/hooks/use-resource/use-resource';
import React, { useCallback } from 'react';
import PasskeyItem from './PasskeyItem';
import ListItemLabel from 'app/components/List/ListItemLabel';
import { TextType } from 'app/components/Text';
import { light } from 'app/constants/colors';
import Passkey from 'app/hooks/use-auth/passkeys';
import Plus from 'app/components/Icons/Plus';

const Passkeys = ({ username, email }: { username: string; email: string }) => {
  const { webauthn_credentialList, ready } = useResourceList(
    'webauthn_credential',
  );
  const copy = useCopy([
    'settingsPasskeysTitle',
    'settingsPasskeysHelp',
    'settingsPasskeysCreatePasskey',
  ]);
  const createAccessToken = useCreate('access_token');
  const invalidateWebAuthnCredentials = useInvalidateList(
    'webauthn_credential',
  );

  const createPasskey = useCallback(async () => {
    // Registers the given username and email with the authenticator
    const response = await Passkey.register(username, email);

    await createAccessToken({
      type: 'access_token',
      attributes: {
        type: 'registration',
        email,
        username,
        response,
      },
    });
    await invalidateWebAuthnCredentials();
  }, [createAccessToken, email, username, invalidateWebAuthnCredentials]);

  if (!ready) {
    return null;
  }

  return (
    <List title={copy.settingsPasskeysTitle} help={copy.settingsPasskeysHelp}>
      {webauthn_credentialList.map((id) => (
        <PasskeyItem key={id} id={id} />
      ))}
      <ListItem onPress={createPasskey}>
        <ListItemLabel type={TextType.body} color={light.Primary}>
          {copy.settingsPasskeysCreatePasskey}
        </ListItemLabel>
        <ListItemIcon width={17} color="Primary" Icon={Plus} />
      </ListItem>
    </List>
  );
};

export default Passkeys;
