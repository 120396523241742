import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function NoImageIcon({
  color,
  width = 21,
  height = 21,
}: {
  color: string;
  width?: number;
  height?: hnumber;
}) {
  return (
    <Svg width={width} height={height} viewBox="0 0 21 21" fill="none">
      <Path
        d="M20.1899 19.19L19.4099 18.41L16.9999 16L12.4099 11.41L2.58989 1.59001L1.80989 0.810007C1.71738 0.717303 1.60749 0.643756 1.48652 0.593574C1.36555 0.543393 1.23586 0.517563 1.10489 0.517563C0.973926 0.517563 0.844244 0.543393 0.72327 0.593574C0.602297 0.643756 0.492408 0.717303 0.399895 0.810007C-0.000105321 1.20001 -0.000105292 1.83001 0.389895 2.22001L1.99989 3.83001V17C1.99989 18.1 2.89989 19 3.99989 19H17.1699L18.7799 20.61C19.1699 21 19.7999 21 20.1899 20.61C20.5799 20.22 20.5799 19.58 20.1899 19.19ZM5.01989 16C4.59989 16 4.36989 15.52 4.62989 15.19L7.11989 11.99C7.16599 11.931 7.22475 11.8831 7.29184 11.8498C7.35893 11.8166 7.43263 11.7988 7.5075 11.7979C7.58237 11.7969 7.6565 11.8128 7.72441 11.8443C7.79233 11.8758 7.8523 11.9222 7.89989 11.98L9.9999 14.51L11.1699 13L14.1699 16H5.01989ZM19.9999 16.17L4.82989 1.00001H17.9999C19.0999 1.00001 19.9999 1.90001 19.9999 3.00001V16.17Z"
        fill={color}
      />
    </Svg>
  );
}

export default NoImageIcon;
