import React from 'react';
import useCopy from 'app/hooks/use-copy';
import Animated, {
  interpolate,
  SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import Button, { ButtonType } from '../Button';
import Chevron from '../Icons/Chevron';
import Text, { TextType } from '../Text';
import Close from '../Icons/Close';
import { StyleSheet, View } from 'react-native';
import { makeThemeStyle } from '../ThemeView';

interface Props {
  expandedValue: SharedValue<number>;
  onContainerPress: () => void;
  onClearTips: () => void;
}

const Header = ({ expandedValue, onContainerPress, onClearTips }: Props) => {
  const copy = useCopy(['tipsShowLess']);

  const headerStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { translateY: interpolate(expandedValue.value, [0, 1], [16, 0]) },
      ],
      opacity: expandedValue.value,
    };
  }, [expandedValue]);

  return (
    <Animated.View style={[styles.nativeHeader, headerStyle]}>
      <Button
        type={ButtonType.secondary}
        onPress={onContainerPress}
        shadow={false}
        size="xs"
        rounded
        style={styles.showLess}
        Icon={Chevron}
        iconStyle={styles.showLessIcon}
        fullWidth={false}
      >
        {copy.tipsShowLess}
      </Button>
      <Button
        type={ButtonType.secondary}
        Icon={Close}
        onPress={onClearTips}
        size="xs"
        shadow={false}
        rounded
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: 12,
    marginBottom: 12,
    maxWidth: 1300,
  },
  nativeHeader: {
    marginBottom: 12,
    paddingLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cardsWebScrollViewContent: {
    paddingBottom: 48,
    // justifyContent: 'center',
    // flex: 1,
  },
  nativeHeaderTitle: { flex: 1 },
  ...makeThemeStyle(
    'clearButton',
    {
      borderRadius: 20,
      height: '100%',
      paddingHorizontal: 40,
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 8,
    },
    {
      backgroundColor: 'gray4',
    },
  ),
  showLess: {
    marginRight: 8,
  },
  showLessIcon: {
    transform: [
      {
        rotateZ: '90deg',
      },
    ],
  },
});

export default Header;
