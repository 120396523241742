import { camelCase } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { FetcherResponseSingle, JSONAPIError } from './make-fetcher';
import makeProvider, { RequestStatus } from './make-provider';
import { Resource } from './types';
import { makeRetrieveUrl } from './urls';
import { SnakeToCamelCase } from './utils';

function makeUseResource<ValidResource extends Resource>({
  useResourceDispatch,
  useResourceFetcher,
  useEntity,
  useRequest,
  // ResourceContext,
  baseUrl,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
  useRequest: ReturnType<typeof makeProvider<ValidResource>>['useRequest'];
  useEntity: ReturnType<typeof makeProvider<ValidResource>>['useEntity'];
  useResourceFetcher: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceFetcher'];
  baseUrl: string;
}) {
  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  /**
   * Retrieves a resource for the given type and ID.
   *
   * @param type the resource type
   * @param [id] the ID of the resource
   * @param [options] the options to configure the resource fetch
   * @param [options.accessToken] the access token to provide as a bearer token for the request
   * @param [options.include=[]] the relationships to include in the API request as `?include` parameter
   * @param [options.refresh=false] whether or not the refresh the data even if there is a cached value
   * @returns the resource object response
   */
  function useResource<
    Type extends keyof TypeToResource,
    TypedResource extends TypeToResource[Type],
  >(
    type: Type,
    id: string | null,
    options: {
      include?: string[];
      refresh?: boolean;
      fetch?: boolean;
    } = {},
  ) {
    const { include, refresh = false, fetch = true } = options;
    const dispatch = useResourceDispatch();
    const url = makeRetrieveUrl({ baseUrl, type, id: id || '', include });
    const fetcher = useResourceFetcher();

    const request = useRequest(url);
    const currentValue = useEntity(type, id);

    const load = useCallback(async () => {
      try {
        dispatch({ type: 'fetch_resource_pending', meta: { url, type } });

        const response = (await fetcher(
          url,
        )) as FetcherResponseSingle<ValidResource>;

        dispatch({
          type: 'fetch_resource_success',
          payload: response.data,
          meta: { url, type },
        });

        // If the response included `Retry-After`, schedule an invalidation in that time.
        const retryAfter = response.response.headers.get('retry-after');
        if (retryAfter) {
          setTimeout(() => {
            dispatch({
              type: 'invalidate_resource',
              meta: { type },
              payload: { url },
            });
          }, parseFloat(retryAfter) * 1000);
        }
      } catch (error) {
        if (error instanceof JSONAPIError) {
          dispatch({
            type: 'fetch_resource_error',
            payload: { error },
            meta: { url, type },
          });
        } else {
          throw error;
        }
      }
    }, [dispatch, type, url, fetcher]);

    const shouldLoad = useMemo(() => {
      if (!id) {
        return false;
      }

      // If the request has finished or is in progress by another hook, we don't
      // need to refetch.
      if (
        request?.status === 'success' ||
        request?.status === 'error' ||
        request?.status === 'pending'
      ) {
        return false;
      }

      const useExistingValue =
        !refresh && currentValue && request?.status !== 'invalidated';

      if (useExistingValue) {
        return false;
      }

      if (!fetch) {
        return false;
      }

      return true;
    }, [currentValue, id, fetch, refresh, request]);

    useEffect(() => {
      if (shouldLoad) {
        load();
      }
    }, [shouldLoad, load]);

    if (global.__IS_SERVER__ && id && shouldLoad) {
      load();
    }

    const camelCaseType = camelCase(type);

    type ErrorResourceObject = {
      [type in SnakeToCamelCase<Type>]: null;
    } & {
      ready: false;
      errors: JSONAPIError['errors'];
      status: RequestStatus;
    };

    type UnreadyResourceObject = {
      [type in SnakeToCamelCase<Type>]: null;
    } & {
      ready: false;
      errors: null;
      status: RequestStatus;
    };

    type ReadyResourceObject = {
      [type in SnakeToCamelCase<Type>]: TypedResource;
    } & {
      ready: true;
      errors: null;
      status: RequestStatus;
    };

    if (currentValue) {
      return {
        [camelCaseType]: currentValue,
        errors: null,
        ready: true,
        status: request?.status || 'pending',
      } as ReadyResourceObject;
    }

    if (request?.error) {
      return {
        [camelCaseType]: null,
        errors: request?.error?.errors || null,
        ready: false,
        status: request?.status || 'pending',
      } as ErrorResourceObject;
    }

    return {
      [camelCaseType]: null,
      errors: null,
      ready: false,
      status: request?.status || 'pending',
    } as UnreadyResourceObject;
  }

  return useResource;
}

export default makeUseResource;
