import { createContext, MutableRefObject } from 'react';
import { SharedValue } from 'react-native-reanimated';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';

export interface StuffListContextShape {
  meta: ReturnType<typeof useStuffsQuery>['meta'];
  parsedQuery: ReturnType<typeof useStuffsQuery>['parsedQuery'];
  url: ReturnType<typeof useStuffsQuery>['url'];
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  onChangeQueryKey: ReturnType<typeof useStuffsQuery>['onChangeQueryKey'];
  resetQuery: ReturnType<typeof useStuffsQuery>['resetQuery'];
  isRefreshing: boolean;
  hasFinishedFirstLoad: boolean;
  data: string[];
  hasMore: boolean;
  listRef: MutableRefObject<FlashList>;
  isVisitor: boolean;
  isPublic: boolean;
  username: string;
  columnWidth: number;
  containerWidth: number;
  contentPaddingHorizontal: number;
  stuffTransitionValue: SharedValue<number>;
  selectedLinkIds: Set<string>;
  setSelectedLinkIds: (selectedLinkIds: Set<string>) => void;
  isSelecting: boolean;
  setIsSelecting: (isSelecting: boolean) => void;
}

const StuffListContext = createContext<StuffListContextShape>({});

export default StuffListContext;
