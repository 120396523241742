import config from 'app/config';
import {
  JSONAPIError,
  unknownJSONAPIError,
} from '../make-resource/make-fetcher';
import CoolStuffToken from 'app/cool-stuff-token';

class BasePasskey {
  protected static async processOptionsResponse(response: Response) {}

  protected static async makeOptionsRequest(body: {
    type: 'authentication' | 'registration';
    email?: string;
    username?: string;
  }) {
    const headers: HeadersInit_ = {
      'content-type': 'application/json',
    };

    const token = await CoolStuffToken.get();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`${config.urls.api}/webauthn_options`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
      credentials: 'include',
    });

    if (!response.ok) {
      let errors: JSONAPIError['errors'];

      try {
        const json = await response.json();
        errors = json['errors'];
      } catch (error) {
        errors = [unknownJSONAPIError];
      }

      throw new JSONAPIError(errors, response.status || 500);
    }

    this.processOptionsResponse(response);

    return await response.json();
  }
}

export default BasePasskey;
