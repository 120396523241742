import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import Topic from './Topic';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';

const Topics = ({
  topics,
  onChangeQuery,
  paddingHorizontal,
}: {
  topics: string[];
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  paddingHorizontal: number;
}) => {
  // We're using fake items to inset the scroll as RNW doesn't support
  // `contentOffset`. See https://github.com/necolas/react-native-web/issues/1273
  return (
    <ScrollView
      contentContainerStyle={styles.container}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      <View style={{ width: paddingHorizontal - 4 }} />
      {topics.map((topicId) => (
        <Topic topicId={topicId} key={topicId} onChangeQuery={onChangeQuery} />
      ))}
      <View style={{ width: paddingHorizontal - 4 }} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    gap: 4,
    // On Safari, when horizontally scrolling (via the `overflow-x: auto` that's added), the
    // bottom of the container is sometimes clipped by a very small amount, depending on the
    // height of the screen. It feels like a Safari bug. So adding a padding-bottom of 1px
    // fixes that.
    paddingBottom: 1,
  },
});

export default Topics;
