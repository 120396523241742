import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import React from 'react';
import { Platform, StyleSheet, TextInput, TextInputProps } from 'react-native';

interface Props extends TextInputProps {
  placeholder: string;
  color?: Colors;
}

function ListItemInput({
  placeholder,
  color: _color = 'LayeredGray',
  ...props
}: Props) {
  const color = useColor(_color);

  return (
    <TextInput
      style={[
        styles.input,
        {
          color,
        },
      ]}
      placeholder={placeholder}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  input: {
    fontSize: 17,
    textAlign: 'right',
    width: Platform.select({
      // On web, this stops the ListItem container from overflowing.
      web: '100%',
      default: 'auto',
    }),
  },
});

export default ListItemInput;
