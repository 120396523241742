import React, { useEffect, useState } from 'react';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import { StyleSheet, View } from 'react-native';
import Text, { TextType } from 'app/components/Text';
import useCopy from 'app/hooks/use-copy';
import useAuth from 'app/hooks/use-auth';
import OTPInput from 'app/components/OTPInput/OTPInput';
import AuthenticationPage from 'app/components/AuthenticationPage';

const MagicLink = ({ route }: ScreenProps<typeof Routes.MAGIC_LINK>) => {
  const { magicLinkId, email } = route.params || {};
  const [token, setToken] = useState('');

  const copy = useCopy(['magicLinkConfirmationPre', 'magicLinkResend']);

  const { createToken } = useAuth();

  useEffect(() => {
    if (magicLinkId && token) {
      createToken({
        magic_link_token: token,
        magic_link_id: magicLinkId,
      });
    }
  }, [createToken, magicLinkId, token]);

  return (
    <AuthenticationPage>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Text color="white" type={TextType.body} style={styles.text}>
            {copy.magicLinkConfirmationPre}
          </Text>
          <Text
            color="white"
            type={TextType.bodyEmphasized}
            style={styles.text}
          >
            {email}
          </Text>
        </View>
        <OTPInput numDigits={6} onFilled={(code) => setToken(code)} />
      </View>
    </AuthenticationPage>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
  },
  textContainer: {
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
});

export default MagicLink;
