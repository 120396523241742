import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import { ViewProps } from 'react-native';
import Svg, { Defs, G, LinearGradient, Path, Stop } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
  style?: ViewProps['style'];
}

const ShareStar = ({ width = 31, height = 34, style }: Props) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 31 34"
      fill="none"
      style={style}
    >
      <Path
        d="M4.60234 30.1301H26.6125C29.2843 30.1301 30.6074 28.4517 30.6074 25.0948V5.18173C30.6074 1.82486 29.2843 0.130127 26.6125 0.130127H4.60234C1.9433 0.130127 0.607422 1.82486 0.607422 5.18173V25.0948C0.607422 28.4517 1.9433 30.1301 4.60234 30.1301Z"
        fill="#FBFDFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1543 30.1301C11.36 30.1301 13.2593 31.9157 14.4586 33.1868C14.8808 33.6342 15.0919 33.8579 15.2949 33.9266C15.4887 33.9923 15.6512 33.9925 15.8451 33.9273C16.0483 33.859 16.2602 33.6355 16.6841 33.1884C17.8892 31.9172 19.8062 30.1301 21.0591 30.1301H10.1543Z"
        fill="#FBFDFF"
      />
      <Path
        d="M16.418 8.39995H16.418L16.4179 8.39592L16.375 7.33202L16.7906 7.76865L16.7908 7.76885L18.148 9.20297C18.2938 9.36737 18.5004 9.44812 18.7044 9.44812C19.1216 9.44812 19.4569 9.14192 19.4569 8.71896C19.4569 8.49812 19.3592 8.33228 19.2128 8.18716L19.2128 8.18715L19.2115 8.1859L16.1911 5.29771C15.9972 5.10576 15.8211 5.03013 15.6029 5.03013C15.3927 5.03013 15.2173 5.1065 15.0158 5.29667L15.0154 5.29711L11.9943 8.1859L11.9943 8.18589L11.993 8.18716C11.8439 8.33502 11.7578 8.50121 11.7578 8.71896C11.7578 9.13924 12.0725 9.44812 12.5014 9.44812C12.6944 9.44812 12.9197 9.36878 13.0669 9.20284L14.415 7.76889L14.8399 7.32907L14.7969 8.39592L14.7968 8.39592V8.39995V17.5005C14.7968 17.9296 15.165 18.2917 15.6029 18.2917C16.0392 18.2917 16.418 17.9312 16.418 17.5005V8.39995ZM18.2397 10.6925H18.1397V10.7925V12.2192V12.3192H18.2397H20.7066C21.1317 12.3192 21.4516 12.4339 21.6653 12.6448C21.8788 12.8555 22.0009 13.1765 22.0009 13.6193V22.3034C22.0009 22.7461 21.8788 23.0671 21.6653 23.2778C21.4516 23.4887 21.1317 23.6035 20.7066 23.6035H10.4992C10.0695 23.6035 9.75224 23.4886 9.54211 23.2783C9.33197 23.068 9.21384 22.7471 9.21384 22.3034V13.6193C9.21384 13.1756 9.33197 12.8546 9.54211 12.6443C9.75224 12.434 10.0695 12.3192 10.4992 12.3192H12.9751H13.0751V12.2192V10.7925V10.6925H12.9751H10.4814C9.53226 10.6925 8.80106 10.9266 8.30759 11.41C7.81373 11.8938 7.5748 12.6107 7.5748 13.5395V22.3831C7.5748 23.312 7.81373 24.0288 8.30759 24.5126C8.80106 24.996 9.53226 25.2301 10.4814 25.2301H20.7334C21.6825 25.2301 22.4137 24.996 22.9072 24.5126C23.401 24.0288 23.64 23.312 23.64 22.3831V13.5395C23.64 12.6107 23.401 11.8938 22.9072 11.41C22.4137 10.9266 21.6825 10.6925 20.7334 10.6925H18.2397Z"
        fill="url(#paint0_linear_2564_33672)"
        stroke="url(#paint1_linear_2564_33672)"
        strokeWidth="0.2"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_2564_33672"
          x1="18.9972"
          y1="29.4694"
          x2="16.7671"
          y2="-13.1756"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1088FC" />
          <Stop offset="1" stopColor="#46B0FB" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_2564_33672"
          x1="18.9972"
          y1="29.4694"
          x2="16.7671"
          y2="-13.1756"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1088FC" />
          <Stop offset="1" stopColor="#46B0FB" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default ShareStar;
