import React, { useCallback, useContext } from 'react';
import { ModalContext } from '../Modal';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import PageFormSheet from './PageFormSheet';
import PageNative from './PageNative';
import PageWeb from './PageWeb';
import useIsMobile from 'app/hooks/use-is-mobile';

interface Props {
  children: React.ReactNode;
  title?: string;
  onClose?: () => void;
  type?: 'modal' | 'formSheet';
  fullHeight?: boolean;
  backButton?: boolean;
}

function Page({
  children,
  title,
  type = 'modal',
  onClose: _onClose,
  fullHeight = false,
  backButton: _backButton = false,
}: Props) {
  const useNativeModal = Platform.OS !== 'web';
  const navigation = useNavigation();
  const { onRequestClose } = useContext(ModalContext);
  const isMobile = useIsMobile();

  const onClose = useCallback(() => {
    if (onRequestClose) {
      onRequestClose();
    } else {
      navigation.goBack();
    }

    if (_onClose) {
      _onClose();
    }
  }, [_onClose, navigation, onRequestClose]);

  if (useNativeModal) {
    if (type === 'formSheet') {
      return (
        <PageFormSheet title={title} onClose={onClose}>
          {children}
        </PageFormSheet>
      );
    }

    return <PageNative>{children}</PageNative>;
  }

  const backButton = _backButton || isMobile;

  return (
    <PageWeb
      fullHeight={fullHeight}
      title={title}
      onClose={onClose}
      backButton={backButton}
    >
      {children}
    </PageWeb>
  );
}

export default Page;
