enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  secondaryBold = 'secondaryBold',
  secondaryDanger = 'secondaryDanger',
  tertiary = 'tertiary',
  tertiaryBold = 'tertiaryBold',
  tertiaryDanger = 'tertiaryDanger',
  anchor = 'anchor',
}

export default ButtonType;
