import React, { useEffect, useState } from 'react';
import Page from 'app/components/Page';
import usePrices, {
  DisplayPrice as DisplayPriceType,
} from 'app/hooks/use-prices';
import { StyleSheet, View } from 'react-native';
import ThemeView, { makeThemeStyle } from 'app/components/ThemeView';
import Sparkles from 'app/components/Icons/Sparkles';
import Fireworks from 'app/components/Icons/Fireworks';
import Text, { TextType } from 'app/components/Text';
import Checkmark from 'app/components/Icons/Checkmark';
import { ShadowType } from 'app/components/ShadowView';
import { light } from 'app/constants/colors';
import {
  useSharedValue,
  withRepeat,
  withTiming,
  Easing,
} from 'react-native-reanimated';
import useCopy from 'app/hooks/use-copy';
import Pressable from 'app/components/Pressable';
import HoverView from 'app/components/HoverView';
import { Price } from 'app/hooks/use-resource/types';
import Chevron from 'app/components/Icons/Chevron';
import LinearGradient from 'app/components/LinearGradient';
import usePurchaseListener from 'app/hooks/use-purchase-listener';
import Button, { ButtonType } from 'app/components/Button';

const productTypeToIcon = {
  free: Sparkles,
  plus: Fireworks,
};

const DisplayPrice = ({
  displayPrice,
  activeSubscribedPrice,
}: {
  displayPrice: DisplayPriceType;
  activeSubscribedPrice?: Price;
}) => {
  const [hovered, setIsHovered] = useState(false);

  const Icon = productTypeToIcon[displayPrice.productType];

  const priceColor = displayPrice.isSubscribed ? 'Primary' : 'LayeredGray';

  let rightElement = null;
  let shadowEnabled = false;
  let isDisabled = false;

  if (displayPrice.isSubscribed) {
    rightElement = <Checkmark />;
  } else if (
    activeSubscribedPrice &&
    activeSubscribedPrice.attributes.platform !== 'cool_stuff'
  ) {
    rightElement = <Chevron />;
  }

  if (
    displayPrice.onPress &&
    ((displayPrice.productType === 'plus' && !activeSubscribedPrice) || hovered)
  ) {
    shadowEnabled = true;
  }

  if (
    activeSubscribedPrice?.attributes.platform === 'cool_stuff' &&
    !displayPrice.isSubscribed
  ) {
    isDisabled = true;
  }

  const shouldAnimate = !activeSubscribedPrice;
  const animatedValue = useSharedValue(shouldAnimate ? 0 : 45);

  useEffect(() => {
    // Only animate if not subscribed
    if (!shouldAnimate) {
      return;
    }

    animatedValue.value = withRepeat(
      withTiming(180, { duration: 6000, easing: Easing.linear }),
      -1,
      false,
    );
  }, [animatedValue, shouldAnimate]);

  let priceContainer = (
    <ThemeView
      darkStyle={styles.priceInnerContainerDark}
      lightStyle={styles.priceInnerContainerLight}
    >
      <View style={styles.icon}>
        <Icon color={isDisabled ? 'LayeredGray' : undefined} />
      </View>
      <View style={styles.text}>
        <Text
          type={TextType.body}
          color={isDisabled ? 'LayeredGray' : 'HeadlineBlack'}
        >
          {displayPrice.title}
        </Text>
        <Text type={TextType.subheadline} color="LayeredGray">
          {displayPrice.description}
        </Text>
      </View>
      {displayPrice.productType === 'plus' && (
        <View>
          <ThemeView
            lightStyle={[
              styles.priceLight,
              displayPrice.isSubscribed && styles.priceSubscribedLight,
            ]}
            darkStyle={[
              styles.priceDark,
              displayPrice.isSubscribed && styles.priceSubscribedDark,
            ]}
          >
            <Text color={priceColor} type={TextType.caption1Emphasized}>
              {displayPrice.cost || displayPrice.type}
            </Text>
          </ThemeView>
          {displayPrice.cost && (
            <Text
              color={priceColor}
              style={styles.priceType}
              type={TextType.caption1Emphasized}
            >
              {displayPrice.type}
            </Text>
          )}
        </View>
      )}
      {rightElement}
    </ThemeView>
  );

  if (displayPrice.productType === 'plus') {
    priceContainer = (
      <LinearGradient
        colors={[light.coolBlue, light.coolPink, light.coolRed]}
        locations={[0, 0.65, 1]}
        useAngle
        style={styles.gradient}
        animatedValue={animatedValue}
      >
        {priceContainer}
      </LinearGradient>
    );
  }

  return (
    <Pressable
      href={displayPrice.href}
      hrefAttrs={displayPrice.hrefAttrs}
      disabled={!displayPrice.onPress}
      onPress={displayPrice.onPress}
      onHoverIn={() => {
        if (displayPrice.onPress) {
          setIsHovered(true);
        }
      }}
      onHoverOut={(event) => {
        if (displayPrice.onPress) {
          setIsHovered(false);
        }
      }}
      onPressIn={() => {
        if (displayPrice.onPress) {
          setIsHovered(true);
        }
      }}
      onPressOut={(event) => {
        if (displayPrice.onPress) {
          setIsHovered(false);
        }
      }}
    >
      <HoverView
        shadowType={ShadowType.medium}
        style={styles.outerContainer}
        shadowEnabled={shadowEnabled}
        hovered={hovered}
      >
        {priceContainer}
      </HoverView>
    </Pressable>
  );
};

const Prices = () => {
  const { prices, renewalInfo, activeSubscribedPrice, restorePurchase } =
    usePrices();
  usePurchaseListener();

  const copy = useCopy([
    'screenTitleSubscriptionManage',
    'screenTitleSubscriptionUpgrade',
    'screenTitleSubscriptionCoolStuffPlatformUpgrade',
    'subscriptionsManage',
    'subscriptionsRestorePurchaseButton',
  ]);

  const title = activeSubscribedPrice
    ? activeSubscribedPrice.attributes.platform === 'cool_stuff'
      ? copy.screenTitleSubscriptionCoolStuffPlatformUpgrade
      : copy.screenTitleSubscriptionManage
    : copy.screenTitleSubscriptionUpgrade;

  return (
    <Page title={title} type="formSheet">
      <View style={styles.container}>
        {(prices || []).map((price) => (
          <DisplayPrice
            displayPrice={price}
            key={price.id}
            activeSubscribedPrice={activeSubscribedPrice}
          />
        ))}
        <View style={styles.footer}>
          {renewalInfo ? (
            <Text
              type={TextType.footnote}
              color={
                renewalInfo.includes('Expires') ? 'DangerRed' : 'LayeredGray'
              }
              style={styles.renewalInfo}
            >
              {renewalInfo}
            </Text>
          ) : (
            restorePurchase && (
              <Button
                size="small"
                type={ButtonType.tertiaryBold}
                onPress={restorePurchase}
                style={styles.restoreButton}
              >
                {copy.subscriptionsRestorePurchaseButton}
              </Button>
            )
          )}
        </View>
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  outerContainer: {
    borderRadius: 13,
  },
  gradient: {
    padding: 2,
    borderRadius: 13,
  },
  ...makeThemeStyle(
    'priceInnerContainer',
    {
      padding: 16,
      borderRadius: 11,
      flexDirection: 'row',
      alignItems: 'center',
      gap: 16,
    },
    {
      backgroundColor: 'SolidWhite',
    },
  ),
  pricePlus: {},
  icon: {
    alignSelf: 'flex-start',
  },
  text: {
    flex: 1,
  },
  ...makeThemeStyle(
    'price',
    {
      borderRadius: 4,
      borderWidth: 2,
      paddingVertical: 2,
      paddingHorizontal: 6,
      alignItems: 'center',
      justifyContent: 'center',
    },
    {
      borderColor: 'LayeredSubtleGray',
    },
  ),
  priceType: {
    textAlign: 'center',
  },
  ...makeThemeStyle(
    'priceSubscribed',
    {},
    {
      borderColor: 'Primary',
    },
  ),
  renewalInfo: {
    alignSelf: 'flex-end',
  },
  restoreButton: {
    alignSelf: 'center',
  },
  footer: {
    marginTop: 8,
    paddingHorizontal: 16,
    gap: 24,
  },
  manage: {
    flex: 1,
  },
});

export default Prices;
