import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
  style?: SvgProps['style'];
}

export default function Apple({
  color = 'white',
  width = 18,
  height = 22,
  style,
}: Props) {
  const colorToUse = useColor(color);

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 18 22"
      fill="none"
      style={style}
    >
      <Path
        d="M8.86515 4.92148C11.5051 5.21482 13.5585 2.31407 13.3955 0C10.7555 0.0977604 8.79998 2.44443 8.86515 4.92148ZM17.8607 16.1986C16.6222 15.6443 15.8074 14.8297 15.351 13.6563C14.8622 12.3853 14.9274 11.0814 15.351 9.9734C15.5792 9.22358 16.0681 8.31106 17.3392 7.56151C16.394 6.15999 14.8948 5.44296 13.1348 5.44296C11.1466 5.44296 10.0059 6.48593 8.99553 6.48593C8.14812 6.48593 7.20295 5.47556 5.60592 5.47556C3.8785 5.47556 2.47704 6.19259 1.56444 7.36594C0.456315 8.73492 0 10.397 0 12.1244C0 14.9599 1.23853 18.2846 3.09631 20.3705C4.10667 21.446 4.85629 22 5.73627 22C7.07257 22 7.78962 21.0221 9.38663 21.0221C10.1037 21.0221 10.6903 21.3156 11.114 21.446C11.7659 21.6741 12.2222 21.9675 12.9718 21.9675C13.7866 21.9675 14.4385 21.6415 14.8622 21.2505C16.2636 19.849 17.4044 17.8607 17.8607 16.1986Z"
        fill={colorToUse}
      />
    </Svg>
  );
}
