import { useMemo } from 'react';
import { LinkData } from './use-resource/types';

function useLinkDataUrl({
  linkData,
  expanded,
}: {
  linkData: LinkData | null;
  expanded: boolean;
}) {
  const formattedUrl = useMemo(() => {
    if (!linkData) {
      return '';
    }

    try {
      const match = linkData.attributes.url.match(
        /^(.*:)\/\/([A-Za-z0-9\-.]+)(:[0-9]+)?(.*)$/,
      );
      if (!match) {
        return linkData.attributes.url;
      }

      const hostname = match[2].replace('www.', '').replace('/', '');
      const pathname = match[4].split('?')[0];

      let _formatted = hostname;

      if (expanded) {
        _formatted = `${_formatted}${pathname}`;
      } else {
        // Remove the leading /
        const pathParts = pathname.substring(1).split('/');
        let firstPart = pathParts[0];

        if (hostname === 'instagram.com' && firstPart === 'p') {
          // Instagram photos have a single /p/ as the first part of the URL. The
          // rest of the URL isn't useful so we just render the hostname.
          firstPart = '';
        } else if (hostname === 'reddit.com' && firstPart === 'r') {
          // Use the subeddit name
          firstPart = `${pathParts[0]}/${pathParts[1]}`;
        }

        if (firstPart) {
          _formatted = `${_formatted}/${firstPart}`;
        }
      }

      return _formatted;
    } catch (error) {
      return linkData.attributes.url;
    }
  }, [linkData, expanded]);

  return formattedUrl;
}

export default useLinkDataUrl;
