import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

export const EXPLORABLE_GROUP_EXPAND_PADDING = 5;

const ExplorableGroup = ({
  children,
  paddingHorizontal,
}: React.PropsWithChildren<{ paddingHorizontal: number }>) => {
  return (
    <ScrollView
      horizontal
      contentContainerStyle={styles.container}
      showsHorizontalScrollIndicator={false}
      style={{ scrollPadding: paddingHorizontal }}
      pagingEnabled
    >
      <View style={{ width: paddingHorizontal - 6 }} />
      {children}
      <View style={{ width: paddingHorizontal - 6 }} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 6,
    scrollPaddingLeft: 40,
    // To allow the items to expand on hover, if they want to.
    paddingVertical: EXPLORABLE_GROUP_EXPAND_PADDING,
  },
});

export default React.memo(ExplorableGroup);
