import React, { useCallback } from 'react';
import LayoutAnimation, { CoolLayoutAnimationType } from './LayoutAnimation';
import Modal from './Modal';
import ShadowView, { ShadowType } from './ShadowView';
import BlurView from './BlurView';
import { makeThemeStyle } from './ThemeView';
import { ScrollView, StyleSheet, View } from 'react-native';
import Text, { TextType } from './Text';
import Button, { ButtonType } from './Button';
import Close from './Icons/Close';
import Pressable from './Pressable';

interface Props {
  isVisible?: boolean;
  setIsVisible?: (visible: boolean) => void;
  children: React.ReactNode;
  aside?: React.ReactNode;
  title?: string;
  body?: string;
  onClose?: () => void;
  minWidth?: number;
  closeButton?: boolean;
  hostName?: string;
}

function Dialog({
  isVisible,
  setIsVisible,
  children,
  title,
  body,
  aside,
  minWidth,
  closeButton,
  onClose: _onClose,
  hostName,
}: Props) {
  const onClose = useCallback(() => {
    if (setIsVisible) {
      setIsVisible(false);
    }

    if (_onClose) {
      _onClose();
    }
  }, [setIsVisible, _onClose]);

  return (
    <Modal
      visible={isVisible}
      onRequestClose={onClose}
      transparent
      darkenBackground
      centered
      hostName={hostName}
    >
      <ScrollView
        nestedScrollEnabled
        contentContainerStyle={styles.scrollViewContainer}
        keyboardShouldPersistTaps="handled"
        style={styles.scrollView}
      >
        <Pressable style={StyleSheet.absoluteFillObject} onPress={onClose} />
        {aside}
        <LayoutAnimation type={CoolLayoutAnimationType.up}>
          <ShadowView
            style={styles.modalContentShadow}
            type={ShadowType.big}
            enabled={false}
          >
            <BlurView
              lightStyle={styles.modalContentLight}
              darkStyle={styles.modalContentDark}
              style={minWidth ? { minWidth } : undefined}
              amount={24}
            >
              <View style={styles.header}>
                {title && (
                  <Text type={TextType.bodyEmphasized} style={styles.title}>
                    {title}
                  </Text>
                )}
                {body && (
                  <Text
                    type={TextType.footnote}
                    style={styles.body}
                    color="LayeredGray"
                  >
                    {body}
                  </Text>
                )}
                {closeButton && (
                  <Button
                    type={ButtonType.secondary}
                    Icon={Close}
                    onPress={onClose}
                    size="xs"
                    shadow={false}
                    rounded
                    style={styles.closeButton}
                  />
                )}
              </View>
              {children}
            </BlurView>
          </ShadowView>
        </LayoutAnimation>
      </ScrollView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContentShadow: {
    borderRadius: 20,
    overflow: 'visible',
  },
  ...makeThemeStyle(
    'modalContent',
    {
      maxWidth: 375,
      minWidth: 320,
      width: '100%',
      padding: 12,
      paddingTop: 24,
      borderRadius: 20,
      gap: 24,
      overflow: 'hidden',
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  title: {
    textAlign: 'center',
    flex: 1,
  },
  body: {
    textAlign: 'center',
    flex: 1,
  },
  scrollView: {
    overflow: 'visible',
  },
  scrollViewContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 36,
    overflow: 'visible',
  },
  header: {
    alignItems: 'center',
    gap: 12,
  },
  closeButton: {
    position: 'absolute',
    right: 12,
    top: 0,
  },
});

export default Dialog;
