import { useResource } from 'app/hooks/use-resource/use-resource';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import SearchIcon from '../Icons/Search';
import Text, { TextType } from '../Text';
import { makeThemeStyle } from '../ThemeView';
import useColorScheme from 'app/hooks/use-color-scheme';

interface Props {
  activeKey: 'ArrowUp' | 'ArrowDown' | 'Enter' | 'Escape' | null;
  searchSuggestionId: string;
  onSelectSuggestion: (suggestion: string) => void;
  onSetSuggestion: (suggestion: string) => void;
}

const SPLIT_PHRASE = '_@mafv18';

const cleanValue = (value: string) => value.replaceAll(SPLIT_PHRASE, '');

function SearchSuggestionTerm({
  activeKey,
  searchSuggestionId,
  onSelectSuggestion,
  onSetSuggestion,
}: Props) {
  const { ready, searchSuggestion } = useResource(
    'search_suggestion',
    searchSuggestionId,
  );
  const [hoveredTerm, setHoveredTerm] = useState('');
  const colorScheme = useColorScheme();
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    if (!searchSuggestion || !searchSuggestion.attributes.terms) {
      return;
    }

    let newIndex;
    let term;
    const size = searchSuggestion.attributes.terms.length;

    if (activeKey === 'ArrowDown') {
      newIndex = (activeIndex + 1) % size;
      term = searchSuggestion.attributes.terms[newIndex];
    } else if (activeKey === 'ArrowUp') {
      newIndex = activeIndex - 1 < 0 ? size - 1 : activeIndex - 1;
      term = searchSuggestion.attributes.terms[newIndex];
    }

    if (newIndex !== undefined && term !== undefined) {
      setActiveIndex(newIndex);
      onSetSuggestion(cleanValue(term));
    }
  }, [activeKey, searchSuggestion, activeIndex, onSetSuggestion]);

  if (!ready) {
    return null;
  }

  return (
    <View style={styles.container}>
      {(searchSuggestion.attributes.terms || []).map((term, index) => {
        const [match, ...rest] = term.split(SPLIT_PHRASE);

        return (
          <Pressable
            key={term}
            style={[
              styles.termContainer,
              (activeIndex === index || hoveredTerm === term) &&
                (colorScheme === 'light'
                  ? styles.termContainerActiveLight
                  : styles.termContainerActiveDark),
            ]}
            onPress={() => {
              onSelectSuggestion(cleanValue(term));
            }}
            onHoverIn={() => setHoveredTerm(term)}
            onHoverOut={() => setHoveredTerm('')}
          >
            <SearchIcon color="gray11" style={styles.icon} />
            <Text type={TextType.body} color="gray11" numberOfLines={1}>
              {match}
              {rest.map((t) => (
                <Text key={t} type={TextType.body} numberOfLines={1}>
                  {t}
                </Text>
              ))}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    gap: 8,
    marginTop: -12,
  },
  termContainer: {
    paddingVertical: 8,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  ...makeThemeStyle(
    'termContainerActive',
    {
      borderRadius: 10,
    },
    {
      backgroundColor: 'grayA3',
    },
  ),
  icon: {
    marginRight: 16,
  },
});

export default SearchSuggestionTerm;
