import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const CopyDoc = ({ width = 17, height = 22, color = 'gray9' }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 17 22" fill="none">
      <Path
        d="M0.0253906 18.8359C0.0253906 20.5708 0.880371 21.4424 2.59863 21.4424H10.4014C12.1196 21.4424 12.9746 20.5625 12.9746 18.8359V17.3003H14.4272C16.1372 17.3003 17.0005 16.4204 17.0005 14.6938V7.95361C17.0005 6.93262 16.793 6.28516 16.1704 5.646L11.9785 1.3877C11.3892 0.781738 10.6919 0.557617 9.80371 0.557617H6.62451C4.91455 0.557617 4.05127 1.4375 4.05127 3.16406V4.69971H2.59863C0.888672 4.69971 0.0253906 5.57129 0.0253906 7.30615V18.8359ZM12.186 10.145L7.6123 5.48828C6.98145 4.84082 6.4585 4.69971 5.52881 4.69971H5.3877V3.18896C5.3877 2.36719 5.82764 1.89404 6.69092 1.89404H10.3599V6.15234C10.3599 7.11523 10.8247 7.57178 11.7793 7.57178H15.6641V14.6689C15.6641 15.499 15.2158 15.9639 14.3525 15.9639H12.9746V12.353C12.9746 11.2905 12.8501 10.8257 12.186 10.145ZM11.5635 5.95312V2.5498L15.3154 6.36816H11.9702C11.6797 6.36816 11.5635 6.24365 11.5635 5.95312ZM1.36182 18.811V7.32275C1.36182 6.50928 1.80176 6.03613 2.66504 6.03613H5.3877V10.8506C5.3877 11.8965 5.91895 12.4194 6.94824 12.4194H11.6382V18.811C11.6382 19.6411 11.1899 20.106 10.335 20.106H2.65674C1.80176 20.106 1.36182 19.6411 1.36182 18.811ZM7.10596 11.166C6.77393 11.166 6.64111 11.0332 6.64111 10.7012V6.34326L11.3809 11.166H7.10596Z"
        fill={colorToUse}
      />
    </Svg>
  );
};

export default CopyDoc;
