import { light } from 'app/constants/colors';
import { useDelete, useResource } from 'app/hooks/use-resource/use-resource';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import Pressable from './Pressable';
import Text, { TextType } from './Text';
import { Collection } from 'app/hooks/use-resource/types/collection';
import Image from './Image';
import ContextMenu from './ContextMenu/ContextMenu';
import { DropdownItem } from './Dropdown';
import Trash from './Icons/Trash';
import HoverView from './HoverView';
import { ShadowType } from './ShadowView';
import ThemeView, { makeThemeStyle } from './ThemeView';
import Ellipsis from './Icons/Ellipsis';
import useIsTouchDevice from 'app/hooks/use-is-touch-device';
import {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

const hoverConfig = {
  damping: 20,
  mass: 1,
  stiffness: 600,
};

function CollectionItem({
  collectionId,
  onPress,
  style,
}: {
  collectionId: string;
  onPress: (collection: Collection) => void;
  style?: ViewProps['style'];
}) {
  const { collection, ready } = useResource('collection', collectionId);
  const [isHovered, setIsHovered] = useState(false);
  const deleteCollection = useDelete('collection');
  const isTouchDevice = useIsTouchDevice();
  const isHoveredValue = useSharedValue(isTouchDevice ? 1 : 0);

  const ellipsisStyle = useAnimatedStyle(
    () => ({
      opacity: isHoveredValue.value,
    }),
    [isHoveredValue],
  );

  useEffect(() => {
    isHoveredValue.value = withSpring(isHovered ? 1 : 0, hoverConfig);
  }, [isHovered, isHoveredValue]);

  if (!ready) {
    return null;
  }

  return (
    <ContextMenu
      actions={[
        <DropdownItem
          label="Remove"
          renderIcon={(isActive) =>
            isActive ? <Trash color="white" /> : <Trash color="red9" />
          }
          last
          textColor="red9"
          activeTextColor="white"
          activeBackgroundColor="red9"
          onPress={() => deleteCollection(collectionId)}
          key="remove"
        />,
      ]}
      onPress={() => onPress(collection)}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={(event) => {
        if (event.type !== 'pointerleave') {
          return;
        }
        setIsHovered(false);
      }}
      style={style}
    >
      {({ open }) => (
        <HoverView
          hovered={isHovered}
          shadowEnabled={false}
          shadowType={ShadowType.smallLight}
          style={styles.container}
          borderRadius={13}
          translateZ={7}
        >
          {collection.attributes.thumbnail ? (
            <Image
              source={collection.attributes.thumbnail}
              style={styles.thumbnail}
              contentPosition="top center"
            />
          ) : (
            <ThemeView
              darkStyle={styles.placeholderDark}
              lightStyle={styles.placeholderLight}
            />
          )}
          <Pressable onPress={open} style={[styles.ellipsis, ellipsisStyle]}>
            <Ellipsis color="white" />
          </Pressable>
          <Text type={TextType.body}>{collection.attributes.title}</Text>
        </HoverView>
      )}
    </ContextMenu>
  );
}

const styles = StyleSheet.create({
  thumbnail: {
    minWidth: 88,
    borderRadius: 13,
    aspectRatio: 1,
    borderWidth: 1,
    borderColor: light.BackgroundGray,
  },
  ...makeThemeStyle(
    'placeholder',
    {
      minWidth: 88,
      borderRadius: 13,
      aspectRatio: 1,
      borderWidth: 1,
    },
    {
      backgroundColor: 'BackgroundGray',
      borderColor: 'BackgroundGray',
    },
  ),
  container: {
    gap: 8,
  },
  ellipsis: {
    padding: 8,
    margin: -8,
    top: 16,
    right: 12,
    position: 'absolute',
  },
});

export default CollectionItem;
