import React, { ReactNode, useEffect, useState } from 'react';
import ShadowView, { ShadowType } from '../ShadowView';
import BlurView from '../BlurView';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import { ScrollView, StyleSheet, View } from 'react-native';
import Text, { TextType } from '../Text';
import useIsMobile from 'app/hooks/use-is-mobile';
import Button, { ButtonType } from '../Button';
import Close from '../Icons/Close';
import Chevron from 'app/components/Icons/Chevron';
import {
  useNavigation,
  useNavigationContainerRef,
  useRoute,
} from '@react-navigation/native';
import Pressable from '../Pressable';

interface Props {
  children: React.ReactNode;
  title?: string;
  onClose?: () => void;
  fullHeight?: boolean;
  backButton?: boolean;
}

function PageWeb({
  children,
  title,
  onClose,
  fullHeight = false,
  backButton = false,
}: Props) {
  const isMobile = useIsMobile();
  const navigation = useNavigation();
  const [headerLeft, setHeaderLeft] = useState<ReactNode>(null);
  const [headerRight, setHeaderRight] = useState<ReactNode>(null);

  useEffect(() => {
    function callback(event) {
      const _headerRight = event.data.options.headerRight;
      if (_headerRight) {
        setHeaderRight(_headerRight);
      }

      const _headerLeft = event.data.options.headerLeft;
      if (_headerLeft) {
        setHeaderLeft(_headerLeft);
      }
    }

    navigation.addListener('options', callback);

    return () => {
      navigation.removeListener('options', callback);
    };
  }, [navigation]);

  return (
    <ShadowView
      style={[styles.modalContentShadow, fullHeight && styles.fullHeight]}
      type={ShadowType.big}
      enabled={false}
    >
      <BlurView
        lightStyle={styles.modalContentLight}
        darkStyle={styles.modalContentDark}
        amount={24}
      >
        <ScrollView
          nestedScrollEnabled
          contentContainerStyle={[styles.scrollViewContainer]}
          keyboardShouldPersistTaps="handled"
          stickyHeaderIndices={[0]}
        >
          <BlurView
            amount={16}
            style={styles.headerBlur}
            pointerEvents="box-none"
          >
            <ThemeView
              lightStyle={styles.headerLight}
              darkStyle={styles.headerDark}
            >
              <View style={styles.backButton}>
                {headerLeft ||
                  (backButton && (
                    <Pressable
                      onPress={navigation.goBack}
                      style={styles.backButtonChevron}
                    >
                      <Chevron width={12} height={21} color="HeadlineBlack" />
                    </Pressable>
                  ))}
              </View>
              {title && (
                <Text type={TextType.bodyEmphasized} style={styles.title}>
                  {title}
                </Text>
              )}
              <View style={styles.closeContainer}>{headerRight}</View>
            </ThemeView>
          </BlurView>
          <View
            style={[
              styles.scrollViewContent,
              isMobile && styles.scrollViewContentMobile,
            ]}
          >
            {children}
          </View>
        </ScrollView>
      </BlurView>
    </ShadowView>
  );
}

const styles = StyleSheet.create({
  modalContentShadow: {
    borderRadius: 24,
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  ...makeThemeStyle(
    'modalContent',
    {
      flex: 1,
      borderRadius: 24,
      overflow: 'hidden',
      transition: 'height 1s',
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  scrollViewContainer: {
    flexGrow: 1,
    paddingBottom: 36,
    overflow: 'visible',
  },
  scrollViewContent: {
    paddingHorizontal: 32,
  },
  scrollViewContentMobile: {
    paddingHorizontal: 16,
  },
  ...makeThemeStyle(
    'header',
    {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 32,
      paddingTop: 36,
      paddingHorizontal: 32,
    },
    {
      backgroundColor: 'GrayA8',
    },
  ),
  headerBlur: {
    mask: 'linear-gradient(0deg, transparent, black 30%)',
  },
  title: {
    textAlign: 'center',
    flex: 3,
  },
  closeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  backButton: {
    flex: 1,
    alignItems: 'flex-start',
  },
  backButtonChevron: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
});

export default PageWeb;
