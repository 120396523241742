import { useCallback } from 'react';
import makeProvider from './make-provider';
import { Resource } from './types';
import { makeRetrieveUrl } from './urls';

function makeUseInvalidate<ValidResource extends Resource>({
  useResourceDispatch,
  baseUrl,
}: {
  useResourceDispatch: ReturnType<
    typeof makeProvider<ValidResource>
  >['useResourceDispatch'];
  baseUrl: string;
}) {
  // Type map used for getting the resource by its type.
  type TypeToResource = {
    [R in ValidResource as R['type']]: R;
  };

  function useInvalidate<Type extends keyof TypeToResource>(type: Type) {
    const dispatch = useResourceDispatch();

    const invalidate = useCallback(
      async (
        id: string,
        options: {
          include?: string[];
        } = {},
      ) => {
        const { include } = options;
        const url = makeRetrieveUrl({ baseUrl, type, id: id || '', include });

        dispatch({
          type: 'invalidate_resource',
          meta: { type },
          payload: { url },
        });
      },
      [dispatch, type],
    );

    return invalidate;
  }

  return useInvalidate;
}

export default makeUseInvalidate;
