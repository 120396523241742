import { useToast } from './use-toast';
import useCopy from './use-copy';
import { useEffect, useRef } from 'react';
import { Platform } from 'react-native';

function useLandingMessage() {
  // NOTE: This only works on web. To have this work on iOS would involve
  // figuring out how to render this hook within a navigator in a singleton
  // fashion.

  const setToast = useToast();
  const copy = useCopy([
    'landingMessageCoolStuffForSafari',
    'landingMessageCoolStuffForChrome',
  ]);
  const hasRun = useRef(false);

  useEffect(() => {
    function load() {
      hasRun.current = true;

      if (Platform.OS !== 'web') {
        return;
      }

      // @ts-ignore
      const urlParams = new URLSearchParams(window.location.search);

      const landing = urlParams.get('landing');

      if (!landing) {
        return;
      }

      if (landing === 'safari-extension') {
        setToast({
          type: 'info',
          text: copy.landingMessageCoolStuffForSafari,
          delay: 5000,
        });
      } else if (landing === 'chrome-extension') {
        setToast({
          type: 'info',
          text: copy.landingMessageCoolStuffForChrome,
          delay: 5000,
        });
      }

      if (landing) {
        // @ts-ignore
        const url = new URL(window.location.href);
        url.searchParams.delete('landing');
        // @ts-ignore
        window.history.replaceState({}, '', url);
      }

      return null;
    }

    if (!hasRun.current) {
      load();
    }
  }, [
    copy.landingMessageCoolStuffForSafari,
    copy.landingMessageCoolStuffForChrome,
    setToast,
  ]);
}

export default useLandingMessage;
