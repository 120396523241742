import { CurrentRenderContext, useNavigation } from '@react-navigation/native';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import ThemeView from 'app/components/ThemeView';
import StuffList from 'app/components/StuffList';
import { useResource } from 'app/hooks/use-resource/use-resource';
import React, { useLayoutEffect } from 'react';
import { Platform, StyleSheet } from 'react-native';
import NotFound from './NotFound';
import useCheckIsVisitor from 'app/hooks/use-is-visitor';
import { Setting } from 'app/hooks/use-resource/types';
import { dark, light } from 'app/constants/colors';
import Splash from 'app/components/StuffList/Splash';
import { useAuthStatus } from 'app/hooks/use-auth/use-auth-status';
import { Status } from 'app/hooks/use-auth/types';

const useUserRedirect = ({
  username,
  setting,
}: {
  username: string | null;
  setting: Setting | null;
}) => {
  const navigation = useNavigation();

  const shouldRedirect = Platform.select({
    // If the settings has loaded, then we redirect if there's no username.
    // If there's already a username, we don't redirect.
    web: setting || username ? !username : null,
    default: false,
  });

  useLayoutEffect(() => {
    if (shouldRedirect && setting) {
      // For some reason, when rendering the first time on the client, after server-side
      // rendering, the navigation redirect doesn't work. Doing it in the next tick does.
      setTimeout(() => {
        // @ts-ignore
        navigation.setParams({
          username: setting.attributes.username,
        });
      }, 1);
    }
  }, [shouldRedirect, setting, username, navigation]);

  // React-navigation's navigation.setOptions doesn't work in SSR. It actually
  // uses a small context object that's set on mount from the route's options. We're
  // using that same mechanism here to overwrite it when we have the user loaded.
  const serverNavigation = React.useContext(CurrentRenderContext);

  // If we're SSR, we need to load immediately as effects aren't run
  if (global.__IS_SERVER__ && shouldRedirect && setting) {
    // @ts-ignore
    serverNavigation.options = {
      redirectUrl: `/${setting.attributes.username}`,
    };
  }

  return shouldRedirect;
};

const User = ({ route }: ScreenProps<typeof Routes.USER>) => {
  const status = useAuthStatus();
  const providedUsername = route?.params?.username || null;

  // Load settings if the user is authed. We use this to determine if the user
  // is a visitor.
  const { setting } = useResource(
    'setting',
    status === Status.USER ? 'me' : null,
  );

  // Load the user if the username is given. We use this for the display name.
  const { user, errors: userErrors } = useResource(
    'user',
    providedUsername ? providedUsername : null,
  );

  const checkIsVisitor = useCheckIsVisitor();
  const isVisitor = checkIsVisitor(providedUsername);
  const shouldRedirect = useUserRedirect({
    username: providedUsername,
    setting,
  });

  if (userErrors && userErrors.find((error) => error.status == 404)) {
    return <NotFound />;
  }

  // Use the visitor display name if visitor, otherwise use the setting
  const displayName = isVisitor
    ? user && user.attributes.display_name
    : setting && setting.attributes.display_name;
  const isPublic = isVisitor || (setting && setting.attributes.public);
  // If no username was provided, then we use the authed user's
  const username = providedUsername || setting?.attributes?.username || null;

  // We only redirect if:
  // 1. We know we don't need to redirect
  // 2. isVisitor status has loaded (it's not null)
  // 3. We have a username and a display name (either setting or user has loaded depending on isVisitor)
  // 4. We know if we're public or not
  const shouldRender =
    shouldRedirect === false &&
    isVisitor !== null &&
    username &&
    displayName &&
    isPublic !== null;

  // NOTE: I removed the isVisitor check as it meant anytime we invalidated setting
  // it would cause a remount. I could probably just ensure that the setting doesn't
  // change status to pending.
  // if (shouldRedirect || isVisitor === null) {
  if (!shouldRender) {
    return <Splash />;
  }

  const stuffList = (
    <StuffList
      username={username}
      displayName={displayName}
      isVisitor={isVisitor}
      isPublic={isPublic}
      ogGraphImage={user ? user.attributes.open_graph_image : undefined}
    />
  );

  if (isVisitor && Platform.OS === 'ios') {
    return (
      <>
        <ThemeView
          lightStyle={styles.containerVisitorNativeLight}
          darkStyle={styles.containerVisitorNativeDark}
          style={[styles.container, StyleSheet.absoluteFill]}
        >
          {stuffList}
        </ThemeView>
      </>
    );
  }

  return (
    <ThemeView
      style={styles.container}
      lightStyle={styles.containerLight}
      darkStyle={styles.containerDark}
    >
      {stuffList}
    </ThemeView>
  );
};

const styles = StyleSheet.create({
  container: Platform.select({
    web: {
      minHeight: '100%',
    },
    default: {
      flex: 1,
    },
  }),
  containerLight: { backgroundColor: light.SolidWhite },
  containerDark: {
    backgroundColor: Platform.select({
      web: dark.gray1,
      default: dark.black,
    }),
  },
  containerVisitorNativeDark: {
    backgroundColor: dark.gray2,
  },
  containerVisitorNativeLight: {
    // TODO: Ideally this should be LayeredWhite. But to do that, we need a blurView
    // underneath the content. But when we do that, the largeTitle doesn't collapse
    // when we scroll. Supposedly it should if the scrollview is full screen, but somehow
    // the blurview stops that from happening.
    // { backgroundColor: 'LayeredWhite' },
    // NOTE: This is LayeredWhite without opacity.
    backgroundColor: light.SolidWhite,
  },
  nativeBlur: { flex: 1 },
});

export default React.memo(User);
