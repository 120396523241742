import { Colors, dark, light } from 'app/constants/colors';
import useColorScheme from 'app/hooks/use-color-scheme';
import React, { Ref } from 'react';
import { TextStyle, View, ViewProps, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';

interface Props extends ViewProps {
  lightStyle?: ViewProps['style'];
  darkStyle?: ViewProps['style'];
}

function ThemeView(
  { lightStyle, darkStyle, style, ...props }: Props,
  ref: Ref<Animated.View | View>,
) {
  const colorScheme = useColorScheme();
  let styles = [];

  if (lightStyle || darkStyle) {
    styles.push(colorScheme === 'light' ? lightStyle : darkStyle);
  }

  if (style) {
    if (Array.isArray(style)) {
      style.filter((s) => s).forEach((s) => styles.push(s));
    } else {
      styles.push(style);
    }
  }

  return <Animated.View {...props} style={styles} ref={ref} />;
}

type ColorProperties =
  | 'backgroundColor'
  | 'color'
  | 'borderColor'
  | 'borderBottomColor'
  | 'borderTopColor'
  | 'borderLeftColor'
  | 'borderRightColor';

export function makeThemeStyle<
  Name extends string,
  ThemeName extends `${Name}Light` | `${Name}Dark`,
>(
  name: Name,
  style: ViewProps['style'] | TextStyle,
  colors: {
    [key in ColorProperties]?: Colors;
  },
  switchScheme: boolean = false,
): {
  [name in ThemeName]: ViewStyle;
} {
  const lightStyle: ViewStyle = {};
  const darkStyle: ViewStyle = {};

  for (const [key, value] of Object.entries(colors)) {
    if (value in dark) {
      lightStyle[key] = switchScheme ? dark[value] : light[value];
      darkStyle[key] = switchScheme ? light[value] : dark[value];
    } else {
      lightStyle[key] = value;
      darkStyle[key] = value;
    }
  }

  return {
    [`${name}Light`]: {
      ...style,
      ...lightStyle,
    },
    [`${name}Dark`]: {
      ...style,
      ...darkStyle,
    },
  };
}

export function useThemeColor(color: Colors) {
  const colorScheme = useColorScheme();

  return colorScheme === 'light' ? light[color] : dark[color];
}

export default React.forwardRef<View | Animated.View, Props>(ThemeView);
