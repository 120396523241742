import React, { useState } from 'react';
import RNLinearGradient, {
  LinearGradientProps,
} from 'react-native-linear-gradient';
import {
  runOnJS,
  SharedValue,
  useAnimatedReaction,
} from 'react-native-reanimated';

interface Props extends LinearGradientProps {
  animatedValue?: SharedValue<number>;
}

function LinearGradient({ angle, animatedValue, ...props }: Props) {
  const [_angle, setAngle] = useState(angle);

  useAnimatedReaction(
    () => (animatedValue ? animatedValue.value : null),
    (value) => {
      if (!value) {
        return;
      }

      runOnJS(setAngle)(value);
    },
  );

  return <RNLinearGradient {...props} angle={angle || _angle} />;
}

export default LinearGradient;
