import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Button, { ButtonType } from '../Button';
import Routes from '../Navigator/ROUTES';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';
import { useResourceList } from 'app/hooks/use-resource/use-resource';
import ExplorableItem from './ExplorableItem';

interface Props {
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  paddingHorizontal: number;
  username: string;
}

const Explorables = ({ onChangeQuery, paddingHorizontal, username }: Props) => {
  const navigation = useNavigation();
  const { explorableList, ready } = useResourceList('explorable', {
    include: [
      'searches',
      'topics',
      'collections',
      'explorables.smart_search',
      'explorables.collection',
    ],
    query: {
      'filter[user.id]': username,
    },
  });

  if (!ready) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.explorables}>
        {explorableList.map((explorableId) => (
          <ExplorableItem
            id={explorableId}
            onChangeQuery={onChangeQuery}
            key={explorableId}
            paddingHorizontal={paddingHorizontal}
          />
        ))}
      </View>
      <View style={{ paddingHorizontal }}>
        {Platform.OS !== 'web' && (
          <Button
            // style={styles.button}
            type={ButtonType.secondary}
            shadow={false}
            onPress={() => navigation.navigate(Routes.SETTINGS)}
          >
            Settings
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 48,
  },
  explorables: {
    gap: 36,
  },
});

export default React.memo(Explorables);
