import { Portal } from '@gorhom/portal';
import { useNavigation } from '@react-navigation/native';
import useCopy from 'app/hooks/use-copy';
import pluralize from 'pluralize';
import React, { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Button, { ButtonType } from '../Button';
import Routes from '../Navigator/ROUTES';
import RefreshControl from '../RefreshControl';
import Text, { TextType } from '../Text';
import StuffListContext from './StuffListContext';

const Footer = () => {
  const copy = useCopy([
    'homeItemName',
    'homeNoMatches',
    'homeNoMatchesFor',
    'homeNoLinks',
    'homeNoLinksAddFirst',
    'homeAddAnother',
  ]);
  const navigation = useNavigation();

  const { parsedQuery, meta, listRef, isVisitor, isRefreshing } =
    useContext(StuffListContext);

  const { title, subtitle, actionText } = useMemo<{
    title: string;
    subtitle?: string;
    actionText?: string;
  }>(() => {
    if (!meta?.count) {
      if (parsedQuery.isActive) {
        const info = {
          title: copy.homeNoMatches,
          actionText: '',
          subtitle: '',
        };

        if (
          parsedQuery.reactionFlag ||
          parsedQuery.reactionLove ||
          parsedQuery.reactionEmphasis
        ) {
          return info;
        }

        info.subtitle = `${copy.homeNoMatchesFor} “${parsedQuery.search}”`;

        return info;
      }

      return {
        title: copy.homeNoLinks,
        actionText: !isVisitor && copy.homeNoLinksAddFirst,
      };
    }

    return {
      title: `${meta.count} ${pluralize(copy.homeItemName, meta.count)}`,
    };
  }, [
    copy.homeNoMatches,
    copy.homeItemName,
    copy.homeNoLinks,
    copy.homeNoMatchesFor,
    copy.homeNoLinksAddFirst,
    isVisitor,
    meta?.count,
    parsedQuery,
  ]);

  const footer = (
    <View style={[styles.footer, !meta?.count && styles.footerNoLinkCount]}>
      {isRefreshing ? (
        <RefreshControl refreshing />
      ) : (
        <>
          <Text
            type={TextType.bodyEmphasized}
            color="gray10"
            style={styles.text}
          >
            {title}
          </Text>
          {subtitle && (
            <Text type={TextType.body} color="gray10" style={styles.text}>
              {subtitle}
            </Text>
          )}
          {actionText && (
            <Button
              type={ButtonType.anchor}
              size="small"
              shadow={false}
              style={styles.actionButton}
              onPress={() => {
                if (isVisitor) {
                  if (listRef?.current) {
                    listRef.current.scrollToOffset({
                      offset: 0,
                      animated: true,
                    });
                  }
                } else {
                  navigation.navigate(Routes.SAVE_LINK);
                }
              }}
            >
              {actionText}
            </Button>
          )}
        </>
      )}
    </View>
  );

  return footer;
};

const styles = StyleSheet.create({
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 36,
  },
  footerNoLinkCount: {
    paddingVertical: 128,
  },
  text: {
    textAlign: 'center',
  },
  actionButton: {
    alignSelf: 'stretch',
    // Overriding the height from button
    height: 22,
  },
});

export default Footer;
