import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import React, { useCallback, useEffect, useState } from 'react';
import Routes, { ScreenProps } from 'app/components/Navigator/ROUTES';
import config from 'app/config';
import { getHeaders } from 'app/hooks/use-resource/use-resource';
import Page from 'app/components/Page';
import useCopy from 'app/hooks/use-copy';
import { StyleSheet, View } from 'react-native';

const stripePromise = loadStripe(config.stripePublishableKey);

const StripeCheckout = ({
  route,
  navigation,
}: ScreenProps<typeof Routes.STRIPE_CHECKOUT>) => {
  const { priceId } = route.params || {};
  const [session, setSession] = useState<{
    id: string;
    clientSecret: string;
  } | null>(null);
  const copy = useCopy(['screenTitleSubscriptionUpgrade']);

  useEffect(() => {
    const load = async () => {
      // Create a Checkout Session as soon as the page loads
      const headers = await getHeaders();
      const response = await fetch(
        `${config.urls.api}/stripe/create-checkout-session`,
        {
          method: 'POST',
          body: JSON.stringify({ price_id: priceId }),
          headers: {
            ...headers,
            'content-type': 'application/json',
          },
        },
      );

      const { id, clientSecret }: { clientSecret: string; id: string } =
        await response.json();
      setSession({
        id,
        clientSecret,
      });
    };

    load();
  }, [priceId]);

  const handleOnComplete = useCallback(() => {
    if (!session) {
      return;
    }

    navigation.replace(Routes.FINALIZE_SUBSCRIPTION, {
      checkoutSessionId: session.id,
    });
  }, [session, navigation]);

  return (
    <Page title={copy.screenTitleSubscriptionUpgrade} fullHeight>
      <View style={styles.container}>
        {session && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: session.clientSecret,
              onComplete: handleOnComplete,
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 13,
    overflow: 'hidden',
  },
});

export default StripeCheckout;
