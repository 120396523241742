import React, { useEffect, useRef, useState } from 'react';
import { LayoutRectangle, View, ViewProps } from 'react-native';
import Modal from '../Modal';

import DropdownContainer from './DropdownContainer';

interface Props extends ViewProps {
  trigger: React.ReactNode;
  dropdownWidth: number;
  offsetY?: number;
  offsetX?: number;
  footer?: React.ReactNode;
  open: boolean;
  onToggle: (open: boolean) => void;
  showTriangle?: boolean;
}

function Dropdown({
  trigger,
  dropdownWidth,
  offsetY: offsetTop = 0,
  offsetX: offsetRight = 0,
  open,
  onToggle,
  footer,
  showTriangle,
  ...props
}: Props) {
  const [dropdownPosition, setDropdownPosition] =
    useState<LayoutRectangle | null>(null);
  const ref = useRef<View>(null);

  useEffect(() => {
    if (ref.current && !dropdownPosition) {
      ref.current.measure((x, y, width, height, pageX, pageY) => {
        setDropdownPosition({
          x: pageX + (width - dropdownWidth) + offsetRight,
          y: pageY + height + offsetTop,
          height,
          width,
        });
      });
    }
  }, [dropdownWidth, offsetTop, offsetRight, dropdownPosition]);

  return (
    <>
      <View ref={ref}>{trigger}</View>
      {dropdownPosition && (
        <Modal
          visible={open}
          transparent
          onRequestClose={() => onToggle(false)}
        >
          <DropdownContainer
            footer={footer}
            style={{
              position: 'absolute',
              top: dropdownPosition.y,
              left: dropdownPosition.x,
              width: dropdownWidth,
            }}
          >
            {React.Children.map(props.children, (child) => {
              if (!React.isValidElement(child)) {
                return null;
              }

              return React.cloneElement(child, {
                ...child.props,
                onPress: () => {
                  onToggle(false);
                  child.props.onPress();
                },
              });
            })}
          </DropdownContainer>
        </Modal>
      )}
    </>
  );
}

export default Dropdown;
