import { Colors, dark, light } from 'app/constants/colors';
import { ColorSchemeName } from 'react-native';
import useColorScheme from './use-color-scheme';

function useColor(
  color: Colors | string | undefined,
  forceColorScheme?: ColorSchemeName,
) {
  const appearance = useColorScheme();

  if (color && color in light) {
    return (forceColorScheme || appearance) === 'light'
      ? light[color]
      : dark[color];
  }

  return color;
}

export default useColor;
