import { Resource } from './types';

export interface FetcherOptions {
  method?: 'GET' | 'PATCH' | 'DELETE' | 'POST';
  payload?: object;
}

export class JSONAPIError extends Error {
  constructor(
    readonly errors: {
      code: string;
      title: string;
      source: string;
      status: number;
    }[],
    readonly status_code: number,
  ) {
    super();

    this.errors = errors;
    this.status_code = status_code;
  }
}

export interface FetcherResponseSingle<R> {
  data: {
    data: R;
    included?: R[];
  };
  response: Response;
}

export interface FetcherResponseList<R> {
  data: {
    data: R[];
    included?: R[];
    meta: {
      count: number;
    };
    links: {
      next?: string;
    };
  };
  response: Response;
}

export type FetcherResponse<R> =
  | FetcherResponseSingle<R>
  | FetcherResponseList<R>;

export const unknownJSONAPIError = {
  code: 'unknown_error',
  title: 'An unknown error occurred.',
  source: '',
  status: 500,
};

export const networkJSONAPIError = {
  code: 'network_error',
  title: "You're offline",
  source: '',
  status: 500,
};

export default function makeFetcher<ValidResource extends Resource>(
  getHeaders?: () => Promise<{ [key: string]: string }>,
  handleResponse?: (response: Response) => void,
) {
  async function fetcher(
    url: string,
    options: FetcherOptions = {
      method: 'GET',
    },
  ) {
    let headers: { [key: string]: string } = {};
    const isServer = global.__IS_SERVER__;

    if (getHeaders) {
      headers = isServer ? getHeaders() : await getHeaders();
    }

    headers['content-type'] = 'application/vnd.api+json';

    const requestInit: RequestInit = {
      headers,
      method: options.method,
      // TODO: Make configurable
      credentials: 'include',
    };

    if (options.payload) {
      requestInit.body = JSON.stringify(options.payload);
    }

    let response;

    try {
      response = isServer
        ? fetch(url, requestInit)
        : await fetch(url, requestInit);
    } catch (error) {
      if (error.message === 'Network request failed') {
        throw new JSONAPIError([networkJSONAPIError], response?.status || 500);
      }

      throw new JSONAPIError([unknownJSONAPIError], response?.status || 500);
    }

    if (handleResponse) {
      handleResponse(response);
    }

    if (!response?.ok) {
      let errors: JSONAPIError['errors'];

      try {
        const json = isServer ? response.json() : await response.json();
        errors = json['errors'];
      } catch (error) {
        errors = [unknownJSONAPIError];
      }

      throw new JSONAPIError(errors, response?.status || 500);
    }

    if (response.status === 204) {
      return;
    }

    const data = isServer ? response.json() : await response.json();
    return { data, response } as FetcherResponse<ValidResource>;
  }

  return fetcher;
}
