import useCopy from 'app/hooks/use-copy';
import { useDelete, useDeleteList } from 'app/hooks/use-resource/use-resource';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LayoutAnimation,
  Platform,
  StyleSheet,
  View,
  ViewProps,
} from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { RNSpringConfig } from '../LayoutAnimation';
import Pressable from '../Pressable';
import Swipeable from '../Swipable';
import Text, { TextType } from '../Text';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import Tip from './Tip';
import Modal from '../Modal';
import useIsMobile from 'app/hooks/use-is-mobile';
import useExpand from 'app/hooks/use-expand';
import Header from './Header';

interface Props {
  style?: ViewProps['style'];
  tipStyle?: ViewProps['style'];
  tipIds: string[];
  onClearAll?: () => void;
}

const TIPS_MOBILE_HEADER_HEIGHT = 30 + 12;
export const TIP_PEEK = 8;
export const TIP_HEIGHT = 94;

const expansionConfig = {
  mass: 0.15,
  damping: 10,
  stiffness: 40,
  restDisplacementThreshold: 0.001,
  restSpeedThreshold: 0.1,
};

const Cards = ({
  tipIds,
  expanded,
  tipStyle,
  onClearAll,
  setExpanded,
}: {
  tipIds: string[];
  expanded: boolean;
  tipStyle: ViewProps['style'];
  onClearAll?: () => void;
  setExpanded: (expanded: boolean) => void;
}) => {
  const copy = useCopy(['tipsShowLess', 'tipsClear', 'tipsClearAll']);
  const isSingleTip = tipIds.length === 1;
  const showHeader = useIsMobile() && !isSingleTip;
  const deleteTip = useDelete('tip');
  const deleteTipList = useDeleteList('tip');
  const expandedValue = useSharedValue(0);
  const isMobile = useIsMobile();

  useEffect(() => {
    expandedValue.value = withSpring(expanded ? 1 : 0, expansionConfig);
  }, [expanded, expandedValue]);

  const containerStyle = useAnimatedStyle(() => {
    /*
     * Calculates the height of the container based on the number of tips.
     */
    const collapsedPeak = Math.min(tipIds.length - 1, 2);

    const heightValues = [
      TIP_HEIGHT + collapsedPeak * TIP_PEEK,
      // We subtract 10 as 10 is the gap between tips and doesn't exist at the bottom
      tipIds.reduce((partialSum, _) => partialSum + TIP_HEIGHT + 10, 0) - 10,
    ];

    // We don't show the header on mobile so we don't need to
    // account for the layout
    if (!showHeader) {
      return {
        height: expandedValue.value ? heightValues[1] : heightValues[0],
      };
    }

    return {
      height: interpolate(expandedValue.value, [0, 1], heightValues),
      transform: [
        {
          translateY: interpolate(
            expandedValue.value,
            [0, 1],
            [-TIPS_MOBILE_HEADER_HEIGHT, 0],
          ),
        },
      ],
      marginBottom: interpolate(
        expandedValue.value,
        [0, 1],
        [-TIPS_MOBILE_HEADER_HEIGHT, 0],
      ),
    };
  }, [expandedValue, showHeader, tipIds]);

  const onContainerPress = useCallback(() => {
    /*
     * Expand when the container is pressed
     */
    ReactNativeHapticFeedback.trigger('impactLight');

    setExpanded(!expanded);
  }, [setExpanded, expanded]);

  const onClearTips = useCallback(() => {
    /*
     * Collapse when clearing
     */
    LayoutAnimation.configureNext(RNSpringConfig);
    ReactNativeHapticFeedback.trigger('impactMedium');
    setExpanded(false);

    LayoutAnimation.configureNext(RNSpringConfig);

    if (onClearAll) {
      onClearAll();
    }

    // Delete the tips
    deleteTipList();
  }, [setExpanded, deleteTipList, onClearAll]);

  const onDeleteTip = useCallback(
    async (tipId: string) => {
      const isLast = tipIds.length <= 1;

      // If on desktop and is expanded, collapse first, then clear the tips
      if (isLast && !isMobile && expanded) {
        setExpanded(false);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      if (isLast) {
        LayoutAnimation.configureNext(RNSpringConfig);

        if (onClearAll) {
          onClearAll();
        }

        deleteTip(tipId);
      } else {
        LayoutAnimation.configureNext(RNSpringConfig);
        deleteTip(tipId);
      }
    },
    [deleteTip, tipIds.length, expanded, isMobile, setExpanded, onClearAll],
  );

  const tipElements = tipIds.map((tipId, index) => (
    <Tip
      key={tipId}
      tipId={tipId}
      expandedValue={expandedValue}
      expanded={expanded}
      // expandedValue={isSingleTip ? alwaysOnValue : expandedValue}
      index={index}
      disabled={!expanded && !isSingleTip}
      style={tipStyle}
      onDelete={() => onDeleteTip(tipId)}
      onContainerPress={onContainerPress}
      isSingleTip={isSingleTip}
    />
  ));

  return (
    <View>
      {showHeader && (
        <Header
          expandedValue={expandedValue}
          onContainerPress={onContainerPress}
          onClearTips={onClearTips}
        />
      )}
      {tipIds.length <= 1 ? (
        tipElements
      ) : (
        <Pressable onPress={onContainerPress} style={[containerStyle]}>
          <Swipeable
            enabled={!expanded && Platform.OS !== 'web'}
            overshootFriction={8}
            containerStyle={styles.swipableContainer}
            renderRightActions={() => (
              <Pressable onPress={onClearTips} style={{ height: TIP_HEIGHT }}>
                <ThemeView
                  lightStyle={styles.clearButtonLight}
                  darkStyle={styles.clearButtonDark}
                >
                  <Text type={TextType.body} color="gray11">
                    {copy.tipsClearAll}
                  </Text>
                </ThemeView>
              </Pressable>
            )}
          >
            {tipElements}
          </Swipeable>
        </Pressable>
      )}
    </View>
  );
};

const BaseTips = ({ style, tipStyle, tipIds, onClearAll }: Props) => {
  const [expanded, _setExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useIsMobile();

  const {
    open,
    close,
    collapsedStyle,
    expandedStyle,
    transform,
    collapsedRef,
    expandedRef,
  } = useExpand({
    // This doesn't work when true unless we scale about the correct axis
    scaleType: 'none',
    type: 'onTop',
  });

  const modalAnimatedValue = useDerivedValue(() => transform.value.progress);

  const setExpanded = useCallback(
    (_expanded: boolean) => {
      _setExpanded(_expanded);

      if (isMobile) {
        return;
      }

      if (_expanded) {
        setIsModalOpen(_expanded);
        setTimeout(() => {
          open();
        }, 100);
      } else {
        close(() => {
          setIsModalOpen(false);
        });
      }
    },
    [open, close, isMobile],
  );

  if (!tipIds.length) {
    return null;
  }

  // We need a separate component so that we can render it twice, once in flow,
  // once in the Modal, but with a different value of `expanded`.
  const cards = (
    <Cards
      expanded={expanded}
      tipIds={tipIds}
      setExpanded={setExpanded}
      onClearAll={onClearAll}
      tipStyle={tipStyle}
    />
  );

  if (isMobile) {
    return (
      <Animated.View style={[style, { overflow: 'visible' }]}>
        {cards}
      </Animated.View>
    );
  }

  // NOTE: CollapsedStyle modifies opacity, so we need a parent to hold `style`, as
  // that can also set opacity.

  return (
    <>
      <Animated.View style={style}>
        <Animated.View
          style={[collapsedStyle, { overflow: 'visible' }]}
          ref={collapsedRef}
        >
          {React.cloneElement(cards, { expanded: false })}
        </Animated.View>
      </Animated.View>
      <Modal
        visible={isModalOpen}
        onRequestClose={() => setExpanded(false)}
        darkenBackground
        // We don't center because doing so screws up the pageY measurement. It seems that
        // the way RNW is centering scroll view content doesn't play nice with pageY
        centered
        scrollable
        animatedValue={modalAnimatedValue}
        handleAnimations={false}
        animateContent={false}
        hostName="expandedLinks"
      >
        <Animated.View
          style={[styles.cardsWebScrollViewContent, style, expandedStyle]}
          ref={expandedRef}
        >
          {cards}
        </Animated.View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  nativeHeader: {
    marginBottom: 12,
    paddingLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardsWebScrollViewContent: {
    marginVertical: 48,
  },
  nativeHeaderTitle: { flex: 1 },
  ...makeThemeStyle(
    'clearButton',
    {
      borderRadius: 20,
      height: '100%',
      paddingHorizontal: 40,
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 8,
    },
    {
      backgroundColor: 'gray4',
    },
  ),
  swipableContainer: {
    overflow: Platform.select({
      web: 'visible',
      default: 'visible',
    }),
  },
});

export default BaseTips;
