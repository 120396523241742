import { useCallback, useEffect, useState } from 'react';

import { getIconName, setIconName } from 'app/lib/app-icon';

const appIconConfig = {
  default: {
    label: 'Cool Stuff',
    smallIcon: require('app/assets/app-icons/default.jpg'),
    thumbhash: '+RcGDwS2iZiLiHifZId4KKaIG3D4JXcA',
  },
  TVGlowAppIcon: {
    label: 'TV Glow',
    smallIcon: require('app/assets/app-icons/tv-glow.jpg'),
    thumbhash: 'zBcKDwRXWHiId3iAeXp3uHeHRZDGJXgA',
  },
  FlowersAppIcon: {
    label: 'Flowers',
    smallIcon: require('app/assets/app-icons/flowers.jpg'),
    thumbhash: 'GhgGDwypNWiAeWRrV4Z5JVhRdQFJmXAJ',
  },
};

export const appIcons: (keyof typeof appIconConfig)[] = [
  'default',
  'TVGlowAppIcon',
  'FlowersAppIcon',
];

function useAppIcon() {
  const [currentAppIconName, _setAppIconName] =
    useState<keyof typeof appIconConfig>('default');

  const loadIcon = useCallback(async () => {
    const icon = await getIconName();
    _setAppIconName(icon as keyof typeof appIconConfig);
  }, [_setAppIconName]);

  useEffect(() => {
    loadIcon();
  }, [loadIcon]);

  const _setIconName = useCallback(
    async (name: string) => {
      _setAppIconName(name as keyof typeof appIconConfig);
      await setIconName(name);
      loadIcon();
    },
    [loadIcon],
  );

  return {
    currentAppIcon: {
      name: currentAppIconName,
      label: appIconConfig[currentAppIconName].label,
    },
    appIcons: appIcons.map((name) => ({
      name,
      ...appIconConfig[name],
    })),
    setAppIconName: _setIconName,
  };
}

export default useAppIcon;
