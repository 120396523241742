import { useMemo } from 'react';
import { ScaledSize } from 'react-native';
import useIsMobile from './use-is-mobile';
import useWindowDimensions from './use-window-dimensions';

// The padding around the thumbnail
export const THUMBNAIL_PADDING = 8;
// NOTE: This MUST match CONTENT_PADDING_EXPANDED_MOBILE in useLayoutInfo.
// TODO: Refactor so there's a single definition
export const CONTENT_PADDING = 8;

function getThumbnailDimensions({
  expanded,
  width,
  aspectRatio,
  isMobile,
  windowDimensions,
}: {
  expanded: boolean;
  width?: number;
  aspectRatio: number;
  isMobile: boolean;
  windowDimensions: ScaledSize;
}) {
  if (!expanded && width) {
    const targetWidth = width - THUMBNAIL_PADDING;
    return {
      thumbnailWidth: targetWidth,
      thumbnailHeight: targetWidth / aspectRatio,
    };
  }

  // On larger screens, we should the link actions to the left of the link.
  // TODO: We'll probably need to move this logic to Link ana have LinkData configured
  // it's size based on `onLayout`.
  const actionsWidth = isMobile ? 0 : 200;

  let thumbnailWidth = windowDimensions.width - actionsWidth;

  if (isMobile) {
    thumbnailWidth -= THUMBNAIL_PADDING + CONTENT_PADDING * 2;
  }

  let thumbnailHeight = thumbnailWidth / aspectRatio;

  if (thumbnailHeight > windowDimensions.height) {
    thumbnailHeight = windowDimensions.height;
    thumbnailWidth = thumbnailHeight * aspectRatio;
  }

  if (!isMobile) {
    thumbnailWidth *= 0.75;
    thumbnailHeight *= 0.75;
  }

  return { thumbnailWidth, thumbnailHeight };
}

export function getLinkDataHeight({
  columnWidth,
  thumbnailWidth,
  thumbnailHeight,
  linkPadding,
  title,
}: {
  columnWidth: number;
  thumbnailWidth: number;
  thumbnailHeight: number;
  linkPadding: number;
  title: string;
}) {
  let aspectRatio = thumbnailWidth / thumbnailHeight;

  // If there image doesn't have a width/height, we generate a
  // placeholder. This replicates the placeholder's aspect ratio.
  // TODO: Centralize this with the logic in LinkData.tsx
  if (!aspectRatio) {
    aspectRatio = 400 / 200;
  }

  const scaledThumbnailHeight = Math.round(
    (columnWidth - THUMBNAIL_PADDING) / aspectRatio,
  );

  // Max character length.
  // NOTE: THIS IS HACKY.
  const expectedTitleWidth = title.length * 9;
  const footer = expectedTitleWidth > columnWidth - 32 ? 76 : 54;

  return scaledThumbnailHeight + THUMBNAIL_PADDING + footer + linkPadding * 2;
}

const useLinkDataLayout = ({
  expanded,
  width,
  aspectRatio,
}: {
  expanded: boolean;
  width?: number;
  aspectRatio: number;
}) => {
  const isMobile = useIsMobile();
  const windowDimensions = useWindowDimensions();

  return useMemo(() => {
    const { thumbnailWidth, thumbnailHeight } = getThumbnailDimensions({
      expanded,
      width,
      aspectRatio,
      isMobile,
      windowDimensions,
    });

    return {
      thumbnailWidth,
      thumbnailHeight,
      containerWidth: thumbnailWidth + THUMBNAIL_PADDING,
      thumbnailPadding: THUMBNAIL_PADDING,
    };
  }, [isMobile, expanded, windowDimensions, width, aspectRatio]);
};

export default useLinkDataLayout;
