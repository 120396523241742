import React, { useCallback, useEffect } from 'react';
import { Platform, StyleSheet, Switch } from 'react-native';
import Pressable from '../Pressable';
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import ShadowView, { ShadowType } from '../ShadowView';
import useColor from 'app/hooks/use-color';

interface Props extends React.ComponentProps<typeof Switch> {}

function ListItemSwitch(props: Props) {
  const { value, onValueChange } = props;
  const animatedValue = useSharedValue(value ? 1 : 0);

  useEffect(() => {
    animatedValue.value = withSpring(value ? 1 : 0, {
      mass: 1,
      stiffness: 500,
      damping: 35,
    });
  }, [animatedValue, value]);

  const onPress = useCallback(() => {
    if (onValueChange) {
      onValueChange(!value);
    }
  }, [value, onValueChange]);

  const trackDisabledColor = useColor('BackgroundGray');

  const animatedTrackStyle = useAnimatedStyle(
    () => ({
      backgroundColor: interpolateColor(
        animatedValue.value,
        [0, 1],
        [trackDisabledColor, '#34C759'],
      ),
    }),
    [animatedValue, trackDisabledColor],
  );

  const animatedThumbStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateX: interpolate(animatedValue.value, [0, 1], [2, 22]),
        },
      ],
    }),
    [animatedValue],
  );

  if (Platform.OS !== 'web') {
    return <Switch {...props} />;
  }

  return (
    <Pressable onPress={onPress} disabled={props.disabled}>
      <Animated.View style={[styles.track, animatedTrackStyle]} />
      <ShadowView
        style={styles.thumb}
        animatedStyle={animatedThumbStyle}
        type={ShadowType.medium}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  track: {
    borderRadius: 15.5,
    height: 31,
    width: 51,
  },
  thumb: {
    backgroundColor: 'white',
    borderRadius: 13.5,
    height: 27,
    width: 27,
    position: 'absolute',
    top: 2,
  },
});

export default ListItemSwitch;
