import React, { useContext } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCreate } from 'app/hooks/use-resource/use-resource';
import { setGenericPassword } from 'react-native-keychain';
import config from 'app/config';
import { Platform } from 'react-native';
import CoolStuffToken from 'app/cool-stuff-token';
import { useNavigation } from '@react-navigation/native';
import Passkey from './passkeys';
import Routes from 'app/components/Navigator/ROUTES';
import { JSONAPIError } from '../make-resource/make-fetcher';
import { useToast } from '../use-toast';
import {
  useSharedValue,
  withRepeat,
  withSequence,
  withSpring,
} from 'react-native-reanimated';
import { AuthContext } from './context';

export const APP_STORE_REVIEW_EMAIL = 'appstorereview@coolstuff.app';

const shakeAnimatedConfig = {
  mass: 0.2,
  stiffness: 700,
  damping: 20,
};

// PROVIDER

interface ResourceProviderProps {
  children: React.ReactNode;
  token?: string;
}

export function AuthProvider({
  children,
  token: defaultToken,
}: ResourceProviderProps) {
  const [token, _setToken] = useState<string | null>(
    defaultToken !== undefined ? defaultToken : null,
  );

  useEffect(() => {
    async function getToken() {
      const t = await CoolStuffToken.get();
      _setToken(t || '');
    }

    getToken();
  }, []);

  const setToken = useCallback(async (_token: string) => {
    if (_token === null) {
      return;
    }

    if (_token) {
      await CoolStuffToken.set(_token);
    } else {
      await CoolStuffToken.remove();
    }

    _setToken(_token);
  }, []);

  const context = useMemo(
    () => ({
      token,
      setToken,
    }),
    [token, setToken],
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

interface CreateTokenRegisterWebAuthn {
  response: object;
  type: 'registration';
  email: string;
  username: string;
}

interface CreateTokenAuthenticateWebAuthn {
  response: object;
  type: 'authentication';
  email: string;
}

interface CreateTokenAuthenticateMagicLink {
  magic_link_id: string;
  magic_link_token: string;
}

interface CreateTokenAuthenticatePassword {
  email: string;
  password: string;
}

function useAuth() {
  const { setToken } = useContext(AuthContext);
  const createAccessToken = useCreate('access_token');
  const createMagicLink = useCreate('magic_link');
  const navigation = useNavigation();
  const [pending, setPending] = useState(false);
  const setToast = useToast();
  const errorShakeAnimatedValue = useSharedValue(0);

  const createToken = useCallback(
    async (
      options:
        | CreateTokenRegisterWebAuthn
        | CreateTokenAuthenticateWebAuthn
        | CreateTokenAuthenticateMagicLink
        | CreateTokenAuthenticatePassword,
    ) => {
      const accessToken = await createAccessToken({
        type: 'access_token',
        attributes: {
          // @ts-ignore
          ...options,
        },
      });

      if (Platform.OS === 'ios') {
        // Store the token in the keychain so it's accessible from extensions
        setGenericPassword('token', accessToken.attributes.token, {
          accessGroup: config.accessGroup,
          service: config.bundleId,
        });
      }

      setToken(accessToken.attributes.token);
    },
    [createAccessToken, setToken],
  );

  const setError = useCallback(
    (message: string) => {
      errorShakeAnimatedValue.value = withSequence(
        withSpring(-1, shakeAnimatedConfig),
        withRepeat(withSpring(1, shakeAnimatedConfig), 5, true),
        withSpring(0, shakeAnimatedConfig),
      );
    },
    [errorShakeAnimatedValue],
  );

  const setErrorsFromException = useCallback(
    (e: unknown) => {
      let message = null;

      if (e instanceof JSONAPIError) {
        message = e.errors[0].title;
      } else {
        message =
          e instanceof Error ? e.toString() : 'An unknown error occurred';
      }

      setError(message);
    },
    [setError],
  );

  const autofill = useCallback(async () => {
    try {
      // This will resolve the promise if the autofill was triggered. The email
      // is the email associated with the selected passkeys.
      const { response, email } = await Passkey.autofill();

      await createToken({
        type: 'authentication',
        response,
        email,
      });
    } catch (e) {}
  }, [createToken]);

  const authenticate = useCallback(
    // Passwords are only support for app store review
    async (email: string, password?: string) => {
      setPending(true);

      if (email === APP_STORE_REVIEW_EMAIL) {
        if (!password) {
          setError('Please enter a password');
          setPending(false);
          return;
        }

        try {
          await createToken({
            email,
            password,
          });
        } catch (magicLinkError) {
          setErrorsFromException(magicLinkError);
        }

        setPending(false);
        return;
      }

      try {
        // This manually triggers the system to find any matching passkeys
        // with this email.
        const response = await Passkey.authenticate(email);

        await createToken({
          type: 'authentication',
          response,
          email,
        });
      } catch (e) {
        try {
          // Attempt magic link instead
          const magicLink = await createMagicLink({
            type: 'magic_link',
            attributes: {
              email,
              type: 'authentication',
            },
          });

          navigation.navigate(Routes.MAGIC_LINK, {
            email,
            magicLinkId: magicLink.id,
          });
        } catch (magicLinkError) {
          setErrorsFromException(magicLinkError);
        }
      }

      setPending(false);
    },
    [
      createMagicLink,
      createToken,
      navigation,
      setErrorsFromException,
      setError,
    ],
  );

  const register = useCallback(
    async (username: string, email: string) => {
      setPending(true);

      try {
        // Registers the given username and email with the authenticator
        const response = await Passkey.register(username, email);

        await createToken({
          type: 'registration',
          email,
          username,
          response,
        });
      } catch (e) {
        try {
          // Attempt magic link instead
          const magicLink = await createMagicLink({
            type: 'magic_link',
            attributes: {
              email,
              username,
              type: 'registration',
            },
          });

          navigation.navigate(Routes.MAGIC_LINK, {
            email,
            magicLinkId: magicLink.id,
          });
        } catch (magicLinkError) {
          setErrorsFromException(magicLinkError);
        }
      }

      setPending(false);
    },
    [createToken, createMagicLink, navigation, setErrorsFromException],
  );

  return {
    createToken,
    autofill,
    cancelAutofill: Passkey.cancelAutofill,
    authenticate,
    register,
    pending,
    errorShakeAnimatedValue,
  };
}

export default useAuth;
