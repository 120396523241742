import React from 'react';
import Animated from 'react-native-reanimated';
import { Image as ExpoImage, ImageProps } from 'expo-image';

const AnimatedExpoImage = Animated.createAnimatedComponent(ExpoImage);

interface Props extends ImageProps {
  animated?: boolean;
}

function Image({ animated, ...props }: Props) {
  const Component = animated ? AnimatedExpoImage : ExpoImage;
  return <Component {...props} />;
}

export default Image;
