import React from 'react';
import { StyleSheet, View } from 'react-native';
import Text, { TextType } from '../Text';

interface Props {
  children: string;
  color?: React.ComponentProps<typeof Text>['color'];
  type?: React.ComponentProps<typeof Text>['type'];
  disabled?: boolean;
}

function ListItemValue({
  children,
  color = 'LayeredGray',
  type = TextType.body,
  disabled = false,
}: Props) {
  return (
    <View style={[styles.container]}>
      <Text
        color={disabled ? 'LayeredSubtleGray' : color}
        type={type}
        numberOfLines={1}
      >
        {children}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default ListItemValue;
