import React, { useCallback } from 'react';

import Button, { ButtonType } from '../Button';
import {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Plus from '../Icons/Plus';
import { useNavigation } from '@react-navigation/native';
import Routes from '../Navigator/ROUTES';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';

function CreateLink() {
  const navigation = useNavigation();
  const hoverOffset = useSharedValue(0);
  const hoverStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateY: withSpring(hoverOffset.value, {
            damping: 3,
            mass: 0.15,
          }),
        },
      ],
    }),
    [hoverOffset],
  );
  const onPress = useCallback(() => {
    ReactNativeHapticFeedback.trigger('impactMedium');
    navigation.navigate(Routes.SAVE_LINK);
  }, [navigation]);

  return (
    <Button
      style={hoverStyle}
      onPress={onPress}
      onHoverIn={() => (hoverOffset.value = -2)}
      onHoverOut={() => (hoverOffset.value = 0)}
      type={ButtonType.primary}
      Icon={Plus}
      size="large"
      shadow
      rounded
    />
  );
}

export default CreateLink;
