import { Colors } from 'app/constants/colors';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  color: Colors;
  width?: number | string;
  height?: number | string;
}

export default function LoveIcon({ color, width = 16, height = 16 }: Props) {
  return (
    <Svg width={width} height={height} viewBox="0 0 18 15" fill="none">
      <Path
        d="M2.25697 2.25589C0.566687 3.94343 0.566688 6.67948 2.25697 8.36702L6.76601 12.8468C7.82615 13.9001 8.35623 14.4267 9.01334 14.4259C9.67044 14.4251 10.1992 13.8972 11.2568 12.8413L15.7382 8.36719C17.4285 6.67965 17.4285 3.94361 15.7382 2.25607C14.0479 0.568525 11.3074 0.568524 9.61715 2.25606L8.99768 2.87453L8.37803 2.25589C6.68774 0.568349 3.94725 0.56835 2.25697 2.25589Z"
        fill={color}
      />
    </Svg>
  );
}
