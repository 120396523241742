import React, { useCallback, useRef } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import Pressable from '../Pressable';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import useColor from 'app/hooks/use-color';

interface Props {
  borderBottom?: boolean;
  onPress?: () => void;
  href?: string;
  hrefAttrs?: object;
  withIcon?: boolean;
  disabled?: boolean;
  disabledOnPress?: () => void;
}

const hoverConfig = {
  damping: 20,
  mass: 1,
  stiffness: 600,
};

const ListItem = ({
  children,
  borderBottom,
  onPress,
  href,
  hrefAttrs,
  withIcon,
  disabled,
  disabledOnPress,
}: React.PropsWithChildren<Props>) => {
  const ref = useRef<React.ReactNode | TextInput>();
  const hoverOffset = useSharedValue(0);

  const pressableBackgroundColor = useColor('BackgroundGray');
  const borderColor = useColor('DividerGray');

  const pressableStyle = useAnimatedStyle(
    () => ({
      backgroundColor: interpolateColor(
        hoverOffset.value,
        [0, 1],
        ['transparent', pressableBackgroundColor],
      ),
    }),
    [hoverOffset, pressableBackgroundColor],
  );
  const borderStyle = useAnimatedStyle(
    () => ({
      backgroundColor: interpolateColor(
        hoverOffset.value,
        [0, 1],
        [borderColor, 'transparent'],
      ),
    }),
    [hoverOffset, borderColor],
  );

  const _onPress = useCallback(() => {
    if (onPress) {
      return onPress();
    } else if (ref.current && ref.current.focus) {
      ref.current.focus();
    }
  }, [onPress]);

  return (
    <Pressable
      style={[styles.container, pressableStyle]}
      onPress={disabled && disabledOnPress ? disabledOnPress : _onPress}
      href={href}
      hrefAttrs={hrefAttrs}
      disabled={disabledOnPress ? false : disabled}
      onPressIn={() => {
        hoverOffset.value = withSpring(1, hoverConfig);
      }}
      onPressOut={() => {
        hoverOffset.value = withSpring(0, hoverConfig);
      }}
    >
      {React.Children.map(children, (child, index) => {
        if (!child) {
          return child;
        }

        return React.cloneElement(child, {
          disabled,
        });
      })}
      {borderBottom && (
        <Animated.View
          style={[
            styles.border,
            withIcon && styles.borderWithIcon,
            borderStyle,
          ]}
        />
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  border: {
    height: 1,
    position: 'absolute',
    left: 16,
    right: 0,
    bottom: 0,
  },
  borderWithIcon: {
    left: 58,
  },
});

export default ListItem;
