import React, { useContext, useMemo } from 'react';
import { DropdownContainer, DropdownItem } from '../Dropdown';

import { StyleSheet, ViewProps, ViewStyle } from 'react-native';
import { Link, LinkData } from 'app/hooks/use-resource/types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import Text, { TextType } from '../Text';
import {
  useCreate,
  useDelete,
  useInvalidateList,
  useUpdate,
} from 'app/hooks/use-resource/use-resource';
import Globe from '../Icons/Globe';
import Trash from '../Icons/Trash';
import Dots from '../Icons/Dots';
import useConfirm from 'app/hooks/use-confirm';
import useCopy from 'app/hooks/use-copy';
import { useToast } from 'app/hooks/use-toast';
import useShareLink from 'app/hooks/use-share-link';
import Clipboard from '@react-native-clipboard/clipboard';
import Share from '../Icons/Share';
import CopyDoc from '../Icons/CopyDoc';
import { useNavigation } from '@react-navigation/native';
import Routes from '../Navigator/ROUTES';
import { ButtonType } from '../Button';
import { useAuthStatus } from 'app/hooks/use-auth/use-auth-status';
import { Status } from 'app/hooks/use-auth/types';
import StuffListContext from '../StuffList/StuffListContext';
import useHasFeature from 'app/hooks/use-has-feature';
import useHasEntitlement from 'app/hooks/use-has-entitlement';
import PlusSquare from '../Icons/PlusSquare';

interface Props {
  linkData: LinkData;
  link: Link;
  onClose: () => void;
  isVisitor?: boolean;
  style?: ViewStyle;
  pointerEvents?: ViewProps['pointerEvents'];
}

function LinkActions({
  link,
  linkData,
  onClose,
  isVisitor,
  style,
  pointerEvents,
}: Props) {
  const addedOn = useMemo(() => {
    if (!link) {
      return '';
    }

    // TODO: The API doesn't return UTC timezones (as sqlite doesn't have timezone
    // support), so here we're just forcing the iso8601 string into UTC. We should do
    // better...
    const date = new Date(link.attributes.created_at + '+00:00');

    return `Added ${formatDistanceToNow(date)} ago`;
  }, [link]);
  const navigation = useNavigation();
  const { parsedQuery } = useContext(StuffListContext);

  const authStatus = useAuthStatus();
  const deleteLink = useDelete('link');
  const invalidateStuff = useInvalidateList('stuff');
  const createLink = useCreate('link');
  const updateLink = useUpdate('link');
  const confirm = useConfirm();
  const setToast = useToast();
  const shareLink = useShareLink();

  const hasCollectionFeature = useHasFeature('collections');
  const hasCreateCollectionEntitlement = useHasEntitlement('create_collection');
  const canAddToCollection =
    hasCollectionFeature && hasCreateCollectionEntitlement && !isVisitor;

  const copy = useCopy([
    'linkMenuRemoveConfirmText',
    'linkMenuRemoveCancelText',
    'linkMenuShareConfirmation',
    'linkMenuCopyConfirmation',
    'linkMenuAddToMyStuffConfirmation',
    'linkMenuRemoveConfirmation',
    'linkMenuSignupToAddToStuff',
    'linkMenuRemoveTitle',
    'linkMenuAddToCollection',
    'linkMenuRemoveFromCollection',
    'linkMenuRemoveFromCollectionConfirmation',
  ]);

  const collectionId = link.relationships.collections.data.find(
    (relationship) => relationship.id === parsedQuery.collectionId,
  )?.id;

  return (
    <DropdownContainer
      style={[styles.container, style]}
      footer={addedOn && <Text type={TextType.footnote}>{addedOn}</Text>}
      pointerEvents={pointerEvents}
    >
      <DropdownItem
        label="Open"
        Icon={Globe}
        onPress={onClose}
        href={linkData.attributes.url}
        hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
      />
      <DropdownItem
        label="Share"
        Icon={Share}
        onPress={async () => {
          const result = await shareLink({
            title: linkData.attributes.title,
            url: linkData.attributes.url,
            shareConfirmation: copy.linkMenuShareConfirmation,
          });

          if (result.type === 'clipboard') {
            onClose();
          }
        }}
      />
      <DropdownItem
        label="Copy"
        Icon={CopyDoc}
        onPress={() => {
          onClose();
          Clipboard.setString(linkData.attributes.url);
          setToast({
            type: 'info',
            text: copy.linkMenuCopyConfirmation,
            delay: 2000,
          });
        }}
      />
      {canAddToCollection && (
        <DropdownItem
          label={copy.linkMenuAddToCollection}
          Icon={PlusSquare}
          onPress={() => {
            navigation.navigate(Routes.ADD_TO_COLLECTION, { links: [link.id] });
          }}
        />
      )}
      {collectionId && canAddToCollection && (
        <DropdownItem
          label={copy.linkMenuRemoveFromCollection}
          renderIcon={(isActive) =>
            isActive ? <Trash color="white" /> : <Trash color="red9" />
          }
          onPress={() => {
            onClose();
            setTimeout(async () => {
              updateLink(
                link.id,
                {
                  id: link.id,
                  type: 'link',
                  relationships: {
                    collections: {
                      data: link.relationships.collections.data.filter(
                        (relationship) => relationship.id !== collectionId,
                      ),
                    },
                  },
                },
                {
                  invalidateLists: ['stuff'],
                },
              );
            }, 800);

            setToast({
              type: 'info',
              text: copy.linkMenuRemoveFromCollectionConfirmation,
              delay: 2000,
            });
          }}
        />
      )}
      {isVisitor && (
        <DropdownItem
          label="Save to My Stuff"
          Icon={Dots}
          ActiveIcon={(props) => <Dots {...props} colorOverride="SolidWhite" />}
          last
          onPress={() => {
            onClose();

            if (isVisitor && authStatus === Status.USER) {
              createLink(
                {
                  type: 'link',
                  relationships: {
                    link_data: {
                      data: {
                        id: link.relationships.link_data.data.id,
                        type: 'link_data',
                      },
                    },
                  },
                },
                {
                  invalidateLists: false,
                },
              );
              setToast({
                type: 'info',
                text: copy.linkMenuAddToMyStuffConfirmation,
                delay: 2000,
              });
            } else {
              setTimeout(() => {
                navigation.navigate(Routes.AUTHENTICATION);
                setToast({
                  type: 'info',
                  text: copy.linkMenuSignupToAddToStuff,
                  delay: 2000,
                });
              }, 450);
            }
          }}
        />
      )}
      {!isVisitor && (
        <DropdownItem
          label="Remove"
          renderIcon={(isActive) =>
            isActive ? <Trash color="white" /> : <Trash color="red9" />
          }
          last
          textColor="red9"
          activeTextColor="white"
          activeBackgroundColor="red9"
          onPress={() => {
            confirm({
              cancelText: copy.linkMenuRemoveCancelText,
              confirmText: copy.linkMenuRemoveConfirmText,
              body: copy.linkMenuRemoveTitle,
              confirmButtonType: ButtonType.secondaryDanger,
              callback: (didConfirm) => {
                if (didConfirm) {
                  onClose();
                  setTimeout(async () => {
                    // Stuff IDs for links are the link ID
                    await deleteLink(link.id);
                    invalidateStuff();
                  }, 800);
                  setToast({
                    type: 'info',
                    text: copy.linkMenuRemoveConfirmation,
                    delay: 2000,
                  });
                }
              },
            });
          }}
        />
      )}
    </DropdownContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 200,
  },
});

export default LinkActions;
