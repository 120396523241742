import base64 from 'base-64';
import BasePasskey from './passkeys.base';
import {
  startAuthentication,
  startRegistration,
} from '@simplewebauthn/browser';
import {
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from '@simplewebauthn/typescript-types';

class Passkey extends BasePasskey {
  public static async authenticate(email: string) {
    const options: PublicKeyCredentialRequestOptionsJSON =
      await this.makeOptionsRequest({
        type: 'authentication',
        email,
      });

    // If there aren't any allowed credentials, it means the account
    // doesn't use passkeys. So rather than showing the user a blank
    // authenticator (that will ask if they have a passkey on a nearby
    // device), we instead jump straight to passkeys.
    if (!options.allowCredentials?.length) {
      throw Error();
    }

    return await startAuthentication(options);
  }

  public static async register(username: string, email: string) {
    const options: PublicKeyCredentialCreationOptionsJSON =
      await this.makeOptionsRequest({
        type: 'registration',
        username,
        email,
      });

    return await startRegistration(options);
  }

  public static async autofill() {
    const options: PublicKeyCredentialRequestOptionsJSON =
      await this.makeOptionsRequest({
        type: 'authentication',
      });

    const response = await startAuthentication(options, true);
    const email = base64.decode(response.response.userHandle);

    return { response, email };
  }

  public static async cancelAutofill() {}
}

export default Passkey;
