import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const Plus = ({ width = 21, height = 21, color = 'white' }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 26 26" fill="none">
      <Path
        d="M0.75 13C0.75 13.8527 1.46013 14.5445 2.29455 14.5445H11.4555V23.7055C11.4555 24.54 12.1474 25.25 13 25.25C13.849 25.25 14.5592 24.5436 14.5592 23.7055V14.5445H23.7055C24.54 14.5445 25.25 13.8526 25.25 13C25.25 12.1511 24.5436 11.4409 23.7055 11.4409H14.5592V2.29455C14.5592 1.45648 13.849 0.75 13 0.75C12.1474 0.75 11.4555 1.46012 11.4555 2.29455V11.4409H2.29455C1.45649 11.4409 0.75 12.151 0.75 13Z"
        fill={colorToUse}
        stroke={colorToUse}
        strokeWidth="0.5"
      />
    </Svg>
  );
};

export default Plus;
