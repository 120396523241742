import React, { useCallback } from 'react';
import List, { ListItem, ListItemIcon } from 'app/components/List';
import { Setting } from 'app/hooks/use-resource/types';
import ListItemLabel from 'app/components/List/ListItemLabel';
import Chevron from 'app/components/Icons/Chevron';
import { NavigationProp } from '@react-navigation/native';
import Routes from 'app/components/Navigator/ROUTES';
import useCopy from 'app/hooks/use-copy';
import ListItemValue from 'app/components/List/ListItemValue';
import { Platform } from 'react-native';
import { appearanceToLabels } from './Appearance';
import useAppIcon from 'app/hooks/use-app-icon';

function Customization({
  setting,
  navigation,
}: {
  setting: Setting;
  // TODO: Type navigation properly
  navigation: NavigationProp<ReactNavigation.RootParamList>;
}) {
  const copy = useCopy([
    'settingsCustomizationTitle',
    'settingsCustomizationHelpTextWithPlus',
    'settingsCustomizationHelpTextWithoutPlus',
  ]);
  const hasAppearance =
    setting.attributes.entitlements.includes('set_appearance');
  const hasAppIcon = setting.attributes.entitlements.includes('set_app_icon');
  const hasPlus = hasAppearance || hasAppIcon;

  const currentAppearance = Platform.select({
    web: setting.attributes.appearance_web,
    default: setting.attributes.appearance_ios,
  });
  const { currentAppIcon } = useAppIcon();

  const openPrices = useCallback(() => {
    navigation.navigate(Routes.SUBSCRIPTION, {
      screen: Routes.PRICES,
    });
  }, [navigation]);

  return (
    <List
      title={copy.settingsCustomizationTitle}
      help={
        hasPlus
          ? copy.settingsCustomizationHelpTextWithPlus
          : copy.settingsCustomizationHelpTextWithoutPlus
      }
    >
      <ListItem
        disabled={!hasAppearance}
        onPress={() => navigation.push(Routes.SETTINGS_APPEARANCE)}
        disabledOnPress={openPrices}
      >
        <ListItemLabel>Appearance</ListItemLabel>
        <ListItemValue>{appearanceToLabels[currentAppearance]}</ListItemValue>
        <ListItemIcon Icon={Chevron} />
      </ListItem>
      <ListItem
        disabled={!hasAppIcon}
        onPress={() => navigation.push(Routes.SETTINGS_APP_ICON)}
        disabledOnPress={openPrices}
      >
        <ListItemLabel>App Icon</ListItemLabel>
        <ListItemValue>{currentAppIcon.label}</ListItemValue>
        <ListItemIcon Icon={Chevron} />
      </ListItem>
    </List>
  );
}

export default Customization;
