import { dark, light } from 'app/constants/colors';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, StyleSheet, ViewProps, ViewStyle } from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Bookmark from '../Icons/Bookmark';
import EmphasisIcon from '../Icons/EmphasisIcon';
import LoveIcon from '../Icons/LoveIcon';
import ShadowView, { ShadowType } from '../ShadowView';
import { makeThemeStyle } from '../ThemeView';
import useColorScheme from 'app/hooks/use-color-scheme';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

export enum ReactionType {
  reactionFlag = 'reaction_flag',
  reactionEmphasis = 'reaction_emphasis',
  reactionLove = 'reaction_love',
}

interface Props {
  selected?: boolean;
  onSelect?: () => void;
  style?: ViewStyle;
  type: ReactionType;
  shadow?: boolean;
  pointerEvents?: ViewProps['pointerEvents'];
  expanded?: boolean;
  disabled?: boolean;
  transparentBackground?: boolean;
}

const typeConfig = {
  [ReactionType.reactionFlag]: {
    Icon: Bookmark,
    iconColor: light.coolBlue,
  },
  [ReactionType.reactionEmphasis]: {
    Icon: EmphasisIcon,
    iconColor: light.coolRed,
  },
  [ReactionType.reactionLove]: {
    Icon: LoveIcon,
    iconColor: light.coolPink,
  },
};

const iconSizes: {
  [type in ReactionType]: {
    default: { width: number; height: number };
    expanded: { width: number; height: number };
  };
} = {
  [ReactionType.reactionFlag]: {
    default: {
      width: 9.43,
      height: 15,
    },
    expanded: {
      width: 13.47,
      height: 21.43,
    },
  },
  [ReactionType.reactionLove]: {
    default: {
      width: 16.02,
      height: 14.09,
    },
    expanded: {
      width: 22.88,
      height: 20.13,
    },
  },
  [ReactionType.reactionEmphasis]: {
    default: {
      width: 13.71,
      height: 16.4,
    },
    expanded: {
      width: 19.58,
      height: 23.43,
    },
  },
};

function Reaction({
  selected,
  onSelect: _onSelect,
  style,
  type,
  shadow = false,
  expanded,
  disabled,
  pointerEvents,
  transparentBackground = false,
}: Props) {
  const [hovered, setHovered] = useState(false);
  const colorScheme = useColorScheme();

  const hoveredAnimatedValue = useSharedValue(0);
  const selectedAnimatedValue = useSharedValue(0);

  let _iconColor = light.gray8;
  const Icon = typeConfig[type].Icon;
  const { width: iconWidth, height: iconHeight } =
    iconSizes[type][expanded ? 'expanded' : 'default'];

  if (selected) {
    _iconColor = typeConfig[type].iconColor;
  }

  useEffect(() => {
    hoveredAnimatedValue.value = withSpring(hovered ? 1 : 0, {
      damping: 6,
      mass: 0.2,
      stiffness: 250,
      restDisplacementThreshold: 0.01,
      restSpeedThreshold: 0.1,
    });
  }, [hovered, hoveredAnimatedValue]);

  useEffect(() => {
    selectedAnimatedValue.value = withSpring(selected ? 1 : 0, {
      damping: 9,
      mass: 0.6,
      stiffness: 500,
      restDisplacementThreshold: 0.01,
      restSpeedThreshold: 0.1,
    });
  }, [selected, selectedAnimatedValue]);

  const containerAnimatedStyle = useAnimatedStyle(() => {
    if (expanded) {
      return {};
    }

    return {
      transform: [
        {
          scale: interpolate(hoveredAnimatedValue.value, [0, 1], [1, 1.05]),
        },
      ],
    };
  }, [hoveredAnimatedValue, expanded]);

  const iconStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale: interpolate(
            selectedAnimatedValue.value + hoveredAnimatedValue.value,
            [0, 1, 2],
            [0.9, 1.1, 1.2],
          ),
        },
      ],
    };
  }, [selectedAnimatedValue, hoveredAnimatedValue]);

  const onSelect = useCallback(() => {
    if (_onSelect) {
      _onSelect();
      setHovered(false);
    }
  }, [_onSelect]);

  const containerStyles = [
    colorScheme === 'light' ? styles.containerLight : styles.containerDark,
    style,
    transparentBackground && styles.containerTransparent,
  ];

  return (
    <AnimatedPressable
      onHoverIn={() => onSelect && setHovered(true)}
      onHoverOut={() => onSelect && setHovered(false)}
      onPress={onSelect}
      hitSlop={8}
      pointerEvents={pointerEvents}
      disabled={disabled}
    >
      <ShadowView
        type={ShadowType.small}
        style={[containerAnimatedStyle, styles.container]}
        animatedStyle={containerStyles}
        enabled={shadow}
      >
        <Animated.View style={iconStyle}>
          <Icon color={_iconColor} width={iconWidth} height={iconHeight} />
        </Animated.View>
      </ShadowView>
    </AnimatedPressable>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
  },
  containerLight: {
    backgroundColor: light.SolidWhite,
  },
  containerDark: {
    backgroundColor: dark.gray6,
  },
  containerTransparent: {
    backgroundColor: 'transparent',
  },
});

export default Reaction;
