import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const LogoVerticalText = ({ width = 80, height = 78.2, color }: Props) => {
  const textColor = useColor(color || 'gray1');

  return (
    <Svg width={width} height={height} viewBox="0 0 178 174" fill="none">
      <Circle cx="88.9995" cy="144.499" r="29.2964" fill="#E6534C" />
      <Circle cx="148.097" cy="144.499" r="29.2964" fill="#EFA7EB" />
      <Circle cx="29.9019" cy="144.499" r="29.2964" fill="#5F7EE3" />
      <Path
        d="M24.6992 46.9238C36.418 46.9238 44.668 39.3926 45.0742 29.2988L45.1055 28.9551H34.0742L34.0117 29.2988C33.2305 34.3301 29.6992 37.4238 24.7305 37.4238C18.4492 37.4238 14.4492 32.2363 14.4492 23.5176V23.4863C14.4492 14.8613 18.418 9.70508 24.6992 9.70508C29.9492 9.70508 33.2305 13.0176 33.9492 17.7051L34.0117 18.1738H45.043V17.8613C44.6367 7.76758 36.4805 0.205078 24.6992 0.205078C11.2305 0.205078 2.76172 8.79883 2.76172 23.5176V23.5488C2.76172 38.2676 11.168 46.9238 24.6992 46.9238Z"
        fill={textColor}
      />
      <Path
        d="M63.5127 46.8301C74.2315 46.8301 80.919 40.3613 80.919 29.1113V29.0488C80.919 17.9551 74.1065 11.3613 63.5127 11.3613C52.9502 11.3613 46.1065 18.0176 46.1065 29.0488V29.1113C46.1065 40.3301 52.7315 46.8301 63.5127 46.8301ZM63.544 38.4238C59.7002 38.4238 57.3565 35.1113 57.3565 29.1113V29.0488C57.3565 23.1738 59.7627 19.7676 63.5127 19.7676C67.2627 19.7676 69.7002 23.1738 69.7002 29.0488V29.1113C69.7002 35.0801 67.3252 38.4238 63.544 38.4238Z"
        fill={textColor}
      />
      <Path
        d="M98.9512 46.8301C109.67 46.8301 116.357 40.3613 116.357 29.1113V29.0488C116.357 17.9551 109.545 11.3613 98.9512 11.3613C88.3887 11.3613 81.545 18.0176 81.545 29.0488V29.1113C81.545 40.3301 88.17 46.8301 98.9512 46.8301ZM98.9825 38.4238C95.1387 38.4238 92.795 35.1113 92.795 29.1113V29.0488C92.795 23.1738 95.2012 19.7676 98.9512 19.7676C102.701 19.7676 105.139 23.1738 105.139 29.0488V29.1113C105.139 35.0801 102.764 38.4238 98.9825 38.4238Z"
        fill={textColor}
      />
      <Path
        d="M117.36 46.1039H128.454V1.01014H117.36V46.1039Z"
        fill={textColor}
      />
      <Path
        d="M21.3867 97.9238C33.3242 97.9238 40.3867 92.2051 40.3867 83.2051V83.1738C40.3867 75.9551 35.9492 72.0801 26.3867 70.2363L21.7617 69.3613C16.918 68.4551 14.793 67.1738 14.793 64.7363V64.7051C14.793 62.1113 17.168 60.2051 21.3867 60.2051C25.5117 60.2051 28.418 62.0488 28.7617 65.0176L28.793 65.3613H39.4492L39.418 64.7051C39.0117 56.5176 32.6055 51.2051 21.293 51.2051C10.9492 51.2051 3.41797 56.7988 3.41797 65.4863V65.5176C3.41797 72.4238 8.13672 76.8301 17.0742 78.4863L21.6992 79.3613C26.9805 80.3926 28.9805 81.5801 28.9805 84.1426V84.1738C28.9805 86.9863 26.168 88.9238 21.6055 88.9238C17.1992 88.9238 13.8242 87.0176 13.2617 84.1113L13.1992 83.7676H2.54297L2.57422 84.3301C3.07422 92.9551 10.168 97.9238 21.3867 97.9238Z"
        fill={textColor}
      />
      <Path
        d="M57.1065 97.8301C59.419 97.8301 61.0752 97.6426 62.294 97.4238V89.5488C61.669 89.6426 61.1065 89.7051 60.2315 89.7051C57.5127 89.7051 56.2002 88.6113 56.2002 85.6738V71.2051H62.294V63.0801H56.2002V55.2363H45.1065V63.0801H40.4815V71.2051H45.1065V87.1738C45.1065 94.8613 48.8565 97.8301 57.1065 97.8301Z"
        fill={textColor}
      />
      <Path
        d="M75.0098 97.8301C80.1661 97.8301 83.6348 95.3926 85.3536 91.1113H85.5723V97.1113H96.6348V63.0801H85.5723V82.3926C85.5723 86.1738 83.3536 88.8301 79.8536 88.8301C76.1973 88.8301 74.3848 86.5176 74.3848 82.6113V63.0801H63.2911V84.9863C63.2911 93.0488 67.5411 97.8301 75.0098 97.8301Z"
        fill={textColor}
      />
      <Path
        d="M102.167 97.1113H113.261V71.2051H119.573V63.0801H112.948V61.6113C112.948 59.6426 114.23 58.4863 117.292 58.4863C118.136 58.4863 119.011 58.5176 119.636 58.6426V51.7676C118.417 51.5176 116.261 51.2988 114.323 51.2988C105.417 51.2988 102.167 54.3926 102.167 61.1113V63.0801H97.6358V71.2051H102.167V97.1113Z"
        fill={textColor}
      />
      <Path
        d="M124.106 97.1113H135.199V71.2051H141.512V63.0801H134.887V61.6113C134.887 59.6426 136.168 58.4863 139.231 58.4863C140.074 58.4863 140.949 58.5176 141.574 58.6426V51.7676C140.356 51.5176 138.199 51.2988 136.262 51.2988C127.356 51.2988 124.106 54.3926 124.106 61.1113V63.0801H119.573V71.2051H124.106V97.1113Z"
        fill={textColor}
      />
    </Svg>
  );
};

export default LogoVerticalText;
