import * as React from 'react';
import Svg, {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
}

const PlusCircle = ({ width = 28, height = 28 }: Props) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 28 28" fill="none">
      <G clipPath="url(#clip0_2045_33556)">
        <Path
          d="M14 27.9862C21.6589 27.9862 28 21.6345 28 13.9931C28 6.33806 21.6452 0 13.9863 0C6.34119 0 0 6.33806 0 13.9931C0 21.6345 6.35491 27.9862 14 27.9862Z"
          fill="url(#paint0_linear_2045_33556)"
        />
        <Path
          d="M7.30176 14.0068C7.30176 13.2935 7.79587 12.7996 8.50959 12.7996H12.8057V8.50563C12.8057 7.79226 13.2861 7.28467 13.9861 7.28467C14.6998 7.28467 15.194 7.79226 15.194 8.50563V12.7996H19.5037C20.2037 12.7996 20.7116 13.2935 20.7116 14.0068C20.7116 14.7065 20.2037 15.1729 19.5037 15.1729H15.194V19.4806C15.194 20.194 14.6998 20.6879 13.9861 20.6879C13.2861 20.6879 12.8057 20.1803 12.8057 19.4806V15.1729H8.50959C7.79587 15.1729 7.30176 14.7065 7.30176 14.0068Z"
          fill="#FBFDFF"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_2045_33556"
          x1="19.9826"
          y1="34.0582"
          x2="17.5058"
          y2="-25.6759"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1088FC" />
          <Stop offset="1" stopColor="#46B0FB" />
        </LinearGradient>
        <ClipPath id="clip0_2045_33556">
          <Rect width="28" height="28" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default PlusCircle;
