import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  color: Colors;
  width?: number | string;
  height?: number | string;
}

export default function Bookmark({ color, width = 14, height = 22 }: Props) {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 14 22" fill="none">
      <Path
        d="M1.32555 21.7143C1.81506 21.7143 2.10476 21.4346 3.00387 20.5655L6.90997 16.7193C6.95991 16.6694 7.04982 16.6694 7.08979 16.7193L10.9958 20.5655C11.895 21.4346 12.1847 21.7143 12.6742 21.7143C13.3435 21.7143 13.7332 21.2748 13.7332 20.4955V3.1529C13.7332 1.2548 12.7841 0.285767 10.906 0.285767H3.09378C1.21565 0.285767 0.266602 1.2548 0.266602 3.1529V20.4955C0.266602 21.2748 0.656212 21.7143 1.32555 21.7143Z"
        fill={colorToUse}
      />
    </Svg>
  );
}
