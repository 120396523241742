import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

const CoolStuffToken = {
  async get() {
    if (global.__IS_SERVER__) {
      return null;
    }

    if (Platform.OS === 'web') {
      // @ts-ignore
      const token: string = document.cookie
        .split('; ')
        .find((row: string) => row.startsWith('token='));

      if (token) {
        return token.split('=')[1];
      }

      return null;
    }

    return AsyncStorage.getItem('token');
  },

  _setCookie(token: string) {
    // 1 year
    let cookieValue = `token=${token};max-age=31536000;secure;path=/`;

    if (process.env.NODE_ENV === 'production') {
      cookieValue += ';domain=coolstuff.app;samesite=strict';
    }

    // @ts-ignore
    document.cookie = cookieValue;
  },

  _removeCookie() {
    // @ts-ignore
    let cookieValue =
      'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;secure;path=/';

    if (process.env.NODE_ENV === 'production') {
      cookieValue += ';domain=coolstuff.app;samesite=strict';
    }

    // @ts-ignore
    document.cookie = cookieValue;
  },

  async set(token: string) {
    if (Platform.OS === 'web') {
      this._setCookie(token);
    }

    return AsyncStorage.setItem('token', token);
  },

  async remove() {
    if (Platform.OS === 'web') {
      this._removeCookie();
    }

    return AsyncStorage.removeItem('token');
  },
};

export default CoolStuffToken;
