import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import { StyleSheet } from 'react-native';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const CheckmarkCircle = ({
  width = 20,
  height = 20,
  color = 'blue9',
}: Props) => {
  const colorToUse = useColor(color);

  return (
    <ThemeView
      darkStyle={styles.circleDark}
      lightStyle={styles.circleLight}
      style={{
        width,
        height,
        borderRadius: width / 2,
        borderColor: colorToUse,
      }}
    >
      <Svg
        width={width / 2.2}
        height={width / 2.2}
        viewBox="0 0 15 15"
        fill="none"
      >
        <Path
          d="M6.10938 14.5933C6.46631 14.5933 6.74854 14.4355 6.94775 14.1284L14.792 1.77686C14.9414 1.53613 14.9995 1.35352 14.9995 1.1626C14.9995 0.706055 14.7007 0.407227 14.2441 0.407227C13.9121 0.407227 13.7295 0.515137 13.5303 0.830566L6.07617 12.709L2.20801 7.64551C2.00049 7.35498 1.79297 7.23877 1.49414 7.23877C1.021 7.23877 0.697266 7.5625 0.697266 8.01904C0.697266 8.20996 0.780273 8.42578 0.937988 8.625L5.24609 14.1118C5.49512 14.4355 5.75244 14.5933 6.10938 14.5933Z"
          fill={colorToUse}
        />
      </Svg>
    </ThemeView>
  );
};

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'circle',
    {
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1,
    },
    {
      borderColor: 'LayeredSubtleGray',
    },
  ),
});

export default CheckmarkCircle;
