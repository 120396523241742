import useColor from 'app/hooks/use-color';
import useLinkDataLayout from 'app/hooks/use-link-data-layout';
import { useResource } from 'app/hooks/use-resource/use-resource';
import React, { Ref } from 'react';
import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import HoverView from '../HoverView';
import NoImageIcon from '../Icons/NoImage';
import Image from '../Image';
import Pressable from '../Pressable';
import ShadowView, { ShadowType } from '../ShadowView';
import Text, { TextType } from '../Text';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import { dark, light } from 'app/constants/colors';
import useLinkDataUrl from 'app/hooks/use-link-data-url';
import { ViewState } from '../StuffList/useLayoutInfo';

const LinkData = (
  {
    linkDataId,
    style,
    width,
    hovered,
    onLayout,
    pointerEvents,
    expanded = false,
    thumbnailShadowEnabled = true,
    viewState = ViewState.regular,
    selected = false,
  }: {
    linkDataId: string;
    width?: number;
    style?: ViewStyle | (ViewStyle | false)[];
    hovered?: boolean;
    expanded?: boolean;
    onLayout?: ViewProps['onLayout'];
    pointerEvents?: ViewProps['pointerEvents'];
    useBlurHash?: boolean;
    thumbnailShadowEnabled?: boolean;
    viewState?: ViewState;
    selected?: boolean;
  },
  ref: Ref<View | Animated.View>,
) => {
  const { linkData, ready } = useResource('link_data', linkDataId);

  const noImageColor = useColor('gray9');

  const aspectRatio =
    (linkData?.attributes.thumbnail_width || 400) /
    (linkData?.attributes.thumbnail_height || 200);

  const formattedUrl = useLinkDataUrl({ linkData, expanded });

  const { thumbnailWidth, thumbnailHeight, thumbnailPadding, containerWidth } =
    useLinkDataLayout({
      width,
      expanded,
      aspectRatio,
    });

  if (!ready) {
    return null;
  }

  const thumbnail = linkData.attributes.thumbnail;

  const renderFooter = linkData.attributes.type !== 'image' || expanded;
  const renderTitle = linkData.attributes.type !== 'image';

  return (
    <ThemeView
      style={[
        viewState === ViewState.regular
          ? styles.containerRegular
          : styles.containerSmall,
        style,
        {
          width: containerWidth,
          // This stops the reactions from being pushed back in Z.
          // @ts-ignore
          perspective: 800000,
          padding: thumbnailPadding / 2,
        },
      ]}
      lightStyle={styles.containerLight}
      darkStyle={styles.containerDark}
      onLayout={onLayout}
      pointerEvents={pointerEvents}
      ref={ref}
    >
      <HoverView
        style={[
          { width: thumbnailWidth, height: thumbnailHeight },
          viewState === ViewState.regular
            ? styles.thumbnailRegular
            : styles.thumbnailSmall,
        ]}
        hovered={Boolean(hovered)}
        parallaxEnabled={!expanded}
        shadowEnabled={thumbnailShadowEnabled}
        lightStyle={styles.imagePlaceholderLight}
        darkStyle={styles.imagePlaceholderDark}
        shadowType={ShadowType.small}
      >
        {thumbnail ? (
          <>
            <Pressable
              style={styles.thumbnailImage}
              href={linkData.attributes.url}
              hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
              disabled={!expanded}
            >
              <Image
                style={[
                  styles.thumbnailImage,
                  viewState === ViewState.regular
                    ? styles.thumbnailImageRegular
                    : styles.thumbnailImageSmall,
                ]}
                source={{
                  uri: linkData.attributes.thumbnail,
                  width: thumbnailWidth,
                  height: thumbnailHeight,
                }}
                contentFit="cover"
                placeholder={{
                  blurhash: linkData.attributes.blur_hash,
                  width: 4,
                  height: 3,
                }}
                placeholderContentFit="cover"
                recyclingKey={linkDataId}
                transition={
                  expanded
                    ? undefined
                    : {
                        effect: 'cross-dissolve',
                        duration: 200,
                      }
                }
              />
            </Pressable>
          </>
        ) : (
          <NoImageIcon color={noImageColor} width={32} height={32} />
        )}
      </HoverView>
      {renderFooter && (
        <View
          style={
            viewState === ViewState.regular
              ? styles.footerRegular
              : styles.footerSmall
          }
        >
          {renderTitle && (
            <Pressable
              href={linkData.attributes.url}
              hrefAttrs={{ target: 'blank', rel: 'nofollow' }}
            >
              <Text
                type={
                  viewState === ViewState.regular
                    ? TextType.bodyEmphasized
                    : TextType.subheadlineEmphasized
                }
                numberOfLines={2}
                ellipsizeMode="tail"
              >
                {linkData.attributes.title || '...'}
              </Text>
            </Pressable>
          )}
          <Text
            type={TextType.footnote}
            color="SupportingGray"
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {formattedUrl}
          </Text>
        </View>
      )}
      {selected && (
        <View
          style={[
            StyleSheet.absoluteFill,

            viewState === ViewState.regular
              ? styles.containerSelectedRegular
              : styles.containerSelectedSmall,
          ]}
          pointerEvents="none"
        />
      )}
    </ThemeView>
  );
};

export const LinkDataSkeleton = ({
  width,
  style,
}: {
  width: number;

  style?: ViewStyle | (ViewStyle | false)[];
}) => {
  const { thumbnailWidth, thumbnailHeight, containerWidth, thumbnailPadding } =
    useLinkDataLayout({ expanded: false, width, aspectRatio: 1.2 });

  return (
    <ThemeView
      lightStyle={styles.containerLight}
      darkStyle={styles.containerDark}
      style={[
        styles.container,
        {
          width: containerWidth,
          padding: thumbnailPadding / 2,
        },
        style,
      ]}
    >
      <ShadowView
        type={ShadowType.big}
        style={[
          styles.thumbnail,
          {
            width: thumbnailWidth,
            height: thumbnailHeight,
          },
        ]}
      >
        <ThemeView
          style={styles.thumbnailImage}
          lightStyle={styles.imagePlaceholderLight}
          darkStyle={styles.imagePlaceholderDark}
        ></ThemeView>
      </ShadowView>
      <View style={[styles.footerRegular, styles.footerSkeleton]}></View>
    </ThemeView>
  );
};

const styles = StyleSheet.create({
  containerRegular: {
    borderRadius: 20,
    position: 'relative',
  },
  containerSmall: {
    borderRadius: 16,
    position: 'relative',
  },
  containerLight: {
    backgroundColor: light.gray4,
  },
  containerDark: {
    backgroundColor: dark.gray3,
  },
  // Yeah... this is duplicating stuff
  containerSelectedRegular: {
    borderColor: light.Primary,
    borderWidth: 4,
    borderRadius: 20,
  },
  containerSelectedSmall: {
    borderColor: light.Primary,
    borderWidth: 4,
    borderRadius: 16,
  },
  thumbnailRegular: {
    borderRadius: 16,
  },
  thumbnailSmall: {
    borderRadius: 12,
  },
  thumbnailImage: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  thumbnailImageRegular: {
    borderRadius: 16,
  },
  thumbnailImageSmall: {
    borderRadius: 12,
  },
  containerActive: {
    transform: [{ scale: 1.05 }],
  },
  ...makeThemeStyle(
    'imagePlaceholder',
    {
      alignItems: 'center',
      justifyContent: 'center',
    },
    {
      backgroundColor: 'gray5',
    },
  ),
  footerRegular: {
    paddingTop: 8,
    paddingBottom: 6,
    paddingHorizontal: 12,
  },
  footerSmall: {
    paddingTop: 8,
    paddingBottom: 6,
    paddingHorizontal: 8,
  },
  footerSkeleton: {
    height: 32,
  },
});

export default React.memo(React.forwardRef(LinkData));
