import Clipboard from '@react-native-clipboard/clipboard';
import { Platform, Share } from 'react-native';
import useIsMobile from './use-is-mobile';
import { useToast } from './use-toast';

interface Result {
  type: 'native' | 'clipboard';
}

const useShareLink = () => {
  const isMobile = useIsMobile();
  const setToast = useToast();

  return async ({
    title = '',
    url,
    shareConfirmation,
    tryNative = true,
  }: {
    title?: string;
    url: string;
    shareConfirmation: string;
    tryNative?: boolean;
  }): Promise<Result> => {
    const tryNativeNavigator = tryNative && (isMobile || Platform.OS === 'ios');
    let nativeSucceeded = false;

    if (tryNativeNavigator) {
      if (Platform.OS === 'web') {
        try {
          navigator.share({ title, url });
          nativeSucceeded = true;
        } catch (error) {}
      } else {
        await Share.share({
          message: title,
          url,
        });
        nativeSucceeded = true;
      }
    }

    if (!nativeSucceeded) {
      Clipboard.setString(url);
      setToast({
        type: 'info',
        text: shareConfirmation,
        delay: 2000,
      });
    }

    return {
      type: nativeSucceeded ? 'native' : 'clipboard',
    };
  };
};

export default useShareLink;
