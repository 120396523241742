import { BlurViewProps } from '@react-native-community/blur';
import React from 'react';
import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import {
  interpolate,
  SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import ThemeView from '../ThemeView';

interface Props extends ViewProps {
  amount: number;
  darkStyle?: ViewStyle;
  lightStyle?: ViewStyle;
  animatedValue?: SharedValue<number> | SharedValue<object>;
  animatedValueProp?: string;
  blurType?: BlurViewProps['blurType'];
}

function BlurView({
  amount,
  animatedValue,
  animatedValueProp,
  style: userStyle,
  ...props
}: Props) {
  const style = useAnimatedStyle(() => {
    const value =
      animatedValue === undefined
        ? `blur(${amount}px)`
        : `blur(${interpolate(
            typeof animatedValue.value === 'number'
              ? animatedValue.value
              : animatedValue.value[animatedValueProp],
            [0, 1],
            [0, amount],
          )}px)`;

    return {
      backdropFilter: value,
      WebkitBackdropFilter: value,
    };
  }, [amount, animatedValue, animatedValueProp]);

  return <ThemeView {...props} style={[props.style, style, userStyle]} />;
}

export default BlurView;
