import React, { useContext, useEffect } from 'react';
import { Platform, StyleSheet } from 'react-native';
import Animated, {
  interpolate,
  SharedValue,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import CreateLink from '../CreateLink/CreateLink';
import SharePage from '../SharePage';
import { Portal } from '@gorhom/portal';
import useIsMobile from 'app/hooks/use-is-mobile';
import StuffListContext from './StuffListContext';
import { useNavigationState } from '@react-navigation/native';
import Routes from '../Navigator/ROUTES';
import { useResourceList } from 'app/hooks/use-resource/use-resource';
import { useAuthStatus } from 'app/hooks/use-auth/use-auth-status';
import { Status } from 'app/hooks/use-auth/types';

interface Props {
  presentedAnimatedValue: SharedValue<number>;
}

export const actionButtonAnimatedConfig = {
  damping: 15,
  mass: 0.75,
};

const StuffListAction = ({ presentedAnimatedValue }: Props) => {
  const isMobile = useIsMobile();
  const { parsedQuery, isPublic, isVisitor, url } =
    useContext(StuffListContext);
  const topRouteName = useNavigationState(
    (state) => state.routes[state.index].name,
  );
  const status = useAuthStatus();

  // Prefetch any tips that might render on SaveLink
  useResourceList('tip', {
    query: { 'filter[type]': 'save_link' },
    fetch: status === Status.USER,
  });

  const shareAnimatedValue = useSharedValue(
    isVisitor || parsedQuery.isActive ? 1 : 0,
  );

  useEffect(() => {
    setTimeout(() => {
      if (parsedQuery.isActive || isVisitor) {
        if (shareAnimatedValue.value !== 1) {
          shareAnimatedValue.value = withSpring(1, actionButtonAnimatedConfig);
        }
      } else {
        if (shareAnimatedValue.value !== 0) {
          shareAnimatedValue.value = withSpring(0, actionButtonAnimatedConfig);
        }
      }
    }, 100);
  }, [isVisitor, parsedQuery.isActive, shareAnimatedValue]);

  const linkActionButtonStyle = useAnimatedStyle(() => {
    if (Platform.OS === 'web') {
      return {
        opacity: interpolate(presentedAnimatedValue.value, [-1, 0], [0, 1]),
      };
    } else {
      return {
        transform: [
          {
            translateY: interpolate(
              presentedAnimatedValue.value,
              [-1, 0],
              [184, 0],
            ),
          },
        ],
      };
    }
  }, [presentedAnimatedValue]);

  const createLinkAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: interpolate(shareAnimatedValue.value, [0, 0.6], [1, 0]),
      transform: [
        {
          translateY: interpolate(shareAnimatedValue.value, [0, 0.6], [-32, 0]),
        },
      ],
    }),
    [shareAnimatedValue],
  );
  const shareAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: interpolate(shareAnimatedValue.value, [0.4, 1], [0, 1]),
      transform: [
        {
          translateY: interpolate(shareAnimatedValue.value, [0.4, 1], [0, -32]),
        },
      ],
    }),
    [shareAnimatedValue],
  );

  const actions = (
    <Animated.View
      style={[
        styles.linkActions,
        isMobile && styles.linkActionsMobile,
        Platform.OS !== 'web' && isVisitor && styles.linkActionsIOSVisitor,
        linkActionButtonStyle,
      ]}
    >
      <Animated.View style={[createLinkAnimatedStyle]}>
        <CreateLink />
      </Animated.View>
      {isPublic && (
        <Animated.View style={[styles.shareAction, shareAnimatedStyle]}>
          <SharePage url={url} />
        </Animated.View>
      )}
    </Animated.View>
  );

  if (topRouteName !== Routes.USER && topRouteName !== Routes.HOME) {
    // As screens are rendered in a stack and we render this in a portal (to avoid
    // clipping links when expanding/collapsing), we don't want to render when we're
    // not on a USER page.
    return null;
  }

  if (Platform.OS === 'ios' && isVisitor) {
    // When a visitor page is presented modally, we can't use a portal as it
    // won't scroll with the page as its dismissed. Instead we just absolutely
    // posotion within the page.
    return actions;
  }

  return <Portal hostName="bottomSheet">{actions}</Portal>;
};

const styles = StyleSheet.create({
  linkActions: {
    position: 'absolute',
    right: 32,
    bottom: 0,
  },
  linkActionsMobile: {
    right: Platform.select({
      web: 20,
      default: 24,
    }),
    bottom: Platform.select({
      web: 0,
      default: 86,
    }),
  },
  linkActionsIOSVisitor: {
    bottom: 0,
  },
  shareAction: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
export default StuffListAction;
