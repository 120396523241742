import { useResourceList } from 'app/hooks/use-resource/use-resource';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import SearchSuggestion from './SearchSuggestion';
import useStuffsQuery from './useStuffsQuery';

interface Props {
  activeKey: 'ArrowUp' | 'ArrowDown' | 'Enter' | 'Escape' | null;
  search: string;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  onSelectSuggestion: (suggestion: string) => void;
  onSetSuggestion: (suggestion: string) => void;
  style?: ViewStyle;
  username: string;
  isVisitor: boolean;
}

function SearchSuggestions({
  activeKey,
  search,
  onSelectSuggestion,
  onSetSuggestion,
  style,
  username,
  isVisitor,
  onChangeQuery,
}: Props) {
  const query: { [index: string]: string } = {
    'filter[search]': search.toLowerCase(),
  };

  if (isVisitor) {
    query['filter[user.id]'] = username;
  }

  const { ready, search_suggestionList } = useResourceList(
    'search_suggestion',
    {
      query,
    },
  );

  const [cachedSearchSuggestionList, setCachedSearchSuggestionList] = useState<
    string[] | null
  >(null);

  useEffect(() => {
    if (!ready) {
      return;
    }

    setCachedSearchSuggestionList(search_suggestionList.slice(0, 5));
  }, [search_suggestionList, ready]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open && search) {
      setOpen(true);
    }
  }, [search, open]);

  if (!cachedSearchSuggestionList || !cachedSearchSuggestionList.length) {
    return null;
  }

  return (
    <View style={[style, styles.container]}>
      {cachedSearchSuggestionList.map((id) => (
        <SearchSuggestion
          activeKey={activeKey}
          searchSuggestionId={id}
          key={id}
          onSelectSuggestion={onSelectSuggestion}
          onSetSuggestion={onSetSuggestion}
          onChangeQuery={onChangeQuery}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    paddingBottom: 24,
    gap: 36,
  },
});

export default SearchSuggestions;
