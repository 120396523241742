import React from 'react';
import { StyleSheet } from 'react-native';
import Text, { TextType } from './Text';
import { makeThemeStyle } from './ThemeView';
import Pressable from './Pressable';
import {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import useColor from 'app/hooks/use-color';

const hoverConfig = {
  damping: 20,
  mass: 1,
  stiffness: 600,
};

const Filter = ({
  name,
  onPress,
  enabled = true,
}: {
  name: string;
  onPress: () => void;
  enabled: boolean;
}) => {
  const hoverOffset = useSharedValue(0);

  const pressableBackgroundColor = useColor('BackgroundGray');
  const pressableStyle = useAnimatedStyle(
    () => ({
      backgroundColor: interpolateColor(
        hoverOffset.value,
        [0, 1],
        ['transparent', pressableBackgroundColor],
      ),
    }),
    [hoverOffset, pressableBackgroundColor],
  );

  return (
    <Pressable
      darkStyle={styles.containerDark}
      lightStyle={styles.containerLight}
      style={pressableStyle}
      onPress={onPress}
      disabled={!enabled}
      onHoverIn={() => {
        hoverOffset.value = withSpring(1, hoverConfig);
      }}
      onHoverOut={() => {
        hoverOffset.value = withSpring(0, hoverConfig);
      }}
    >
      <Text
        color={enabled ? 'HeadlineBlack' : 'LayeredSubtleGray'}
        type={TextType.subheadline}
      >
        {name}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'container',
    {
      paddingHorizontal: 12,
      paddingVertical: 6,
      borderRadius: 16,
      borderWidth: 1,
    },
    {
      borderColor: 'BackgroundGray',
    },
  ),
});

export default Filter;
