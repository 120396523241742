import { useResourceList } from 'app/hooks/use-resource/use-resource';
import React from 'react';
import BaseTips from './BaseTips';

const Tips = ({ query }: { query: { [key: string]: string } }) => {
  const { tipList, ready } = useResourceList('tip', { query });

  if (!ready) {
    return null;
  }

  return <BaseTips tipIds={tipList} />;
};

export default Tips;
