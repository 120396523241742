import useIsMobile from 'app/hooks/use-is-mobile';
import useWindowDimensions from 'app/hooks/use-window-dimensions';
import { useCallback, useMemo } from 'react';
import {
  getLinkDataHeight,
  THUMBNAIL_PADDING,
} from 'app/hooks/use-link-data-layout';
import { useResourceStateRef } from 'app/hooks/use-resource/use-resource';
import { Platform } from 'react-native';
import { TIP_HEIGHT, TIP_PEEK } from '../Tips/BaseTips';
import { ParsedQuery } from '../SearchSheet/types';
import useHasFeature from 'app/hooks/use-has-feature';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export enum ViewState {
  small,
  regular,
}

export const CONTENT_PADDING = 12;
export const CONTENT_PADDING_MOBILE = 12;
export const CONTENT_PADDING_EXPANDED_MOBILE = 8;

const LINK_PADDING = {
  [ViewState.small]: 4,
  [ViewState.regular]: 6,
};
const LINK_PADDING_MOBILE = {
  [ViewState.small]: 4,
  [ViewState.regular]: 6,
};
const LINK_CONTAINER_MAX_WIDTH = 1600;

// NOTE: Changing this width effects the bug that causes some masonry columns to not render when switching data
const LINK_WIDTHS: { [key in ViewState]: number } = {
  [ViewState.small]: 160,
  [ViewState.regular]: 360,
};

function useLayoutInfo({
  viewState,
  parsedQuery,
  isVisitor,
}: {
  viewState: ViewState;
  parsedQuery: ParsedQuery;
  isVisitor: boolean;
}) {
  const isMobile = useIsMobile();
  const { width, height } = useWindowDimensions();
  const resourceStateRef = useResourceStateRef();
  const insets = useSafeAreaInsets();

  const collectionsEnabled = useHasFeature('collections');
  const viewStatesEnabled = useHasFeature('view_states');
  const newHeaderEnabled = collectionsEnabled || viewStatesEnabled;

  // NOTE: HeaderVisible is actually referring to the header row we
  // see on desktop, so it's a bit of a misleading name.
  const headerVisible =
    newHeaderEnabled && !isVisitor ? true : Platform.OS === 'web';
  const headerIsSticky =
    newHeaderEnabled && !(isVisitor && Platform.OS === 'ios');

  const linkPadding = isMobile
    ? LINK_PADDING_MOBILE[viewState]
    : LINK_PADDING[viewState];
  const contentPadding = isMobile ? CONTENT_PADDING_MOBILE : CONTENT_PADDING;
  const contentPaddingTop = Platform.select({
    web: 12,
    default: insets.top,
  });
  const contentPaddingHorizontal = useMemo(() => {
    if (!width) {
      return contentPadding;
    }

    const _contentPaddingHorizontal =
      Math.max(
        (width - LINK_CONTAINER_MAX_WIDTH) / 2 + contentPadding,
        contentPadding,
      ) - linkPadding;

    // There's an issue in MasonryFlashList that causes the last column not to render when
    // data changes. If the data change causes less columns to be rendered, when the data
    // is reset and all the columns have data again, FlashList fails to render the last column.
    // But this only happens when this paddingHorizontal is set to a specific number. We calculate
    // this number by evaluating the width of the container, the width of the screen, and the
    // container padding. It seems that setting the number to the exact amount to properly center
    // the content results in FlashList determining that the column shouldn't be rendered (whether
    // that's due to visibility stuff, I'm not sure). Modifying this number by 1 seems to fix
    // it. Why, I have no idea.
    return _contentPaddingHorizontal + 1;
  }, [contentPadding, width, linkPadding]);

  const containerWidth = width - contentPaddingHorizontal * 2;

  // Callbacks
  const [columns, columnWidth] = useMemo(() => {
    if (!width) {
      return [0, 0, 0];
    }

    const layoutWidth = Math.min(width, LINK_CONTAINER_MAX_WIDTH);
    const _columns = width
      ? Math.floor(layoutWidth / LINK_WIDTHS[viewState]) || 1
      : 0;

    // The width of the container that contains the links
    let linkContainerWidth = layoutWidth - contentPadding * 2;

    // Container width after considering padding between items
    linkContainerWidth -= (_columns - 1) * (linkPadding * 2);

    // Divide available space between the columns
    const _columnWidth = Math.ceil(linkContainerWidth / _columns);

    return [_columns, _columnWidth, linkContainerWidth];
  }, [width, contentPadding, linkPadding, viewState]);

  const overrideItemLayout = useCallback(
    (layout: { span?: number; size?: number }, item: unknown) => {
      /*
       * NOTE: The height give here is INCLUSIVE of the padding given to each item. It's the height of each
       * item from the POV of flashlist.
       */
      const entities = resourceStateRef?.current?.entities;

      if (!entities || !columnWidth) {
        layout.size = 0;
        return;
      }

      if (!entities.link || !entities.link_data || !entities.stuff) {
        return;
      }

      if (item === 'tips') {
        // This is the default height of the onboarding container, assuming
        // 2 lines of text on the first onboarding card
        const stuff = entities.stuff[item as string];

        if (!stuff) {
          return;
        }
        const peaks = Math.min(stuff.relationships.tips.data.length - 1, 2);
        layout.size = TIP_HEIGHT + peaks * TIP_PEEK + contentPadding;
        return;
      }

      const link = entities.link[item as string];

      if (!link) {
        return;
      }

      const linkData = entities.link_data[link.relationships.link_data.data.id];

      if (!linkData) {
        return;
      }

      const linkDataHeight = getLinkDataHeight({
        columnWidth,
        thumbnailWidth: linkData.attributes.thumbnail_width,
        thumbnailHeight: linkData.attributes.thumbnail_height,
        linkPadding,
        title: linkData.attributes.title,
      });

      layout.size = linkDataHeight;
    },
    [columnWidth, linkPadding, resourceStateRef, contentPadding],
  );

  // Set the estimated list size so the ScrollView doesn't have to render empty first to
  // get the item size.
  let estimatedListSize: { width: number; height: number } | undefined = {
    // I have no idea why, but adding 1 avoids the bug where links aren't rendered.
    width: width + 1,
    height,
  };

  // The width/height are 0 on the server.
  // On dev, for some reason having this causes the links not to render.
  if (global.__IS_SERVER__ || process.env.NODE_ENV === 'development') {
    estimatedListSize = undefined;
  }

  return {
    columns,
    columnWidth,
    contentPaddingTop,
    contentPaddingHorizontal,
    contentInnerPaddingHorizontal: THUMBNAIL_PADDING / 2,
    containerWidth,
    containerHeight: height,
    overrideItemLayout,
    headerVisible,
    headerIsSticky,
    estimatedListSize,
    linkPadding,
  };
}

export default useLayoutInfo;
