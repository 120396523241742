import React from 'react';
import BlurView from '../BlurView';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import { StyleSheet } from 'react-native';
import Text, { TextType } from '../Text';
import Toast from 'app/hooks/use-toast';
import Button, { ButtonType } from '../Button';
import Close from '../Icons/Close';

interface Props {
  children: React.ReactNode;
  title?: string;
  onClose?: () => void;
}

function PageFormSheet({ children, title, onClose }: Props) {
  return (
    <ThemeView
      lightStyle={styles.appContainerLight}
      darkStyle={styles.appContainerDark}
    >
      <BlurView amount={24} style={styles.formSheetNative}>
        <ThemeView
          lightStyle={styles.headerFormSheetLight}
          darkStyle={styles.headerFormSheetDark}
        >
          {title && (
            <Text type={TextType.title2} style={styles.title}>
              {title}
            </Text>
          )}
          <Button
            type={ButtonType.secondary}
            Icon={Close}
            onPress={onClose}
            size="xs"
            shadow={false}
            rounded
          />
        </ThemeView>
        {children}
        <Toast portalHostName="modal" />
      </BlurView>
    </ThemeView>
  );
}

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
  },
  ...makeThemeStyle(
    'appContainer',
    {
      flex: 1,
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  formSheetNative: {
    paddingHorizontal: 16,
    flexGrow: 1,
  },
  ...makeThemeStyle(
    'headerFormSheet',
    {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 24,
      paddingTop: 16,
    },
    {},
  ),
});

export default PageFormSheet;
