import { measure } from 'app/measure';
import { useCallback, useRef } from 'react';
import { Platform, View, ViewStyle, useWindowDimensions } from 'react-native';
import {
  Extrapolation,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

const DEBUG_ANIMATIONS = false;

export const defaultAnimationConfig = Platform.select({
  ios: {
    stiffness: 300,
    mass: DEBUG_ANIMATIONS ? 100 : 1,
    damping: DEBUG_ANIMATIONS ? 300 : 25,
    restDisplacementThreshold: 0.001,
    restSpeedThreshold: 0.01,
  },
  default: {
    stiffness: 700,
    mass: DEBUG_ANIMATIONS ? 100 : 1,
    damping: DEBUG_ANIMATIONS ? 300 : 200,
    restDisplacementThreshold: 0.001,
    restSpeedThreshold: 0.01,
  },
});

export interface Transform {
  expandedWidth: number;
  expandedHeight: number;
  collapsedWidth: number;
  collapsedHeight: number;
  deltaX: number;
  deltaY: number;
  progress: number;
  scale: number;
}

const initialTransform = {
  progress: 0,
  expandedWidth: 0,
  expandedHeight: 0,
  deltaX: 0,
  deltaY: 0,
  collapsedWidth: 0,
  collapsedHeight: 0,
  scale: 1,
};

/**
 * Handles expanding an element from a collapsed state, to a portal-rendered expanded state.
 * It returns a collapsed and expanded ref, along with collapsed and expanded animated styles,
 * that should be attached the appropriate views. The style and the ref must be on the same
 * view.
 *
 * @param type - either `blend` or `onTop`. If blend, on open, it'll blend the collapsed with
 * the expanded state. Otherwise, the expanded is immediately rendered on top of the collapsed
 * on open.
 * @param scaleType - either `transform` or `dimensions`.
 * @param animationConfig - an animation config passed to withSpring.
 */
function useExpand({
  scaleType = 'transform',
  type = 'blend',
  toScale = 1,
  animationConfig = defaultAnimationConfig,
}: {
  scaleType?: 'dimensions' | 'transform' | 'none';
  type?: 'blend' | 'onTop';
  toScale?: number;
  animationConfig?: typeof defaultAnimationConfig;
} = {}) {
  const collapsedRef = useRef<View>(null);
  const expandedRef = useRef<View>(null);
  const windowDimensions = useWindowDimensions();

  const transform = useSharedValue<Transform>(initialTransform);

  const close = useCallback(
    async (callback?: () => void) => {
      const { pageX: expandedPageX, pageY: expandedPageY } = await measure(
        expandedRef,
      );

      const { pageX: collapsedPageX, pageY: collapsedPageY } = await measure(
        collapsedRef,
      );

      const to = {
        ...transform.value,
        progress: 0,
        scale: 1,
        // The collapsed position will have a transform applied to it, so we need to undo that, as
        // we want to calculate the delta needed to get back to the collapsed beginning state, not
        // where it is now.
        deltaX: collapsedPageX - expandedPageX + transform.value.deltaX,
        deltaY: collapsedPageY - expandedPageY + transform.value.deltaY,
      };

      // Animate to the closed state
      transform.value = withSpring(to, animationConfig, () => {
        if (callback) {
          runOnJS(callback)();
        }

        // Reset them when the animation has finished
        transform.value = initialTransform;
      });
    },
    [transform, collapsedRef, expandedRef, animationConfig],
  );

  const open = useCallback(async () => {
    let {
      pageX: expandedPageX,
      pageY: expandedPageY,
      width: expandedWidth,
      height: expandedHeight,
    } = await measure(expandedRef);

    // Reverse the scale that exists on the scaled measurements, so we can
    // apply the new layout to the collapsed with the new scale.
    let {
      pageX: collapsedPageX,
      pageY: collapsedPageY,
      width: collapsedWidth,
      height: collapsedHeight,
    } = await measure(collapsedRef, transform.value.scale);

    const from = {
      ...transform.value,
      expandedWidth,
      expandedHeight,
      collapsedWidth,
      collapsedHeight,
      deltaX: collapsedPageX - expandedPageX,
      deltaY: collapsedPageY - expandedPageY,
    };

    let newScale = toScale;
    if (collapsedWidth * toScale > windowDimensions.width - 32) {
      newScale = (windowDimensions.width - 32) / collapsedWidth;
    }

    const to = {
      ...from,
      scale: newScale,
      progress: 1,
    };

    // Reset to collapsed current position
    transform.value = from;

    // Animate to the open state
    transform.value = withSpring(to, animationConfig);
  }, [
    transform,
    expandedRef,
    collapsedRef,
    toScale,
    animationConfig,
    windowDimensions.width,
  ]);

  const expandedStyle = useAnimatedStyle(() => {
    if (!transform.value || !transform.value.progress) {
      return {
        opacity: 0,
      };
    }

    const style: ViewStyle = {};

    // The delta is expressed as a negative value to get the expanded view *on top*
    // of the collapsed view. It then animates to 0 to get it back to its expanded state.
    const transformStyle: ViewStyle['transform'] = [
      {
        translateX: interpolate(
          transform.value.progress,
          [0, 1],
          [transform.value.deltaX, 0],
        ),
      },
      {
        translateY: interpolate(
          transform.value.progress,
          [0, 1],
          [transform.value.deltaY, 0],
        ),
      },
    ];

    if (type === 'blend') {
      // When blending, we animate the expanded view at 50% animation
      style.opacity = interpolate(transform.value.progress, [0, 0.5], [0, 1]);
    } else {
      // If it's onTop, then we immediately render as soon as the animation starts
      style.opacity = transform.value.progress ? 1 : 0;
    }

    if (scaleType === 'transform') {
      // The scale is animated in the open/close functions, so we just apply it.
      transformStyle.push({
        scale: transform.value.scale,
      });
    } else if (scaleType === 'dimensions') {
      // If we scae dimensions, then do so via width
      style.width = interpolate(
        transform.value.progress,
        [0, 1],
        [transform.value.collapsedWidth, transform.value.expandedWidth],
      );
    }

    style.transform = transformStyle;

    return style;
  }, [transform, scaleType, type]);

  const collapsedStyle = useAnimatedStyle(() => {
    if (!transform.value) {
      return {
        opacity: 0,
      };
    }

    const style: ViewStyle = {};

    // The delta is expressed as a negative value to get the expanded view *on top*
    // of the collapsed view, so we negate that here.
    const transformStyle: ViewStyle['transform'] = [
      {
        translateX: interpolate(
          transform.value.progress,
          [0, 1],
          [0, -transform.value.deltaX],
        ),
      },
      {
        translateY: interpolate(
          transform.value.progress,
          [0, 1],
          [0, -transform.value.deltaY],
        ),
      },
    ];

    if (type === 'blend') {
      // When blending, we animate the collapsed view to fade out starting at 50% animation
      style.opacity = interpolate(transform.value.progress, [0.5, 1], [1, 0], {
        extrapolateLeft: Extrapolation.CLAMP,
      });
    } else {
      // When onTop, we immediately stop rendering when the animation starts.
      style.opacity = transform.value.progress ? 0 : 1;
    }

    if (scaleType === 'transform') {
      transformStyle.push({
        scale: transform.value.scale,
      });
    } else if (scaleType === 'dimensions' && transform.value.collapsedWidth) {
      style.width = interpolate(
        transform.value.progress,
        [0, 1],
        [transform.value.collapsedWidth, transform.value.expandedWidth],
      );
    }

    style.transform = transformStyle;

    return style;
  }, [transform, scaleType, type]);

  return {
    open,
    close,
    collapsedStyle,
    expandedStyle,
    transform,
    collapsedRef,
    expandedRef,
  };
}

export default useExpand;
