import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const PlaneOutline = ({ width = 19, height = 19, color }: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 19 19" fill="none">
      <Path
        d="M11.3547 19C12.0076 19 12.4702 18.4392 12.8057 17.5708L18.7461 2.09363C18.9093 1.67753 19 1.30666 19 0.999104C19 0.411135 18.6372 0.0493164 18.0477 0.0493164C17.7394 0.0493164 17.3675 0.139767 16.9503 0.302591L1.35131 6.26369C0.589499 6.55315 0 7.01447 0 7.67482C0 8.50701 0.634844 8.78743 1.50549 9.04973L8.05342 10.9674L9.958 17.417C10.2301 18.3306 10.5112 19 11.3547 19ZM8.46157 9.59252L2.20382 7.68385C2.05871 7.63862 2.01336 7.60242 2.01336 7.53912C2.01336 7.47583 2.04964 7.43059 2.18568 7.37633L14.4473 2.74492C15.1728 2.47355 15.8711 2.11172 16.5422 1.80417C15.9437 2.29263 15.2 2.87156 14.7012 3.36907L8.46157 9.59252ZM11.4998 17.0099C11.4272 17.0099 11.391 16.9466 11.3456 16.8019L9.43197 10.5604L15.6716 4.33696C16.1613 3.84849 16.769 3.08866 17.2497 2.47355C16.9413 3.16102 16.5695 3.85754 16.2974 4.59024L11.6539 16.8199C11.5995 16.9556 11.5632 17.0099 11.4998 17.0099Z"
        fill={colorToUse || 'url(#paint0_linear_530_87932)'}
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_530_87932"
          x1="13.5596"
          y1="23.1116"
          x2="11.886"
          y2="-17.3373"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#1088FC" />
          <Stop offset="1" stopColor="#46B0FB" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default PlaneOutline;
