import React, { ComponentProps } from 'react';
import Pressable from './Pressable';
import { ViewProps, useColorScheme } from 'react-native';
import EllipsisCircle from './Icons/EllipsisCircle';
import BlurView from './BlurView';

interface Props extends ComponentProps<typeof Pressable> {
  width?: number;
  height?: number;
  circleStyle?: ViewProps['style'];
}

const EllipsisButton = ({
  width = 20,
  height = 20,
  circleStyle,
  ...props
}: Props) => {
  const colorScheme = useColorScheme();

  return (
    <Pressable
      hitSlop={{
        top: 8,
        left: 8,
        right: 8,
        bottom: 8,
      }}
      {...props}
    >
      <BlurView amount={4} style={{ width, height, borderRadius: width / 2 }}>
        <EllipsisCircle
          width={width}
          height={height}
          style={circleStyle}
          // color={colorScheme === 'dark' ? 'gray12' : 'gray9'}
        />
      </BlurView>
    </Pressable>
  );
};

export default EllipsisButton;
