import { useEffect, useState } from 'react';

const useSelectLinks = () => {
  const [selectedLinkIds, setSelectedLinkIds] = useState(new Set<string>());
  const [isSelecting, setIsSelecting] = useState(false);

  useEffect(() => {
    if (!isSelecting) {
      setSelectedLinkIds(new Set());
    }
  }, [isSelecting]);

  return {
    selectedLinkIds,
    setSelectedLinkIds,
    isSelecting,
    setIsSelecting,
  };
};

export default useSelectLinks;
