import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const CoolStuffDesktop = ({
  width = 30,
  height = 30,
  color = 'white',
}: Props) => {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 30 30" fill="none">
      <Path
        d="M3.084 24.9124H26.916C28.968 24.9124 30 23.6669 30 21.0555V3.85693C30 1.24562 28.968 0 26.916 0H3.084C1.044 0 0 1.24562 0 3.85693V21.0555C0 23.6669 1.044 24.9124 3.084 24.9124ZM8.964 30H21.036C21.564 30 22.008 29.4597 22.008 28.7843C22.008 28.109 21.564 27.5687 21.036 27.5687H8.964C8.43599 27.5687 7.992 28.109 7.992 28.7843C7.992 29.4597 8.43599 30 8.964 30Z"
        fill="#3C3C43"
        fillOpacity="0.3"
      />
      <Circle cx="21.7506" cy="12.3287" r="3.32868" fill={colorToUse} />
      <Circle cx="14.9996" cy="12.3287" r="3.32868" fill={colorToUse} />
      <Circle cx="8.25055" cy="12.3287" r="3.32868" fill={colorToUse} />
    </Svg>
  );
};

export default CoolStuffDesktop;
