import { useResource, useUpdate } from 'app/hooks/use-resource/use-resource';
import React, { useCallback, useEffect, useState } from 'react';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';
import Pressable from '../Pressable';
import { StyleSheet } from 'react-native';
import Text, { TextType } from '../Text';
import { light } from 'app/constants/colors';
import Image from '../Image';
import LinearGradient from '../LinearGradient';
import ContextMenu from '../ContextMenu/ContextMenu';
import { DropdownItem } from '../Dropdown';
import HoverView from '../HoverView';
import Trash from '../Icons/Trash';
import { ShadowType } from '../ShadowView';
import useIsTouchDevice from 'app/hooks/use-is-touch-device';
import {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Ellipsis from '../Icons/Ellipsis';

const hoverConfig = {
  damping: 20,
  mass: 1,
  stiffness: 600,
};

const SmartSearch = ({
  smartSearchId,
  onChangeQuery,
}: {
  smartSearchId: string;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
}) => {
  const { smartSearch } = useResource('smart_search', smartSearchId);
  const [isHovered, setIsHovered] = useState(false);
  const updateSmartSearch = useUpdate('smart_search');
  const isTouchDevice = useIsTouchDevice();
  const isHoveredValue = useSharedValue(isTouchDevice ? 1 : 0);

  const ellipsisStyle = useAnimatedStyle(
    () => ({
      opacity: isHoveredValue.value,
    }),
    [isHoveredValue],
  );

  useEffect(() => {
    isHoveredValue.value = withSpring(isHovered ? 1 : 0, hoverConfig);
  }, [isHovered, isHoveredValue]);

  const onPress = useCallback(() => {
    if (!smartSearch) {
      return;
    }

    const query: { [index: string]: string } = {
      'filter[link.search]': smartSearch.attributes.search,
    };

    const reactionLove = smartSearch.attributes.reaction_love;
    const reactionFlag = smartSearch.attributes.reaction_flag;
    const reactionEmphasis = smartSearch.attributes.reaction_emphasis;

    if (reactionLove !== undefined) {
      query['filter[link.reaction_love]'] = String(reactionLove);
    }
    if (reactionFlag !== undefined) {
      query['filter[link.reaction_flag]'] = String(reactionFlag);
    }
    if (reactionEmphasis !== undefined) {
      query['filter[link.reaction_emphasis]'] = String(reactionEmphasis);
    }

    onChangeQuery(query);
  }, [smartSearch, onChangeQuery]);

  const onRemove = useCallback(() => {
    updateSmartSearch(
      smartSearchId,
      {
        id: smartSearchId,
        type: 'smart_search',
        attributes: {
          hidden: true,
        },
      },
      {
        invalidateLists: ['explorable'],
      },
    );
  }, [smartSearchId, updateSmartSearch]);

  if (!smartSearch) {
    return null;
  }

  return (
    <ContextMenu
      actions={[
        <DropdownItem
          label="Remove"
          renderIcon={(isActive) =>
            isActive ? <Trash color="white" /> : <Trash color="red9" />
          }
          last
          textColor="red9"
          activeTextColor="white"
          activeBackgroundColor="red9"
          onPress={onRemove}
          key="remove"
        />,
      ]}
      onPress={onPress}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={(event) => {
        if (event.type !== 'pointerleave') {
          return;
        }
        setIsHovered(false);
      }}
    >
      {({ open }) => (
        <HoverView
          hovered={isHovered}
          shadowEnabled={isHovered}
          shadowType={ShadowType.smallLight}
          style={styles.container}
          borderRadius={15}
          translateZ={7}
          parallaxEnabled={false}
        >
          <Image
            source={smartSearch.attributes.thumbnail}
            style={[StyleSheet.absoluteFillObject, styles.thumbnail]}
            contentPosition="top center"
          />
          <LinearGradient
            colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0.40)', 'rgba(0,0,0,0.47)']}
            locations={[0.5, 0.75, 1]}
            style={[StyleSheet.absoluteFill, styles.gradient]}
          />
          <Text
            type={TextType.bodyEmphasized}
            color="white"
            style={styles.title}
          >
            {smartSearch.attributes.title}
          </Text>
          <Pressable onPress={open} style={[styles.ellipsis, ellipsisStyle]}>
            <Ellipsis color="white" />
          </Pressable>
        </HoverView>
      )}
    </ContextMenu>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 144,
    height: 144,
    justifyContent: 'flex-end',
    backgroundColor: light.BackgroundGray,
    borderRadius: 13,
    padding: 12,
  },
  thumbnail: {
    zIndex: -1,
    borderRadius: 13,
    borderWidth: 1,
    borderColor: light.BackgroundGray,
  },
  gradient: {
    borderRadius: 13,
    zIndex: -1,
  },
  title: {
    textShadowColor: 'rgba(0, 0, 0, 0.25)',

    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 1,
  },
  ellipsis: {
    padding: 8,
    margin: -8,
    top: 16,
    right: 12,
    position: 'absolute',
    zIndex: 10,
  },
});

export default SmartSearch;
