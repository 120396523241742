// Polyfills the library that exists within RN, but not on web.
import 'setimmediate';

// window._getAnimationTimestamp = () => performance.now();

import { AppRegistry } from 'react-native';
import App from './app/components/App';
import config from './app/config';
import { name as appName } from './app.json';
import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.sentryDsn,
    tunnel: '/tunnel',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/api\.coolstuff\.app/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

enableExperimentalWebImplementation(true);

AppRegistry.registerComponent(appName, () => App);

const rootTag = document.getElementById('root');
AppRegistry.runApplication(appName, {
  rootTag,
  // hydrate: process.env.NODE_ENV === 'production',
});
