import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import Close from './Icons/Close';
import Pressable from './Pressable';
import { makeThemeStyle } from './ThemeView';

interface Props {
  onPress: () => void;
  style?: ViewStyle;
}

const CloseButton = ({ onPress, style }: Props) => (
  <Pressable
    onPress={onPress}
    darkStyle={styles.buttonDark}
    lightStyle={styles.buttonLight}
    style={style}
  >
    <Close color="white" />
  </Pressable>
);

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'button',
    {
      borderRadius: 12,
      height: 24,
      width: 24,
      marginLeft: 4,
      alignItems: 'center',
      justifyContent: 'center',
    },
    {
      backgroundColor: 'SupportingGray',
    },
  ),
});

export default CloseButton;
