import shadows from 'app/styles/shadows';
import React, { Ref, useEffect, useMemo } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import Animated, {
  AnimateStyle,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import ThemeView from './ThemeView';
import useColorScheme from 'app/hooks/use-color-scheme';

export enum ShadowType {
  big = 'big',
  medium = 'medium',
  small = 'small',
  smallLight = 'smallLight',
  icon = 'icon',
}

interface Props extends ViewProps {
  type: ShadowType;
  enabled?: boolean;
  style?: ViewProps['style'];
  lightStyle?: ViewProps['style'];
  darkStyle?: ViewProps['style'];
  animatedStyle?: AnimateStyle<ViewProps['style']>;
  borderRadius?: number;
}

const typeToStyle = {
  [ShadowType.big]: {
    inner: shadows.bigInner,
    outer: shadows.bigOuter,
  },
  [ShadowType.medium]: {
    inner: shadows.mediumInner,
    outer: shadows.mediumOuter,
  },
  [ShadowType.small]: {
    inner: shadows.smallInner,
    outer: shadows.smallOuter,
  },
  [ShadowType.smallLight]: {
    inner: shadows.smallInnerLight,
    outer: shadows.smallOuterLight,
  },
  [ShadowType.icon]: {
    inner: shadows.iconInner,
    outer: shadows.iconOuter,
  },
};

const ShadowView = (
  { type, animatedStyle, enabled = true, borderRadius, ...props }: Props,
  ref: Ref<View>,
) => {
  const appearance = useColorScheme();
  const { inner, outer } = typeToStyle[type];

  const enabledValue = useSharedValue(enabled ? 1 : 0);

  const enabledAnimatedStyle = useAnimatedStyle(
    () => ({
      shadowOpacity: enabledValue.value,
    }),
    [enabledValue],
  );

  useEffect(() => {
    enabledValue.value = withSpring(enabled ? 1 : 0, {
      mass: 0.3,
      damping: 15,
    });
  }, [enabled, enabledValue]);

  const [outerStyle, innerStyle] = useMemo(() => {
    const _outerStyle = [props.style, outer];
    const _innerStyle = [props.style, styles.innerContainer, inner];

    if (borderRadius) {
      const borderStyle = { borderRadius };
      _outerStyle.push(borderStyle);
      _innerStyle.push(borderStyle);
    }

    if (animatedStyle) {
      _outerStyle.push(animatedStyle);
    }

    _outerStyle.push(enabledAnimatedStyle);
    _innerStyle.push(enabledAnimatedStyle);

    return [_outerStyle, _innerStyle];
  }, [
    animatedStyle,
    enabledAnimatedStyle,
    inner,
    outer,
    props.style,
    borderRadius,
  ]);

  return (
    <ThemeView {...props} style={outerStyle} ref={ref}>
      <Animated.View style={innerStyle} />
      {props.children}
    </ThemeView>
  );
};

const styles = StyleSheet.create({
  innerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: -1,
  },
});

export default React.forwardRef(ShadowView);
