import { RefObject, useCallback, useEffect, useRef } from 'react';
import { Gesture } from 'react-native-gesture-handler';
import {
  runOnJS,
  useSharedValue,
  withDelay,
  withSequence,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { ViewState } from './useLayoutInfo';
import ReactNativeHapticFeedback, {
  HapticFeedbackTypes,
} from 'react-native-haptic-feedback';
import { StorageKey } from 'app/storage';
import { useMMKVNumber } from 'react-native-mmkv';
import { ViewToken } from '@shopify/flash-list';
import useHasFeature from 'app/hooks/use-has-feature';
import { MasonryFlashListRef } from '../MasonryFlashList';

const SNAP_DISTANCE_DOWN = 0.7;
const SNAP_DISTANCE_UP = 1.3;

const animationConfig = {
  damping: 10,
  restDisplacementThreshold: 0.0001,
  restSpeedThreshold: 0.001,
};

function useViewState({
  username,
  listRef,
}: {
  username: string;
  listRef: RefObject<MasonryFlashListRef<string>>;
}) {
  const viewStateAnimatedTransform = useSharedValue({ opacity: 1, scale: 1 });
  const [viewState = ViewState.regular, setViewState] = useMMKVNumber(
    StorageKey.viewState,
  );
  const snappedUp = useSharedValue(0);
  const snappedDown = useSharedValue(0);
  const viewStatesEnabled = useHasFeature('view_states');

  const setViewStateDelayed = (_viewState: ViewState, duration: number) => {
    setTimeout(() => {
      setViewState(_viewState);
    }, duration);
  };

  const triggerHaptics = useCallback(
    (type: HapticFeedbackTypes = 'impactLight') =>
      ReactNativeHapticFeedback.trigger(type),
    [],
  );

  const viewableItemRef = useRef('');
  const onViewableItemsChanged = useCallback(
    (info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
      if (info.viewableItems.length) {
        viewableItemRef.current =
          // info.viewableItems[Math.floor(info.viewableItems.length / 4)].item;
          info.viewableItems[0].item;
      }
    },
    [],
  );

  useEffect(() => {
    const item = `${viewableItemRef.current}`;
    if (viewStatesEnabled) {
      setTimeout(() => {
        // Delay as it *seems* to improve the chance the RLF layout includes
        // the index we're trying to scrollTo. But not sure about that as even
        // when you set the delay to 1500 or more, it can still failure to find
        // the layout when scrolling, even though it should have it.
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollToItem({
              item,
              animated: true,
            });
          }
        }, 100);
      }, 0);
    }
  }, [viewState, viewStatesEnabled, listRef]);

  const pinchGesture = Gesture.Pinch()
    .enabled(viewStatesEnabled)
    .onUpdate((e) => {
      const MAX_SCALE = 1.05;
      const MIN_SCALE = 0.95;

      // Normalize value between 0 and 1, where min is 0 and max is 1
      const normalizedValue = (e.scale - MIN_SCALE) / (MAX_SCALE - MIN_SCALE);

      // Apply the tanh function to create a damping effect
      const dampedValue = Math.tanh(normalizedValue * 0.3 - 0.15);

      // Rescale the damped value back to the min-max range
      const newScale =
        MIN_SCALE + ((dampedValue + 1) / 2) * (MAX_SCALE - MIN_SCALE);

      viewStateAnimatedTransform.value = {
        scale: newScale,
        opacity: 1,
      };

      const scale = e.scale;

      if (scale < SNAP_DISTANCE_DOWN && snappedUp.value === 0) {
        snappedUp.value = 1;
        runOnJS(triggerHaptics)('impactLight');
      } else if (
        scale < 1 &&
        scale >= SNAP_DISTANCE_DOWN &&
        snappedUp.value === 1
      ) {
        snappedUp.value = 0;
        // runOnJS(triggerHaptics)('impactLight');
      } else if (scale > SNAP_DISTANCE_UP && snappedDown.value === 0) {
        snappedDown.value = 1;
        runOnJS(triggerHaptics)('impactLight');
      } else if (
        scale > 1 &&
        scale <= SNAP_DISTANCE_UP &&
        snappedDown.value === 1
      ) {
        snappedDown.value = 0;
        // runOnJS(triggerHaptics)('impactLight');
      }
    })
    .onEnd((e) => {
      let newViewState: ViewState | null = null;
      if (e.scale < 0.8) {
        newViewState = ViewState.small;
      } else if (e.scale > 1.2) {
        newViewState = ViewState.regular;
      }

      if (newViewState !== null && newViewState !== viewState) {
        runOnJS(setViewStateDelayed)(newViewState, 250);
        viewStateAnimatedTransform.value = withSequence(
          withTiming(
            {
              scale: viewStateAnimatedTransform.value.scale,
              opacity: 0,
            },
            {
              duration: 300,
            },
          ),
          withDelay(
            600,

            withSpring(
              {
                scale: 1,
                opacity: 1,
              },
              animationConfig,
            ),
          ),
        );
      } else {
        viewStateAnimatedTransform.value = withSpring(
          {
            scale: 1,
            opacity: 1,
          },
          animationConfig,
        );
      }

      // listRef.current?.prepareForLayoutAnimationRender();
      // // After removing the item, we can start the animation.
      // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    });

  return {
    pinchGesture,
    viewState,
    viewStateAnimatedTransform,
    onViewableItemsChanged,
  };
}

export default useViewState;
