export default {
  createLinkUrlPlaceholder: 'Enter a link…',
  createLinkUrlCancel: 'Cancel',
  createLinkUrlConfirm: 'Save',

  loginTitle: 'Log In',
  loginButtonText: 'Continue',
  signupButtonText: 'Continue',
  logoutButtonText: 'Log Out',

  registerTitle: 'Register',
  registerButtonText: 'Register',
  registerCopy:
    '**Cool Stuff** is currently in private beta for browsers. Register your email to receive an invite when an account is ready for you.',
  postRegister: 'We’ll let you know when your account is ready.',
  createAccountTerms: 'By continuing, you agree to Cool Stuff’s',
  createAccountPrivacyButtonText: 'privacy policy',
  createAccountTermsButtonText: 'terms',
  createAccountAndButtonText: 'and',
  alreadyHaveInvite: 'Already have an invite?',
  signupUsernameHelpText: 'First, claim your unique link.',
  signupUsernamePlaceholder: 'uniqueurl',
  signupEmailPlaceholder: 'Enter your email address',
  loginEmailPlaceholder: 'Email address',

  signupTitle: 'Create Account',

  magicLinkConfirmationPre: 'A verification code has been sent to ',
  magicLinkResend: 'Resend code',

  authenticationPageFooterText: '© Fun on the Internet',
  authenticationSeeOurStuff: 'Browse our stuff',

  guestHomeHeaderTitle: 'Collect Your Internet',
  guestHomeGetApp: 'Get the app',
  guestHomeTitle: 'Save the cool stuff you find on the web.',
  guestHomeSubtitle: 'No social. No ads. No algorithms.',
  guestHomeFooterAbout: 'About',
  guestHomeFooterApp: 'iPhone',
  guestHomeFooterSafari: 'Safari',
  guestHomeFooterChrome: 'Chrome',
  guestHomeFooterPrivacy: 'Privacy',
  guestHomeFooterTerms: 'Terms',
  guestHomeFooterContact: 'Contact',
  guestHomeFooterInstagram: 'Instagram',
  guestHomeFooterCopyright: 'Cool Stuff © Fun on the Internet',

  guestHomeFooterAppStoreDialogConfirmText: 'Got It',
  guestHomeFooterAppStoreDialogTitle: 'iPhone app coming soon',
  guestHomeFooterAppStoreDialogBody:
    'Create a free account to get notified when it’s available.',

  guestHomeLoginButtonText: 'Log In',
  guestHomeSignupButtonText: 'Create Account',

  homeNoLinks: 'No Stuff',
  // Will be automatically pluralized
  homeItemName: 'Item',
  homeNoMatches: 'No Matches',
  homeNoMatchesFor: 'for',

  homeAddAnother: 'Add a Link +',
  homeNoLinksAddFirst: 'Add Your First Link +',

  homeSearchPlaceholder: 'Search my stuff',
  homeSearchCancel: 'Cancel',
  homeSearchPlaceholderWithActiveSearch: 'Search more',
  homePageLinkCopied: 'Link copied to clipboard',
  homeLinkAdded: 'Link saved to your stuff',

  homePinnedSearchesPlaceholderWeb: [
    'Tap',
    'on a recent search to favorite it.',
  ],
  homePinnedSearchesPlaceholderIOS:
    'Long press on a recent search to favorite it.',

  searchCopiedToClipboard: 'Link copied to clipboard',
  searchContextMenuSearch: 'Search',
  searchContextMenuShare: 'Share',
  searchContextMenuCopy: 'Copy',
  searchContextMenuFavorite: 'Favorite',
  searchContextMenuUnfavorite: 'Unfavorite',
  searchContextMenuRemove: 'Remove',

  stuffListSelectToolbarItem: 'item',
  stuffListSelectToolbarSelected: 'selected',
  stuffListSelectToolbarAddToCollection: 'Add to Collection',
  stuffListSelectToolbarRemove: 'Remove',
  // "item" is added programatically
  stuffListSelectToolbarRemoveConfirmation: 'Are you sure you want to remove',
  stuffListSelectToolbarRemoveConfirmationCancelText: 'Cancel',
  stuffListSelectToolbarRemoveConfirmationConfirmationText: 'Remove',
  stuffListSelectToolbarRemoveConfirmationConfirmed: 'removed',

  visitorSearchPlaceholder: 'Search',

  searchSheetItemName: 'Item',
  searchSuggestionPinnedNoResults: 'No matching pinned searches.',
  searchSuggestionRecentNoResults: 'No matching recent searches.',

  settingsTitle: 'Settings',
  settingsDisplayNamePlaceholder: 'Enter name',
  settingsShareableEnabledHelpText: 'Anyone with the link can view your stuff.',
  settingsShareableDisabledHelpText:
    'Turn on to get a shareable link for your stuff.',
  settingsResetTips: 'Reset Tips',
  settingsSendFeedback: 'Send Feedback',
  settingsGetBrowserExtensions: 'Get Browser Extensions',
  settingsDisplayNameUpdatedConfirmation: 'Display name updated',
  settingsShareableEnabledConfirmation: 'Your stuff is now shareable',
  settingsShareableDisabledConfirmation: 'Your stuff is now hidden',

  settingsPasskeysTitle: 'PASSKEYS',
  settingsPasskeysHelp:
    'Passkeys are a secure way to log in to Cool Stuff without a password. They’re stored on your device.',
  settingsPasskeysCreatePasskey: 'Create Passkey',
  settingsPasskeysRemovePasskeyTitle: 'Remove Passkey',
  settingsPasskeysRemovePasskeyConfirmText: 'Remove',
  settingsPasskeysRemovePasskeyBody:
    'You won’t be able to use this passkey to log in. You can create a new passkey at any time.',
  settingsPasskeysRemovePasskeyToastMessage: 'Passkey removed',

  settingsDeleteUserTitle: 'Delete Account',
  settingsDeleteUserBody:
    'Your Cool Stuff account and all associated data will immediately be deleted. This action cannot be undone.',
  settingsDeleteUserConfirmText: 'Delete Account',
  settingsDeleteUserConfirmTextWithSubscription: 'Manage Your Plan',
  settingsDeleteUserBodyWithSubscription:
    'You have an active Cool Stuff Plus subscription. Please cancel your subscription before deleting your account.',
  settingsDeleteUserConfirmToast: 'Your account was deleted',

  settingsCustomizationTitle: 'CUSTOMIZE',
  settingsCustomizationHelpTextWithPlus: '',
  settingsCustomizationHelpTextWithoutPlus: 'Upgrade to Plus to unlock.',

  settingsAppearanceTitle: 'Appearance',
  settingsAppearanceHelpTextWithoutPlus: 'Upgrade to Plus to unlock.',

  settingsAppIconTitle: 'App Icon',
  settingsAppIconHelpTextOnWeb: 'Change your Cool Stuff icon on the app.',
  settingsAppIconHelpTextWithoutPlus: 'Upgrade to Plus to unlock.',

  settingsBetaFeaturesTitle: 'EARLY ACCESS',
  settingsBetaFeaturesHelpText: 'Turn on features currently in development.',
  settingsBetaFeaturesHelpTextWithoutPlus: 'Upgrade to Plus to unlock.',
  settingsBetaFeaturesToggleDisabled: 'disabled',
  settingsBetaFeaturesToggleEnabled: 'enabled',

  extensionsModalTitle: 'Browser Extensions',
  extensionsModalSubtitle: 'Save stuff directly from your desktop browser.',
  extensionsModalSafari: 'Safari',
  extensionsModalChrome: 'Chrome',

  linkMenuOpenText: 'Open',
  linkMenuCopyText: 'Copy',
  linkMenuShareText: 'Share',
  linkMenuAddToMyStuffText: 'Save to My Stuff',
  linkMenuRemoveTitle: 'Are you sure you want to remove item?',
  linkMenuRemoveConfirmText: 'Remove',
  linkMenuRemoveCancelText: 'Cancel',
  linkMenuShareConfirmation: 'Link copied',
  linkMenuCopyConfirmation: 'Link copied',
  linkMenuAddToMyStuffConfirmation: 'Saved to your stuff',
  linkMenuRemoveConfirmation: 'Item removed',
  linkMenuSignupToAddToStuff: 'Log in or create an account to save stuff',
  linkMenuAddToCollection: 'Add to Collection',
  linkMenuRemoveFromCollection: 'Remove from Collection',
  linkMenuRemoveFromCollectionConfirmation: 'Removed from Collection',

  tipsShowLess: 'Show less',
  tipsClear: 'Clear',
  tipsClearAll: 'Clear all',

  pageNotFoundTitle: '404 - Not Found',
  pageNotFoundSubtitle:
    'This page is nowhere to be found on the information superhighway.',

  freeSubscriptionSubtitle: 'Up to 90 items',
  freeSubscriptionDescription: `Up to 90 items
Share with anyone
Desktop and mobile`,
  plusSubscriptionSubtitle: 'Unlimited stuff + extras',
  plusSubscriptionDescription: `+ Unlimited stuff
+ Extras features
+ Ad-free forever`,
  subscriptionsManage: 'Manage Your Plan',
  subscriptionsManageInApp: 'Your plan can be managed in the app',
  subscriptionsManageOnWeb: 'Your plan can be managed on coolstuff.app',
  subscriptionsRestorePurchaseButton: 'Restore Purchase',
  subscriptionsRestorePurchaseNoPurchase: 'No previous purchase found',

  screenTitleSubscriptionManage: 'Manage Your Plan',
  screenTitleSubscriptionUpgrade: 'Upgrade Your Plan',
  screenTitleSubscriptionFinalize: 'Upgrade Your Plan',
  screenTitleSubscriptionCoolStuffPlatformUpgrade: 'Plan',

  landingMessageCoolStuffForSafari:
    'Log in or create an account to use Cool Stuff for Safari',
  landingMessageCoolStuffForChrome:
    'Log in or create an account to use Cool Stuff for Chrome',

  screenExtensionsTitle: 'Browser Extensions',
  screenExtensionsBody: 'Save stuff directly from your desktop browser.',
  screenExtensionsSafari: 'Safari',
  screenExtensionsChrome: 'Chrome',

  collectionsCreateNewCollectionTitle: 'New Collection',
  collectionsCreateNewCollectionBody: 'Enter a name for this collection.',
  collectionsCreateNewCollectionCancel: 'Cancel',
  collectionsCreateNewCollectionSave: 'Save',
  collectionsCreateNewCollectionPlaceholder: 'Title',
  screenCollectionsTitle: 'Add to Collection',
  collectionsAddedConfirmation: 'Link added to collection',
  collectionsAddedConfirmationPlural: 'Links added to collection',
  collectionsLinkAlreadyInCollection: 'Link already in collection',

  screenEditCollectionTitle: 'Edit Collection',
  screenEditCollectionTitlePlaceholder: 'Title',
  screenEditCollectionTitleCancel: 'Cancel',
  screenEditCollectionTitleSave: 'Save',
  screenEditCollectionTitleSaveConfirmation: 'Collection saved',

  collectionCopiedToClipboard: 'Link copied to clipboard',
};
