import React, { useCallback, useContext } from 'react';
import { ViewProps } from 'react-native-svg/lib/typescript/fabric/utils';
import { StyleSheet, View } from 'react-native';
import { light } from 'app/constants/colors';
import Pressable from '../Pressable';
import Checkmark from '../Icons/Checkmark';
import StuffListContext from './StuffListContext';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

interface Props {
  children?:
    | ViewProps['children']
    | ((props: { selected: boolean }) => ViewProps['children'] | undefined)
    | undefined;
  linkId: string;
  style?: ViewProps['style'];
}

const hoverConfig = {
  damping: 20,
  mass: 1,
  stiffness: 600,
};

const Selectable = ({ children: _children, linkId, style }: Props) => {
  const { selectedLinkIds, setSelectedLinkIds, isSelecting } =
    useContext(StuffListContext);
  const selected = selectedLinkIds.has(linkId);
  const animatedValue = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(
    () => ({
      opacity: selected ? 1 : animatedValue.value,
    }),
    [animatedValue, selected],
  );

  const onSelect = useCallback(() => {
    const newSelectedLinkIds = new Set([...selectedLinkIds]);
    if (selected) {
      newSelectedLinkIds.delete(linkId);
    } else {
      newSelectedLinkIds.add(linkId);
    }

    setSelectedLinkIds(newSelectedLinkIds);
  }, [linkId, selected, selectedLinkIds, setSelectedLinkIds]);

  const children =
    typeof _children === 'function' ? _children({ selected }) : _children;

  return (
    <Pressable
      onPress={onSelect}
      onHoverIn={() => {
        animatedValue.value = withSpring(0.5, hoverConfig);
      }}
      onHoverOut={() => {
        animatedValue.value = withSpring(0, hoverConfig);
      }}
      style={[styles.container, style]}
      disabled={!isSelecting}
    >
      {children}
      {isSelecting && (
        <>
          <View style={[StyleSheet.absoluteFill, styles.overlay]} />
          <Animated.View style={[styles.outline, animatedStyle]}>
            <Checkmark color="white" />
          </Animated.View>
        </>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  outline: {
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    // borderWidth: 2,
    position: 'absolute',
    right: 16,
    top: 16,
    backgroundColor: light.Primary,
  },
  overlay: {},
});

export default Selectable;
