import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native';

import Reaction, { ReactionType } from './Reaction';
import { Link } from 'app/hooks/use-resource/types';
import { useUpdate } from 'app/hooks/use-resource/use-resource';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import { dark, light } from 'app/constants/colors';

interface Props {
  link: Link;
  update: ReturnType<typeof useUpdate<'link', Link>>;
  style?: ViewProps['style'];
  expanded?: boolean;
  disabled?: boolean;
}

function Reactions({ link, update, style, expanded, disabled }: Props) {
  const updateReaction = useCallback(
    (reaction: 'reaction_emphasis' | 'reaction_love' | 'reaction_flag') => {
      update(link.id, {
        id: link.id,
        attributes: {
          [reaction]: !link.attributes[reaction],
        },
        type: 'link',
      });

      ReactNativeHapticFeedback.trigger('impactMedium');
    },
    [link.attributes, link.id, update],
  );

  const reactions: {
    type: ReactionType;
    selected: boolean;
    onSelect: () => void;
  }[] = useMemo(
    () =>
      [
        {
          selected: link.attributes.reaction_love,
          type: ReactionType.reactionLove,
          onSelect: () => updateReaction('reaction_love'),
        },
        {
          selected: link.attributes.reaction_flag,
          type: ReactionType.reactionFlag,
          onSelect: () => updateReaction('reaction_flag'),
        },
        {
          selected: link.attributes.reaction_emphasis,
          type: ReactionType.reactionEmphasis,
          onSelect: () => updateReaction('reaction_emphasis'),
        },
      ].filter(({ selected }) => selected || (expanded && !disabled)),
    [expanded, link, updateReaction, disabled],
  );

  if (disabled && reactions.length === 0) {
    return null;
  }

  return (
    <ThemeView
      style={[styles.container, expanded && styles.expandedContainer, style]}
      lightStyle={expanded && styles.expandedContainerLight}
      darkStyle={expanded && styles.expandedContainerDark}
    >
      {reactions.map(({ type, selected, onSelect }, index) => (
        <Reaction
          selected={selected}
          onSelect={onSelect}
          style={[
            // index < reactions.length - 1 ? styles.iconMargin : undefined,
            styles.reaction,
            expanded && styles.reactionExpanded,
          ]}
          type={type}
          key={index}
          shadow={!expanded}
          expanded={expanded}
          disabled={disabled}
        />
      ))}
    </ThemeView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 4,
  },
  expandedContainer: { borderRadius: 100, padding: 4 },
  expandedContainerDark: {
    backgroundColor: dark.gray6,
  },
  expandedContainerLight: {
    backgroundColor: light.SolidWhite,
  },
  reaction: {
    width: 28,
    height: 28,
  },
  reactionExpanded: {
    height: 40,
    width: 40,
  },
});

export default Reactions;
