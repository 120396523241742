import useIsMobile from 'app/hooks/use-is-mobile';
import {
  Extrapolation,
  interpolate,
  SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

export interface LinkDataTransform {
  x: number;
  y: number;
  expandedWidth: number;
  expandedHeight: number;
  collapsedWidth: number;
  collapsedHeight: number;
  progress: number;
  scale: number;
}

export default function useLinkStyles({
  expandedPosition,
  dragSnappedUp,
  dragSnappedDown,
  dragOffset,
}: {
  dragOffset: SharedValue<{ x: number; y: number }>;
  expandedPosition: SharedValue<LinkDataTransform>;
  dragSnappedUp: SharedValue<number>;
  dragSnappedDown: SharedValue<number>;
}) {
  const isMobile = useIsMobile();

  const linkDataExpandedAnimatedStyle = useAnimatedStyle(() => {
    if (!expandedPosition.value) {
      return {
        opacity: 0,
      };
    }

    return {
      opacity: interpolate(expandedPosition.value.progress, [0, 0.25], [0, 1]),
      transform: [
        {
          translateX: expandedPosition.value.x,
        },
        { translateY: expandedPosition.value.y },
        {
          translateX: -expandedPosition.value.expandedWidth / 2,
        },
        {
          translateY: -expandedPosition.value.expandedHeight / 2,
        },
        {
          scale:
            interpolate(
              expandedPosition.value.progress,
              [0, 1],
              [
                expandedPosition.value.collapsedWidth /
                  expandedPosition.value.expandedWidth,
                1,
              ],
            ) * expandedPosition.value.scale,
        },
        {
          translateX: expandedPosition.value.expandedWidth / 2,
        },
        {
          translateY: expandedPosition.value.expandedHeight / 2,
        },
      ],
    };
  }, [expandedPosition]);

  const linkDataCollapsedAnimatedStyle = useAnimatedStyle(() => {
    if (!expandedPosition.value || expandedPosition.value.progress === 0) {
      return {
        opacity: 0,
      };
    }

    return {
      transform: [
        {
          translateX: expandedPosition.value.x,
        },
        { translateY: expandedPosition.value.y },
        {
          translateX: -expandedPosition.value.collapsedWidth / 2,
        },
        {
          translateY: -expandedPosition.value.collapsedHeight / 2,
        },
        {
          scale:
            interpolate(
              expandedPosition.value.progress,
              [0, 1],
              [
                1,
                expandedPosition.value.expandedWidth /
                  expandedPosition.value.collapsedWidth,
              ],
            ) * expandedPosition.value.scale,
        },
        {
          translateX: expandedPosition.value.collapsedWidth / 2,
        },
        {
          translateY: expandedPosition.value.collapsedHeight / 2,
        },
      ],
      opacity: interpolate(
        expandedPosition.value.progress,
        [0.25, 0.75],
        [1, 0],
        {
          extrapolateLeft: Extrapolation.CLAMP,
        },
      ),
    };
  }, [expandedPosition]);

  const linkActionsStyle = useAnimatedStyle(() => {
    if (!expandedPosition.value || expandedPosition.value.progress === 0) {
      return {
        opacity: 0,
      };
    }

    const progress =
      expandedPosition.value.progress -
      dragSnappedDown.value -
      dragSnappedUp.value;

    return {
      opacity: interpolate(progress, [0, 1], [0, 1], Extrapolation.CLAMP),
      transform: [
        {
          scale: interpolate(progress, [0, 1], [0.75, 1]),
        },
        {
          translateX:
            expandedPosition.value.x +
            (isMobile
              ? 0
              : interpolate(
                  progress,
                  [0, 1],
                  [-expandedPosition.value.expandedWidth, 0],
                )),
        },
        {
          translateY:
            expandedPosition.value.progress < 1 ? expandedPosition.value.y : 0,
        },
        {
          translateY: dragOffset.value.y,
        },
      ],
    };
  }, [expandedPosition, dragSnappedDown, dragSnappedUp, dragOffset, isMobile]);

  const reactionsStyle = useAnimatedStyle(() => {
    if (!expandedPosition.value || expandedPosition.value.progress === 0) {
      return {
        opacity: 0,
      };
    }

    const progress =
      expandedPosition.value.progress -
      dragSnappedDown.value -
      dragSnappedUp.value;

    return {
      transform: [
        {
          translateY: interpolate(progress, [0, 1], [24, 0]),
        },
        { translateX: expandedPosition.value.x },
        { translateY: expandedPosition.value.y },
      ],
      opacity: interpolate(progress, [0, 1], [0, 1]),
    };
  }, [dragSnappedUp, dragSnappedDown, expandedPosition]);

  return {
    linkActionsStyle,
    linkDataExpandedAnimatedStyle,
    linkDataCollapsedAnimatedStyle,
    reactionsStyle,
  };
}
